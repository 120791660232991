import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button,Table, CardTitle,Badge, FormGroup,Modal,Form, CardHeader, ModalHeader, CardFooter, ModalBody, Label, Spinner, CardText, UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { crypto_key, order_status } from '../../config';
import { connect } from 'react-redux';
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import {Link} from "react-router-dom";
import wordpressIcon from '../../assets/images/wordpress-icon.png';
import shopifyIcon from '../../assets/images/shopify-icon.png';
import customIcon from '../../assets/images/custom-icon.png';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2'
import { copyToClipBoard, errorHandling } from '../../utlis';
import { Copy } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import Status from '../Status';
// import sha256 from 'crypto-js/sha256';
// import CryptoJS from 'crypto-js'
// import AES from 'crypto-js/aes'

const ChannelIntegration = (props) => {
  const [loader, setLoader] = useState(false);
  const [addShopify, setAddShopify] = useState(false);
  const [addWordPress, setAddWordPress] = useState(false);
  const [addCustom, setAddCustom] = useState(false);
  const [payLoadModal, setPayLoadModal] = useState(false);
  const [brandList, setBrandList] = useState({});
  const [intrigatedBrandList, setIntrigatedBrandList] = useState({});
  const [currentCount,setCurrentCount] = useState(1);
  const [loadertable, setLoadertable] = useState(false);
  
  const [showResChannel, setResChannel]  = useState("1") ;
   
  const handleChannelClick = (e) => {
	     setResChannel(e.currentTarget.dataset.id);
  }  
 
  const [showShopifyTab, setShopifyTab]  = useState("1") ;
  const inst_shopify = (e) => {
	  setShopifyTab(e.currentTarget.dataset.id) ;
  }
  
  const [showWpressTab, setWpressTab]  = useState("1") ;
  const inst_wpress = (e) => {
	  setWpressTab(e.currentTarget.dataset.id);
  }
  
  const [showCustmTab, setCustmTab]  = useState("c1") ;
  const inst_custm = (e) => {
	  
  }
  


  const brandPointListing = () =>{
    setLoader(true);
  let data = {}
   if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    data = {"merchant_id":props.user[0].id}
   }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
    }).then(function (response) {
    setLoader(false);
    if(response.data.fatal !== true){
          setBrandList(response.data)
        }
      }).catch(function (response) {
        setLoader(false);
        errorHandling(response,"reload")
      });
  }
  const intrigatedBrandListing = () =>{
    setLoadertable(true);
  let data = {}
   if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    data = {"merchant_id":props.user[0].id, "is_channel" :1}
   }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
    })
      .then(function (response) {
    setLoadertable(false);
    if(response.data.fatal !== true){
      setIntrigatedBrandList(response.data)
        }
      })
      .catch(function (response) {
    setLoadertable(false);
    errorHandling(response,"reload")
      });
  }
  useEffect(() => {
    intrigatedBrandListing();
    setCurrentCount(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const show_toggleShopify = (e) => {
	  console.log(e) ;
	  // alert('here in shopify') ; 
  }
  
  const toggleShopify = () => {
    setAddShopify(!addShopify);
    brandPointListing();
  }
  const toggleWordpress = () => {
    brandPointListing();
    setAddWordPress(!addWordPress);
  }
  const toggleCustom = () => {
    brandPointListing();
    setAddCustom(!addCustom);
  }
  const customPayLoad = () => {
    setPayLoadModal(!payLoadModal);
  }

  const opt_orderStatus = [];
  for (const property in order_status) {
    let tempObj = {label: `${order_status[property]}`, value: property };
    opt_orderStatus.push(tempObj);
  }

  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }


 const { register:shopify, handleSubmit:shopifySubmit,formState: { errors }, setValue} = useForm();
 const { register:wordpress, handleSubmit:wordpressSubmit,formState: { errors: errorswordpress }, setValue:wordpressurl} = useForm();
 const { register:custom, handleSubmit:customSubmit,formState: { errors: errorscustom }, setValue:customurl} = useForm();
 const onShopifySubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'shopify';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddShopify(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      errorHandling(err,"")
    });
 }
 const onWordpressSubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'wordpress';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddWordPress(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      Swal.fire({title:'Error', icon:'error', text:err})
    });
 }
 const onCustomSubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    // data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'custom';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddCustom(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      Swal.fire({title:'Error', icon:'error', text:err})
    });
 }
//  console.log('enc',sha256("Message",crypto_key).toString());
 const getBrand = (brandId)=>{
   console.log('brand', brandId)
    if(brandId !== ''){
      let user_id = props.user[0].id;
      setValue("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${brandId}/${user_id}`)
    }else{
      setValue("webhook_url", '')
    }
  }
 const getBrandwordpress = (brandId)=>{
    if(brandId !== ''){
      let user_id = props.user[0].id;
      wordpressurl("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${brandId}/${user_id}`)
    }else{
      wordpressurl("webhook_url", '')
    }
  }
 const getBrandcustom = (brandId)=>{
    if(brandId !== ''){
      let user_id = props.user[0].id;
      customurl("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${brandId}/${user_id}`)
    }else{
      customurl("webhook_url", '')
    }
  }
  let pLoad = {
      "order_number":121212,
      "tags":"test",
      "total_weight_in_kgs":0.5,
      "customer_ip":"192.168.05.02",
      "delivery_type":"cod or prepaid",
      "order_date":"2022-09-01", 
      "order_time":"18:00:00",
          "customer_details":{
              "name":"Joe",
              "address1":"34 2nd Street",
              "address2":"A Block",
              "phone":"9898989898",
              "city":"New Delhi",
              "zip":"110019",
              "state":"Delhi",
              "email":"abc@gmail.com"
          },
          "product_details":{
              "product_category":"Electronics",
              "product_code":"IPHONE12",
              "product_name":"IPHONE",
              "product_invoice_value":"1800",
              "product_cod_value":"1500",
              "product_qty":"1",
              "length_in_cms":"15",
              "breadth_in_cms":"25",
              "height_in_cms":"25"
          }
      }
 // copy text
 const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}
  return (
    <div className="content-body overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <ToastContainer className="copied-con"/>
      <Row>
	  
       <Col sm={12}>
         <div className="p-0 my-3 channelIntegration content-wrapper">
           
			<div className="page-header main-header mb-3"> 
			<h1>Channel Integration</h1>   
			<ul className="master-tabs nav nav-tabs">
			<li className="text-white nav-item">To Synchronous Your Order From Third Party Apps</li>	
			</ul>	
            </div>    
            
            <Row>
            <Col sm={4}>
              <Card className="shadow border-0">
                <CardBody className="py-0">
                  <div>
                    <Row className="mt-4">
                      <Col sm={12}>
                        <div className="integrate-box mb-4"> 
                            <div className="d-flex align-items-center">
                                <Card className={"mb-0 mx-auto w-100 " +(showResChannel==1 ? 'left_active' : '')}   onClick={handleChannelClick} data-id="1">
                                    <CardBody className="d-flex flex-wrap align-items-center">
                                     <ul className="leftsidebar_channels"> 
									  <li>
									  <img src={shopifyIcon} alt="img" className="courier_img img-fluid" width="50"/>
									  <span> Shopify </span>
									  </li>
									  </ul>
                                    </CardBody> 
                                </Card>
                            </div>     
                        </div>   
                        <Modal isOpen={addShopify} toggle={addShopify} scrollable={true}>
                          <ModalHeader toggle={toggleShopify}>Shopify Webhook</ModalHeader>
                          <ModalBody>
                            <Form onSubmit={shopifySubmit(onShopifySubmit)}>
                              <Row className="mx-0 justify-content-center addModal">                            
                                <Col sm={12}>
                                    <FormGroup>
                                      <Label for="brand">Choose Brand</Label>
                                      <div className="position-relative">
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...shopify("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrand(e.target.value)}>
                                          <option value="">Choose Brand</option>
                                          {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                            <>
                                            <option value={item.id}>{item.brand_name}</option>
                                            </>
                                          ))}
                                        </select>
                                      </div>
                                      {errors.brand && <span className="text-danger d-block error">{errors.brand.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                      <Label for="shopify_webhook">Shopify Webhook</Label>
                                      <input type="text" {...shopify("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="shopify_webhook" readonly="true" />
                                      {/* {errors.shopify_webhook && <span className="text-danger d-block error">{errors.shopify_webhook.message}</span>} */}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} className="text-right">
                                  <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                                </Col>
                              </Row>
                            </Form>  
                          </ModalBody>
                        </Modal>                       
                      </Col>
                      <Col sm={12}>
                        <div className="integrate-box mb-4"> 
                            <div className="d-flex align-items-center">
                                <Card className={"mb-0 mx-auto w-100 " +(showResChannel==2 ? 'left_active' : '')} onClick={handleChannelClick} data-id="2" >
								<CardBody className="d-flex flex-wrap align-items-center">
									<ul className="leftsidebar_channels"> 
									<li><img src={wordpressIcon} alt="img" className="courier_img img-fluid" width="50"/><span> Wordpress </span>
									</li>
									</ul>
								</CardBody>
                                </Card>
                            </div>     
                        </div>  
                        <Modal isOpen={addWordPress} toggle={addWordPress} scrollable={true}>
                          <ModalHeader toggle={toggleWordpress}>Wordpress Web Hook</ModalHeader>
                          <ModalBody>
                            <Form onSubmit={wordpressSubmit(onWordpressSubmit)}>
                              <Row className="mx-0 justify-content-center addModal">
                              <Col sm={12}>
                                    <FormGroup>
                                      <Label for="brand">Choose Brand</Label>
                                      <div className="position-relative">
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...wordpress("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrandwordpress(e.target.value)}>
                                          <option value="">Choose Brand</option>
                                          {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                            <>
                                            <option value={item.id}>{item.brand_name}</option>
                                            </>
                                          ))}
                                        </select>
                                      </div>
                                      {errorswordpress.brand && <span className="text-danger d-block error">{errorswordpress.brand.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                      <Label for="wordpress_webhook">Word Press Webhook</Label>
                                      <input type="text" {...wordpress("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="wordpress_webhook" readonly="true" />
                                      {/* {errors.wordpress_webhook && <span className="text-danger d-block error">{errors.wordpress_webhook.message}</span>} */}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} className="text-right">
                                  <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                                </Col>
                              </Row>
                            </Form>  
                          </ModalBody>
                        </Modal>                          
                      </Col>
                      <Col sm={12}>
                        <div className="integrate-box mb-4"> 
                            <div className="d-flex align-items-center">
                                <Card className={"mb-0 mx-auto w-100 " +(showResChannel==3 ? 'left_active' : '')} onClick={handleChannelClick} data-id="3">
								 
								<CardBody className="d-flex flex-wrap align-items-center">
									<ul className="leftsidebar_channels"> 
									<li><img src={customIcon} alt="img" className="courier_img img-fluid" width="50"/><span> Custom </span>
									</li>
									</ul>
									</CardBody>
                                </Card>
                            </div>     
                        </div>  
                        <Modal isOpen={payLoadModal} toggle={payLoadModal} scrollable={true}>
                          <ModalHeader toggle={customPayLoad}>
                          <span className='ml-2 text-small'>(Use POST Method to send below Payload)</span>
                          <span className='ml-2 text-small d-block' style={{"font-size":'12px'}}>Token - Contact on techsupport@tekiesindia.com</span>
                          </ModalHeader>

                          <ModalBody>
                              <div className='d-flex'>
                                <CardText>
                                <p className='d-none'>
                                  <span onClick={() => copied(JSON.stringify(pLoad))} className="ml-1 copy-btn d-none" id={`copyspyformat`}>
                                  Copy this payload <Copy />
                                    <UncontrolledTooltip placement="bottom" target={`copyspyformat`}>
                                      Copy
                                    </UncontrolledTooltip>
                                  </span>
                                </p>
                                <pre>
                                  {JSON.stringify(pLoad, null, 2) }
                                </pre>
                              </CardText> 
                              <Table responsive borderless className="text-center customTable">
                                  <thead>
                                    <tr>
                                      <th>Data Type</th>
                                      <th>Rules</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr><td>order_number (</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                    <tr><td>Tags</td><td>Not Required</td></tr>
                                    <tr><td>Weight in kgs</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                    <tr><td>Customer IP</td><td>Not Required</td></tr>
                                    <tr><td>Delivery Type</td><td><span className='text-danger'>Required</span> (cod or prepaid)</td></tr>
                                    <tr><td>Order Date</td><td><span className='text-danger'>Required</span> (YYYY-MM-DD)</td></tr>
                                    <tr><td>Order Time</td><td><span className='text-danger'>Required</span> (H-i-s)</td></tr>
                                    <tr><td>Customer Name</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                    <tr><td>Customer Address 1</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                    <tr><td>Customer Address 2</td><td>Not Required (Varchar 255)</td></tr>
                                    <tr><td>Customer Phone</td><td><span className='text-danger'>Required</span> (10 Digits Number Only) </td> </tr>
                                    <tr><td>Customer City</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                    <tr><td>Customer Zip</td><td><span className='text-danger'>Required</span> (6 Digits Numbers Only) </td></tr>
                                    <tr><td>Customer State</td><td><span className='text-danger'>Required</span> (Varchar 100) </td></tr>
                                    <tr><td>Customer Email</td><td>Not Required (Varchar 50)</td></tr>
                                    <tr><td>Product Category</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                    <tr><td>Product Code</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                    <tr><td>Product Name</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                    <tr><td>Product Invoice Value</td><td><span className='text-danger'>Required</span> (Decimal 10,2) Float Value  </td></tr>
                                    <tr><td>Product COD Value</td><td><span className='text-danger'>Required</span> (If Order is COD) (Decimal 10,2) Float Value</td></tr>
                                    <tr><td>Product Qty</td><td><span className='text-danger'>Required</span> (INT) Integer</td></tr>
                                    <tr><td>Product Length in cm</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                    <tr><td>Product Breadth in cm</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                    <tr><td>Product Height in cm</td><td><span className='text-danger'>Required</span>(Decimal 5,2) Float Value</td></tr>
                                  </tbody>
                              </Table>
                            </div> 
                          </ModalBody>
                        </Modal>                          
                        <Modal isOpen={addCustom} toggle={addCustom} scrollable={true} >
                          <ModalHeader toggle={toggleCustom}>Wordpress Web Hook</ModalHeader>
                          <ModalBody>
                            <Form onSubmit={customSubmit(onCustomSubmit)}>
                              <Row className="mx-0 justify-content-center addModal">
                              <Col sm={12}>
                                    <FormGroup>
                                      <Label for="brand">Choose Brand</Label>
                                      <div className="position-relative">
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...custom("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrandcustom(e.target.value)}>
                                          <option value="">Choose Brand</option>
                                          {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                            <>
                                            <option value={item.id}>{item.brand_name}</option>
                                            </>
                                          ))}
                                        </select>
                                      </div>
                                      {errorscustom.brand && <span className="text-danger d-block error">{errorscustom.brand.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                      <Label for="custom_webhook">Custom Webhook</Label>
                                      <input type="text" {...custom("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="custom_webhook" readonly="true" />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} className="text-right">
                                  <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                                </Col>
                              </Row>
                            </Form>  
                          </ModalBody>
                        </Modal>                          
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
           




		   <Col sm={8}>
                <Card className="shadow">
				<CardBody className="minheight"> 
                      
				 { showResChannel == 1 &&
			        <div id="instr_shopify">
					<Card className="mb-0 mt-5 mx-auto w-100">
							<CardBody className="d-flex flex-wrap align-items-center">

							<ul className="channel_instruct ins-shopify">
							<li className={showShopifyTab==1 ? 'active' : ''} onClick={inst_shopify} data-id="1">Shopify</li> 
							<li className={showShopifyTab==2 ? 'active' : ''} onClick={inst_shopify}  data-id="2">Tekipost</li> 
							</ul>
							
							{ showShopifyTab==1 &&
							<div id="shopify_inst" className="insruction-set">
							   <ul className="channel-instructions">
								<li className="inst_head">Pre-Requiste</li>
								<li>You must have a Shopify Store.</li>
								<li>The ability to create Shopify private apps (you may not have this permission if you are not the direct owner of the store you are working with)</li>
								<li>To access the Shopify API, create a private app. Private apps are built to work exclusively for your Shopify store (unlike public apps that can be used by any Shopify store and are listed on the Shopify App store). Private apps also allow you to access your store's data directly using the Shopify API. </li>
								<li className="inst_head">Create Shopify Webhook (using Shopify Admin panel)</li> 
								<li>Click on "Settings".</li>
								<li>Click on "Notifications".</li>
								<li>Go to end of the page and find webhook section.</li>
								<li>Click "Create Webhook" button.</li>
								<li>A pop up occurs to create webhook.</li>
								<li>Select "Order Creation" from events drop down.</li>
								<li>Set "Format" as JSON.</li>
								<li>Enter "URL". This url can got from Tekipost/Channel-Integration. Click on Shopify. Popup will occur with heading of "Shopify Webhook".
								Copy the url from "Shopify Wbhook" (read only input ). This webhook url is created only for your account on Tekipost.
								Now paste the url in Shopify URL (input).</li>
								<li>Click the "Save" Button.</li>
								<li>Your can send test data from Shopify by clicing on button named "Send Test Data". This data will be recived on the url mentioned in above #8 and will be displayed in your channel orders.</li>
							 </ul>
							</div>	
							}
							
							{ showShopifyTab==2 &&
							 <div id="shopify_teki" className="insruction-set">
				<div className="api">
				<div className="api_label">Submit Order API:</div>

				<span> 
				URL:  https://api.tekipost.com/connect/order-ship,
				</span>
				<span> Method: POST </span>
				<span>Bearer Token: base64_encode(email:password) </span>
				<span>
				Params:
				&#123;
				"order_no":"11117111","customer_name":"Test 123","customer_address_1":"Test address","customer_address_2":"Address 2","customer_pincode":110020,"customer_city":"New Delhi","customer_state":"Delhi","customer_mobile_no":"9898989898","customer_alt_no":"","customer_email":"test@gmail.com","product_category":"Category","product_code":"TEst","product_name":"Product","product_qty":1,"invoice_value":1200,"cod_value":999,"weight_in_kgs":0.5,"length_in_cms":10,"breadth_in_cms":10,"height_in_cms":10,"warehouse_id":47,"movement_type":"Forward","store_id":"","courier_id":"","custom_awb_no":"","service_name":"Surface"
				&#125; 
				</span>
				<span> Response : <br/>
				&#123; "error": false, "msg": "Uploaded","order_id": 28409 &#125; 
				</span>
				</div> 

				<div className="api">
				<div className="api_label">Below are the status codes and meaning</div>
				<div className="status_codes">
				"order_no"           :  required and Unique <br/>
				"customer_name"      :  required, <br/>
				"customer_address_1" :  required, <br/>
				"customer_pincode"   :  required, valid 6 digits only <br/>
				"customer_city"      :  required, <br/>
				"customer_state"     :  required, <br/>
				"customer_mobile_no" :  required, must be 10-digit mobile number <br/>
				"cod_value"          :  if cod_value is not set or null or 0 then it will be a prepaid order.<br/>
				"product_code"       :  required,<br/>
				"product_name"       :  required,<br/>
				"product_qty"        :  required, type: integer<br/>
				"invoice_value"      :  required, type:  float,<br/>
				"weight_in_kgs"      :  required, type: float, must be > 0 <br/>
				"length_in_cms"      :  required, type: integer,<br/>
				"height_in_cms"    	 :  required, type:  integer<br/>
				"service_name"    	 :  required, either Surface or Express<br/>
				"warehouse_id"       :  required, valid integer id,<br/>
				"movement_type"    	 :  required, must be "forward" or "reverse”, <br/>
				"store_id"      	 :  not required, If you pass then valid integer <br/>
				"courier_id"    	 :  not required, If you ship specific courier then use courier_id &#123;Get from your panel under settings &#125; <br/>

				</div>
				</div>

				<div className="api">
				<div className="api_label">Below are the status codes (of an order) and meaning </div>
				<div className="status_codes">
				"1”: "Order Uploaded", <br/>
				"2”: "Order Assigned", <br/>
				"3”: "Order Failed", <br/>
				"4”: "Pickup Pending", <br/>
				"5”: "Picked", <br/>
				"6": "Intransit",  <br/>
				"7": "Out for Delivery",<br/>
				"8": "Order Cancelled",<br/>
				"9” : "Shipment Cancelled",<br/>
				"10": "Delivered",<br/>
				"11": "Delivery Failed",<br/>
				"12": "RTO Pending",<br/>
				"13": "RTO Delivered",<br/>
				"14": "Heldup",<br/>
				"15": "Lost",<br/>
				"16": "Damaged",<br/>
				"17": "Contact Customer Care",<br/>
				"18": "Arrived at Destination",<br/>
				"19": "Cancellation Requested",<br/>
				"20": "RTO Undelivered",<br/>
				"21": "Pickup Cancelled by Merchant",<br/>
				"22": "Pickup Cancelled by Courier",<br/>
				"23": "Pickup Failed",<br/>
				"24": "Auto Cancelled",<br/>
				"25": "Order Processing"<br/>
				</div>	
				</div>

				<div className="api">
				<div className="api_label">2. PULL API Tracking</div>
				<span>
				URL- https://api.tekipost.com/connect/pull-tracking
				</span>

				<span>Method - POST</span>
				<span>Token - base64_encode(yourloginemail:password)</span>
				<span>Encrypted Token (example): bW9oaXRhbmFuZEBhcmloYW50Ym9va3MuY29tOkFyaWhhbnRAMTIz</span>
				<span>Required Params - &#123; "awb_no":"75445943134" &#125;</span>

				<span> 
				Values passed to awb_no   can be separated by comma and maximum 10 values are accepted.
				</span>

				<span> For example &#123;"awb_no ": "75445943134,4535090349,7968795679"&#125; </span>

				<span>  Api Returns:  Array of order status.  Status codes of order are listed above.</span>
				</div>
				</div>	
				 }
							</CardBody>
							<CardFooter>
							<div className="d-flex justify-content-between align-items-center">
							<Button className="btn ctm-btn btn-sm mr-2" color="primary" onClick={toggleShopify}>Setup</Button><Link target="_blank" download to="/format/shopify_add_webhook.pdf"><span className='text-primary cursor-pointer'>Documentation</span></Link></div>
							</CardFooter>
					</Card>
					</div>
				 }
					
					{ showResChannel == 2 &&
					<div id="instr_wordpress">
					<Card className="mb-0 mt-5 mx-auto w-100">
					<CardBody className="d-flex flex-wrap align-items-center">
					
							<ul className="channel_instruct ins-wpress">
							<li onClick={inst_wpress} className={showWpressTab ==1 ? 'active' : ''}  data-id="1">Wordpress</li> 
							<li onClick={inst_wpress} className={showWpressTab ==2 ? 'active' : ''} data-id="2">Tekipost</li> 
							</ul>
							
							
							{ showWpressTab==1 && 
							<div id="wpress_inst" className="wpress-inst">
							 <ul className="channel-instructions">
								<li className="inst_head">Pre-Requiste</li>
								<li>You must have a Wordpres Site with WooCommerce Plugin installed and configured.</li>
								 
								<li className="inst_head">Create Webhook in WooCommerce</li> 
								<li>Click on "Settings" in WooCommerce Section in Admin panel.</li>
								<li>Click on "Advanced".</li>
								<li>In "Page Setup" section find "Webhooks" link and click on it.</li>
								<li>Click "Create Webhook" button.</li>
								<li>Give a Name to Webhook (used to Identify a webhook).</li>
								<li>Set "Status" to "Active".</li>
								<li>Select "Order Created" from "Topic" dropdown list.</li>
								<li>Enter "Delivery URL". This is the url of a file on your own server. This file is used to make API calls to tekipost API ( to send the data of order created by WooCommerce).</li>
								<li>Enter Secret Key. This is a consumer/secret key generated for Hashing/encrypt API calls.</li>
								
								<li>Select the latest API Version.</li>
								<li>Click "Save Webhook" button to save the settings.</li>
							 </ul>
							</div>	
							}
							
							{ showWpressTab ==2 && 
							    <div id="wpress_teki" className="wpress-teki">
				<div className="api">
				<div className="api_label">Submit Order API:</div>

				<span> 
				URL:  https://api.tekipost.com/connect/order-ship,
				</span>
				<span> Method: POST </span>
				<span>Bearer Token: base64_encode(email:password) </span>
				<span>
				Params:
				&#123;
				"order_no":"11117111","customer_name":"Test 123","customer_address_1":"Test address","customer_address_2":"Address 2","customer_pincode":110020,"customer_city":"New Delhi","customer_state":"Delhi","customer_mobile_no":"9898989898","customer_alt_no":"","customer_email":"test@gmail.com","product_category":"Category","product_code":"TEst","product_name":"Product","product_qty":1,"invoice_value":1200,"cod_value":999,"weight_in_kgs":0.5,"length_in_cms":10,"breadth_in_cms":10,"height_in_cms":10,"warehouse_id":47,"movement_type":"Forward","store_id":"","courier_id":"","custom_awb_no":"","service_name":"Surface"
				&#125; 
				</span>
				<span> Response : <br/>
				&#123; "error": false, "msg": "Uploaded","order_id": 28409 &#125; 
				</span>
				</div> 

				<div className="api">
				<div className="api_label">Below are the status codes and meaning</div>
				<div className="status_codes">
				"order_no"           :  required and Unique <br/>
				"customer_name"      :  required, <br/>
				"customer_address_1" :  required, <br/>
				"customer_pincode"   :  required, valid 6 digits only <br/>
				"customer_city"      :  required, <br/>
				"customer_state"     :  required, <br/>
				"customer_mobile_no" :  required, must be 10-digit mobile number <br/>
				"cod_value"          :  if cod_value is not set or null or 0 then it will be a prepaid order.<br/>
				"product_code"       :  required,<br/>
				"product_name"       :  required,<br/>
				"product_qty"        :  required, type: integer<br/>
				"invoice_value"      :  required, type:  float,<br/>
				"weight_in_kgs"      :  required, type: float, must be > 0 <br/>
				"length_in_cms"      :  required, type: integer,<br/>
				"height_in_cms"    	 :  required, type:  integer<br/>
				"service_name"    	 :  required, either Surface or Express<br/>
				"warehouse_id"       :  required, valid integer id,<br/>
				"movement_type"    	 :  required, must be "forward" or "reverse”, <br/>
				"store_id"      	 :  not required, If you pass then valid integer <br/>
				"courier_id"    	 :  not required, If you ship specific courier then use courier_id &#123; Get from your panel under settings&#125; <br/>

				</div>
				</div>

				<div className="api">
				<div className="api_label">Below are the status codes (of an order) and meaning </div>
				<div className="status_codes">
				"1”: "Order Uploaded", <br/>
				"2”: "Order Assigned", <br/>
				"3”: "Order Failed", <br/>
				"4”: "Pickup Pending", <br/>
				"5”: "Picked", <br/>
				"6": "Intransit",  <br/>
				"7": "Out for Delivery",<br/>
				"8": "Order Cancelled",<br/>
				"9” : "Shipment Cancelled",<br/>
				"10": "Delivered",<br/>
				"11": "Delivery Failed",<br/>
				"12": "RTO Pending",<br/>
				"13": "RTO Delivered",<br/>
				"14": "Heldup",<br/>
				"15": "Lost",<br/>
				"16": "Damaged",<br/>
				"17": "Contact Customer Care",<br/>
				"18": "Arrived at Destination",<br/>
				"19": "Cancellation Requested",<br/>
				"20": "RTO Undelivered",<br/>
				"21": "Pickup Cancelled by Merchant",<br/>
				"22": "Pickup Cancelled by Courier",<br/>
				"23": "Pickup Failed",<br/>
				"24": "Auto Cancelled",<br/>
				"25": "Order Processing"<br/>
				</div>	
				</div>

				<div className="api">
				<div className="api_label">2. PULL API Tracking</div>
				<span>
				URL- https://api.tekipost.com/connect/pull-tracking
				</span>

				<span>Method - POST</span>
				<span>Token - base64_encode(yourloginemail:password)</span>
				<span>Encrypted Token (example): bW9oaXRhbmFuZEBhcmloYW50Ym9va3MuY29tOkFyaWhhbnRAMTIz</span>
				<span>Required Params - &#123; "awb_no":"75445943134" &#125;</span>

				<span> 
				Values passed to awb_no   can be separated by comma and maximum 10 values are accepted.
				</span>

				<span> For example &#123; "awb_no ":  "75445943134,4535090349,7968795679" &#125;  </span>

				<span>  Api Returns:  Array of order status.  Status codes of order are listed above.</span>
				</div>
				</div>			 
							}
							
							</CardBody>
							 <CardFooter>
							 <div className="d-flex justify-content-between align-items-center"><Button className="ctm-btn btn-sm mr-2" color="primary"  onClick={toggleWordpress}>Setup</Button><span className='text-primary cursor-pointer'>Documentation</span></div>
							 </CardFooter>
							
							</Card>
					</div>
					}
					
					
					{ showResChannel==3  && 
					<div id="instr_custom">
						<Card className="mb-0 mx-auto w-100">
						
						<CardBody className="d-flex flex-wrap align-items-center">
								<p className="instruction-head">
								 Below are the instructions for data (required or not required) which is to be used in custom call to API.
								</p>
								 
								<div id="cust_inst" className="cust-inst">
								
									<Table responsive borderless className="table-striped customTable">
									<thead>
									<tr>
									<th className="text-nowrap w-some">Data Type</th>
									<th className="text-nowrap">Rules</th>
									</tr>
									</thead>
									<tbody>
									<tr> <td>order_number</td><td> (Required (Varchar 100) </td></tr>
									<tr> <td>Tags</td><td>Not Required</td></tr>
									<tr> <td>Weight in kgs</td><td>Required (Decimal 5,2) Float Value</td></tr>
									<tr> <td>Customer IP</td><td>Not Required</td></tr>
									<tr> <td>Delivery Type</td><td>Required (cod or prepaid)</td></tr>
									<tr> <td>Order Date</td><td>Required (YYYY-MM-DD)</td></tr>
									<tr> <td>Order Time	</td><td>Required (H-i-s)</td></tr>
									<tr> <td>Customer Name</td><td>Required (Varchar 255)</td></tr>
									<tr> <td>Customer Address 1</td><td>Required (Varchar 255)</td></tr>
									<tr> <td>Customer Address 2	</td><td>Not Required (Varchar 255)</td></tr>
									<tr> <td>Customer Phone</td><td>Required (10 Digits Number Only)</td></tr>
									<tr> <td>Customer City</td><td>Required (Varchar 100)</td></tr>
									<tr> <td>Customer Zip</td><td>Required (6 Digits Numbers Only)</td></tr>
									<tr> <td>Customer State</td><td>Required (Varchar 100)</td></tr>
									<tr> <td>Customer Email</td><td>Not Required (Varchar 50)</td></tr>
									<tr> <td>Product Category</td><td>Required (Varchar 255)</td></tr>
									<tr> <td>Product Code</td><td>Required (Varchar 100)</td></tr>
									<tr> <td>Product Name</td><td>Required (Varchar 255)</td></tr>
									<tr> <td>Product Invoice Value</td><td>Required (Decimal 10,2) Float Value</td></tr>
									<tr> <td>Product COD Value</td><td>Required (If Order is COD) (Decimal 10,2) Float Value</td></tr>
									<tr> <td>Product Qty</td><td>Required (INT) Integer</td></tr>
									<tr> <td>Product Length in cm</td><td>Required (Decimal 5,2) Float Value</td></tr>
									<tr> <td>Product Breadth in cm</td><td>Required (Decimal 5,2) Float Value</td></tr>
									<tr> <td>Product Height in cm</td><td>Required(Decimal 5,2) Float Value</td></tr>
									 
									<tr> <td> <strong> For payload example click on below "Payload" button. </strong> </td> <td> </td> </tr>
									
									</tbody>
									</Table>
							 
								</div>	
						
						</CardBody>
						
						<CardFooter>
						 <div className="d-flex justify-content-between align-items-center">
						{ /* <Button className="ctm-btn btn-sm mr-2" color="primary"  onClick={toggleCustom}>Setup</Button> */ }
						<span className='text-primary cursor-pointer' onClick={customPayLoad}>Payload</span></div>
						</CardFooter>
						</Card>
					</div>
					
					}
			  
			    </CardBody>
				</Card>
            </Col>
            </Row>
		  </div>
      </Col>
         
		
		<Col sm={12}>
				<Card className="shadow">
				<CardBody className="p-0">                  
				{checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
				<div className="showing-count"><span className="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(brandList.data).length)} of {brandList.TotalRows}</span></div>: ''}    
						 <Table responsive borderless className="table-striped customTable">
                        <thead> 
                          <tr>
                            <th>Brand</th>
                            <th>Channel</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {checkData(intrigatedBrandList.data) ? intrigatedBrandList.data.map((item, index) => (
                          <tr>
                            <td>
                            <div className="d-inline-flex align-items-center">
                                <div className="list-icon mr-2">
                                  <div className="avatar">
                                    <div className="avatar-content">
                                    {item.logo !== null && item.logo !== undefined ?
                                      <img className="img-fluid" src={process.env.REACT_APP_IMAGE_MERCHANT+item.logo} alt="logo" width="30" />
                                    : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-content text-left">
                                  <div className="font-weight-bold">
                                    <p className="mb-0">{(item.brand_name)}</p>                             
                                  </div>
                                  <div>
                                    <span className="fsc-2">{item.brand_website}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                            {item.shopify_webhook_status === 1 &&
                            <div className="d-flex align-items-center">
                                <div className="list-icon mr-2">
                                  <div className="avatar">
                                    <div className="avatar-content">
                                    {item.logo !== null && item.logo !== undefined ?
                                      <img className="img-fluid" src={shopifyIcon} alt="logo" width="32" />
                                    : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-content text-left">
                                  <div className="font-weight-bold">
                                    <p className="mb-0">{'Shopify'}</p>                             
                                  </div>
                                  <div>
                                    <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${item.id}/${item.merchant_id}`}</span>
                                    <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn d-none" id={`copyspy${item.id}`}>
                                      <Copy />
                                      <UncontrolledTooltip placement="bottom" target={`copyspy${item.id}`}>
                                        Copy
                                      </UncontrolledTooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            }
                            {item.wordpress_webhook_status === 1 &&
                            <div className="d-flex align-items-center">
                                <div className="list-icon mr-2">
                                  <div className="avatar">
                                    <div className="avatar-content">
                                    {item.logo !== null && item.logo !== undefined ?
                                      <img className="img-fluid" src={wordpressIcon} alt="logo" width="32" />
                                    : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-content text-left">
                                  <div className="font-weight-bold">
                                    <p className="mb-0">{'Word Press'}</p>                             
                                  </div>
                                  <div>
                                    <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${item.id}/${item.merchant_id}`}</span>
                                    <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn d-none" id={`copywprs${item.id}`}>
                                      <Copy />
                                      <UncontrolledTooltip placement="bottom" target={`copywprs${item.id}`}>
                                        Copy
                                      </UncontrolledTooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            }
                            {item.custom_webhook_status === 1 &&
                            <div className="d-flex align-items-center">
                                <div className="list-icon mr-2">
                                  <div className="avatar">
                                    <div className="avatar-content">
                                    {item.logo !== null && item.logo !== undefined ?
                                      <img className="img-fluid" src={customIcon} alt="logo" width="32" />
                                    : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-content text-left">
                                  <div className="font-weight-bold">
                                    <p className="mb-0">{'Custom'}</p>                             
                                  </div>
                                  <div>
                                    <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${item.id}/${item.merchant_id}`}</span>
                                    <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn d-none" id={`copycstm${item.id}`}>
                                      <Copy />
                                      <UncontrolledTooltip placement="bottom" target={`copycstm${item.id}`}>
                                        Copy
                                      </UncontrolledTooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            }
                            </td>
                            <td><Badge color={item.status === 1 ? 'success' : 'danger'} pill>{item.status === 1 ? 'Active' : 'InActive'}</Badge></td>
                            <td><Status statusUrl={process.env.REACT_APP_MERCHANT_BRANDS} listId={item.id} valStatus={item.status} reListing={intrigatedBrandListing} data={{"merchant_id":props.user[0].id, "status": item.status === 1 ? 0 : 1, "brand_id" :item.id}} /></td>
                            </tr> 
                          ))
                          : loadertable ? <tr><td colSpan="4">Loading...</td> </tr>:
                          <tr><td colSpan="4">No Data Found</td> </tr> }
                    </tbody>
                  </Table>

				</CardBody>
				</Card>
				 
				  
				  
	    </Col>
		
		
		
		
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return {
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(ChannelIntegration);