import React, { useState } from 'react';
import { Row, Col, Card, CardTitle, CardHeader,Nav,NavItem,NavLink,TabContent,TabPane, Container,} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import classnames from 'classnames';
import {useParams} from 'react-router-dom'
import CourierAddressDetails from './CourierAddressDetails';
import CourierBankDetails from './CourierBankDetails';
import CourierRegulatoryDetails from './CourierRegulatoryDetails';
import CourierSpocDetails from './CourierSpocDetails';




const CourierDetails = (props) => {

  const [activeTab, setActiveTab] = useState('1');

  const {courierId} = useParams();


 
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

 

  return (
    <div className="content-body overflow-hidden">
      <Container fluid>
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 courierDetails content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Update Courier Details</CardTitle>
              <Link to='/couriers' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></Link>
            </CardHeader>
            <Row>
              <Col sm={3}>
                <Nav tabs vertical className="mt-2 tabSection">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggle('1'); }}
                    >
                      Address Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggle('2'); }}
                    >
                      Bank Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggle('3'); }}
                    >
                      Regulatory Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => { toggle('4'); }}
                    >
                      Spoc Details
                    </NavLink>
                  </NavItem>
                </Nav>
                </Col>
                <Col sm={9}>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {activeTab === '1' &&
                      <CourierAddressDetails courierId={courierId} /> 
                    }              
                  </TabPane>
                  <TabPane tabId="2">
                    {activeTab === '2' &&
                      <CourierBankDetails courierId={courierId} />
                    }
                  </TabPane>
                  <TabPane tabId="3">
                    {activeTab === '3' &&
                      <CourierRegulatoryDetails courierId={courierId} /> 
                    }              
                  </TabPane>
                  <TabPane tabId="4">
                    {activeTab === '4' &&
                      <CourierSpocDetails courierId={courierId} /> 
                    }              
                  </TabPane>
                </TabContent>
                </Col>
                </Row>
          </Card>
      </Col>
      
      </Row>
      </Container>
       </div>
  );
}

export default CourierDetails;