import React, {useState} from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from './Overview/Overview';
import Courier from './Couriers/Couriers';
import NDR from './NDR/NDR';

const MerchantDashboard = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="content-body position-relative overflow-hidden">
          <div className="page-header main-header mb-3"> 
		  <h1> Dashboard </h1>
			  <div className="header-buttons">
			      <Nav tabs pills className="justify-content-center mb-2 border-0">
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                              Overview
                          </NavLink>
                      </NavItem>
                      <NavItem className='d-none'>
                          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                              Couriers
                          </NavLink>
                      </NavItem>
                      <NavItem className='d-none'>
                          <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                             NDR
                          </NavLink>
                      </NavItem>
                  </Nav>
			  </div>
		  </div>
		  
          <Row>
           <Col sm={12}>            
            <div className="p-0 my-3 dashboard content-wrapper">
                 
                <TabContent activeTab={activeTab} className="pb-0">
                  <TabPane tabId="1">
                    <Overview user={props.user} />
                  </TabPane>
                  <TabPane tabId="2">
                    {/* <Courier user={props.user} /> */}
                  </TabPane>
                  <TabPane tabId="3">
                    {/* <NDR user={props.user} /> */}
                  </TabPane>
                </TabContent>
            </div>
            </Col>         
          </Row>
        </div>
    );
}

export default MerchantDashboard;