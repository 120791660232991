import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Label, Input, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, Nav, CardHeader, Table, Pagination, Spinner, PaginationItem, PaginationLink, NavItem, NavLink, Badge, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown, faFilter, faSpinner, faCheck, faTimes, faTruck, faAngleDown, faChartBar, faComment, faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
// import { DateRangePicker } from 'rsuite';
import { firstDayOftheMonth, changeIntoDateTime, copyToClipBoard, moreText, errorHandling, remExtraSpace, arrayValueSum } from '../../utlis';
import { delivery_type_id, channel_order_status, download_delay, block_merchant_id, service } from '../../config';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import classnames from 'classnames';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from "react-hook-form";
import { MultiSelect, } from "react-multi-select-component";
import { connect, useDispatch } from 'react-redux';
import { AllChannelBrand } from '../../actions';
import EditOrder from './EditOrder';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
// import StarRatings from 'react-star-ratings';

const ChannelOrder = (props) => {
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [filterDateOverview, setFilterDateOverview] = useState([new Date(), new Date()]);
  const [activeTab, setActiveTab] = useState('in-process');
  const [channelOrder, setChannelOrder] = useState({});
  const [channelSummary, setChannelSummary] = useState({});
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [allTagsChecked, setAllTagsChecked] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [editmodal, setEditmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [merchantId, setMerchantId] = useState('');
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [shipOrdersModal, setShipOrdersModal] = useState(false);
  const [wghtDmnModal, setWghtDmnModal] = useState(false);
  const [courierList, setCourierList] = useState({});
  const [overviewModal, setOverviewModal] = useState(false);
  const [pickupLocation, setPickupLocation] = useState({});
  const [searchValue, setSearchValue] = useState(' ');
  const [blockfeatures, setBlockfeatures] = useState(false);
  const [datestate, setDateState] = useState({ start: '', end: '', });
  let ShipmentStartDate = (new URLSearchParams(window.location.search)).get("start_date");
  let ShipmentEndDate = (new URLSearchParams(window.location.search)).get("end_date");
  let orderTab = (new URLSearchParams(window.location.search)).get("o");
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }

  const orderData = [
    { id: 1, value: "Customer Name", isChecked: false },
    { id: 2, value: "Store Name", isChecked: false },
    { id: 3, value: "Ware House", isChecked: false },
    { id: 4, value: "Upload Date", isChecked: false }
  ];
  const [tagChecked, setTagChecked] = useState(orderData.map((index, key) => (
    key === false
  )));
  const dropFilterCount = 20;
  const history = useHistory();
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    if (props.user[0].user_type === "Merchant") {
      // channelOrderListing(tab);   
      history.push(`/channel-orders?o=${tab}`)
    }
  }

  const clearAll = (value) => {
    setAllTagsChecked(false);
    setTagChecked(false);
  };
  const ApplyTagFIlter = () => {
    // console.log('checked',checked)
  }
  const selectAll = (value) => {
    setOverallChecked(false);
    let abc = checkData(channelOrder.data) && channelOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      var opt_order_id = [];
      checkData(channelOrder.data) && Object.keys(channelOrder.data).map(function (key) {
        let tempObj = channelOrder.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  // const selectOverAll = () => {
  //   setOverallChecked(true);
  //   // setCheckboxOrderId([]);
  // }

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  };
  const toggleTagsCheck = (event, utilityCheckbox, dataId, dataValue) => {
    // console.log('value', JSON.stringify(value))
    var opt_tag_id = tagData;
    setTagChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_tag_id.push(dataId)
      setTagData(opt_tag_id)
    } else {
      function arrayRemove(arr, dataId) {
        return arr.filter(function (ele) {
          return ele !== dataId;
        });
      }
      var result = arrayRemove(tagData, dataId);
      setTagData(result)
    };
  };
  const tagToggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const resetForm = () => {
    channelOrderListing(activeTab);
  }
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const [allBrand, setAllBrand] = useState([]);
  const allBrandMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Brand"
  }
  const dispatch = useDispatch();

  var opt_allBrand = [];
  if (checkData(props.state) && checkData(props.state.channelBrandData) && checkData(props.state.channelBrandData.brands)) {
    Object.keys(props.state.channelBrandData.brands).map(function (key) {
      let tempObj = { label: props.state.channelBrandData.brands[key].brandName, value: props.state.channelBrandData.brands[key].brand_id }
      return opt_allBrand.push(tempObj)
    });
  }
  const [leadStatus, setLeadStatus] = useState([]);
  const allLeadStatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Lead Status"
  }
  var opt_allLeadStatus = [];

  for (const property in channel_order_status) {
    let tempObj = { label: `${channel_order_status[property]}`, value: property };
    opt_allLeadStatus.push(tempObj);
  }

  const [allTags, setAllTags] = useState([]);
  const allTagsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "System Tags"
  }
  var opt_allTags = [
    { label: 'Duplicate Mobile', value: 'Duplicate Mobile' },
    { label: 'Short Address', value: 'Short Address' },
    { label: 'Duplicate Email', value: 'Duplicate Email' },
    { label: 'Duplicate IP', value: 'Duplicate IP' },
    { label: 'Cancellation Requested', value: 'Cancellation Requested' },
    { label: 'Invalid Mobile', value: 'Invalid Mobile' },
    { label: 'Multiple Order', value: 'Multiple Order' },
  ];

  const [custRating, setCustRating] = useState([]);
  const custRatingMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Customer Rating"
  }
  var opt_custRaing = [
    { label: '1 ★', value: 1 },
    { label: '2 ★', value: 2 },
    { label: '3 ★', value: 3 },
    { label: '4 ★', value: 4 },
    { label: '5 ★', value: 5 },
  ];


  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setOrderDetail({});
    setLoader(true);
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    axios.post(process.env.REACT_APP_CHANNEL_ORDER_DETAIL, data)
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setOrderDetail(res.data.data);
          }
        }
      }).catch((err) => {
        setLoader(false);
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      })
  }
  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const channelOrderSummary = () => {
    setLoader(true);
    // let data = {}
    let data = { "start_date": checkData(filterDateOverview) ? moment(filterDateOverview[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDateOverview) ? moment(filterDateOverview[1]).format('YYYY-MM-DD') : '' }
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios.post(process.env.REACT_APP_CHANNEL_ORDER_SUMMARY, data)
      .then((res) => {
        setLoader(false)
        if (res.data.error === false) {
          setChannelSummary(res.data);
        } else {
          Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "reload");
      })
  }
  const channelOrderListing = (order_status_id) => {
    let odr_status_id = "";

    if (order_status_id === "in-process") {
      odr_status_id = "1";
    } else if (order_status_id === "cancelled") {
      odr_status_id = "2";
    } else if (order_status_id === "shipped") {
      odr_status_id = "3";
    } else if (order_status_id === "all") {
      odr_status_id = "";
    }
    resetFilter();
    setAllBrand([]);
    setAllTags([]);
    setCustRating([]);

    if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate)) {
      setFilterDate([ShipmentStartDate, ShipmentEndDate]);
      setDateState({ start: ShipmentStartDate, end: ShipmentEndDate, })
    } else {
      // if(orderTab !== "all" && activeTab !== "all"){        
      //   setFilterDate([firstDayOftheMonth(),new Date()]);
      // }
      // else{
      //   setFilterDate([]);
      // }
      setDateState({ start: moment(), end: moment(), });

      setFilterDate([new Date(), new Date()]);
    }
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setCurrentCount(1);
    setPage(1)
    setTableLoader(true);
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1',"start_date":changeIntoDateYmd(firstDayOftheMonth()),"end_date":changeIntoDateYmd(new Date())}
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1'}
    let data = { "order_status_id": order_status_id !== undefined && odr_status_id }

    if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate)) {
      data.start_date = ShipmentStartDate;
      data.end_date = ShipmentEndDate;
    } else {
      data.start_date = moment().format('YYYY-MM-DD');
      data.end_date = moment().format('YYYY-MM-DD');
    }

    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios.post(process.env.REACT_APP_CHANNEL_ORDER, data)
      .then((res) => {
        setTableLoader(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setChannelOrder(res.data);
          }
        }
      }).catch((err) => {
        setTableLoader(false)
        errorHandling(err, "reload");
      })
  }
  const allChannelFilter = () => {
    let mypost = {}
    let myget = {}
    if (checkData(props.user)) {
      if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
        mypost = { "admin_id": props.user[0].id }
      } else {
        mypost = { "merchant_id": props.user[0].id, }
      }
    }
    dispatch(AllChannelBrand(mypost, myget)).then((response) => {
    }).catch(function (response) {
      Swal.fire({ title: 'Error', icon: 'error', text: response })
    });
  }
  function checkMerchant(mid) {
    console.log('ag', mid)
    if (mid === parseInt(props.user[0].id)) {
      setBlockfeatures(true)
    }
    return
  }
  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true)
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { "merchant_id": checkData(props.user) && props.user[0].id }
      }).then(function (response) {
        setLoader(false)
        if (response.data.error === false) {
          setCourierList(response.data.data.courierList)
        } else {
          Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
        }

      }).catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload")
      })
    }
  }
  useEffect(() => {
    //  block_merchant_id.filter(checkMerchant)
    if (orderTab === 'all') {
      setActiveTab('all')
    }
    couurierListing();
    setDataList(orderData);
    let allChecked = true;
    for (const utilityCheckbox in tagChecked) {
      if (tagChecked[utilityCheckbox] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setAllTagsChecked(true);
    } else {
      setAllTagsChecked(false);
    }
    if (checkData(props.state) && checkData(props.state.channelBrandData)) {
    } else {
      if (checkData(props.user)) {
        allChannelFilter();
      }
    }
    setSelectperpage(20);
    setLoader(false);
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      pickupLocationList();
      if (orderTab !== null) {
        history.push(`/channel-orders?o=${orderTab}`)
        channelOrderListing(orderTab);
        setActiveTab(orderTab)
      } else {
        history.push(`/channel-orders?o=${activeTab}`)
        channelOrderListing(activeTab);
      }
    } else {
      setActiveTab('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagChecked, orderTab])

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setTableLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.totalPage)) {
      // &&checkUndeNullBlank(channelOrder.totalPage).length>page.length
      if (channelOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(channelOrder.data).length)
        setPage(page + 1)
        setTableLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(channelOrder.data)
      if (currentCount > Object.entries(channelOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterChannelOrderSubmit, watch: filterWatch, reset: resetFilter } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterchannelOrder = (data, pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.is_verify) || checkData(data.is_user_tag) || checkData(data.delivery_type_id) || checkData(leadStatus) || checkData(data.merchant_tags) || checkData(allBrand) || checkData(allTags) || checkData(filterDate) || checkData(custRating)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {

    let byData = {}
    let tmpLeadStatus = []
    let tmpBrand = [];
    let tmpAllTags = [];
    let tmpCustRating = [];
    let odr_status_id = '';

    if (checkData(leadStatus)) {
      leadStatus.map((item) => (
        tmpLeadStatus.push(item.value)
      ));
    }
    if (checkData(allBrand)) {
      allBrand.map((item) => (
        tmpBrand.push(item.value)
      ));
    }
    allTags.map((item) => (
      tmpAllTags.push(item.value)
    ));
    custRating.map((item) => (
      tmpCustRating.push(item.value)
    ));

    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }

    if (activeTab === "in-process") {
      odr_status_id = "1";
    } else if (activeTab === "cancelled") {
      odr_status_id = "2";
    } else if (activeTab === "shipped") {
      odr_status_id = "3";
    } else if (activeTab === "all") {
      odr_status_id = "";
    }
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    // byData={"order_status_id":activeTab !== '4' ? activeTab:'',"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?remExtraSpace(data.search_value):'',"is_verify":checkUndeNullBlank(data.is_verify)?data.is_verify:'',"merchant_tags":checkUndeNullBlank(data.merchant_tags)?data.merchant_tags:'',"is_user_tag":checkUndeNullBlank(data.is_user_tag)?data.is_user_tag:'',"system_tags":checkUndeNullBlank(tmpAllTags)?tmpAllTags:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"brand_id":checkUndeNullBlank(tmpBrand)?tmpBrand:'',"start_date":filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '',"end_date":filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : ''}
    byData = { "order_status_id": odr_status_id, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "is_verify": checkUndeNullBlank(data.is_verify) ? data.is_verify : '', "merchant_tags": checkUndeNullBlank(data.merchant_tags) ? data.merchant_tags : '', "is_user_tag": checkUndeNullBlank(data.is_user_tag) ? data.is_user_tag : '', "system_tags": checkUndeNullBlank(tmpAllTags) ? tmpAllTags : '', "customer_rating": checkUndeNullBlank(tmpCustRating) ? tmpCustRating : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "brand_id": checkUndeNullBlank(tmpBrand) ? tmpBrand : '', "start_date": dateStart, "end_date": dateEnd }

    setFilterInput(byData);
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setChannelOrder(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };
  const exportOrders = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios.post(process.env.REACT_APP_CHANNEL_ORDER, data).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  const toggleChangeStatus = () => {
    if (checkboxOrderId.length > 0) {
      setChangeStatusModal(!changeStatusModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const { register: changeStatus, handleSubmit: changeStatusSubmit, reset: reset2 } = useForm();
  const onChangeStatus = data => {
    data.order_ids = checkboxOrderId
    data.merchant_name = checkData(props.user) && props.user[0].name
    data.merchant_id = checkData(props.user) && props.user[0].id
    axios.post(`${process.env.REACT_APP_CHANNEL_ORDER_UPDATE_STATUS}`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
            if (okay) {
              reset2();
              setChangeStatusModal(false);
              setCheckboxOrderId([]);
              setCheckedAll(false);
              setChecked(false);
              if (checkData(filterInput)) {
                onCommonFilter();
              } else {
                channelOrderListing();
              }
            }
          });

        } else {
          Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
        }
      }).catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
  }
  const closeChangeStatus = (orderId) => {
    setChangeStatusModal(false);
    reset2();
  }


  const pickupLocationList = () => {
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
      data: mydata,
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.error === false) {
            setPickupLocation(res.data.data);
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
    });
  }

  const toggleShipOrders = () => {
    if (checkboxOrderId.length > 0) {
      setShipOrdersModal(!shipOrdersModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }

  const { register: shipOrders, handleSubmit: shipOrdersSubmit, formState: { errors: errors1 }, setValue } = useForm();
  const onShipOrders = data => {
    // let data = {"order_ids":checkboxOrderId}
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      axios.post(process.env.REACT_APP_CHANNEL_ORDER_SAVE_SHIP, data)
        .then((res) => {
          setLoader(false);
          if (res.data.error === false) {
            Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
              if (okay) {
                setShipOrdersModal(false);
                if (checkData(filterInput)) {
                  onCommonFilter();
                } else {
                  channelOrderListing();
                }
                // if(checkData(failedOrder.data)){
                //   channelOrderListing();
                // }else{
                // history.push("/shipments");
                // }
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th style="padding: 0.25rem 0;">Order No</th><th style="padding: 0.25rem 0;">Response</th></tr>
              ${res.data.data.map((item, index) => '<tr style="font-size:0.8rem;"><td>' + item.order_no + '</td><td>' + item.msg.map(data => { return data; }) + '</td></tr>')}
              </tbody></Table>`,
              title: 'Error'
            });
          }
        }).catch((err) => {
          setLoader(false);
          errorHandling(err, "");
        })
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }

  const toggleWghtDmn = () => {
    if (checkboxOrderId.length > 0) {
      setWghtDmnModal(!wghtDmnModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }

  const { register: wghtDmn, handleSubmit: WghtDmnSubmit, formState: { errors: errors2 } } = useForm();
  const onWghtDmn = data => {
    console.log(data);
  }

  const toggleOverview = () => {
    setOverviewModal(!overviewModal);
  }






  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  let totalCod = 0;
  let totalPrepaid = 0;
  let totalCodPrepaid = 0;

  let brandorderSummary = checkData(channelSummary) && checkData(channelSummary.data) && Object.values(channelSummary.data.reduce((osi, item) => {
    let brandId = item.brand_id
    if (!osi[brandId])
      osi[brandId] = { brand_id: brandId, brand_name: item.brand_name, cod: [], prepaid: [] };

    let codcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 1 ? item.total : 0;
    let prepaidcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 3 ? item.total : 0;
    osi[brandId].cod.push(codcount);
    osi[brandId].prepaid.push(prepaidcount);

    totalCod += codcount;
    totalPrepaid += prepaidcount;
    totalCodPrepaid = totalCod + totalPrepaid
    return osi;
  }, {}));

  const getRatingColor = (val) => {
    if (val < 3) return "red";
    else if (val < 4) return "#fd7e14";
    else if (val <= 5) return "#198754";
  }

  return (
    <div className="content-body overflow-hidden">
      <Row>
        <Col sm={12}>
          {loader && <div className="formLoader"><Spinner /></div>}
          <div className={`${blockfeatures ? 'overlay-0' : ''}`}></div>
          
		  	<div className="page-header main-header mb-3"> 
				<h1> Channel Orders </h1>
				
                <div className='card-header-right d-flex flex-wrap align-items-center ml-auto'>
                  <div className="btn-text btn-light d-none" onClick={() => { toggleOverview(); channelOrderSummary(); }}><span className="icon mr-2"><FontAwesomeIcon icon={faChartBar} /></span><span>Overview</span></div>
                  <Link to="/sms-log" className="btn-text btn-light d-none"><span className="icon mr-2"><FontAwesomeIcon icon={faComment} /></span><span>SMS Log</span></Link>
                  {!blockfeatures && <div className="btn-text btn-light" onClick={exportOrders}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span></div>}
                  <UncontrolledDropdown>
                    <DropdownToggle caret className="btn-text btn-light">
                      Choose Action<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <div className="dropdown-item" onClick={toggleChangeStatus}>Change Status</div> */}
                      <div className="dropdown-item" onClick={toggleShipOrders}>Move to Upload Orders</div>
                      {/* <div className="dropdown-item" onClick={toggleWghtDmn}>Upload Weight/Dimension</div> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <div className="btn-text btn-light" onClick={toggleChangeStatus}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Choose Action</span></div> */}
                </div>
              </div>
		  
		  <Card className="p-0 my-3 channelOrder shadow content-wrapper border-0">
               
              <div className="mx-3 d-none">
                <Nav tabs className="mb-4 pt-2 border-bottom text-center flex-nowrap">
                  {props.user[0].user_type === "Merchant" &&
                    <>
                      <NavItem className="">
                        <NavLink className={`warning-ctm ${classnames({ active: activeTab === 'in-process' })}`} onClick={() => { toggle('in-process'); }}>
                          <div className="">
                            <span className="tab-icon mr-2"><FontAwesomeIcon icon={faSpinner} /></span>
                            <span className="tab-text text-nowrap">In Process</span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink className={`danger-ctm ${classnames({ active: activeTab === 'cancelled' })}`} onClick={() => { toggle('cancelled'); }}>
                          <div>
                            <span className="tab-icon mr-2"><FontAwesomeIcon icon={faTimes} /></span>
                            <span className="tab-text text-nowrap">Cancelled</span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink className={`success-ctm ${classnames({ active: activeTab === 'shipped' })}`} onClick={() => { toggle('shipped'); }}>
                          <div>
                            <span className="tab-icon mr-2"><FontAwesomeIcon icon={faTruck} /></span>
                            <span className="tab-text text-nowrap">Shipped</span>
                          </div>
                        </NavLink>
                      </NavItem>
                    </>
                  }
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'all' })} onClick={() => { toggle('all'); }}>
                      <div>
                        <span className="tab-icon mr-2"><FontAwesomeIcon icon={faDropbox} /></span>
                        <span className="tab-text text-nowrap">All</span>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Form onSubmit={filterChannelOrderSubmit(onFilterchannelOrder)} className="filter-form p-3">
                <Row className="mx-0 justify-content-start justify-content-lg-center mb-3 mb-lg-0">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select className="form-control-sm form-control rounded-right-0" {...filter("search_field")} onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text); } else { setSearchValue('') } }}>
                              <option value="">Search By</option>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_name">Customer Pincode</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="customer_ip">Customer IP</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input type="text" {...filter("search_value")} className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Delivery Type</option>
                        {Object.entries(delivery_type_id).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className='d-none'>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allLeadStatus}
                        value={leadStatus}
                        onChange={(e) => setLeadStatus(e)}
                        labelledBy="All Lead Status"
                        overrideStrings={allLeadStatusMultiSelectSettings}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allBrand}
                        value={allBrand}
                        onChange={(e) => setAllBrand(e)}
                        labelledBy="All Brand"
                        overrideStrings={allBrandMultiSelectSettings}
                        className={checkData(allBrand) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <MultiSelect
                        options={opt_allTags}
                        value={allTags}
                        onChange={(e) => setAllTags(e)}
                        labelledBy="System Tags"
                        overrideStrings={allTagsMultiSelectSettings}
                        className={checkData(allTags) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("is_user_tag")} className={`form-control-sm form-control ${checkData(filterWatch("is_user_tag")) ? 'valid' : ''}`}>
                        <option value="">User Tag</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("is_verify")} className={`form-control-sm form-control ${checkData(filterWatch("is_verify")) ? 'valid' : ''}`}>
                        <option value="">Mobile Verify</option>
                        <option value='1'>Is Verified</option>
                        <option value='0'>Not Verified</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className='d-none'>
                    <FormGroup>
                      <MultiSelect
                        options={opt_custRaing}
                        value={custRating}
                        onChange={(e) => setCustRating(e)}
                        labelledBy="Customer Rating"
                        overrideStrings={custRatingMultiSelectSettings}
                        className={checkData(custRating) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3} className='d-none'>
                    <FormGroup>
                      <input {...filter("merchant_tags")} type="text" className="form-control-sm form-control" placeholder="Filter your own tags comma seperated" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomStart" ref={dateRangeRef} change={onDateChange} /> */}
                      <DateRangePicker
                        initialSettings={ranges}
                        onCallback={handleCallback}
                      >
                        {datestate.start !== '' && datestate.end !== '' ?
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                      </DateRangePicker>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={resetForm}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                {/* <div className="showing-count">
                  {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                  <span class="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(channelOrder.data).length)} of {channelOrder.TotalRows}</span>: ''}  
                </div> */}
                {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                  <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                        Showing {currentCount} to {(currentCount - 1) + (Object.entries(channelOrder.data).length)} of {channelOrder.TotalRows} entries
                      </div>
                      {channelOrder.TotalRows > dropFilterCount ?
                        <Form>
                          <FormGroup>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                              <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                              <option value={250}>250</option>
                              <option value={500}>500</option>
                            </Input>
                          </FormGroup>
                        </Form>
                        : ''}
                    </div>
                  </>
                  : ''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th><div className="form-check2"><input name="cust-type form-check-input" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>
                      <th className="text-nowrap">ORDER DETAILS </th>
                      <th className="text-nowrap">PRODUCT DETAILS</th>
                      <th className="text-nowrap">Customer Details </th>
                      <th className="text-nowrap">STATUS/TAGS
                        <span className='d-none'><Dropdown isOpen={dropdownOpen} toggle={tagToggle}>
                          <DropdownToggle caret>
                            <FontAwesomeIcon icon={faFilter} />
                          </DropdownToggle>
                          <DropdownMenu>
                            {dataList.map((data, key) =>
                              <FormGroup>
                                <div className="form-check2 d-flex flex-wrap align-items-center">
                                  <input checked={tagChecked[key]} name={'check' + key} type="checkbox" id={data.id} onChange={(event) => toggleTagsCheck(event, key, data.id, data.value)} />
                                  <Label className="mx-2 my-0" for={data.id} >{data.value}</Label>
                                </div>
                              </FormGroup>
                            )}
                            <DropdownItem divider />
                            <FormGroup>
                              <DropdownItem><span className='mr-2'><Button onClick={clearAll} className="ctm-btn" color='primary'>Clear All</Button></span><span><Button onClick={ApplyTagFIlter} className="ctm-btn" color='primary'>Apply</Button></span></DropdownItem>
                            </FormGroup>
                          </DropdownMenu>
                        </Dropdown></span>
                      </th>
                      <th className="text-nowrap">UPLOADED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(channelOrder.data) ? channelOrder.data.map((item, index) => {
                      if (item.system_tags[0] === ',') {
                        item.system_tags = item.system_tags.substring(1);
                      }
                      return (
                        <tr>
                          <td className='align-middle'><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div></td>
                          <td className='text-left align-middle'>
                            <div className={`text-nowrap ${blockfeatures ? 'filter-blr-2' : ''}`}><span className="font-weight-500 fsc-4">ORDER NO. - <br /></span><Link to={`/channel-order-detail/${item.id}`}>{item.order_no || 'NA'}</Link>
                              <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn d-none" id={`copyOdr${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <span className="font-weight-500 fsc-4">STORE - <br /></span><span className='text-uppercase text-nowrap'>{item.merchantBrandName || 'NA'}</span><br />
                            <span className="font-weight-500 fsc-4">WAREHOUSES - <br /></span><span className='text-uppercase text-nowrap'>{item.MerchantPickupPointName || 'NA'}</span>
                          </td>
                          <td className='align-middle'>
                            <div className="w-product" id={`productTooltip${item.id}`}>{moreText(item.product_name)}</div>
                            <UncontrolledTooltip placement="top" target={`productTooltip${item.id}`}>
                              {item.product_name}
                            </UncontrolledTooltip>
                            <span className="font-weight-500 fsc-2">MODE -</span> {delivery_type_id[item.delivery_type_id]}{item.product_cod_value > 0 && ' ( ' + item.product_cod_value + ' ) '}<br />
                            <span className="font-weight-500 fsc-2">DIM | WEIGHT -</span> {item.length_in_cms}*{item.breadth_in_cms}*{item.height_in_cms} | {item.product_weight_in_kgs}
                          </td>
                          <td className="align-middle">

                            {item.customer_name}<br />
                            <span className={`${blockfeatures ? 'filter-blr-2' : ''}`}>{item.customer_mobile} </span>
                            {/* <div className="d-flex align-items-center">
                          <div>
                            <StarRatings
                              rating={item.customer_rating}
                              starDimension="12px"
                              starSpacing="1px"
                              starRatedColor={getRatingColor(item.customer_rating)}
                              numberOfStars={5}
                            />
                          </div>
                          <Badge className="ml-2 cust-badge" color={`${item.customer_rating < 3 ? 'danger' : item.customer_rating < 4 ? 'warning' : item.customer_rating <= 5 ? 'success' :''}`}>{item.customer_rating}</Badge>
                        </div> */}
                            {checkUndeNullBlank(item.is_verify) && parseInt(item.is_verify) === 1 ? <Badge color='success' className='bg-transparent badge-65-text'> <span className='text-secondary'><FontAwesomeIcon icon={faComment} /> </span>Verified </Badge> : parseInt(item.is_verify) === 0 ? <Badge color='danger' className='bg-transparent badge-65-text'> <span className='text-secondary'><FontAwesomeIcon icon={faComment} /> </span>Not Verified</Badge> : ''} {checkUndeNullBlank(item.merchant_tags) && <> <br /> <span>{item.merchant_tags.split(',').map((step) => <Badge color='warning' pill className='mt-2 mr-1 badge-70-text' >{step}{"\n"}</Badge>)}</span></>}</td>
                          <td className='align-middle'>
                            <Badge color={`${item.order_status_id === 2 ? 'danger' : item.order_status_id === 3 ? 'success' : 'primary'}`} pill >{channel_order_status[item.order_status_id]}</Badge><br />
                            {checkUndeNullBlank(item.system_tags) && item.system_tags.split(',').map((step) => <><Badge color='warning' pill className={`mt-2 mr-1 badge-70-text ${blockfeatures ? 'filter-blr-2' : ''}`} >{step}{"\n"}</Badge><br /></>)}
                          </td>
                          <td className='align-middle'><span className="text-nowrap mr-2">{changeIntoDateTime(item.created_at)}</span>
                            {checkData(props.user) && props.user[0].user_type === "Merchant" &&
                              <span><Button className="ctm-btn btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                            }
                          </td>
                        </tr>
                      )
                    }) :
                      tableLoader ? <tr><td colSpan="6" className="text-center">Loading...</td></tr>
                        : <tr><td colSpan="6" className="text-center">No Data Found</td></tr>
                    }

                  </tbody>
                </Table>
                <ToastContainer className="copied-con" />
                {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                  <>
                    {channelOrder.TotalRows > dropFilterCount ?
                      <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                        <div className="showing-count">
                          Showing {currentCount} to {(currentCount - 1) + (Object.entries(channelOrder.data).length)} of {channelOrder.TotalRows} entries
                        </div>
                        <Pagination aria-label="Page navigation example">
                          <PaginationItem>
                            <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                      : ''}
                  </>
                  : ''}
                {/* {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                <>
                {channelOrder.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example align-items-center">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''} */}
              </CardBody>
            </Card>
          
        </Col>

      </Row>
      {checkData(orderDetail) &&
        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditOrder orderDetails={orderDetail} merchantId={merchantId} setEditmodal={setEditmodal} user={props.user} channelOrderListing={channelOrderListing} />
          </ModalBody>
        </Modal>
      }
      <Modal isOpen={changeStatusModal} toggle={changeStatusModal} scrollable={true} size="md">
        <ModalHeader toggle={closeChangeStatus}>Change Status</ModalHeader>
        <ModalBody>
          <Form onSubmit={changeStatusSubmit(onChangeStatus)} className="">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={12}>
                <FormGroup>
                  <Label>Select Status</Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...changeStatus("order_status_id")} className="form-control-sm form-control">
                      <option value="">Select Status</option>
                      {Object.entries(channel_order_status).map(([key, value]) => (
                        <option value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <textarea {...changeStatus("remarks")} className="form-control-sm form-control" placeholder="Enter Remarks" />
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={shipOrdersModal} toggle={toggleShipOrders} scrollable={true} size="md">
        <ModalHeader toggle={toggleShipOrders}>Select Courier/Ware House</ModalHeader>
        <ModalBody>
          <Form onSubmit={shipOrdersSubmit(onShipOrders)} className="cardFilter">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={12}>
                <FormGroup>
                  {/* <Label>Select Service<span className="text-danger">*</span></Label> */}
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <Badge color='success' pill className={`badge-70-text selected-option-badge d-none }`} ><span className="small-check-ctm"><FontAwesomeIcon icon={faCheck} /></span></Badge>
                    <select {...shipOrders("service", { required: 'Please Select Service' })} className="form-control-sm form-control choose-option">
                      <option value="">Select Service</option>
                      {Object.entries(service).map(([key, value]) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  {errors1.service && <span className="text-danger d-block error">{errors1.service.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Select Courier</Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...shipOrders("courier_id")} className="form-control-sm form-control">
                      <option value="">Select Courier</option>
                      {checkData(courierList) && courierList.map((item) => (
                        item.status !== 0 &&
                        <option value={item.courier_id}>{item.brand_name}</option>
                      ))}
                    </select>
                  </div>
                  {errors1.courier_id && <span className="text-danger d-block error">{errors1.courier_id.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Select Pickup Point<span className="text-danger">*</span></Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <Badge color='success' pill className={`badge-70-text selected-option-badge d-none }`} ><span className="small-check-ctm"><FontAwesomeIcon icon={faCheck} /></span></Badge>
                    <select {...shipOrders("pickup_point_id", { required: 'Please Select Pickup Point' })} className="form-control-sm form-control choose-option">
                      {/* <option value="">Select Pickup Point</option> */}
                      {pickupLocation !== undefined && pickupLocation !== null && Object.entries(pickupLocation).length > 0 ? pickupLocation.map((item, index) => {
                        [
                          { name: 'pickup_point_id', value: item.is_default === 1 && item.id }
                        ].forEach(({ name, value }) => setValue(name, value))
                        return (
                          <option data-id={index} value={item.id}>{item.contact_name}</option>
                        )
                      }) : ''}
                    </select>
                  </div>
                  {errors1.pickup_point_id && <span className="text-danger d-block error">{errors1.pickup_point_id.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={wghtDmnModal} toggle={toggleWghtDmn} scrollable={true} size="lg">
        <ModalHeader toggle={toggleWghtDmn}>Upload Weight and Dimenstion</ModalHeader>
        <ModalBody>
          <Form onSubmit={WghtDmnSubmit(onWghtDmn)} className="">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={4}>
                <FormGroup>
                  <Label>Enter Weight IN KGS.<span className="text-danger">*</span> <small>eg.0.5</small></Label>
                  <input {...wghtDmn("weight", {
                    required: 'Please Enter Weight',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Weight' }
                  })} className="form-control-sm form-control" />
                  {errors2.weight && <span className="text-danger d-block error">{errors2.weight.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={8}>
                <FormGroup>
                  <Label>Dimension<span className="text-danger">*</span></Label>
                  <div className="d-flex">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2"><Label>L</Label></div>
                        <div><input {...wghtDmn("length_in_cms", {
                          required: 'Please Enter Length',
                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Length' }
                        })} className="form-control-sm form-control" /></div>
                      </div>
                      {errors2.length_in_cms && <span className="text-danger d-block error">{errors2.length_in_cms.message}</span>}
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2"><Label>B</Label></div>
                        <div><input {...wghtDmn("breadth_in_cms", {
                          required: 'Please Enter Breadth ',
                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Breadth ' }
                        })} className="form-control-sm form-control" /></div>
                      </div>
                      {errors2.breadth_in_cms && <span className="text-danger d-block error">{errors2.breadth_in_cms.message}</span>}
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2"><Label>H</Label></div>
                        <div><input {...wghtDmn("height_in_cms", {
                          required: 'Please Enter Height',
                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Height ' }
                        })} className="form-control-sm form-control" /></div>
                      </div>
                      {errors2.height_in_cms && <span className="text-danger d-block error">{errors2.height_in_cms.message}</span>}
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={overviewModal} toggle={toggleOverview} scrollable={true} size="lg">
        <ModalHeader toggle={toggleOverview}>Channel Order Overview</ModalHeader>
        <ModalBody>
          <Form className="cardFilter">
            <Row className="justify-content-start mb-3">
              <Col sm={6} md={4} lg={3} xl={4}>
                <FormGroup className='mb-sm-0'>
                  {/* <DateRangePicker value={filterDateOverview} onChange={date => setFilterDateOverview(date)} placement="bottomStart" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="dd-MM-yyyy" /> */}
                </FormGroup>
              </Col>
              <Col sm={3} className="btn-container">
                <Button className="btn btn-sm" color="primary" onClick={() => channelOrderSummary()}>Search</Button>
              </Col>
            </Row>
          </Form>
          <div>
            <Table responsive borderless className="text-center customTable">
              <thead>
                <tr>
                  <th></th>
                  <th>COD</th>
                  <th>Prepaid</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th className="text-nowrap">{totalCod}</th>
                  <th className="text-nowrap">{totalPrepaid}</th>
                  <th className="text-nowrap">{totalCodPrepaid} </th>
                </tr>
              </thead>
              <tbody>
                {checkData(brandorderSummary) ? brandorderSummary.map((item, index) => {
                  let codCount = checkUndeNullBlank(item.cod) ? arrayValueSum(item.cod) : 0;
                  let prepaidCount = checkUndeNullBlank(item.prepaid) ? arrayValueSum(item.prepaid) : 0;
                  let total = codCount + prepaidCount
                  return (
                    <tr>
                      <td>{item.brand_name}</td>
                      <td className="text-nowrap">{codCount}</td>
                      <td className="text-nowrap">{prepaidCount}</td>
                      <td className="text-nowrap">{total}</td>
                    </tr>)
                }) : ''
                }

              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(ChannelOrder);
