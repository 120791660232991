import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardSubtitle,Button, CardText, Table, Spinner, ListGroup, ListGroupItem, UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ArrowLeft, Copy } from 'react-feather';
import TrackOrder from "../orders/TrackOrder";
import { useParams } from 'react-router-dom';
 //import axios from 'axios';
import axios from '../../CommonAxios'; 

import Swal from 'sweetalert2';
import { delivery_type_id, service, states, kyc_type_id } from '../../config';
import { changeIntoDate, changeIntoDateYmd, copyToClipBoard } from '../../utlis';
import { toast, ToastContainer } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrderDetail = (props) => {
    const { orderId } = useParams();
    const [orderDetail, setOrderDetail] = useState({});
    const [walkInCustDetails, setWalkInCustDetails] = useState({});
    const [remittDetails, setRemittDetails] = useState({});
    const [freightDetails, setFreightDetails] = useState({});
    const [loader, setLoader] = useState(false);
    const [orderTracking, setOrderTracking] = useState({});

    const orderTrack = () => {
        let data = { "order_id": orderId }
        axios.post(`${process.env.REACT_APP_TRACK_ORDER}`, data)
            .then((res) => {
                setLoader(false);
                if (res.data !== undefined && res.data.fatal !== true) {
                    if (parseInt(res.data.errno) > 0) {
                        if (res.data.sqlMessage !== undefined) {
                            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                        }
                    } else {
                        if (res.fatal !== true && res.error !== true) {
                            console.log('trackk', res.data)
                            setOrderTracking(res.data.data)
                        }
                        else {
                            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
                        }
                    }
                }
            }).catch((err) => {
                Swal.fire({ icon: 'error', text: err, title: 'Error' });
                setLoader(false);
            });
    }
    useEffect(() => {
        setLoader(true);
        orderTrack();
        let data = {}
        if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
            data = { "order_id": orderId, "admin_id": 1 }
        } else {
            data = { "order_id": orderId, "merchant_id": props.user[0].id }
        }
        axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
            .then((res) => {
                setLoader(false)
                if (res.data !== undefined && res.data.fatal !== true) {
                    if (parseInt(res.data.errno) > 0) {
                        if (res.data.sqlMessage !== undefined) {
                            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                        }
                    } else {
                        setOrderDetail(res.data.orderDetail);
                        setWalkInCustDetails(res.data.walkIngCustDetails[0]);
                        setRemittDetails(res.data.RemittDetails[0]);
                        setFreightDetails(res.data.FreightDetails);
                    }
                }
            }).catch((err) => {
                setLoader(false);
                Swal.fire({ icon: 'error', text: err, title: 'Error' });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    // copy text
    const copied = (text) => {
        let decision = copyToClipBoard(text);
        decision.then(function (result) {
            if (result === true) {
                toast("Copied!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    const singleLableDownload = (data) => {
        data = {}
        data.awb_no = checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].awb_no) ? orderDetail[0].awb_no : ''
        labelDownloadCommon(data);
    }
    const labelDownloadCommon = (data) => {
        setLoader(true);
        if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
            data.logo = props.user[0].logo
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DOWNLOAD_ADDRESS_LABEL}`,
            data: data,
        }).then((res) => {
            setLoader(false);
            if (res.error !== true) {
                history.push("/my-downloads");
            }
            else {
                Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            Swal.fire({ icon: 'error', text: err, title: 'Error' });
            setLoader(false);
        });
    }
    const reCalculate = () =>{
        setLoader(true);
        let data = {}
       
         data.order_ids = checkUndeNullBlank(orderId) ? orderId : "";
        
        axios({
            method: "post", 
            url: `${process.env.REACT_APP_FREIGHT_RECALC}`,
            data: data,
        }).then((res)=>{
            setLoader(false);
            if(res.data.error === false){
                Swal.fire({title: 'Success',text: res.data.msg,icon: 'success'})
            }
        }).catch((err) => {
            console.log('err',err)
            setLoader(false);
          });

    }
    const totalFreightAmount = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.total_amt)).reduce((prev, curr) => prev + curr, 0) : 0;
    const totalForword = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.forward_charge)).reduce((prev, curr) => prev + curr, 0) : 0;
    const totalRto = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.rto_charge)).reduce((prev, curr) => prev + curr, 0) : 0;
    const totalCod = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.cod_charge)).reduce((prev, curr) => prev + curr, 0) : 0;
    const totalTax = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.tax_amt)).reduce((prev, curr) => prev + curr, 0) : 0;
    //const totalChargedWeight = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.charged_weight)).reduce((prev, curr) => prev + curr, 0) : 0;
    let totalChargedWeight=0;
    if(checkData(freightDetails)){
        freightDetails.map(item => {
            if(parseInt(item.is_suplimentary) === 1){
                totalChargedWeight+=parseFloat(item.charged_weight);
            }else{
                totalChargedWeight=parseFloat(item.charged_weight);
            }
            return true;
        })
    }
    return (
        <div className="content-body content overflow-hidden">
            <div className="OrderDetail content-wrapper ">
                <Row>
                    <Col sm={8}>
                        <Card className="p-0 my-3 shadow border-0">
                            <Card className="border-0">
                                <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                    <CardTitle className="mb-0 page-title">Order Detail</CardTitle>
                                    <div className="d-flex flex-wrap">
                                        {/* <div className="btn-text btn-light" onClick={() => singleLableDownload()}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Label</span></div> */}
                                        <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>All Shipments</span></div>
                                    </div>
                                </CardHeader>
                                {checkData(orderDetail) ?
                                    <CardBody>
                                        <div className="d-flex justify-content-between mb-4">
                                            <div>
                                                <CardTitle className="mb-0">AWB No-<span className="font-weight-500">{orderDetail[0].awb_no}</span>
                                                    <span onClick={() => copied(orderDetail[0].awb_no)} className="ml-1 icon-sm copy-btn d-none" id={`copy${orderDetail[0].id}`}>
                                                        <Copy />
                                                        <UncontrolledTooltip placement="bottom" target={`copy${orderDetail[0].id}`}>
                                                            Copy
                                                        </UncontrolledTooltip>
                                                    </span>
                                                </CardTitle>
                                            </div>
                                            <ToastContainer style={{ width: "200px" }} />
                                            <div>
                                                <CardTitle className="mb-0">Order No-<span className="font-weight-500">{orderDetail[0].order_no}</span></CardTitle>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">From</CardTitle>
                                                </CardHeader>
                                                <div>
                                                    <CardSubtitle tag="h6" className="mb-0">{orderDetail[0].MerchantPickupPointName}</CardSubtitle>
                                                    <p className="my-0 card-text d-inline-block">
                                                        <span>{orderDetail[0].pickup_address_1}</span><br />
                                                        <span>{orderDetail[0].pickup_city_name} </span>
                                                        <span>{states[orderDetail[0].pickup_state_id]}</span>
                                                        <span>{orderDetail[0].pickup_pincode}</span>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">To</CardTitle>
                                                </CardHeader>
                                                <div>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <CardSubtitle tag="h6" className="mb-0">{orderDetail[0].customer_name}</CardSubtitle><span>, {orderDetail[0].customer_mobile}</span></div>
                                                    <p className="my-0 card-text">
                                                        <span>{orderDetail[0].customer_address_1}</span><br />
                                                        <span>{orderDetail[0].customer_city}, </span>
                                                        <span>{orderDetail[0].customer_state},</span>
                                                        <span>{orderDetail[0].destination_pincode}</span>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Shiping Details</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Courier</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="d-flex align-items-start">
                                                            <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + orderDetail[0].CourierLogo} alt={orderDetail[0].CourierName} width="60" />
                                                            <p className="card-text mb-0">{orderDetail[0].CourierName}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Declared Weight</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].product_weight_in_kgs + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Dimension</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].length_in_cms + ' cm'} X {orderDetail[0].breadth_in_cms + ' cm'} X {orderDetail[0].height_in_cms + ' cm'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Volumetric Weight</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].product_vol_weight_in_kgs + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Delivery Type</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{delivery_type_id[orderDetail[0].delivery_type_id]} - {orderDetail[0].delivery_type_id === 1 && '₹ ' + orderDetail[0].product_cod_value}</p>
                                                    </Col>
                                                </Row>
                                                {checkUndeNullBlank(totalChargedWeight) && totalChargedWeight > 0 && <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Total Charged Weight</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{totalChargedWeight} Kg[s]</p>
                                                    </Col>
                                                </Row>}
                                            </Col>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Order Details</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Order Date</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{changeIntoDate(orderDetail[0].created_at)}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Booking Date</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].booking_date !== null && orderDetail[0].booking_date !== undefined ? changeIntoDate(orderDetail[0].booking_date) : 'NA'}</p>
                                                    </Col>
                                                </Row>
                                                {orderDetail[0].order_status_id === 10 && <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Delivered Date</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].last_event_date !== null && orderDetail[0].last_event_date !== undefined ? changeIntoDate(orderDetail[0].last_event_date) : 'NA'}</p>
                                                    </Col>
                                                </Row>}
                                                {orderDetail[0].order_status_id === 13 && <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">RTO Date</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].last_event_date !== null && orderDetail[0].last_event_date !== undefined ? changeIntoDate(orderDetail[0].last_event_date) : 'NA'}</p>
                                                    </Col>
                                                </Row>}
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Service</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{service[orderDetail[0].service_id]}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">System Order Number</CardSubtitle>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].system_order_no}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Table responsive borderless className="text-center customTable">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Invoice Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="col-6">{orderDetail[0].product_name}</td>
                                                    <td>{orderDetail[0].product_qty}</td>
                                                    <td>₹{orderDetail[0].product_invoice_value}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </CardBody>
                                    : loader ? <div className="w-100 text-center"><Spinner /></div> : ''}
                            </Card>
                        </Card>

                        <Row>
                            {walkInCustDetails !== undefined && walkInCustDetails !== null && Object.entries(walkInCustDetails).length > 0 ?
                                <Col sm={12}>
                                    <Card className="my-3 shadow border-0">
                                        <CardBody>
                                            <CardHeader className="px-0"><CardTitle>Walk In Customer Details</CardTitle></CardHeader>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row className="align-items-center mb-1">
                                                        <Col sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Mobile Number</CardSubtitle>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.mobile : 'NA'}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row className="align-items-center mb-1">
                                                        <Col sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Full Name</CardSubtitle>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.name : 'NA'}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row className="align-items-center mb-1">
                                                        <Col sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Email</CardSubtitle>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.email : 'NA'}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row className="align-items-center mb-1">
                                                        <Col sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Kyc ID</CardSubtitle>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <p className="card-text mb-0">{kyc_type_id[checkData(walkInCustDetails) ? walkInCustDetails.kyc_type_id : 'NA']}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row className="align-items-center mb-1">
                                                        <Col sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Kyc Number</CardSubtitle>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.kyc_no : 'NA'}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col> : ""}

                            {checkData(remittDetails) && remittDetails.trans_amt !== null && remittDetails.trans_amt > 0 &&
                                <Col sm={12}>
                                    <Card className="my-3 shadow border-0 remittance-detail">
                                        <CardBody>
                                            <CardHeader className="px-0 d-flex justify-content-between">
                                                <CardTitle>Remittance Details</CardTitle>
                                                <CardTitle tag="h4">₹{checkData(remittDetails) ? remittDetails.trans_amt : 'NA'}</CardTitle>
                                            </CardHeader>
                                            <ListGroup>
                                                <ListGroupItem className="border-0 border-bottom px-0 mb-2">
                                                    <Row>
                                                        <Col sm={6}><CardSubtitle tag="h6">Trans. Date</CardSubtitle></Col>
                                                        <Col sm={6}>{checkData(remittDetails) ? changeIntoDateYmd(remittDetails.trans_date) : 'NA'}</Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem className="border-0 px-0 mb-2">
                                                    <Row>
                                                        <Col sm={6}><CardSubtitle tag="h6">Trans. ID</CardSubtitle></Col>
                                                        <Col sm={6}>{checkData(remittDetails) ? remittDetails.trans_id : 'NA'}</Col>
                                                    </Row>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </CardBody>
                                    </Card>
                                </Col>}
                            {checkData(freightDetails) &&
                                <Col sm={12}>
                                    <Card className="d-none my-3 shadow border-0 freight-detail">
                                        <CardBody>
                                            <CardHeader className="px-0 d-flex justify-content-between">
                                                <CardTitle>Freight Details</CardTitle>
                                            </CardHeader>
                                            <ListGroup>
                                                <ListGroupItem className="border-0 border-bottom px-0 mb-2">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div><CardSubtitle tag="h6">Total Forward</CardSubtitle></div>
                                                                <div>₹ {totalForword || '0'}</div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} className="border-left border-right">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div><CardSubtitle tag="h6">Total RTO</CardSubtitle></div>
                                                                <div>₹ {totalRto || '0'}</div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} className="border-left border-right">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div><CardSubtitle tag="h6">Total COD</CardSubtitle></div>
                                                                <div>₹ {totalCod || '0'}</div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div><CardSubtitle tag="h6">Total Tax</CardSubtitle></div>
                                                                <div>₹ {totalTax || '0'}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem className="border-0 px-0 mb-2">
                                                    <Col sm={12} className="pr-0">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div><CardSubtitle tag="h6">Total Amount</CardSubtitle></div>
                                                            <div>₹ {checkUndeNullBlank(totalFreightAmount) ? totalFreightAmount.toFixed(2) : 0}</div>
                                                        </div>
                                                    </Col>
                                                </ListGroupItem>
                                            </ListGroup>
                                            <div className="d-flex justify-content-between border-top pt-2">
                                                {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                                                    <div>
                                                        <Button className="ctm-btn btn-sm mx-2" color="primary" onClick={reCalculate}>Freight Recalculate</Button>
                                                    </div>}
                                                {checkUndeNullBlank(totalChargedWeight) && <CardText tag="p" className="text-right ml-auto"><span>Total Charged Weight : </span> <span className="font-weight-bold">{totalChargedWeight} Kg[s]</span></CardText>}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>}
                        </Row>

                    </Col>
                    <Col sm={4}>
                        <Card className="p-0 my-3 shadow border-0">
                            <TrackOrder orderTrack={orderTrack} orderTracking={orderTracking} lastStatus={checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].order_status_id) && orderDetail[0].order_status_id} statusRecheckOrderId={[orderId]} />
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default OrderDetail;