import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardImg, UncontrolledCollapse, Modal, ModalHeader,Input, ModalBody, FormGroup, Label, Spinner, Container} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowLeft } from 'react-feather';
// import axios from 'axios';
import axios from '../../CommonAxios';
import {useParams} from 'react-router-dom'
import { faArrowsAlt, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import PricingTable from '../pricing/PricingTable';
import Swal from 'sweetalert2'
import CourierPriority from './CourierPriority';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
const configData = require("../../config");

const MerchantPricing = (props) => {
  let merchantName = (new URLSearchParams(window.location.search)).get("merchantName")
    const [serviceType, setServiceType] = useState('1');
    const [movementId, setMovementId] = useState('');
    const [addmodal, setAddmodal] = useState(false);
    const [courierAdd, setCourierAdd] = useState(false);
    const [courierId, setCourierId] = useState(false);
    const [freeCourier, setFreeCourier] = useState();
    const [courierList, setCourierList] = useState({});
    const [courierPricing, setCourierPricing] = useState([]);
    const {merchantId} = useParams();
    const [loader, setLoader] = useState(false);
    const getCourierStatus = (courier_id, e )=> {
    setLoader(true);
  axios({
    method: "post",
    url : process.env.REACT_APP_MERCHANT_COURIER_STATUS,
    data: {"merchant_id" : merchantId, "status_id" : e.target.checked ? e.target.value : '0', "courier_id" : courier_id}
  }).then(function (response) {
    if(response.data.error === false){
    setLoader(false)
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
      })
    }
      couurierListing()
  }).catch(function (err){
    Swal.fire({title:'Error', icon:'error', text:err})
  })

};
const couurierListing = () => {
  setLoader(true)
  axios({
    method: "post",
    url : process.env.REACT_APP_MERCHANT_COURIER_LIST,
    data: {"merchant_id" : merchantId}
  }).then(function (response) {
    setLoader(false)
    if(response.data.error===false){
      setCourierList(response.data.data)
      courierPriceing();
    }else{
      Swal.fire({ title: 'Error',text: response.data.msg?response.data.msg:'Something went wrong',icon: 'error'});
    }
    
  }).catch(function (err){
    setLoader(false);
    Swal.fire({title:'Error', icon:'error', text:err})
  })
}
const courierPriceing = () => {
  setLoader(true);
  axios({
    method: "post",
    url : process.env.REACT_APP_MERCHANT_COURIER_PRICING,
    data: {"merchant_id" : merchantId}
  }).then(function (response) {
    setLoader(false);
    if(response.data.fatal !== true){
    setCourierPricing(response.data)
    }
  }).catch(function (err){
    setLoader(false);
    Swal.fire({title:'Error', icon:'error', text:err})
  })
}

 useEffect(() => {
  couurierListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [merchantId])


  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list)
  //   const [removed] = result.splice(startIndex, 1)
  //   result.splice(endIndex, 0, removed)
  //   return result
  // }

  const toggleAdd = () => {
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_FREE_COURIER}`,
      data:{"merchant_id":merchantId}
    })
      .then(function (response) {
        setLoader(false);
        setFreeCourier(response.data.courier)
      }).catch(function (err) {
        setLoader(false);
        Swal.fire({title:'Error', icon:'error', text:err})
      });
    setAddmodal(!addmodal)
  };
  const courierShort = () => setCourierAdd(!courierAdd);
  const handleCourierChoose =(e) => {
    let index =  e.target.selectedIndex;
     var optionElement= e.target.childNodes[index];
     var my_movement_type_id= optionElement.getAttribute('data-id');
      setMovementId(my_movement_type_id)

    setCourierId(e.target.value)
}
 return (
    <div className="content-body overflow-hidden">
    {loader && <div className="formLoader"><Spinner /></div>}
    <Container fluid>
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 merchantPricing shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Manage {merchantName} Pricing</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <Link to='/merchants' className="btn-text btn-light mx-1"><span className="icon"><ArrowLeft /></span><span>Back</span></Link>
                <div className="btn-text btn-light mx-2" onClick={courierShort}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAlt} /></span><span>Priority</span></div>
                <div className="btn-text btn-light mx-2" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add New Courier</span></div>
              </div>
              <Modal isOpen={addmodal} toggle={addmodal} className="modal-xl"  scrollable={true}>
                <ModalHeader toggle={toggleAdd}>ADD Courier Pricing</ModalHeader>
                <ModalBody>
                      {freeCourier !== undefined && Object.entries(freeCourier).length >0 ?
                    <Row className="mx-0 justify-content-center addModal">
                      <Col sm={12}>
                      <FormGroup>
                        <Label for="selectCourier">Select Courier</Label>
                        <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select className="form-control-sm form-control" onChange={handleCourierChoose}>
                              <option>Select Courier</option>
                              {freeCourier !== undefined && Object.entries(freeCourier).length >0 ? freeCourier.map((item, index) => (
                              <option data-id={item.movement_type_id} value={item.id}>{item.full_name}</option>
                            ))
                          :''}
                          </select>
                        </div>
                      </FormGroup>
                      </Col>
                      <Col sm={12}>
                        {movementId !== '' &&
                          <PricingTable movement_type_id={movementId} courierId={courierId} merchantId={merchantId} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD}  user={props.user} fType={checkData(courierList.merchantFreightType)&&checkUndeNullBlank(courierList.merchantFreightType[0].freight_type)?courierList.merchantFreightType[0].freight_type:''} />
                        }
                      </Col>
                    </Row> 
                      :<Col sm={12} className="d-flex justify-content-center"><Spinner /></Col>
                    }
                </ModalBody>
              </Modal>
              <Modal isOpen={courierAdd} toggle={courierAdd} className="modal-lg">
                <ModalHeader toggle={courierShort}>Courier Priority</ModalHeader>
                <ModalBody>
                <div className="d-flex justify-content-center mt-3 py-2">
                    <FormGroup check className="mr-4">
                        <Label check>
                            <Input type="radio" name="courier_type" onClick={() => setServiceType('1')} defaultChecked/>
                            Express
                        </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                        <Label check>
                            <Input type="radio" name="courier_type" onClick={() => setServiceType('7')} />
                            Surface
                        </Label>
                    </FormGroup>
                </div>
                  <CourierPriority merchantId={merchantId} serviceType={serviceType} shipMethod={'1'} setCourierAdd={setCourierAdd} /> 
                </ModalBody>
              </Modal>
              </CardHeader>
              {courierList.courierList !== null && courierList.courierList !== undefined && Object.entries(courierList.courierList).length >0 ? courierList.courierList.map((item, index) => (
              <>
              <Card className="m-1">
                  <CardBody className="d-flex justify-content-between align-items-center">
                  <div id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faChevronDown} /></div>
                  <div id={`couriertoggler${item.courier_id}`}>Courier ID - <span className="font-weight-500">{item.courier_id}</span></div>
                  <div className="courierLogo" id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}> <CardImg top src={`${process.env.REACT_APP_BASE_URL+'/'+courierList.path+'/'+item.logo}`} alt="logo" style={{maxWidth:'60px'}} /></div>
                  <div className="courierName" id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}>{item.brand_name}</div>
                  <div class="form-check form-switch courierSwitch">
                    <input class="form-check-input" onClick={(e) => getCourierStatus(item.courier_id, e)} value="1" type="checkbox" style={{cursor:'pointer'}} defaultChecked={item.status===1}/>
                    <label class="form-check-label" for="flexSwitchCheckDefault" style={{cursor:'pointer'}}>{configData.status[item.status]}</label>
                  </div>
                  </CardBody>
              </Card>
              <UncontrolledCollapse toggler={`couriertoggler${item.courier_id}`}>
               <CardBody className={`px-0 ${item.courier_id}`}>
                 {courierPricing !== undefined && courierPricing !== null && Object.entries(courierPricing).length >0 ?
               <PricingTable movement_type_id={item.movement_type_id} pricingList={courierPricing[item.courier_id !== undefined && item.courier_id]} courierId={item.courier_id} merchantId={merchantId} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD}  user={props.user} fType={checkData(courierList.merchantFreightType)&&checkUndeNullBlank(courierList.merchantFreightType[0].freight_type)?courierList.merchantFreightType[0].freight_type:''} />
               :'Loading...'
              }
              </CardBody>
              </UncontrolledCollapse>
              </>
              )): loader? <Col sm={12} className="text-center">loading..</Col> :<Col sm={12} className="text-center">No Data Found</Col>}
            </Card>
        </Card>
      </Col>
      </Row>
      </Container>
       </div>
  );
}

export default MerchantPricing;