import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, Collapse, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import axios from '../../CommonAxios';
import { useParams } from 'react-router-dom'
import Status from '../Status';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { changeIntoDate } from '../../utlis';
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';

const PickupPoints = (props) => {
  const [pickupList, setPickupList] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropFilterCount = 20
  const { merchantId } = useParams();

  const merchantPickupPointListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let data = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data = { "merchant_id": props.user[0].id }
    } else {
      data = { "merchant_id": merchantId }
    }
    setLoader(true)
    axios({
      method: "post",
	  headers: {
		"x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl",
      },
      url: process.env.REACT_APP_MERCHANT_PICKUPPOINT,  
      data: data,
    })
      .then(function (response) {
        setLoader(false)
        if (response.data !== true) {
          setLoadertable(false)
          setPickupList(response.data)
        }
      })
      .catch(function (response) {
        setLoadertable(false);
        Swal.fire({ title: 'Error', icon: 'error', text: response })
      });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(pickupList.data) && checkUndeNullBlank(pickupList.totalPage)) {
      // &&checkUndeNullBlank(pickupList.totalPage).length>page.length
      if (pickupList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(pickupList.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(pickupList.data)
      if (currentCount > Object.entries(pickupList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  useEffect(() => {
    merchantPickupPointListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [merchantId])
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    } else {
      mydata = { "merchant_id": merchantId }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_PICKUPPOINT}?contact_name=${checkUndeNullBlank(data.brand_name) ? data.brand_name : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: mydata,
    }).then(function (response) {
      setLoader(false);
      if (response.data.fatal !== true) {
        setPickupList(response.data)
      }
    }).catch(function (response) {
      setLoader(false);
      Swal.fire({ title: 'Error', icon: 'error', text: response })
    });
  };
  const onFilter = () => setIsOpen(!isOpen);
  return (
    <div className="content-body content overflow-hidden">
     <div className="container-fluid">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
			<Card className="p-0 my-3 master-content content-wrapper border-0">
			<div className="page-header content-header">
                
					<div className="card-title title_icon">
					<i className="bi bi-card-heading"></i> 
					Manage Hubs   
					 
					<div className="card-header-right d-flex align-items-center ml-auto">
					{checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
					  <>
						<Link to='/merchants' className="btn-text btn-light mx-1"><span className="icon"><ArrowLeft /></span><span>Merchant List</span></Link>
					  </>
					}
					{(checkPermission(props.user, props?.user[0]?.permissions?.merchant_Warehouses?.add) || checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant") &&
					  <Link to={`/add-pickuppoint/${checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" ? props.user[0].id : merchantId}`} className="btn-text btn-light mx-1"><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Warehouse</span></Link>
					}
					<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
					</div>
					 
					</div>
			   
            
            <Collapse isOpen={isOpen}>
              <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
                <Row className="mx-0 pt-1 justify-content-start justify-content-sm-center">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Enter Brand Name" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={merchantPickupPointListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
            </Collapse>
            <CardBody className="p-0">
              {checkData(pickupList) && checkData(pickupList.data) && checkUndeNullBlank(pickupList.TotalRows) ?
                <>
                  <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                    <div className="showing-count">
                      Showing {currentCount} to {(currentCount - 1) + (Object.entries(pickupList.data).length)} of {pickupList.TotalRows} entries
                    </div>
                    {pickupList.TotalRows > dropFilterCount ?
                      <Form>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </FormGroup>
                      </Form>
                      : ''}
                  </div>
                </>
                : ''}
              <Table responsive borderless className="text-center customTable">
                <thead>
                  <tr>
                    <th>Warehouse ID</th>
                    <th className="text-left">Stores Name</th>
                    <th>Mobile</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {checkData(pickupList.data) ? pickupList.data.map((item, index) => (
                    <tr>
                      <td>{item.id}</td>
                      <td className="text-left">
                        <Link to={`/edit-pickuppoint/${item.id}`} className="trade"><span>{item.contact_name}</span></Link>
						 
                      </td>
                      <td>{item.pickup_phone}</td>
                      <td className="text-nowrap">{changeIntoDate(item.created_at)}</td>
                      <td className="text-nowrap">{changeIntoDate(item.updated_at)}</td>
                      <td><span><Status statusUrl={process.env.REACT_APP_MERCHANT_PICKUPPOINT} listId={item.id} valStatus={item.status} reListing={merchantPickupPointListing} data={{ "pickup_point_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span></td>
                    </tr>
                  ))
                    : loadertable ? <tr><td colSpan="6">Loading...</td></tr> : <tr><td colSpan="6">No Data Found</td></tr>}
                </tbody>
              </Table>
              {checkData(pickupList) && checkData(pickupList.data) && checkUndeNullBlank(pickupList.TotalRows) ?
                <>
                  {pickupList.TotalRows > dropFilterCount ?
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                        Showing {currentCount} to {(currentCount - 1) + (Object.entries(pickupList.data).length)} of {pickupList.TotalRows} entries
                      </div>
                      <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                          <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                    : ''}
                </>
                : ''}
            </CardBody>
			</div>
          </Card>
        </Col>

      </Row>
	  </div>
    </div >
  );
}

export default PickupPoints;