import React from 'react';
import CourierRemittance from './CourierRemittance';

const CourierRemittancePage = (props) => {

    return (
        <div className="CourierRemittancePage">
            <div className="mb-4"><CourierRemittance user={props.user} /></div>
        </div>
    );
}

export default CourierRemittancePage;