import React, {useEffect,useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, Spinner} from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';

import Swal from 'sweetalert2'

const CourierRegulatoryDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const [gstCopy, setGstCopy] = useState({});
  const [panCopy, setPanCopy] = useState({});
  const [regutaloryDetails, setRegutaloryDetails] = useState({});

  const handleFileInputpan = (e) => {
    setPanCopy(e.target.files[0])
}
  const handleFileInputgst = (e) => {
    setGstCopy(e.target.files[0])
}
    
  const { register:regulatory, handleSubmit:regulatorySubmit, formState: { errors: errors3 }, reset:reset3 } = useForm();
  useEffect(() => {
    axios({
          method: "post",
          url: `${process.env.REACT_APP_COURIER_REGULATRYDETAILS}/view`,
          data: {"courier_id":props.courierId !== undefined ? props.courierId:''},
        })
          .then(function (response) {
            let myResponse=response.data[0];
            reset3(myResponse);
            setRegutaloryDetails(myResponse);
          })
          .catch(function (response) {
          });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reset3])
    const onRegulatory = (data) => {
      setLoader(true);
      let formData = new FormData();
      formData.append("courier_id", props.courierId !== undefined ? props.courierId:'');
      formData.append("pan_card_no", data.pan_card_no);
      formData.append("pan_card", panCopy !== '' ? panCopy : regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== "" ? regutaloryDetails.pan_card : 'NA');
      formData.append("gst_number", data.gst_number);
      formData.append("gst_file", gstCopy !== '' ? gstCopy : regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== "" ? regutaloryDetails.gst_file : 'NA');
    
      axios
        .post(process.env.REACT_APP_COURIER_REGULATRYDETAILS, formData)
        .then((res) => {
      setLoader(false);
      if(res.data.error === false){
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
            })
          }
        })
        .catch((err) => {
      setLoader(false);
      Swal.fire({title:'Error', text:err, icon:'error'});
        });
    };
  const onregulatoryError = (errors3, e) => console.log('Error', errors3);


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={regulatorySubmit(onRegulatory,onregulatoryError)}>
              <Row className="mx-0 pt-3">
                <Col sm={12} md={6} xl={6} className="mb-2">
                    <FormGroup>
                      <Label for="panNumber">Enter Pan Card Number</Label>
                      <input {...regulatory("pan_card_no",{ required: 'Please Enter Pan Card Number'})} className="form-control-sm form-control" placeholder="PAN Number" id="panNumber" />
                      {errors3.pan_card_no && <span className="text-danger d-block mb-2">{errors3.pan_card_no.message}</span>}
                    </FormGroup>                      
                </Col>
                <Col sm={12} md={6} xl={6} className="mb-2">
                    <FormGroup>
                      <Label for="uploadPan">Upload Pan Card</Label>
                      <input {...regulatory("pan_cards", { required: regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== "" ? false: true && 'Please Add Pan Card Copy'})} type="file" onChange={handleFileInputpan} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadPan" />
                      {regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== null &&<div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+regutaloryDetails.pan_card} alt="logo" style={{width:"120px"}} /></div>}
                      {errors3.pan_cards && <span className="text-danger d-block mb-2">{errors3.pan_cards.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={6} className="mb-2">
                    <FormGroup>
                      <Label for="gstNumber">Enter GST Number</Label>
                      <input {...regulatory("gst_number", { required: 'Please Enter GST Number'})}  className="form-control-sm form-control" placeholder="GSTIN Number" id="gstNumber" />
                      {errors3.gst_number && <span className="text-danger d-block mb-2">{errors3.gst_number.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={6} className="mb-2">
                    <FormGroup>
                      <Label for="uploadGst">Upload GST Copy</Label>
                      <input {...regulatory("gst_files", { required: regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== "" ? false: true && 'Please Add GST Copy'})} type="file" onChange={handleFileInputgst} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadGst" />
                      {regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+regutaloryDetails.gst_file} alt="logo" style={{width:"120px"}} /></div>}
                      {errors3.gst_files && <span className="text-danger d-block mb-2">{errors3.gst_files.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="text-right">
                  <Button className="btn  btn-sm save" color="primary">Save</Button>
                </Col>
              </Row>
            </Form>
        </>
    );
}

export default CourierRegulatoryDetails;
