import React, {useState, useEffect} from 'react';
import { Row, Col, Label, Form, FormGroup, Input, CardTitle, Collapse, Card, CardBody, CardText, Button, Spinner } from 'reactstrap';
import customIcon from '../../../assets/images/custom-icon.png';
import economicalIcon from '../../../assets/images/economical-icon.png';
import performanceIcon from '../../../assets/images/performance-icon.png';
import CourierPriority from '../CourierPriority';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2';

const CourierSetting = (props) => {

    const [isCustom, setIsCustom] = useState(true);
    const [serviceType, setServiceType] = useState('1');
    const [shipMethod, setShipMethod] = useState('1');
    const [loader, setLoader] = useState(false);
  const toggleCutom = () => setIsCustom(!isCustom);
    useEffect(() => {
    let data = {"merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'', "setting_id":'2',}
      axios.post(`${process.env.REACT_APP_MERCHANT_COURIERSETTING_VIEW}`, data)
        .then((res) => {
          if(res.data !== undefined && res.data.fatal !== true){
            if(parseInt(res.data.errno)>0){
              if(res.data.sqlMessage!==undefined){
                Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
              }
            }else{
              if(res.data.error === false){
                setShipMethod(res.data[0].setting_value)
              }
            }
          }
        }).catch((err) => {
          Swal.fire({icon:'error',text:err,title:'Error'});
        });
    })
  const submitCourierSetting = () => {
    setLoader(true);
    let data = {"merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'', "setting_id":'2', "setting_value" : shipMethod}
        axios.post(`${process.env.REACT_APP_MERCHANT_UPDATE_COURIERPRIORITY}`, data)
        .then((res) => {
          setLoader(false);
          if(res.data !== undefined && res.data.fatal !== true){
            if(parseInt(res.data.errno)>0){
              if(res.data.sqlMessage!==undefined){
                Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
              }
            }else{
              if(res.data.error === false){
                Swal.fire({title:'Successful', text:res.data.msg, icon:'success'})
              }else{
                Swal.fire({title:'Error', text:res.data.msg, icon:'error'})
              }
            }
          }
        }).catch((err) => {
          setLoader(false);
          Swal.fire({icon:'error', text:"Something went wrong", title:'Error'})
        });
  }
    return (
      <>
        {loader && <div className="formLoader"><Spinner /></div>}
        <Form>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Courier Setting</CardTitle>
            </div>
            <Row>
                <Col sm={12}>
                  <Row className="d-none">
                    <Col sm={4}>
                      <Card className="font-weight-500">
                        <CardBody className="text-center">
                          <div className="pb-2"><img src={customIcon} style={{height: "100px"}} alt="custom" /></div>
                          <FormGroup className="my-2">
                            <FormGroup check >
                                <Label check className="font-weight-500">
                                    <Input type="radio" name="cep_name" onClick={() => {toggleCutom(); setShipMethod('1');}} defaultChecked/>
                                    Custom
                                </Label>
                            </FormGroup>
                          </FormGroup>
                          <CardText className="text-muted">Some quick example text to build </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card className="font-weight-500">
                        <CardBody className="text-center">
                          <div className="pb-2"><img src={economicalIcon} style={{height: "100px"}} alt="economical" /></div>
                          <FormGroup className="my-2">
                            <FormGroup check className="font-weight-500">
                                <Label check>
                                    <Input type="radio" name="cep_name" onClick={() => {setIsCustom(false); setShipMethod('2')}} />
                                    Economical
                                </Label>
                            </FormGroup>
                          </FormGroup>
                          <CardText className="text-muted">Some quick example text to build </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card className="font-weight-500">
                        <CardBody className="text-center">
                          <div className="pb-2"><img src={performanceIcon} style={{height: "100px"}} alt="performance" /></div>
                          <FormGroup className="my-2">
                            <FormGroup check className="font-weight-500">
                                <Label check>
                                    <Input type="radio" name="cep_name" onClick={() => {setIsCustom(false);setShipMethod('3')}} />
                                    Performance
                                </Label>
                            </FormGroup>
                          </FormGroup>
                          <CardText className="text-muted">Some quick example text to build </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>                
                <Collapse isOpen={isCustom}>
                    <div className="d-flex justify-content-center mt-3 py-2">
                        <FormGroup check className="mr-4">
                            <Label check>
                                <Input type="radio" name="courier_type" onClick={() => setServiceType('1')} defaultChecked/>
                                Express
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mr-4">
                            <Label check>
                                <Input type="radio" name="courier_type" onClick={() => setServiceType('7')} />
                                Surface
                            </Label>
                        </FormGroup>
                    </div>
                    {shipMethod === '1' &&
                   <CourierPriority serviceType={serviceType} shipMethod={shipMethod} user={props.user} />
    }
                </Collapse>
                {shipMethod !== '1' &&
                <Col sm={12} className="text-right mt-4">
                    <Button className="btn btn-sm" color="primary" onClick={submitCourierSetting}>Save</Button>
                </Col>  
                }     
            </Row>
        </Form>
        </>
    );
}

export default CourierSetting;