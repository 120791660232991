import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, Table, Spinner, Col, Form, FormGroup, CardBody } from 'reactstrap'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDatedmY } from '../../utlis';
import AddAdmin from './AddAdmin';
import EditAdmin from './EditAdmin';

const AllAdmin = (props) => {
    const [addmodal, setAddmodal] = useState(false);
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editmodal, setEditmodal] = useState(false);
    const [editData, setEditData] = useState({});
    const [permission, setPermission] = useState({});


    const toggleAdd = () => {
        setAddmodal(!addmodal);
    }
    
    const toggleEdit = (id) => {
        setEditmodal(!editmodal);
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setLoader(true);
        http.post(`admin/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                console.log('res.data.s', res.data.data[0])
                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                res.data.data[0].confirm_password = res.data.data[0].password
                res.data.data[0].permissions = checkUndeNullBlank(res.data.data[0].permissions) ? JSON.parse(res.data.data[0].permissions) : ''
                setEditData(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`admin/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const permissionApi = () => {
        let data = { admin_id: checkData(props.user) ? props.user.id : '', }
        setLoader(true);
        http.post(`admin/permissions`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setPermission(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    useEffect(() => {
        onApiInteg();
        permissionApi();
    }, []);
    console.log('editData', editData)
    return (
        <div className="content-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <AddAdmin addmodal={addmodal} toggleAdd={toggleAdd} setAddmodal={setAddmodal} onApiInteg={onApiInteg} permission={permission} />
            {checkData(editData) &&
                <EditAdmin addmodal={editmodal} toggleAdd={toggleEdit} editData={editData} setEditmodal={setEditmodal} onApiInteg={onApiInteg} permission={permission} />
            }
            <Row>
                <Col sm={12}>
                    <Card className="p-0 manageOrder shadow content-wrapper border-0">
                        <Card className="border-0">
                            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                <CardTitle className="page-title">Manage Admin</CardTitle>
                                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                                    <Button color="primary" className="vs-btn border-0" onClick={toggleAdd}>Add Admin</Button>
                                </div>
                            </CardHeader>
                            <Form className="filter-form">
                                <Row className="mx-0 justify-content-start justify-content-lg-center">
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <input className="form-control-sm form-control" placeholder="Enter title*" id="title" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3} className="filter-btn">
                                        <Button  className="btn  btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <CardBody className="p-0">
                                <div className="showing-count">
                                    {/* {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                                            <span class="small">Showing 1 of 20</span> : ''} */}
                                </div>
                                <Table responsive borderless className="text-center customTable">
                                    <thead>
                                        <tr>
                                            <th className="text-nowrap">ID</th>
                                            <th className="text-nowrap">Name</th>
                                            <th className="text-nowrap">Email</th>
                                            <th className="text-nowrap">User Type</th>
                                            <th className="text-nowrap">Created At</th>
                                            <th className="text-nowrap">Updated At</th>
                                            <th className="text-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {checkData(apiData) ? apiData.map((item, index) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.user_type}</td>
                                                <td>{changeIntoDatedmY(item.created_at)}</td>
                                                <td>{changeIntoDatedmY(item.updated_at)}</td>
                                                <td><Button color="primary" className="vs-btn border-0" onClick={() => toggleEdit(item.id)}>Edit</Button></td>
                                            </tr>
                                        )) : ''}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AllAdmin