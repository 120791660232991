import React, {useEffect, useState} from 'react';
import { Row, Col,Button, FormGroup,Form,InputGroup, InputGroupAddon, InputGroupText, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2'
import { checkData } from '../../ObjectExist';
import { Eye, EyeOff } from 'react-feather';
const EditMerchant = (props) => {
  const [logo, setLogo] = useState();
  const [passwordTypeView, setPasswordTypeView] = useState(true);
  const [passwordType, setPasswordType] = useState(true);

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
}
  
const { register:editMerchantdata, handleSubmit:merchantEditSubmit,formState: { errors: errors2 }, reset:reset2} = useForm();
 
  useEffect(() => {
    reset2(props.editMerchant[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reset2])
const onEditMMerchantSubmit = (data) => {
  let formData = new FormData();
  formData.append("admin_id", checkData(props.user[0]) ? props.user[0].id : '');
  formData.append("company_name", data.company_name);
  formData.append("brand_name", data.brand_name);
  formData.append("email", data.email);
  formData.append("billing", data.billing);
  formData.append("freight_type", data.freight_type);
  formData.append("ship_status", data.ship_status);
  formData.append("document_status", data.document_status);
  formData.append("status", data.status);
  formData.append("mobile", data.mobile);
  formData.append("wallet_balance", data.wallet_balance);
  formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
  formData.append("max_cod_value_per_order", data.max_cod_value_per_order);
  formData.append("remittance_tat_period", data.remittance_tat_period);
  formData.append("refer_by", data.refer_by);
  formData.append("logo", logo !== undefined && logo !== '' ? logo : props.editMerchant[0].logo);

  axios
    .post(`${process.env.REACT_APP_GET_MERCHANT}/update/${props.editMerchant[0].id}`, formData)
    .then((res) => {
      if(res.data.error === false){
          props.seteditModal(false);
          props.tableDataFetch();
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    }).catch((err) => Swal.fire({title: 'Error', text: err, icon: 'error',}));
};
const onErrorUpdate = (errors2, e) => console.log('Error', errors2);

  
  return (
    
        <Form onSubmit={merchantEditSubmit(onEditMMerchantSubmit,onErrorUpdate)}>
        <Row className="mx-0 justify-content-center addModal">
            <Col sm={6}>
                <FormGroup>
                <Label for="companyName_edit">Enter Company Name</Label>
                <input {...editMerchantdata("company_name",{ required: 'Please Enter Company Name'})} className="form-control-sm form-control" placeholder="Enter Company Name*" id="companyName_edit" />
                {errors2.company_name && <span className="text-danger d-block mb-2">{errors2.company_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="email_edit">Enter Email</Label>
                <input {...editMerchantdata("email",{ required: 'Please Enter Email'})} type="email" className="form-control-sm form-control" placeholder="Enter Email*" id="email_edit" />
                {errors2.email && <span className="text-danger d-block mb-2">{errors2.email.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="brandName_edit">Enter Stores Name</Label>
                <input {...editMerchantdata("brand_name",{ required: 'Please Stores Name'})} className="form-control-sm form-control" placeholder="Enter Stores Name*" id="brandName_edit" />
                    {errors2.brand_name && <span className="text-danger d-block mb-2">{errors2.brand_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="contact_edit">Enter Phone Number</Label>
                <input {...editMerchantdata("mobile",{ required: 'Please Enter Phone Number'})} type="number" className="form-control-sm form-control" placeholder="Enter Phone Number*" id="contact_edit" />
                    {errors2.mobile && <span className="text-danger d-block mb-2">{errors2.mobile.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="walletBlance_edit">Enter Wallet Balance</Label>
                <input {...editMerchantdata("wallet_balance",{ required: 'Please Wallet Balance'})} type="number" readOnly className="form-control-sm form-control" placeholder="Enter Wallet Balance*" id="walletBlance_edit" />
                    {errors2.wallet_balance && <span className="text-danger d-block mb-2">{errors2.wallet_balance.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="maxLiaShip_edit">Enter Max Liability Shipment</Label>
                <input {...editMerchantdata("max_liability_per_shipment",{ required: 'Please Enter Max Liability Shipment'})} type="number" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiaShip_edit" />
                    {errors2.max_liability_per_shipment && <span className="text-danger d-block mb-2">{errors2.max_liability_per_shipment.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
                    <input {...editMerchantdata("remittance_tat_period",{ required: 'Please Enter Remittance TAT Period'})} className="form-control-sm form-control" placeholder="Enter Remittance TAT Period*" defaultValue="5" id="remittance_tat_period_add" />
                    {errors2.remittance_tat_period && <span className="text-danger d-block error">{errors2.remittance_tat_period.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="refer_by_add">Refer By</Label>
                <input {...editMerchantdata("refer_by",{ required: false})} className="form-control-sm form-control" placeholder="Enter Refer By" id="refer_by_add" />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
                    <input {...editMerchantdata("max_cod_value_per_order",{pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Max COD Value'}})} type="text" className="form-control-sm form-control" placeholder="Enter Max COD Value*" id="maxCodVal_add" />
                    {errors2.max_cod_value_per_order && <span className="text-danger d-block error">{errors2.max_cod_value_per_order.message}</span>}
                </FormGroup>
            </Col>
            {/* <Col sm={6}>
                <FormGroup>
                <Label for="password_edit">Enter Password</Label>
                <input {...editMerchantdata("password", { required: 'Please Enter Password'})} type="password" className="form-control-sm form-control" placeholder="Enter Password*" id="password_edit" />
                    {errors2.password && <span className="text-danger d-block mb-2">{errors2.password.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="confirmPass_edit">Enter Confirm Password</Label>
                <input {...editMerchantdata("confirmPassword", { required: 'Please Enter Confirm'})} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password*" id="confirmPass_edit" />
                    {errors2.confirmPassword && <span className="text-danger d-block mb-2">{errors2.confirmPassword.message}</span>}
                </FormGroup>
            </Col> */}
            <Col sm={6}>
                <FormGroup>
                <Label for="password">Password</Label>
                <InputGroup>
                    <input {...editMerchantdata("password")} className="form-control-sm form-control" type={passwordTypeView? "password" : "text"} readOnly placeholder="Password" id="" />
                    <InputGroupAddon addonType="append" onClick={() => setPasswordTypeView(!passwordTypeView)}>
                    <InputGroupText className="py-0"> {passwordTypeView ? <EyeOff /> : <Eye />}</InputGroupText>
                    </InputGroupAddon>
                </InputGroup> 
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="brandLogo_edit">Upload Stores Logo</Label>
                <input {...editMerchantdata("brandlogo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="brandLogo_edit" />
                <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT+props.editMerchant[0].logo} alt="logo" style={{width:"150px"}} /></div>
                    {errors2.brandlogo && <span className="text-danger d-block mb-2">{errors2.brandlogo.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
            <FormGroup>
                <FormGroup check className="pl-0">
                <Label>Billing</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2" {...editMerchantdata("billing", { required: 'Please Choose Billing' })} type="radio" value="2" />
                        Prepaid Billing
                        </Label> 
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...editMerchantdata("billing", { required: 'Please Choose Billing' })} type="radio" value="1" />
                        Postpaid Billing
                        </Label>
                    </span>
                    </div>
                {errors2.billing && <span className="text-danger d-block error">{errors2.billing.message}</span>}
                </FormGroup>
            </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <FormGroup check className="pl-0">
                    <Label>Freight Type</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2"  {...editMerchantdata("freight_type", { required: 'Please Choose Freight Type' })} type="radio" value="1" id="fixCharge_add" />
                        Fixed Charge
                        </Label>
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...editMerchantdata("freight_type", { required: 'please Choose Freight Type' })} type="radio" value="2" id="varCharge_add" />
                        Variables Charge
                        </Label>
                    </span>
                    </div>
                {errors2.freight_type && <span className="text-danger d-block error">{errors2.freight_type.message}</span>}
                </FormGroup>
                </FormGroup>
            </Col>
            <Col sm={6}>
            <FormGroup>
                <FormGroup check className="pl-0">
                <Label>Ship Status</Label>
                <div>
                    <span className="mr-2">
                    <Label check>
                        <input className="mx-2" {...editMerchantdata("ship_status", { required: 'Please Choose Ship Status' })} type="radio" value="1" id="active_add" />
                        Active
                    </Label>
                    </span>
                    <span>
                    <Label check>
                        <input className="mx-2" {...editMerchantdata("ship_status", { required: 'Please Choose Ship Status' })} type="radio" value="0" id="inactive_add" />
                        InActive
                    </Label>
                    </span>
                </div>
                {errors2.ship_status && <span className="text-danger d-block error">{errors2.ship_status.message}</span>}
                </FormGroup>
            </FormGroup>
            </Col> 
            <Col sm={6}>
                <FormGroup>
                    <FormGroup check className="pl-0">
                    <Label>Document Status<span className="text-danger">*</span></Label>
                    <div>
                        <span className="form-check2 mr-2">                               
                            <input className="mx-2" {...editMerchantdata("document_status", { required: 'Please Choose Document Status' })} type="radio" value="1" id="validate" />
                            <Label for="validate">Validated</Label>
                        </span>
                        <span className="form-check2">                                
                            <input className="mx-2" {...editMerchantdata("document_status", { required: 'Please Choose Document Status' })} type="radio" value="0" id="not_validate" />
                            <Label for="not_validate">Not Validated</Label>
                        </span>
                    </div>
                    {errors2.ship_status && <span className="text-danger d-block error">{errors2.ship_status.message}</span>}
                    </FormGroup>
                </FormGroup>
                </Col>                     
            <Col sm={6}>
                <FormGroup check className="pl-0">
                <Label>Status</Label>
                <div>
                    <span className="mr-2">
                    <Label check>
                        <input className="mx-2" {...editMerchantdata("status", { required: true })} type="radio" value="1" id="active_add" />
                        Active
                    </Label>
                    </span>
                    <span>
                    <Label check>
                        <input className="mx-2" {...editMerchantdata("status", { required: true })} type="radio" value="0" id="inactive_add" />
                        InActive
                    </Label>
                    </span>
                </div>
                </FormGroup>
            </Col>
            <Col sm={12} className="text-right">
            <Button className="btn btn-sm" color="primary">Save</Button>
            </Col>
        </Row>
        </Form>  
  );
}

export default EditMerchant;