import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Collapse, FormGroup,InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Spinner, Input} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCartPlus, faChevronDown, faChevronLeft, faChevronRight, faFilter } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
// import axios from 'axios';
import axios from '../../CommonAxios';
import { useForm } from "react-hook-form";
import { baseString,delivery_type_id, download_delay } from '../../config';
import { changeIntoDate, changeIntoDateYmd, errorHandling,moreText} from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import EditBulkOrders from './EditBulkOrders';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const FailedOrders = (props) => {
  const [allCourier, setAllCourier] = useState([]);
  const [failedOrder, setFailedOrder] = useState({})
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [editmodal, setEditmodal] = useState(false);
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isExport, setIsExport] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [merchantId, setMerchantId] = useState('');
  const [courierList, setCourierList] = useState({});

  const [checked, setChecked] = useState(false);
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  const placeBulkOrder = () => {
    let data = {"order_ids":checkboxOrderId}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
      data.merchant_wallet_balance=parseInt(props.user[0].wallet_balance)>0 ? props.user[0].wallet_balance : 0
    }
    if(checkboxOrderId.length > 0){
      setLoader(true);
    axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
    .then((res) => {
      setLoader(false);
      if(res.data.error !== true && res.data.fatal !== true){
          Swal.fire({icon:'success',text:res.data.msg,title:'Success',allowOutsideClick: false,allowEscapeKey: false}).then(okay => {
            if (okay) {
              history.push("/allshipments");
           }
         });
      }else{
            Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoader(false);
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
    }else{
      Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
    }
  }

  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true)
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { "merchant_id": checkData(props.user) && props.user[0].id },
        headers: { "token": window.btoa(baseString) }
      }).then(function (response) {
        setLoader(false)
        if (response.data.error === false) {
          setCourierList(response.data.data.courierList)
        } else {
          Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
        }

      }).catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload")
      })
    }
  }

  const changeCourierToggle = () => {
    if(checkboxOrderId.length > 0){
      setChangeCourierModal(!changeCourierModal);
    }else{
      Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
    }
  }
  const selectAll = (value) => {
    let abc = checkData(failedOrder.data)&&failedOrder.data.map((key) => (
      key===false
    ));
    if(value){
      // checkData(failedOrder.data)&&failedOrder.data.map((item) => (
      // setCheckboxOrderId(item)
    var opt_order_id = [];
      checkData(failedOrder.data) && Object.keys(failedOrder.data).map(function(key){
      let tempObj = failedOrder.data[key].id    
        opt_order_id.push(tempObj)
        
      return setCheckboxOrderId(opt_order_id)
    });
    // ));
    }else{
      setCheckboxOrderId([])
    }
    
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const toggleCheck = (utilityCheckbox, event, Id) => {
    var opt_order_id = checkboxOrderId;
  
      setChecked((prevState) => {
        const newState = { ...prevState };
        newState[utilityCheckbox] = !prevState[utilityCheckbox];
        return newState;
      });
      if(event.target.checked){
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
      }else{
      function arrayRemove(arr, value) { 
          return arr.filter(function(ele){ 
              return ele !== value; 
          });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
      };
    }

  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const toggleEditClose = () => setEditmodal(false); 
  const toggleEdit = (productId, merchantId) => {
    let data = {}
    data = {"order_id":productId, "merchant_id":merchantId, "is_edit":1}
    setMerchantId(merchantId)
    setEditmodal(!editmodal); 
      setLoader(true);
      axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
  .then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        setOrderDetail(res.data);
      }
    }
  }).catch((err) => {
      setLoader(false);
      Swal.fire({icon:'error',text:err,title:'Error'});
  })
  } 
  useEffect(() =>{
    setTableLoader(true);
    faildOrderListing();  
    couurierListing();
 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  const [deliveryType, setDeliveryType] = useState([]);
  const deliveryTypeMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Delivery Type"
  }
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = {label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }


  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }     
      return opt_merchants.push(tempObj)
    });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  //listing faild Order
  const faildOrderListing = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setIsExport(false);
    let data = {"order_status_id":3, "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=1
    }else{
      data.merchant_id=props.user[0].id
    }
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    reset();
    setFilterDate('');setDeliveryType([]);setAllCourier([]);setMerchants([]);setSearchValue('');

    axios.post(process.env.REACT_APP_ALL_ORDER, data)
    .then((res) => {
      setTableLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setFailedOrder(res.data);
        }
      }
    }).catch((err) => {
      setTableLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
  }
  const exportFailedOrder = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = 1
      }else{
        data.merchant_id = props.user[0].id
      }
      data.order_status_id=3
      data.is_export=1
      axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
    .then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
    }else{
      Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
    }
  }
  function handleSelectChange(event) {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(failedOrder.data)&&checkUndeNullBlank(failedOrder.totalPage)){
    // &&checkUndeNullBlank(failedOrder.totalPage).length>page.length
      if(failedOrder.totalPage > page){
        setCurrentCount(currentCount+Object.entries(failedOrder.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  setCheckboxOrderId([]);
  setCheckedAll(false);
  setChecked(false);
  if(page >1){
  setPage(page-1)
  checkData(failedOrder.data)
  if(currentCount>Object.entries(failedOrder.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }

  // filter Bulk Order
  const { register:filter, handleSubmit:filterFaildOrderSubmit, reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
  setCheckboxOrderId([]);
  setCheckedAll(false);
  setChecked(false);
    onCommonFilter(data,pagePerpage)
  };
  const onFilterFaildOrder = (data,pagePerpage) => {
  setIsExport(true);
  pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(merchants) || checkData(deliveryType) || checkData(allCourier) || checkUndeNullBlank(datestate)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data,pagePerpage) => {
  setCheckboxOrderId([]);
  setCheckedAll(false);
  setChecked(false);
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setLoader(true)
    let byData = {}
    let tmpDeliveryType = []
    let courierId = [];
    let merchantsId = [];

    if(checkData(allCourier)){
      allCourier.map((item) => (
        courierId.push(item.value)
      )); 
    } 

    if(checkData(merchants)){
      merchants.map((item) => (
        merchantsId.push(item.value)
      )); 
    } 

    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    byData={"order_status_id":3,"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"delivery_type_id":checkData(tmpDeliveryType)?tmpDeliveryType:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'', "start_date":dateStart,"end_date":dateEnd}
    setFilterInput(byData);
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setFailedOrder(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
  const { register:changeCourier, handleSubmit:changeCourierSubmit,formState: { errors: errors4 }, reset:resetChangeCourier} = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids=checkboxOrderId
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
  axios.post(`${process.env.REACT_APP_CHANGE_COURIER}`,data)
  .then((res) => {
    setLoader(false);
    if(res.fatal !== true && res.data.error !== true){
      Swal.fire({icon:'success',text:res.data.msg,title:'Success'});
      faildOrderListing();
      setChangeCourierModal(false);
      resetChangeCourier();
    }
    else{
      Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',})
    }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
      
       <Col sm={12}>
         {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderDetail) &&
        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditBulkOrders orderDetails={orderDetail} setEditmodal={setEditmodal} setOrderDetail={setOrderDetail} merchantId={merchantId} user={props.user} faildOrderListing={faildOrderListing} /> 
          </ModalBody>
        </Modal>
        }
        <Card className="p-0 my-3 master-content content-wrapper border-0">
            <div className="">
 	
				<div className="card-title title_icon">
				<i className="bi bi-card-heading"></i> 
				Manage Failed Orders 

					<div className="card-header-right d-flex align-items-center ml-auto">
					{checkData(failedOrder.data) && 
					<>
					  {props.user[0].user_type === "Merchant" &&
					  <>
					  <div className="btn-text btn-light mx-2" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>Ship Orders</span></div>  
						<div className="btn-text btn-light" onClick={changeCourierToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>changeCourier</span></div>
					  </>
					  }
					  <div className="btn-text btn-light" onClick={exportFailedOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Failed Shipment</span></div>
					</>
					}
					<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
				  </div>
				</div>
			</div>
			
			
            <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                <ModalHeader toggle={changeCourierToggle}>Change Courier</ModalHeader>
                <ModalBody>
                  <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                   <Row className="m-0 pb-1">                      
                     <Col sm={12}>
                        <FormGroup className='position-relative'>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...changeCourier("courier_id", {required : 'Please Choose Courier'})} className="form-control-sm form-control border-right-0">
                              <option value="">Select Courier</option>
                              {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => (
                                <option value={item.courier_id}>{item.courierName}</option>
                              ))} */}
                               {checkData(courierList) && courierList.map((item) => (
                                item.status !== 0 &&
                                <option value={item.courier_id}>{item.brand_name}</option>
                              ))}
                            </select>
                            {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Submit</Button>
                    </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
             <Collapse isOpen={isOpen}>
                <Form onSubmit={filterFaildOrderSubmit(onFilterFaildOrder)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control border-right-0" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value="">Search By</option>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_name">Customer Pincode</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                        </FormGroup>
                    </Col>}

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_deliveryType}
                              value={deliveryType}
                              onChange={setDeliveryType}
                              labelledBy="Delivery Type"
                              overrideStrings={deliveryTypeMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col> 
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>                   
                                       
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange}  /> */}
                          <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              <div className='form-control cursor-pointer d-flex align-items-center'>
                                {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                              </div>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {faildOrderListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              </Collapse>
              <CardBody className="p-0">
              {checkData(failedOrder) && checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(failedOrder.data).length)} of {failedOrder.TotalRows} entries
                    </div>
                      {failedOrder.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)}  value="checkedall"/></th>
                      <th>Order Number</th>
                      <th>Product Name</th>
                      <th>Courier</th>
                      <th>Uploaded</th>
                      <th>Last Updated</th>
                      <th>Failed response</th>
                      <th>Action</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(failedOrder.data) ? failedOrder.data.map((item, index) => (
                      <tr>
                      <td><input checked={checked !== undefined && checked[index]} name={'check'+index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} /></td>
                      <td><Link to={`/order-detail/${item.id}`}>{item.order_no}</Link></td>
                      <td className="col-2">
                        <div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                      </td>
                      <td>{item.courierName}</td>
                      <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null?changeIntoDate(item.created_at):'NA')}</td>
                      <td className="text-nowrap">{(item.updated_at !== undefined && item.updated_at !== null?changeIntoDate(item.updated_at):'NA')}</td>
                      <td className="text-nowrap">{item.api_msg}</td>
                      <td className="text-nowrap"><Button className="mx-2 btn-sm" color="primary" outline  onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></td>
                    </tr>
                    )) :
                    tableLoader ? <tr>
                    <td colSpan="8">Loading...</td>
                  </tr>
                    :<tr><td colSpan="8" className="text-center">No Data Found</td></tr>
                     }
                    </tbody>
                </Table>
                {checkData(failedOrder) && checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) ?
                <>
                {failedOrder.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(failedOrder.data).length)} of {failedOrder.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
      
  );
}

function mapDispatchToProps (state) {
  return {
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(FailedOrders);