import React, {useEffect, useState} from 'react';
import { Card, CardBody, Table } from 'reactstrap';
// import axios from 'axios';
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import LoaderDefault from '../../../LoaderDefault';
import {connect, useDispatch} from 'react-redux'
import { dashRemittanceOverView } from '../../../../actions';
import remittance from "../../../../assets/images/remittance.png";


const RemittanceOverview = (props) => {
    const [remittanceView, setRemittanceView] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const dispatch = useDispatch();
    
    const listingSummary = () => {
    setLoadertable(true);
    let tatperiod = checkUndeNullBlank(props.user[0].remittance_tat_period) ? props.user[0].remittance_tat_period : '';
    let mypost = {"merchant_id":props.user[0].id,"remittance_tat_period":tatperiod,}
    let myget = {}
    dispatch(dashRemittanceOverView(mypost,myget)).then((response) => {
        if(response.error === false){
            setRemittanceView(response.data);
        }
        setLoadertable(false)
      }).catch(function (response) {
        setLoadertable(false);
        Swal.fire({title:'Error', icon:'error', text:response})
      });
    }
    useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewAllRemittanceOver)&& checkData(props.state.viewAllRemittanceOver.data)){
            setRemittanceView(props.state.viewAllRemittanceOver.data);
          }else{
            listingSummary();
        }
    }, []);
    return (
        <div className="remittanceOverview">
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title"><figure><img className="img-fluid" src={remittance} alt="remittance" /></figure> Remittance</div>  
					
                </div>
                <CardBody className="p-0">
				    <div className="block_info m-3 mt-0">
                    <Table responsive borderless className=""> 
                        <tbody>  
                        {checkData(remittanceView) ? 
						        <React.Fragment>
                                <tr>
								    <td><i className="bi bi-cash"></i> Total COD: </td>
                                    <td>{checkUndeNullBlank(remittanceView.total_remittance) ? Math.round(remittanceView.total_remittance) : 0}</td>
								</tr>
								<tr>
								    <td><i className="bi bi-wallet2"></i>  COD Paid: </td>
                                    <td>{checkUndeNullBlank(remittanceView.total_remittance_paid) ? Math.round(remittanceView.total_remittance_paid) : 0}</td>
								</tr>
								<tr>
                                    <td><i className="bi bi-wallet"></i> COD Adjusted: </td>								
                                    <td>{checkUndeNullBlank(remittanceView.total_remittance_adjusted) ? Math.round(remittanceView.total_remittance_adjusted) : 0}</td>
								</tr>
								<tr>
									<td><i className="bi bi-exclamation-diamond"></i> COD Unpaid: </td>	
                                    <td>{checkUndeNullBlank(remittanceView.total_remittance_due) ? Math.round(remittanceView.total_remittance_due) : 0}</td>
								</tr>
								<tr>
									<td><i className="bi bi-clock-history"></i> COD Due <small>(as per TAT)</small> :</td>	
                                    <td>{checkUndeNullBlank(remittanceView.total_remittance_due_as_per_tat) ? Math.round(remittanceView.total_remittance_due_as_per_tat) : 0}</td>
								</tr>
								</React.Fragment>
								 :
                                loadertable ? <tr>
                                <td colSpan="4">Loading...</td>
                                </tr>
                                :<tr>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
					</div>
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps (state) {
    return{
      state:state.DataReducer
    }
  }
  export default connect(mapDispatchToProps)(RemittanceOverview);