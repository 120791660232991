import React, {useState, useEffect, useRef} from 'react';
import {Card,CardBody, Table, CardHeader,CardTitle, Form, Row, Col,Label,Input, FormGroup,Button} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { errorHandling } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import {connect, useDispatch} from 'react-redux'
import { dashFreightRmittance } from '../../../../actions';
import { CSVLink } from "react-csv";

const FreightAndRemittance = (props) => {
    const [merchants, setMerchants] = useState([]);
    const [remittanceFreightList, setRemittanceFreightList] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [tdWidth, setTdWidth] = useState('');
    const [negativeAccount, setNegativeAccount] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    const onNegativeAccountChange = (event) =>{
        if(event.target.checked === true){
            setNegativeAccount(true);
        }else{
            setNegativeAccount(false);
        }
    }
  //  console.log('negativeAccount', negativeAccount)
   const headers = [
        { label: "Merchant Name", key: "merchant_name" },
        { label: "Total Remittance", key: "total_remittance" },
        { label: "Paid Remittance", key: "paid_remittance" },
        { label: "Adjusted Remittance", key: "adjusted_remittance" },
        { label: "Due Remittance", key: "due_remittance" },
        { label: "As Per Tat Remittance", key: "tat_remittance" },
        { label: "Total Freight", key: "total_freight" },
        { label: "Adjusted Freight", key: "adjusted_freight" },
        { label: "Received Freight", key: "received_freight" },
        { label: "Outstanding Freight", key: "outstanding_freight" },
        { label: "Remittance Payable", key: "remittance_payable" },
      ];
    const data = [];
    var opt_merchants = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
        Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
            let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
            let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
            let tempObj = {} 
            if(merchantName && merchantId){
                tempObj = {label: merchantName, value: merchantId }
                opt_merchants.push(tempObj)   
            }
            return true
        });
    }
    var filterMerchant = []
    if(merchants.length > 0){
        filterMerchant = merchants
    }else{
        filterMerchant = opt_merchants
    }
    const merchantsMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select Merchant"
    }
    const listingRemittance = (mrchnt) => {
        let merchantsId = [];
        if(checkData(mrchnt)){
            mrchnt.map((item) => (
              merchantsId.push(item.value)
            )); 
        }
        setLoadertable(true);
        setRemittanceFreightList({})
        let mypost = {"merchant_ids":merchantsId}
        let myget = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            mypost.admin_id=props.user[0].id
          }else{
            mypost.merchant_id=props.user[0].id
          }
        dispatch(dashFreightRmittance(mypost,myget)).then((response) => {
            setLoadertable(false)
            if(response.error === false){
                setRemittanceFreightList(response.data);
            }
        }).catch(function (response) {
            setLoadertable(false)
            errorHandling(response,"reload");
        });

    }
    const getListSize = () => {
        const newWidth = ref.current ? ref.current.clientWidth : 0;
        setTdWidth(newWidth);
      };
    useEffect(() => {
        getListSize();
        if(checkData(props.state) && checkData(props.state.viewDashFreightRemittance) && checkData(props.state.viewDashFreightRemittance.data)){
            setRemittanceFreightList(props.state.viewDashFreightRemittance.data);
          }else{
            listingRemittance();
        }
        console.log('width', ref.current ? ref.current.offsetWidth : 0);
        // setTdWidth(ref.current ? ref.current.offsetWidth : 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);
   // console.log('tdWidth',tdWidth)
    let merchantDetailsResponse = remittanceFreightList.merchantDetailsResponseArr
    let totalRemittance = remittanceFreightList.totalRemittanceArr
    let totalRemittancePaid = remittanceFreightList.totalRemittancePaidArr
    let totalRemittanceAdjusted = remittanceFreightList.totalRemittanceAdjustedArr
    let remittanceTATResponse = remittanceFreightList.remittanceTATResponseArr
    let totalFreight = remittanceFreightList.totalFreightArr
    let totalFreightPaid = remittanceFreightList.totalFreightPaidArr
    let totalFreightAdjusted = remittanceFreightList.totalFreightAdjustedArr
    return (
        <div className="CourierRemittance">
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Freight and Remittance</CardTitle>  
                    <div className="card-header-right d-flex align-items-center ml-auto">
                        <div className="btn-text btn-light">
                        <CSVLink data={data} headers={headers}>
                            <span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span>
                            <span>Download</span>
                        </CSVLink>
                        </div>
                    </div>
                </CardHeader>
                <Form className="filter-form mb-3 mb-sm-0">   
                    <Row className="mx-0 justify-content-start justify-content-md-center">
                        <Col md={3} sm={6}>
                            <FormGroup>
                                <MultiSelect 
                                    options={opt_merchants}
                                    value={merchants}
                                    onChange={setMerchants}
                                    labelledBy="Select Merchant"
                                    overrideStrings={merchantsMultiSelectSettings}
                                    className={checkData(merchants) && "active"}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6}>
                            <FormGroup check>
                                <span className="form-check2 mr-2">
                                <input type="checkbox" name='negative_account' onChange={ onNegativeAccountChange } id="n_account" />
                                <Label for="n_account">Negative Accounts</Label>
                                </span>
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="btn-container">
                            <Button className="ctm-btn btn-sm" color="primary" onClick={() => listingRemittance(merchants)}>Search</Button>
                        </Col>
                    </Row>
                </Form>

                <CardBody className="p-0">  
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                            <th className="text-nowrap"></th>
                            <th className="text-nowrap border-bottom-2 border-right-2 border-left-2" colSpan="5">Remittance</th>
                            <th className="text-nowrap border-bottom-2 border-right-2" colSpan="4">Freight</th>
                            <th className="text-nowrap" colSpan="2"></th>
                            </tr>
                            <tr>
                                <th className="text-nowrap">Merchant<br />Name</th>
                                <th className="text-nowrap border-left-2">(A) <br />Total</th>
                                <th className="text-nowrap">(B) <br />Paid </th>
                                <th className="text-nowrap">(C) <br/>Adjusted</th>
                                <th className="text-nowrap">(A-B-C) = (D) <br/>Due </th>
                                <th className="text-nowrap border-right-2">(E) <br/>As Per TAT </th>
                                <th className="text-nowrap">(X) <br />Total </th>
                                <th className="text-nowrap">(Y)<br/>Adjusted </th>
                                <th className="text-nowrap">(Z)<br/>Received </th>
                                <th className="text-nowrap border-right-2">(X-Y-Z)<br />Outstanding </th>
                                <th className="text-nowrap">(D-X-Y-Z) <br />Remittance/Wallet </th>
                                <th className="text-nowrap">Wallet<br />Credit </th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {checkData(filterMerchant) ? filterMerchant.map((item, index) => {
                                let mid = item.value
                                let mName = item.label
                                let merchantDetails = checkData(merchantDetailsResponse) && merchantDetailsResponse.find(o => o.id === mid);
                                let merchantDetailsCheck = checkUndeNullBlank(merchantDetails) ? merchantDetails : ''
                                let merchantBalance = checkUndeNullBlank(merchantDetailsCheck) && checkUndeNullBlank(merchantDetailsCheck.totalBal) ?  merchantDetailsCheck.totalBal : 0;
                                let merchantbilling = checkUndeNullBlank(merchantDetailsCheck) && checkUndeNullBlank(merchantDetailsCheck.billing) ?  merchantDetailsCheck.billing : 1;
                                let merchantCategory = merchantbilling === 1 ? "Postpaid" : "Prepaid"
                                let totalRemittanceCount = checkData(totalRemittance) && totalRemittance.find(o => o.merchant_id === mid);
                                let totalRemittanceCountCheck = checkUndeNullBlank(totalRemittanceCount) ? totalRemittanceCount : ''
                                let totalRemittancePaidCount = checkData(totalRemittancePaid) && totalRemittancePaid.find(o => o.merchant_id === mid);
                                let totalRemittancePaidCountCheck = checkUndeNullBlank(totalRemittancePaidCount) ? totalRemittancePaidCount : ''
                                let totalRemittanceAdjustedCount = checkData(totalRemittanceAdjusted) && totalRemittanceAdjusted.find(o => o.merchant_id === mid);
                                let totalRemittanceAdjustedCountCheck = checkUndeNullBlank(totalRemittanceAdjustedCount) ? totalRemittanceAdjustedCount : ''
                                let remittanceTATResponseCount = checkData(remittanceTATResponse) && remittanceTATResponse.find(o => o.merchant_id === mid);
                                let remittanceTATResponseCountCheck = checkUndeNullBlank(remittanceTATResponseCount) ? remittanceTATResponseCount : ''
                                let totalRem = checkUndeNullBlank(totalRemittanceCountCheck) && checkUndeNullBlank(totalRemittanceCountCheck.totalCOD) ?  totalRemittanceCountCheck.totalCOD : 0;
                                let totalPaidRem = checkUndeNullBlank(totalRemittancePaidCountCheck) && checkUndeNullBlank(totalRemittancePaidCountCheck.totalCOD) ?  totalRemittancePaidCountCheck.totalCOD : 0;
                                let totalAdjustRem = checkUndeNullBlank(totalRemittanceAdjustedCountCheck) && checkUndeNullBlank(totalRemittanceAdjustedCountCheck.totalCOD) ?  totalRemittanceAdjustedCountCheck.totalCOD : 0;
                                let totalTatRem = checkUndeNullBlank(remittanceTATResponseCountCheck) && checkUndeNullBlank(remittanceTATResponseCountCheck.totalCOD) ?  remittanceTATResponseCountCheck.totalCOD : 0;
                                let totalDueRem = (totalRem-totalPaidRem-totalAdjustRem).toFixed(2)
                                let totalFreightCount = checkData(totalFreight) && totalFreight.find(o => o.merchant_id === mid);
                                let totalFreightCountCheck = checkUndeNullBlank(totalFreightCount) ? totalFreightCount : ''
                                let totalFreightPaidCount = checkData(totalFreightPaid) && totalFreightPaid.find(o => o.merchant_id === mid);
                                let totalFreightPaidCountCheck = checkUndeNullBlank(totalFreightPaidCount) ? totalFreightPaidCount : ''
                                let totalFreightAdjustedCount = checkData(totalFreightAdjusted) && totalFreightAdjusted.find(o => o.merchant_id === mid);
                                let totalFreightAdjustedCountCheck = checkUndeNullBlank(totalFreightAdjustedCount) ? totalFreightAdjustedCount : ''
                                let totalFrt = checkUndeNullBlank(totalFreightCountCheck) && checkUndeNullBlank(totalFreightCountCheck.totalFreight) ?  totalFreightCountCheck.totalFreight : 0;
                                let totalPaidFrt = checkUndeNullBlank(totalFreightPaidCountCheck) && checkUndeNullBlank(totalFreightPaidCountCheck.totalFreight) ?  totalFreightPaidCountCheck.totalFreight : 0;
                                let totalAdjustFrt = checkUndeNullBlank(totalFreightAdjustedCountCheck) && checkUndeNullBlank(totalFreightAdjustedCountCheck.totalFreight) ?  totalFreightAdjustedCountCheck.totalFreight : 0;
                                let totalOutstandFrt = (totalFrt-totalPaidFrt-totalAdjustFrt).toFixed(2)
                                let remittancePayble = (totalDueRem-totalOutstandFrt).toFixed(2)
                                let freRem = { 
                                        merchant_name: mName, total_remittance: totalRem, paid_remittance: totalPaidRem,adjusted_remittance: totalAdjustRem, due_remittance: totalDueRem, tat_remittance: totalTatRem,
                                        total_freight: totalFrt, adjusted_freight: totalAdjustFrt, received_freight: totalPaidFrt,outstanding_freight: totalOutstandFrt, remittance_payable: remittancePayble
                                    }
                                data.push(freRem)
                                return(
                                    <>
                                    {negativeAccount && remittancePayble < 0 ?
                                    <tr>
                                        <td ref={ref}>{mName}</td>
                                        <td className='border-left-2'>{totalRem}</td>
                                        <td>{totalPaidRem}</td>
                                        <td>{totalAdjustRem}</td>
                                        <td>{totalDueRem}</td>
                                        <td className='border-right-2'>{totalTatRem}</td>
                                        <td>{totalFrt}</td>
                                        <td>{totalAdjustFrt}</td>
                                        <td>{totalPaidFrt}</td>
                                        <td className='border-right-2'>{totalOutstandFrt}</td>
                                        <td className={`${remittancePayble < 0 ? 'text-danger' : ''} font-weight-bold`}>{Math.round(remittancePayble)}</td>
                                        <td>{merchantBalance}<br /><small className='font-weight-bold'>({merchantCategory})</small></td>
                                    </tr>:
                                    !negativeAccount&&<tr>
                                        <td ref={ref}>{mName}</td>
                                        <td className='border-left-2'>{totalRem}</td>
                                        <td>{totalPaidRem}</td>
                                        <td>{totalAdjustRem}</td>
                                        <td>{totalDueRem}</td>
                                        <td className='border-right-2'>{totalTatRem}</td>
                                        <td>{totalFrt}</td>
                                        <td>{totalAdjustFrt}</td>
                                        <td>{totalPaidFrt}</td>
                                        <td className='border-right-2'>{totalOutstandFrt}</td>
                                        <td className={`${remittancePayble < 0 ? 'text-danger' : ''} font-weight-bold`}>{Math.round(remittancePayble)}</td>
                                        <td>{merchantBalance}<br /><small className='font-weight-bold'>({merchantCategory})</small></td>
                                    </tr>}
                                    </>
                                )}):loadertable? <tr><td colSpan="12">Loading...</td></tr> : <tr><td colSpan="12">No Data Found</td></tr>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}

function mapDispatchToProps (state) {
    return {
      state:state.DataReducer
    }
}
export default connect(mapDispatchToProps)(FreightAndRemittance);