import React from 'react';
import ShipmentsSummary from './ShipmentsSummary';

const ShipmentsSummaryPage = (props) => {

    return (
        <div className="ShipmentsSummaryPage">
            <div className="mb-4"><ShipmentsSummary user={props.user} /></div>
        </div>
    );
}

export default ShipmentsSummaryPage;