import React, { useState, useEffect } from "react";
import { Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import classnames from 'classnames';
import OrderList from "./OrderList";
import FailedOrders from "./FailedOrders";
import ProcessingOrder from "./ProcessingOrder";
import { useHistory } from 'react-router-dom';
import { checkPermission } from "../../ObjectExist";

const AllOrders = (props) => {
    let orderTab = (new URLSearchParams(window.location.search)).get("o");
    let ShipmentStartDate = (new URLSearchParams(window.location.search)).get("start_date");
    let ShipmentEndDate = (new URLSearchParams(window.location.search)).get("end_date");
    const [activeTab, setActiveTab] = useState('upload');
    const history = useHistory();
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        history.push(`/all-orders?o=${tab}`)
    }
    //   const toggleDataAdd = () => setDataAdd(!dataAdd);
    useEffect(() => {
        if (orderTab === null && ShipmentStartDate === null && ShipmentEndDate === null) {
            history.push(`/all-orders?o=${activeTab}`)
        }
        if (orderTab === 'upload') {
            setActiveTab('upload')
        } else if (orderTab === 'processing') {
            setActiveTab('processing')
        } else if ((orderTab === 'failed') || (ShipmentStartDate !== null && ShipmentEndDate !== null)) {
            setActiveTab('failed')
        } else {
            setActiveTab('upload')
        }
    }, []);

    return (
        <div className="content-body">
            <Container fluid>
               <div className="page-header main-header"> 
				<h1>Manage Orders</h1>
					
                    <Nav tabs className="master-tabs">
                        {(checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.all_orders) || props.user[0].user_type === "Merchant") &&
                            <NavItem className="text-white">
                                <NavLink
                                    className={classnames({ active: activeTab === 'upload' })}
                                    onClick={() => { toggle('upload'); }}
                                >
                                    <span className="text-white">Uploaded Orders</span>
                                </NavLink>
                            </NavItem>
                        }
                        {(checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.processing_orders) || props.user[0].user_type === "Merchant") &&
                            <NavItem className="text-white">
                                <NavLink
                                    className={classnames({ active: activeTab === 'processing' })}
                                    onClick={() => { toggle('processing'); }}
                                >
                                    <span className="text-white">Under Process</span>
                                </NavLink>
                            </NavItem>
                        }
                        {(checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.failed_orders) || props.user[0].user_type === "Merchant") &&
                            <NavItem className="text-white">
                                <NavLink
                                    className={classnames({ active: activeTab === 'failed' })}
                                    onClick={() => { toggle('failed'); }}
                                >
                                    <span className="text-white">Failed Orders</span>
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                </div>
                <div className="master-content card border-0 shadow">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="upload">
                            <Row>
                                <Col sm="12">
                                    {activeTab === 'upload' && (checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.all_orders) || props.user[0].user_type === "Merchant") &&
                                        <OrderList user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="processing">
                            <Row>
                                <Col sm="12">
                                    {activeTab === 'processing' && (checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.processing_orders) || props.user[0].user_type === "Merchant") &&
                                        <ProcessingOrder user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="failed">
                            <Row>
                                <Col sm="12">
                                    {activeTab === 'failed' && (checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.failed_orders) || props.user[0].user_type === "Merchant") &&
                                        <FailedOrders user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    )
}

export default AllOrders