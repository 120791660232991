import React,{useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import Logo from "../../assets/images/logo_new.png";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { encryptStorageLogin } from '../../EncStorage';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import Footer from '../Footer';
import { baseString } from '../../config';
const Signin = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const [loader, setLoader] = useState(false);
    const history = useHistory();
useEffect(() =>{
    encryptStorageLogin.removeItem('userenc')
    // if(props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0){
    //     history.push("/");
    //   }else{
    //     history.push("/login");
    //   }
}, [])
    const { register:loginInput, handleSubmit:loginSubmit, formState: { errors }} = useForm();
    const onMerchantLogin = (data) => {
       
      let loginData={"email":data.email,"password":data.password};
      setLoader(true);
      fetch(`${process.env.REACT_APP_Merchant_LOGIN}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          'x-ref': window.btoa(baseString)
        },
        body:JSON.stringify(loginData)
      }).then((resp)=>{
        resp.json().then((result)=>{
            setLoader(false);
            if(Object.entries(result).length >0 ){
              if(result.error === false) {
                //   localStorage.setItem("user", JSON.stringify(result));
                encryptStorageLogin.setItem('userenc', JSON.stringify(result));
                if(checkData(data.data) && checkUndeNullBlank(data.data[0].document_status) && data.data[0].document_status !== 0 ){
                    history.push("/");
                }else{
                    history.push("/profile");
                }
              }
          }if(result.error === true){
            setLoader(false);
            Swal.fire({icon:'error', text:"Username Or Password Wrong", title:'Error'});
          }
        });
      }).catch(err => {
              Swal.fire({icon:'error', text:err, title:'Error'});
        });
    };
      const onError = (errors, e) => console.log('Error', errors);
	  const chk = process.env.REACT_APP_Merchant_LOGIN ;
    return (
        <div className="app-content m-0 p-0 content overflow-hidden" dats="{chk}">
          {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Col sm={12} lg={12} className="d-lg-flex align-items-center auth-left p-0 auth-bg position-relative">
                        <div className="d-lg-flex justify-content-center align-items-center w-100 credintials">
                            {/* <img className="img-fluid" src={loginBg} alt="img" /> */}
                            <Card className="border-0 px-xl-3 mx-auto col-md-6 col-lg-4">
                            <CardHeader className="my-3">
                            <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3"><img className="img-fluid logoimg" src={Logo} alt="img" /></CardTitle></Link>
                            </CardHeader>
                              <CardTitle tag="h4">Welcome</CardTitle>
                            <CardBody className="align-items-center py-3">
                              <p className="card-text">Sign in to continue to Tekies India.</p>
                              <Form onSubmit={loginSubmit(onMerchantLogin,onError)}>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <input {...loginInput("email",{ required: 'Please Enter Email'})} className="form-control" placeholder="Enter Email" id="email" />
                                            {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                                        </FormGroup> 
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <div className="d-flex justify-content-between">
                                                <Label for="password">Password</Label>
											<Link to="/forgotpassword">Forgot Password?</Link>
                                            </div>
                                            <InputGroup>
                                                <input {...loginInput("password",{ required: 'Please Enter Password'})} type={passwordType? "password" : "text"} placeholder="Enter Password" className="form-control" id="password" />
                                                <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                                    <InputGroupText className="py-0"> {passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>  
                                            {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <FormGroup check>
                                                <Label check><Input type="checkbox" />Remember Me </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>              
                                            <Button className="btn-sm w-100" color="primary"> Sign in</Button>                                     
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <p className="text-center">
                                            <span>Don't have an account ?</span>
                                            <Link to="/signup" className="ml-25">Signup now</Link>
                                        </p>
                                    </Col>
                                </Row>
                              </Form>
                            </CardBody>
                        <Footer />
                        </Card> 
                        </div>
                        <div className="bg-overlay bg-primary"></div>
                        <ul className="bg-bubbles">
                            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                        </ul>
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
export default Signin