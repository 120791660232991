import React, {useEffect, useState} from 'react';
import {Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
// import axios from 'axios';
import axios from '../../../../CommonAxios';

import Swal from 'sweetalert2';
import { checkData } from '../../../../ObjectExist';
import CodPrepaid_Icon from "../../../../assets/images/cod_icon.png" ;


const CodPrepaid = (props) => {

  const [codPrepaid, setCodPrepaid] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const codPrepaidData = () => {
        setLoadertable(true);
        let data = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: { "x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" }, // this line is added as on Oct 18,2023 //
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_COD_PREPAID}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setCodPrepaid(res.data.data);
                console.log('res.data.data', res.data.data)
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });
    }
    useEffect(() => {
        codPrepaidData();
    }, []);
    let codData = []
    let prepaidData = []
    checkData(codPrepaid) && Object.values(codPrepaid.reduce((osi, item) => {
      let cod = item.delivery_type_id === 1 ? item.total : 0
      let prepaid = item.delivery_type_id === 3 ? item.total : 0
       codData.push(cod);
       prepaidData.push(prepaid);
      return osi;
  }, {}));

    const data = {
        labels: ['COD', 'Prepaid'],
        datasets: [
          {
            label: 'Shipment (counts)',
            data: [eval(codData.join('+')), eval(prepaidData.join('+'))],
            backgroundColor: [
            'rgb(251,172,66, 0.3)',
            'rgb(90,141,238, 0.3)',
            ],
            borderColor: [
              'rgb(251,172,66, 1)',
              'rgb(90,141,238, 1)',
              ],
            hoverOffset: 4
          },
          
        ],
        
      };

      const options = {
        plugins: {
          title: {
            display: false,
            text: ''
          },
          legend: {
            display: true,
            position:"right",
            labels: { usePointStyle: true }
         },
         datalabels: {
          display: true,
          color: "white",
        },
        tooltips: {
          backgroundColor: "#5a6e7f",
        },  
        }
      };

    return (
        <div className="CodPrepaid">
            <Card className="border-0 shadow">
                <div className="page-header content-header d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                	 <div className="page-title">
                    <figure><img className="img-fluid" src={CodPrepaid_Icon} alt="img1" /></figure>
					COD & Prepaid 
					</div>		
                </div>
				
				
                <CardBody className="py-0">
                  {!loadertable ?
                    <Doughnut data={data} options={options} />
                    : 'NA'}
                </CardBody>
            </Card>            
        </div>
    );
}

export default CodPrepaid;