import { faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState, useEffect} from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, Form,Input, FormGroup, Row, Col, Pagination, PaginationItem, PaginationLink, CardTitle, Spinner, } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import EditCity from "./EditCity";
import { changeIntoDateTime } from "../../utlis";
import { checkData, checkPermission, checkUndeNullBlank } from "../../ObjectExist";
import { connect, useDispatch} from "react-redux";
import { AllCity } from "../../actions";
import { baseString } from "../../config";

const City = (props) => {
  console.log('props', props)
  const [editCity, setEditCity] = useState({});
  const [city, setCity] = useState({});
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [editmodal, seteditModal] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const dropFilterCount = 20
  const toggleEditclose = () => seteditModal(!editmodal);
  const dispatch = useDispatch();

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
}
const toggleEdit = (i) => {
    seteditModal(!editmodal)
    fetch(`${process.env.REACT_APP_GET_CITY}/view/${i}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
        result[0].is_metro = String(result[0].is_metro)
        setEditCity(result[0])
      });
    });
  };
  const RemoveCity = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to delete this record?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
    fetch(`${process.env.REACT_APP_REMOVE_CITY}/${id}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
      Swal.fire({title: 'Success', text: result.msg, icon: 'success'})
    tableDataFetch()
  });
    }).catch((err) =>{
      Swal.fire({title: 'Error', text: err, icon: 'error'})
    });
  } 
})
  }
  const tableDataFetch = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = {}
      let myget = {"page":page, "per_page":selectperpage};
      dispatch(AllCity(mypost,myget)).then((response) => {
        setCity(response)
        setLoadertable(false);
      }).catch((err) =>{
        setLoadertable(false);
        Swal.fire({title: 'Error', text: err, icon: 'error'});
        });
    // fetch(`${process.env.REACT_APP_GET_CITY}?page=${page}&per_page=${selectperpage}`,{
    //   method:'POST',
    //   headers:{
    //     "Content-Type":'application/json',
    //     "Accept":'application/json',
    //   },
    // }).then((resp)=>{
    //   resp.json().then((result)=>{
    //     if(result.fatal !== true){
    //         setCity(result)
    //         setTotalData(result.TotalRows)
    //     }
    //   });
    // }).catch((err) =>{
    //   Swal.fire({title: 'Error', text: err, icon: 'error'})
    // });
  }
  useEffect(() => {
    if(checkData(props.state) && checkData(props.state.viewAllCity)){
      setCity(props.state.viewAllCity);
    }else{
    tableDataFetch();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage]);
  function nextPageData() {
    if(checkData(city.data)&&checkUndeNullBlank(city.totalPage)){
      // &&checkUndeNullBlank(city.totalPage).length>page.length
        if(city.totalPage > page){
          setCurrentCount(currentCount+Object.entries(city.data).length)
      setPage(page+1)
      setLoader(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(city.data)
    if(currentCount>Object.entries(city.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
    
  };
  const onSubmit = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.state) || checkUndeNullBlank(data.city)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
      fetch(`${process.env.REACT_APP_GET_CITY}?state=${checkUndeNullBlank(data.state)?data.state:''}&city=${checkUndeNullBlank(data.city)?data.city:''}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          'x-ref': window.btoa(baseString)
        },
      }).then((resp)=>{
        resp.json().then((result)=>{
            setLoader(false);
            if(result.fatal !== true){
            setCity(result)
          }
        });
      }).catch((err) => {
        setLoader(false);
        Swal.fire({icon:"error", title:"Error", text:err})
      });
  };
  
    return(
        <>
      {loader && <div className="formLoader"><Spinner /></div>}
        <Modal isOpen={editmodal} toggle={editmodal} >
            <ModalHeader toggle={toggleEditclose}>Edit City</ModalHeader>
            <ModalBody>
            {Object.entries(editCity).length >0 &&
                <EditCity tableDataFetch={tableDataFetch} editCity={editCity} seteditModal={seteditModal} setLoader={setLoader} />
            }
            </ModalBody>
        </Modal>
        <Row className="justify-content-between card-header align-items-center px-3 pt-3">
          <Col sm={3}><CardTitle>Manage City</CardTitle></Col>
          <Col sm={3}>
          {checkData(city) && checkData(city.data) && checkUndeNullBlank(city.TotalRows) ?
                <>
                {city.TotalRows > dropFilterCount ?
            <Form>
              <FormGroup>
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                  <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      </Input>
              </FormGroup>
            </Form>
            :''}
            </>
            :''}
          </Col>
        </Row>
        <div className="filter-form">
          <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
            <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
              <Col sm={6} md={4} lg={3} xl={3}>
                <FormGroup>
                    <input {...register("state")}  className="form-control" placeholder="Enter State" />
                  </FormGroup>
                
              </Col>
              <Col sm={6} md={4} lg={3} xl={3}>
                  <FormGroup>
                    <input {...register("city")}  className="form-control" placeholder="Enter City" />
                  </FormGroup>
              </Col>
              <Col sm={3} className="filter-btn">
                <Button className="btn  btn-sm" color="primary">Search</Button>
                <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={tableDataFetch}>Reset</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Table responsive borderless className="text-center customTable">
            <thead>
            <tr>
                <th>State</th>
                <th>City</th>
                <th>Created</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {checkData(city.data) ? city.data.map((item, index) => (
            <tr>
                <td>{item.state || 'NA'}</td>
                <td>{item.name || 'NA'}</td>
                <td>{item.created_at !== undefined && item.created_at !== null ? changeIntoDateTime(item.created_at) : 'NA'}</td>
                {checkPermission(props.user, props?.user[0]?.permissions?.manage_City?.edit) ?
                <td>
                <span><Button outline className="btn btn-sm light ml-2" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                <span><Button outline className="btn btn-sm light ml-2" color="danger" onClick={() => RemoveCity(item.id)}><FontAwesomeIcon icon={faTrash} /> </Button></span>
                </td>
                :
                <td>NA</td>
                }
                </tr>
            ))
        : loadertable ? <tr><td colSpan="4">Loading...</td></tr> :
        <tr><td colSpan="4">No Data Found</td></tr> }
            </tbody>
        </Table>
        {checkData(city) && checkData(city.data) && checkUndeNullBlank(city.TotalRows) ?
                <>
                {city.TotalRows > dropFilterCount ?
        <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
          <div className="showing-count">
          Showing {currentCount} to {(currentCount-1)+(Object.entries(city.data).length)} of {city.TotalRows} entries
          </div>
          <Pagination aria-label="Page navigation example">
              <PaginationItem>
              <PaginationLink previous href="#" onClick={previousPageData}>Previous</PaginationLink> 
              </PaginationItem>
              <PaginationItem>
              <PaginationLink next href="#" onClick={nextPageData}>Next</PaginationLink>
              </PaginationItem>
          </Pagination>
        </div>
        :''}
        </>
        :''}
        </>
    )
}
function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(City);