import React, {useEffect} from "react";
import {Row, Col, FormGroup, Button, Form, Label } from "reactstrap";
import { StateList } from "./StateList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';

const EditCity = (props) => {

  const { register:editCitydata, handleSubmit:cityEditSubmit,  formState: { errors }, reset} = useForm();

  useEffect(() => {
  reset(props.editCity)
  }, [props.editCity, reset]);
const onEditCitySubmit = (data) => {
  props.setLoader !== undefined && props.setLoader(true);
  let dataAdd={"name":data.name,"state_id":data.state_id,"is_metro":data.is_metro};
    fetch(`${process.env.REACT_APP_GET_CITY}/update/${props.editCity.id}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
      },
      body:JSON.stringify(dataAdd)
    }).then((resp)=>{
      resp.json().then((result)=>{
        props.setLoader !== undefined && props.setLoader(false);
        if(result.error === false){
          props.seteditModal(false)
          props.seteditModal !== undefined && props.tableDataFetch();
          Swal.fire({
            title: 'Success',
            text: result.message,
            icon: 'success',
          })
        }
      });
    }).catch((err) =>{
        props.setLoader !== undefined && props.setLoader(false);
        Swal.fire({title: 'Error', text: err, icon: 'error'});
    });
};
    const onError = (errors, e) => console.log('Error', errors);
 
    return(
      <Form onSubmit={cityEditSubmit(onEditCitySubmit,onError)}>
    <Row className="mx-0 justify-content-center addModal">
        <Col sm={12}>
        <FormGroup>
        <Label for="state_edit">Select State</Label>
        <div className="position-relative">
            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
            <select {...editCitydata("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_edit">
                <option value="">select State</option>
                {StateList.map((item, index) => (
                    <option value={item.state_id}>{item.state_name}</option>
                ))
            }
            </select>
            {errors.state_id && <span className="text-danger d-block mb-2">{errors.state_id.message}</span>}
        </div>
        </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup>
            <Label for="city_edit">Enter City</Label>
            <input {...editCitydata("name",{ required: 'Please Enter City'})} className="form-control-sm form-control" placeholder="Enter City" id="city_edit" />
            {errors.city && <span className="text-danger d-block mb-2">{errors.city.message}</span>}
            </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup className="mb-0">
              <Label>Status</Label>
            <div className="d-flex">
              <FormGroup check className="px-0 mr-2">
                  <Label check>
                      <input type="radio" {...editCitydata("is_metro", { required: true})} value="1" className="mx-2" />
                      Yes
                  </Label>                               
              </FormGroup>
              <FormGroup check className="px-0">
                  <Label check>
                      <input type="radio" {...editCitydata("is_metro", { required: true})} value="0" className="mx-2" />
                      No
                  </Label>                               
              </FormGroup>
            </div>
            </FormGroup>
        </Col>
        <Col sm={12} className="text-right">
        <Button className="btn  btn-sm" color="primary">Save</Button>
        </Col>
    </Row>
    </Form>
    )
}
export default EditCity