import React,{useEffect, useState} from 'react';
import {Card, Button, FormGroup,CardBody, Table, CardHeader,CardTitle, Form, Row, Col, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import { MultiSelect, } from "react-multi-select-component";
import axios from 'axios';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import LoaderDefault from '../../../LoaderDefault';
// import NodataFound from '../../../NodataFound';
import { getTodayData,get1DayData,get2DayData,get3DayData,get4DayData,get5DayData,get6DayData,get7DayData,getDaySevenPlusData } from './DayWise';
import {Link, useHistory} from 'react-router-dom';
import { download_delay } from '../../../../config';
import { errorHandling } from '../../../../utlis';

const PickupPendingShipments = (props) => {
    const [merchants, setMerchants] = useState([]);
    const [Pending, setPendingList] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [loader, setLoader] = useState(false);
    const history = useHistory();  

    var opt_merchants = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
        Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
        let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
        let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
        let tempObj = {} 
        if(merchantName && merchantId){
            tempObj = {label: merchantName, value: merchantId }
            opt_merchants.push(tempObj)   
        }
        return
        });
    }
    const merchantsMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select Merchant"
    }

    const listingPending = () => {
        let data = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_PICKUP_PENDING_SHIPMENT}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPendingList(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            errorHandling(err,"reload");
          });
    }
    useEffect(() => {
        listingPending();
    }, []);

    
      let todayTotal = 0
      let oneDayTotal = 0
      let twoDayTotal = 0
      let threeDayTotal = 0
      let fourDayTotal = 0
      let fiveDayTotal = 0
      let sixDayTotal = 0
      let sevenDayTotal = 0
      let sevenPlusDayTotal = 0
      let grandTotal = 0

    let pendingStatus = checkData(Pending) && Object.values(Pending.reduce((osi, item) => {
        let courierName = checkUndeNullBlank(item.courierName) && item.courierName
        let courierId = checkUndeNullBlank(item.courier_id) && item.courier_id
        if (!osi[courierName])
        osi[courierName] = {courier_name: courierName,courier_id: courierId,date: [],total: [], today: [], oneDay: [], twoDay: [], threeDay : [], fourDay: [], fiveDay : [], sixDay : [], sevenDay : [], sevenPlusDay : []};
        let dates = checkUndeNullBlank(item.dateOn) ? item.dateOn : 0;
        let totals = checkUndeNullBlank(item.total) ? item.total : 0;
        osi[courierName].date.push(dates);
        osi[courierName].total.push(totals);

        if(getTodayData(new Date(item.dateOn))){
            osi[courierName].today.push(totals);
            todayTotal += totals;
        }else if(get1DayData(new Date(item.dateOn))){
            osi[courierName].oneDay.push(totals)
            oneDayTotal += totals;
        }else if(get2DayData(new Date(item.dateOn))){
            osi[courierName].twoDay.push(totals)
            twoDayTotal += totals;
        }else if(get3DayData(new Date(item.dateOn))){
            osi[courierName].threeDay.push(totals)
            threeDayTotal += totals;
        }else if(get4DayData(new Date(item.dateOn))){
            osi[courierName].fourDay.push(totals)
            fourDayTotal += totals;
        }else if(get5DayData(new Date(item.dateOn))){
            osi[courierName].fiveDay.push(totals)
            fiveDayTotal += totals;
        }else if(get6DayData(new Date(item.dateOn))){
            osi[courierName].sixDay.push(totals)
            sixDayTotal += totals;
        }else if(get7DayData(new Date(item.dateOn))){
            osi[courierName].sevenDay.push(totals)
            sevenDayTotal += totals;
        }else{
            osi[courierName].sevenPlusDay.push(totals)
            sevenPlusDayTotal += totals;
        }
        grandTotal = todayTotal+oneDayTotal+twoDayTotal+threeDayTotal+fourDayTotal+fiveDayTotal+sixDayTotal+sevenDayTotal+sevenPlusDayTotal
        return osi;
    }, {}));
    const exportShipment = (courierid,total) => {
        let courierId = [];
        let pickup_pending_id = [4];
        courierid.forEach(item => {
            courierId.push(item)
        });

        let data = {"all_shipment":1,"order_status_ids":pickup_pending_id}
        
        data.courier_id = courierId;
        data.total = checkUndeNullBlank(total) ? total : "";

        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id = props.user[0].id
        }else{
            data.merchant_id = props.user[0].id
        }
        data.is_export=1
        setLoader(true);
        axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
        .then((res) => {
        if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
            if(parseInt(res.data.errno)>0){
                    setLoader(false);
                    if(res.data.sqlMessage!==undefined){
                    Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
                }
            }else{
                if(res.data.is_export === 1){
                    setTimeout(function(){ 
                      setLoader(false);
                      history.push("/my-downloads"); 
                    },download_delay);
                }
            }
        }}).catch((err) => {
            errorHandling(err,"")
        });        
    }
    
    const {handleSubmit:filterPickupPendingSubmit} = useForm();

    const onFilterPickupPending = (data) => {
        if(checkData(merchants)){
            onCommonFilter(data)
        }
    };

    const onCommonFilter = (data) => {
        let byData = {}
        let merchantsId = [];
        setLoader(true); 
               
        if(checkData(merchants)){
            merchants.map((item) => (
                merchantsId.push(item.value)
            )); 
        } 

        byData={"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:''}
        
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
          byData.admin_id = props.user[0].id
        }else{
          byData.merchant_id = props.user[0].id
        }
          axios({
            method: "post",
            url: `${process.env.REACT_APP_PICKUP_PENDING_SHIPMENT}`,
            data: byData,
          }).then((res) => {
            setLoader(false);
            if(res.data.error === false){
                setPendingList(res.data.data);
            }
          }).catch((err) => {
            errorHandling(err,"search")
            setLoader(false);
          });
    };

    return (
        <div className="PickupPendingShipments">
            {loader && <div className="formLoader"><Spinner /></div>}        
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Pickup Pending Shipments</CardTitle>  
                </CardHeader>
                <Form onSubmit={filterPickupPendingSubmit(onFilterPickupPending)} className="filter-form mb-3 mb-sm-0">   
                    <Row className="mx-0 justify-content-start justify-content-md-center">
                        <Col md={3} sm={6}>
                            <FormGroup>
                                <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <MultiSelect 
                                        options={opt_merchants}
                                        value={merchants}
                                        onChange={setMerchants}
                                        labelledBy="Select Merchant"
                                        overrideStrings={merchantsMultiSelectSettings}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm={3} className="btn-container">
                            <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                        </Col>
                    </Row>
                </Form>

                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap w-some">Couriers</th>
                                <th className="text-nowrap">0 Day</th>
                                <th className="text-nowrap">1 Day</th>
                                <th className="text-nowrap">2 Day</th>
                                <th className="text-nowrap">3 Day</th>
                                <th className="text-nowrap">4 Day</th>
                                <th className="text-nowrap">5 Day</th>
                                <th className="text-nowrap">6 Day</th>
                                <th className="text-nowrap">7 Day</th>
                                <th className="text-nowrap">7+ Day</th>
                                <th className="text-nowrap">Total</th>
                            </tr>                            
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-nowrap">Total</th>
                                <td className="text-nowrap">{todayTotal}</td>
                                <td className="text-nowrap">{oneDayTotal}</td>
                                <td className="text-nowrap">{twoDayTotal}</td>
                                <td className="text-nowrap">{threeDayTotal}</td>
                                <th className="text-nowrap">{fourDayTotal}</th>
                                <td className="text-nowrap">{fiveDayTotal}</td>
                                <th className="text-nowrap">{sixDayTotal}</th>
                                <th className="text-nowrap">{sevenDayTotal}</th>
                                <th className="text-nowrap">{sevenPlusDayTotal}</th>
                                <td className="text-nowrap"><div className="text-left mx-auto dowbload-con d-flex justify-content-between">{grandTotal}{grandTotal > 0 && <span className="download-icon mx-1" onClick={()=>exportShipment([],grandTotal)}><FontAwesomeIcon icon={faDownload} /></span>}</div></td>
                            </tr>    
                            {checkData(pendingStatus) ? pendingStatus.map((courierItem, index) => {
                                let today = courierItem.today.length > 0 ? eval(courierItem.today.join('+')): 0;
                                let onDay = courierItem.oneDay.length > 0 ? eval(courierItem.oneDay.join('+')): 0;
                                let twoDay = courierItem.twoDay.length > 0 ? eval(courierItem.twoDay.join('+')): 0;
                                let threeDay = courierItem.threeDay.length > 0 ? eval(courierItem.threeDay.join('+')): 0;
                                let fourDay = courierItem.fourDay.length > 0 ? eval(courierItem.fourDay.join('+')): 0;
                                let fiveDay = courierItem.fiveDay.length > 0 ? eval(courierItem.fiveDay.join('+')): 0;
                                let sixDay = courierItem.sixDay.length > 0 ? eval(courierItem.sixDay.join('+')): 0;
                                let sevenDay = courierItem.sevenDay.length > 0 ? eval(courierItem.sevenDay.join('+')): 0;
                                let sevenPlusDay = courierItem.sevenPlusDay.length > 0 ? eval(courierItem.sevenPlusDay.join('+')): 0;
                                let gTotal = today+onDay+twoDay+threeDay+fourDay+fiveDay+sixDay+sevenDay+sevenPlusDay
                                   return(
                                    <tr key={index}>
                                        <td>{courierItem.courier_name}</td>
                                        <td>{today}</td>
                                        <td>{onDay}</td>
                                        <td>{twoDay}</td>
                                        <td>{threeDay}</td>
                                        <td>{fourDay}</td>
                                        <td>{fiveDay}</td>
                                        <td>{sixDay}</td>
                                        <td>{sevenDay}</td>
                                        <td>{sevenPlusDay}</td>
                                        <td><div className="text-left mx-auto dowbload-con d-flex justify-content-between">{gTotal}{gTotal > 0 && <span className="download-icon mx-1" onClick={()=>exportShipment([courierItem.courier_id],gTotal)}><FontAwesomeIcon icon={faDownload} /></span>}</div></td>
                                    </tr>
                                    );
                                }) :
                                loadertable ? <tr>
                                <td colSpan="7">Loading...</td>
                                </tr>
                                :<tr><td colSpan="7" className="text-center">No Data Found</td></tr>
                            } 
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}

function mapDispatchToProps (state) {
    return {
      state:state.DataReducer
    }
}

export default connect(mapDispatchToProps)(PickupPendingShipments);