import React, { useEffect, useState } from "react";
import AdminHeader from './Componant/admin/header/Header';
import Sidebar from './Componant/admin/header/Sidebar';
import MerchantHeader from './Componant/merchant/header/Header';
import MerchantSidebar from './Componant/merchant/header/Sidebar';
import Master from './Componant/masters/Master'
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Signin from './Componant/admin/Signin';
import MerchanSignin from './Componant/merchant/Signin';
import Dashboard from "./Componant/admin/dashboard/Dashboard";
import { useLocation } from 'react-router';
import Couriers from "./Componant/couriers/Couriers";
import CourierDetails from "./Componant/couriers/courierDetails";
import Zoning from "./Componant/couriers/Zoning";
import Merchant from "./Componant/merchant/Merchant";
import MerchantDetails from "./Componant/merchant/MerchantDetails";
import PickupPoints from "./Componant/merchant/PickupPoints";
import AddPickupPoint from "./Componant/merchant/AddPickupPoint";
import MerchantBrands from "./Componant/merchant/MerchantBrands";
import MerchantPricing from "./Componant/merchant/MerchantPricing";
import CourierPricing from "./Componant/couriers/CourierPricing";
import AccountSetting from "./Componant/admin/AccountSetting/AccountSetting";
import PriceCalculator from "./Componant/UtilityTool/PriceCalculator";
import UtilityTool from "./Componant/UtilityTool/UtilityTool";
import Serviceability from "./Componant/Serviceability/Serviceability";
import Passbook from "./Componant/merchant/passbook/Passbook";
import ForgotPassword from "./Componant/merchant/ForgotPassword";
import Signup from "./Componant/merchant/Signup";
import MerchantAccountSetting from "./Componant/merchant/Setting/Setting";
import EditPickupPoint from "./Componant/merchant/EditPickupPoint";
import { encryptStorageLogin } from "./EncStorage";
import { checkData, checkPermission } from './ObjectExist';
import CreateOrder from "./Componant/orders/CreateOrder";
import AllOrders from "./Componant/orders/AllOrders";
import OrderDetail from "./Componant/orders/OrderDetail";
import Rto from "./Componant/orders/Rto";
import { connect, useDispatch } from 'react-redux';
import { AllShipmentMerchantCourier, TotalWalletBalance } from './actions';
import Swal from 'sweetalert2';
import MerchantDownloads from './Componant/merchant/MerchantDownloads';
import AdminDownloads from './Componant/admin/AdminDownloads';
import Remittance from "./Componant/Remittance/Remittance";
import Sales from "./Componant/sales/Sales";
import Shipments from "./Componant/shipments/Shipments";
import Account from "./Componant/account/Account";
import CreditNote from "./Componant/merchant/CreditNote";
import DebitNote from "./Componant/merchant/DebitNote";
import Notfound from "./Componant/Notfound";
import Ndr from "./Componant/ndr/Ndr";
import MerchantDashboard from "./Componant/merchant/Dashboard/MerchantDashboard";
import WeightMismatch from "./Componant/WeightMismatch/WeightMismatch";
import InvoiceDatail from './Componant/Invoices/InvoiceDetail';
import ChannelIntegration from "./Componant/ChannelIntegration/ChannelIntegration";
import ChannelOrder from "./Componant/ChannelIntegration/ChannelOrder";
import MerchantCreditNoteDatail from "./Componant/merchant/MerchantCreditNoteDetail";
import Footer from '../src/Componant/Footer';
import CustomInvoice from "./Componant/Invoices/CustomInvoice";
import AllAdmin from "./Componant/admin/AllAdmin";


function App(props) {
  const [navExpand, setNavExpand] = useState(true);
  const [userBalance, setUserBalance] = useState('');

  // const userget = JSON.parse(localStorage.getItem('user'));
  // const user =  userget !== null &&  userget.data;
  const userdec = encryptStorageLogin.getItemFromPattern('userenc');
  const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc.data;
  const location = useLocation()
  const history = useHistory();
  const dispatch = useDispatch();

  const sideNavToggle = () => {
    setNavExpand(!navExpand)
  }
  const walletBalanceUpdate = () => {
    let mypost = {}
    let myget = {}
    if (checkData(user)) {
      if (user[0].user_type === "Super") {
        mypost = { "admin_id": user[0].id }
      } else {
        mypost = { "merchant_id": user[0].id, "wallet_balance": user[0].wallet_balance, "remittance_tat_period": user[0].remittance_tat_period }
      }
    }
    dispatch(TotalWalletBalance(mypost, myget)).then((response) => {
      if (response.error === false) {
        // console.log("response",response);
        setUserBalance(response.balance)
      }
    }).catch(function (response) {
      Swal.fire({ title: 'Error', icon: 'error', text: response })
    });
  }
  const allShipmentCourier = () => {
    let mypost = {}
    let myget = {}
    if (checkData(user)) {
      if (user[0].user_type === "Super") {
        mypost = { "admin_id": user[0].id }
      } else {
        mypost = { "merchant_id": user[0].id, }
      }
    }
    dispatch(AllShipmentMerchantCourier(mypost, myget)).then((response) => {
    }).catch(function (response) {
      Swal.fire({ title: 'Error', icon: 'error', text: response })
    });
  }
  useEffect(() => {
    //   if(user !== undefined && user !== null && Object.entries(user) >0 && user.error){
    //     history.push("/tekiespost/backend");
    // }
    // if(userdec === undefined && location.pathname !== "/tekiespost/backend"){
    //   history.push("/my-signin");
    // }else if(checkData(userdec)){
    //       if(userdec.userenc.error && location.pathname !== "/tekiespost/backend"){
    //         history.push("/my-signin");
    //       }else if(userdec.userenc.error && location.pathname === "/tekiespost/backend"){
    //         history.push("/tekiespost/backend");
    //       }
    // }else{
    //   history.push("/tekiespost/backend");
    // }

    if (user === false && location.pathname === "/tekiespost/backend") {
      history.push("/tekiespost/backend");
    } else if (user === false && location.pathname === "/signup") {
      history.push("/signup");
    } else if (user === false && location.pathname === "/my-signin") {
      history.push("/my-signin");
    } else if (user === false) {
      history.push("/");
    }

    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier)) {
    } else {
      if (checkData(user)) {
        allShipmentCourier();
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  
 
  
  return (
    <div className={`App ${navExpand ? 'appCollapsed' : ''}`}>
      <React.Fragment>
        {user !== undefined && user !== null && Object.entries(user).length > 0 &&
          <>
            {location.pathname !== "/my-signin" && location.pathname !== "/tekiespost/backend" && location.pathname !== "/forgot-password" && location.pathname !== "/signup" ?
              <>
                {user !== undefined && user !== null && (user[0].user_type === "Super" || user[0].user_type === "SubAdmin") ?
                  <>
                    <Sidebar user={user[0]} sideNavToggle={sideNavToggle}  />
                    <AdminHeader sideNavToggle={sideNavToggle} user={user} />
                  </>
                  :
                  <>
                    <MerchantSidebar user={user} sideNavToggle={sideNavToggle} />
                    <MerchantHeader sideNavToggle={sideNavToggle} user={user} walletBalanceUpdate={walletBalanceUpdate} userBalance={userBalance} />
                  </>
                }

              </>
              : ''}
          </>
        }
		
      <Switch>  
          <Route path="/tekiespost/backend"><Signin /></Route>
          <Route path="/my-signin"><MerchanSignin /></Route>
          <Route path="/forgot-password"><ForgotPassword /></Route>
          <Route path="/signup"><Signup /></Route>
          <Route path="/price-calculator"><PriceCalculator user={user} /></Route>
          <Route path="/all-orders"><AllOrders user={user} /></Route>
          <Route path="/order-detail/:orderId"><OrderDetail user={user} /></Route>
          <Route path="/allshipments"><Shipments user={user} /></Route>
          {checkData(user) && (checkPermission(user, user[0]?.permissions?.manage_RTO?.rto) || user[0].user_type === "Merchant") &&
            <Route path="/rto"><Rto user={user} /></Route>
          }
          {checkData(user) && (checkPermission(user, user[0]?.permissions?.manage_Credit_note?.merchant_credit_note) || user[0].user_type === "Merchant") &&
            <Route path="/credit-note"><CreditNote user={user} /></Route>
          }
          {checkData(user) && (checkPermission(user, user[0]?.permissions?.manage_Debit_note?.merchant_debit_note) || user[0].user_type === "Merchant") &&
            <Route path="/debit-note"><DebitNote user={user} /></Route>
          }
          <Route path="/invoice-detail/:invoiceId"><InvoiceDatail user={user} /></Route>
          {/* </Switch> */}
          {user !== undefined && user !== null && Object.entries(user).length > 0 && (user[0].user_type === "Super" || user[0].user_type === "SubAdmin") ?
            <>
              {/* <Switch> */}
              {checkPermission(user, user[0]?.permissions?.dashboard?.index) &&
                <Route exact path="/"><Dashboard user={user} /></Route>
              }
              <Route path="/master/:active_tab?"><Master user={user} /></Route>
              {checkPermission(user, user[0]?.permissions?.manage_Couriers?.list) &&
                <Route path="/couriers"><Couriers user={user} /></Route>
              }
              <Route path="/courierDetails/:courierId"><CourierDetails /></Route>
              {checkPermission(user, user[0]?.permissions?.courier_Zone?.zoning) &&
                <Route path="/zoning/:courierId"><Zoning /></Route>
              }
              {checkPermission(user, user[0]?.permissions?.courier_Pricing?.courier_pricing) &&
                <Route path="/courier-pricing/:courierId"><CourierPricing user={user} /></Route>
              }
              {checkPermission(user, user[0]?.permissions?.manage_Merchants?.list) &&
                <Route path="/merchants"><Merchant user={user} /></Route>
              }
              <Route path="/merchants-details/:merchantId"><MerchantDetails /></Route>
              <Route path="/merchants-pickuppoint/:merchantId"><PickupPoints user={user} /></Route>
              <Route path="/merchant-passbook/:merchantId"><Passbook user={user} /></Route>
              <Route path="/add-pickuppoint/:merchantId"><AddPickupPoint user={user} /></Route>
              {checkPermission(user, user[0]?.permissions?.merchant_Warehouses?.edit) &&
                <Route path="/edit-pickuppoint/:merchantId"><EditPickupPoint user={user} /></Route>
              }
              <Route path="/merchants-brand/:merchantId"><MerchantBrands user={user} /></Route>
              <Route path="/merchant-pricing/:merchantId"><MerchantPricing /></Route>
              {checkPermission(user, user[0]?.permissions?.manage_Serviceability?.service_all) &&
                <Route path="/serviceability"><Serviceability user={user} /></Route>
              }
              <Route path="/all-orders"><AllOrders user={user} /></Route>
              <Route path="/profile"><AccountSetting user={user} /></Route>
              <Route path="/my-downloads"><AdminDownloads user={user} /></Route>
              <Route path="/remittance"><Remittance user={user} /></Route>
              <Route path="/sales"><Sales user={user} /></Route>
              {checkPermission(user, user[0]?.permissions?.manage_NDR?.ndr_all) &&
                <Route path="/ndr"><Ndr user={user} /></Route>
              }
              {( (checkPermission(user, user[0]?.permissions?.manage_Weight?.weight_recon_list)) || (user[0].user_type === "Merchant")) &&
                
                <Route path="/weight-mismatch"><WeightMismatch user={user} /></Route>
              }
              {checkData(user) && checkPermission(user, user[0]?.permissions?.manage_Uitlity_tool.utility) &&
                <Route path="/utility"><UtilityTool user={user} /></Route>
              }
              <Route path="/credit-note-detail/:creditNoteId"><MerchantCreditNoteDatail user={user} /></Route>
              <Route path="/custom-invoice"><CustomInvoice user={user} /></Route>
              {/* <Route path="*" component={Notfound} /> */}
              {/* </Switch> */}
              {/* <Route path="/users" element={<AllAdmin user={user[0]} />} /> */}
              <Route path="/users"><AllAdmin user={user[0]} /></Route>
            </>
            :
            user !== undefined && user !== null && Object.entries(user).length > 0 && user[0].user_type === "Merchant" ?
              <>
                {/* <Switch> */}
                <Route exact path="/"><MerchantDashboard user={user} /></Route>
                <Route path="/profile"><MerchantAccountSetting user={user} /></Route>
                <Route path="/my-pickuppoint"><PickupPoints user={user} /></Route>
                <Route path="/add-pickuppoint"><AddPickupPoint user={user} /></Route>
                <Route path="/edit-pickuppoint/:merchantId"><EditPickupPoint user={user} /></Route>
                <Route path="/create-order"><CreateOrder user={user} /></Route>
                <Route path="/my-downloads"><MerchantDownloads user={user} /></Route>
                <Route path="/brands"><MerchantBrands user={user} /></Route>
                <Route path="/passbook"><Passbook user={user} /></Route>
                <Route path="/account"><Account user={user} /></Route>
                <Route path="/ndr"><Ndr user={user} /></Route>
                <Route path="/weight-mismatch"><WeightMismatch user={user} /></Route>
                <Route path="/integration-channel"><ChannelIntegration user={user} /></Route>
                <Route path="/channel-orders"><ChannelOrder user={user} /></Route>
                {/* <Route path="*" component={Notfound} /> */}
                {/* <Route path="*" exact={true}>
              <Redirect to="/" />
            </Route> */}

                {/* </Switch> */}

              </> :
              <>
                <Route exact path="/"><Notfound /></Route>
              </>
          }
        </Switch>
        {checkData(user) &&
          <Footer />
        }
      </React.Fragment>
    </div >
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(App);
