import React, {useEffect} from 'react';
import {  Navbar,  Nav,  NavItem,  NavLink, UncontrolledDropdown, Button, Form, DropdownToggle, DropdownMenu, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faCalculator, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { DownloadCloud } from 'react-feather';
import { encryptStorageLogin } from '../../../EncStorage';
import {useForm} from 'react-hook-form';
import { checkData } from '../../../ObjectExist';
import Logo from "../../../assets/images/logo.png";

const Header = (props) => {
  let searchKeyword = (new URLSearchParams(window.location.search)).get("search_keyword");
  const logOutUser = () =>{
    // localStorage.removeItem("user");
    encryptStorageLogin.removeItem('userenc')
    history.push("/tekiespost/backend");
  }
  const history = useHistory();
  const { register: register2, handleSubmit:handleSubmit2, getValues, setValue} = useForm();
  useEffect(() => {
    if(checkData(searchKeyword)){
      [ 
         { name: 'search_keyword', value: searchKeyword },
      ].forEach(({ name, value }) => setValue(name, value));
   }else{
    [ 
      { name: 'search_keyword', value: '' },
   ].forEach(({ name, value }) => setValue(name, value));
   }
   }, [searchKeyword]);
   const onSearchNo = (data) => {
    const searchTrack = getValues("search_keyword");
    searchTrack.length > 0 && history.push(`/allshipments?search_keyword=${searchTrack}`);
  }
  return (
    <div className="header">
      <Navbar light expand="md" className="header-navbar topNav d-flex justify-content-between">
          <div className="header-left">
            <Nav className="mr-auto" navbar>
              
			<div className="logoSection">
			<Nav vertical>
				<NavItem>
					<Link to="/" className="nav-link"><img className="img-fluid logosidebar" src={Logo} alt="img" /></Link>
				</NavItem>
			</Nav>
			</div>
			  
              <NavItem>
                <Form onSubmit={handleSubmit2(onSearchNo)}>
                  <div class="input-group search-area d-xl-inline-flex d-none">
                  <input type="text" {...register2('search_keyword')} className="autocomplete-search form-control" placeholder="Enter AWB Number or Order Number..." />
                  <div class="input-group-append">
                    <span class="input-group-text py-0">
                      {/* <a href="javascript:void(0)"> */}
                      <Button className="btn btn-sm px-0 py-0" color="primary"><FontAwesomeIcon icon={faSearch} /></Button>
                    
                  {/* </a> */}
                  </span>
                  </div>
                </div>
                </Form>
              </NavItem>  
            </Nav>
          </div>
          <div className="d-flex header-right">
            <Nav className="mr-auto align-items-center position-relative" navbar>
              <NavItem>
                
				<Link to="/price-calculator" className="py-0 nav-link">
				<i className="bi bi-calculator"></i>
				</Link>
				
              </NavItem>
              <NavItem>
                
				<Link to="/my-downloads" className="py-0 nav-link">
                  <i className="bi bi-file-earmark-arrow-down-fill"></i> 
                </Link>
				 
              </NavItem>            
            </Nav>   
            <Nav>
              <NavItem>
                <NavLink href="#" className="py-0 header-profile">
                  <UncontrolledDropdown setActiveFromChild >
                    <DropdownToggle caret className="py-0 px-2 d-flex align-items-center">
                      <div className="avatar">{props.user[0].name} <i className="bi bi-person-circle"></i> </div>
                    </DropdownToggle>
                    <DropdownMenu className="mt-2">
                      <Link to="/profile" className="dropdown-item"><span className="mr-2">
                      <FontAwesomeIcon icon={faUser} />
                      </span>Profile</Link>
                              <NavLink href="/logout" className="dropdown-item"  onClick={logOutUser}><span className="mr-2">
                              <FontAwesomeIcon icon={faArrowLeft} />
                      </span>Logout</NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem> 
            </Nav>       
          </div>
      </Navbar>
    </div>
  );
}

export default Header;