import React, {useState} from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import DailySummary from './DailySummary/DailySummary';
import PendingShipments from './PendingShipments/PendingShipments';
import ShipmentsSummaryPage from './ShipmentsSummary/ShipmentsSummaryPage';
import CourierRemittancePage from './CourierRemittance/CourierRemittancePage';
import FreightAndRemittancePage from './FreightAndRemittance/FreightAndRemittancePage';

const Dashboard = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
       <div className="content-body">
                     
            <div className="container-fluid">
				<div className="page-header main-header"> 
				
                  <Nav tabs className="master-tabs nav nav-tabs">
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                             <span className="text-white"> Daily Summary </span>
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                              <span className="text-white"> Pending Shipments </span>
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                              <span className="text-white"> Shipments Summary </span>
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                             <span className="text-white"> Courier Remittance </span>
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                              <span className="text-white">Freight and Remittance</span>
                          </NavLink>
                      </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab} className="pb-0">
                  <TabPane tabId="1">
                    <DailySummary user={props.user}  oneCalendar={props.oneCalendar}/>
                  </TabPane>
                  <TabPane tabId="2">
                   <PendingShipments user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                  <TabPane tabId="3">
                    <ShipmentsSummaryPage user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                  <TabPane tabId="4">
                    <CourierRemittancePage user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                  <TabPane tabId="5">
                    <FreightAndRemittancePage user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                </TabContent>
            </div>
            
        </div>
    );
}

export default Dashboard;