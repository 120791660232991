import React, { useState, } from 'react';
import { Row, Col, Card, CardTitle,  CardHeader,Nav,NavItem,NavLink,TabContent,TabPane, Container} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import classnames from 'classnames';
import {useParams} from 'react-router-dom'
import BankDetail from './Setting/BankDetail';
import KYCDetail from './Setting/KYCDetail';
import BillingDetail from './Setting/BillingDetail';
import EmailNotification from './Setting/EmailNotification';
import MerchnatResetPassword from './MerchantResetPassword';




const MerchantDetails = (props) => {

  const [activeTab, setActiveTab] = useState('2');
  const {merchantId} = useParams();

  

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

 

  return (
    <div className="content-body overflow-hidden">
        <Container fluid>
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 merchantDetails content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Update Merchant Details</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <Link to='/merchants' className="btn-text btn-light ml-auto"><span className="icon"><ArrowLeft /></span><span>Back</span></Link>
              </div>
            </CardHeader>
            <Row>
              <Col sm={3}>
                <Nav tabs vertical className="mt-2 tabSection">
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}> Bank Details </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}> Reset Password  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}> KYC Details  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}> Billing Details  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}> Email Notification  </NavLink>
                  </NavItem>
                </Nav>
                </Col>
                <Col sm={9}>
                <TabContent activeTab={activeTab} className="pb-0">
                  <TabPane tabId="2" className="p-4">
                  {activeTab === '2' &&
                    <BankDetail user={props.user} merchantId={merchantId} />
                  }
                  </TabPane>
                  <TabPane tabId="4" className="p-4">
                  {activeTab === '4' &&
                    <MerchnatResetPassword user={props.user} merchantId={merchantId} />
                  }          
                  </TabPane>
                  <TabPane tabId="5" className="p-4">
                  {activeTab === '5' &&
                    <KYCDetail user={props.user} merchantId={merchantId} />
                  }
                  </TabPane>
                  <TabPane tabId="6" className="p-4">
                  {activeTab === '6' &&
                    <BillingDetail user={props.user} merchantId={merchantId} />
                  }
                  </TabPane>
                  <TabPane tabId="7" className="p-4">
                  {activeTab === '7' &&
                    <EmailNotification user={props.user} merchantId={merchantId} />
                  }
                  </TabPane>
                </TabContent>
                </Col>
                </Row>
          </Card>
      </Col>
      
      </Row>
      </Container>
       </div>
  );
}

export default MerchantDetails;
         