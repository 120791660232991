import React, {useState, useEffect} from "react";
import {Row, Col, FormGroup, Button, Form } from "reactstrap";
import { StateList } from "./StateList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { baseString } from "../../config";

const EditPincode = (props) => {
  const [stateCity, setStateCity] = useState({});
console.log('stateCity', stateCity)
const { register:editpincode, handleSubmit:pincodeEditSubmit, formState: { errors }, reset} = useForm();
    const handleStateChange =(e) => {
        // setSelectedClient(event.target.value);
          fetch(`${process.env.REACT_APP_GET_CITY_STATE}?state_id=${e.target.value}`,{
            method:'POST',
            headers:{
              "Content-Type":'application/json',
              "Accept":'application/json',
            },
          }).then((resp)=>{
            resp.json().then((result)=>{
              setStateCity(result)
            });
          }).catch((err) =>{
            Swal.fire({title: 'Error', text: err, icon: 'error'})
          });
    }
    useEffect(() => {
        reset(props.editPin)
    },[props.editPin, reset])
  const onEditPincodeSubmit = (data) => {
    let dataAdd={"city_id":data.city_id,"state_id":data.state_id,"pincode":data.pincode};
      fetch(`${process.env.REACT_APP_GET_PINCODE}/${props.editPin.id}`,{
        method:'PUT',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          'x-ref': window.btoa(baseString)
        },
        body:JSON.stringify(dataAdd)
      }).then((resp)=>{
        resp.json().then((result)=>{
          props.seteditModal !== undefined && props.seteditModal(false)
          props.seteditModal !== undefined && props.tableDataFetch()
          if(result.error === false){
            Swal.fire({
              title: 'Success',
              text: result.message,
              icon: 'success',
            })
          }
        });
      }).catch((err) =>{
        Swal.fire({title: 'Error', text: err, icon: 'error'});
      });
  };
    const onError = (errors, e) => console.log('Error', errors);
 
    return(
        <Form onSubmit={pincodeEditSubmit(onEditPincodeSubmit,onError)}>
            <Row className="mx-0 pt-3 justify-content-center addModal">
                <Col sm={12}>
                <FormGroup className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editpincode("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" onChange={handleStateChange}>
                        <option value="">Select State</option>
                        {StateList.map((item, index) => (
                        <option value={item.state_id}>{item.state_name}</option>
                        ))
                    }
                    </select>
                {errors.state_id && <span className="text-danger d-block mb-2">{errors.state_id.message}</span>}
                </FormGroup>
                </Col>
                <Col sm={12}>
                <FormGroup className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editpincode("city_id",{ required: 'Please Select City'})}  className="form-control-sm form-control">
                        <option value="">Select City</option>
                        {Object.entries(props.stateCity).length >0 && props.stateCity !== undefined ? props.stateCity.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                        ))
                    :''}
                    </select>
                {errors.city_id && <span className="text-danger d-block mb-2">{errors.city_id.message}</span>}
                </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                    <input {...editpincode("pincode",{ required: 'Please Enter Picode'})} type="number" className="form-control-sm form-control" placeholder="Enter Pincode" />
                    {errors.pincode && <span className="text-danger d-block mb-2">{errors.pincode.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="text-right">
                <Button className="btn  btn-sm search" color="primary">Save</Button>
                </Col>
            </Row>
        </Form>  
    )
}
export default EditPincode