import React, {useState, useEffect} from "react";
import {Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col} from 'reactstrap';
import classnames from 'classnames';
import MerchantRemittance from "../Remittance/MerchantRemittance";
import MerchantFreight from "../sales/MerchantFreight";
import MerchantInvoices from "../Invoices/MerchantInvoices";
import MerchantCreditNote from "../merchant/MerchantCreditNote";
import MerchantDebitNote from "../merchant/MerchantDebitNote";

const Account = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
//   const toggleDataAdd = () => setDataAdd(!dataAdd);
  useEffect(() => {
}, []);

		
    return(
        <div className="content-body">
       
            <Container fluid>
                <div className="page-header main-header d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
                   <h1> Manage Account </h1>
				   
                    <Nav tabs className="master-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                    <span className="text-white">COD Remittance</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                <span className="text-white">Freight</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="d-none">
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                               <span className="text-white">Invoice</span>
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}
                            >
                               <span className="text-white">Credit Note</span>
                            </NavLink>
                        </NavItem> */}
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '5' })}
                                onClick={() => { toggle('5'); }}
                            >
                               <span className="text-white">Debit Note</span>
                            </NavLink>
                        </NavItem> */}
						
                        { /* <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '6' })}
                                onClick={() => { toggle('6'); }}
                            >
                               <span className="text-white">Invoices</span>
                            </NavLink>
                        </NavItem> */ }
                    </Nav>
                </div>
                <div className="master-content card border-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                {activeTab === '1' && 
                                    <MerchantRemittance user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                {activeTab === '2' && 
                                    <MerchantFreight user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                {activeTab === '3' && 
                                    <MerchantInvoices user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                {activeTab === '4' && 
                                    <MerchantCreditNote user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                {activeTab === '5' && 
                                    <MerchantDebitNote user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="6">
                            <Row>
                                <Col sm="12">
                                {activeTab === '6' && 
                                    <MerchantInvoices user={props.user} />
                                }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    )
}
export default Account