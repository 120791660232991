import React,{useEffect, useState} from 'react';
import {Card, CardBody, Table, CardHeader,CardTitle, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import LoaderDefault from '../../../LoaderDefault';
// import NodataFound from '../../../NodataFound';
import { getTodayData,get1DayData,get2DayData,get3DayData,get4DayData,get5DayData,get6DayData,get7DayData,getDaySevenPlusData } from './DayWise';
import { zone_category_id, download_delay } from '../../../../config';
import { errorHandling } from '../../../../utlis';

const NotAttemptedShipments = (props) => {
    const [Pending, setPendingList] = useState({});
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const history = useHistory();  

    const listingPending = () => {
        let data = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_NOT_ATTEMPTED}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPendingList(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });
    }
    useEffect(() => {
        listingPending();
    }, []);

    
      let todayTotal = 0
      let oneDayTotal = 0
      let twoDayTotal = 0
      let threeDayTotal = 0
      let fourDayTotal = 0
      let fiveDayTotal = 0
      let sixDayTotal = 0
      let sevenDayTotal = 0
      let eightDayTotal = 0
      let nineDayTotal = 0
      let tenDayTotal = 0
      let tenPlusDayTotal = 0
      let grandTotal = 0

    let pendingStatus = checkData(Pending) && Object.values(Pending.reduce((osi, item) => {
        let zoneId = checkUndeNullBlank(item.zone_category_id) && item.zone_category_id
        if (!osi[zoneId])
        osi[zoneId] = {zone_category_id: zoneId,date: [],total: [], today: [], oneDay: [], twoDay: [], threeDay : [], fourDay: [], fiveDay : [], sixDay : [], sevenDay : [], eightDay : [], nineDay : [], tenDay : [], tenPlusDay : []};
        let dates = checkUndeNullBlank(item.dateOn) ? item.dateOn : 0;
        let totals = checkUndeNullBlank(item.total) ? item.total : 0;
        osi[zoneId].date.push(dates);
        osi[zoneId].total.push(totals);

        if(getTodayData(new Date(item.dateOn))){
            osi[zoneId].today.push(totals);
            todayTotal += totals;
        }else if(get1DayData(new Date(item.dateOn))){
            osi[zoneId].oneDay.push(totals)
            oneDayTotal += totals;
        }else if(get2DayData(new Date(item.dateOn))){
            osi[zoneId].twoDay.push(totals)
            twoDayTotal += totals;
        }else if(get3DayData(new Date(item.dateOn))){
            osi[zoneId].threeDay.push(totals)
            threeDayTotal += totals;
        }else if(get4DayData(new Date(item.dateOn))){
            osi[zoneId].fourDay.push(totals)
            fourDayTotal += totals;
        }else if(get5DayData(new Date(item.dateOn))){
            osi[zoneId].fiveDay.push(totals)
            fiveDayTotal += totals;
        }else if(get6DayData(new Date(item.dateOn))){
            osi[zoneId].sixDay.push(totals)
            sixDayTotal += totals;
        }else if(get7DayData(new Date(item.dateOn))){
            osi[zoneId].sevenDay.push(totals)
            sevenDayTotal += totals;
        }else if(get7DayData(new Date(item.dateOn))){
            osi[zoneId].eightDay.push(totals)
            eightDayTotal += totals;
        }else if(get7DayData(new Date(item.dateOn))){
            osi[zoneId].nineDay.push(totals)
            nineDayTotal += totals;
        }else if(get7DayData(new Date(item.dateOn))){
            osi[zoneId].tenDay.push(totals)
            tenDayTotal += totals;
        }else{
            osi[zoneId].tenPlusDay.push(totals)
            tenPlusDayTotal += totals;
        }
        grandTotal = todayTotal+oneDayTotal+twoDayTotal+threeDayTotal+fourDayTotal+fiveDayTotal+sixDayTotal+sevenDayTotal+eightDayTotal+nineDayTotal+tenDayTotal+tenPlusDayTotal
        return osi;
    }, {}));

    const exportShipment = (zoneid,total) => {

        let data = {"all_shipment":1}
        data.zone_category_id = checkUndeNullBlank(zoneid) ? zoneid : '';
        data.total = checkUndeNullBlank(total) ? total : '';

        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id = props.user[0].id
        }else{
            data.merchant_id = props.user[0].id
        }
        data.is_export=1;
        data.is_attempted=0;
        setLoader(true);
        axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
        .then((res) => {
        if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
            if(parseInt(res.data.errno)>0){
                    setLoader(false);
                    if(res.data.sqlMessage!==undefined){
                    Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
                }
            }else{
                if(res.data.is_export === 1){
                    setTimeout(function(){ 
                      setLoader(false);
                      history.push("/my-downloads"); 
                    },download_delay);
                }
            }
        }}).catch((err) => {
            errorHandling(err,"")
        });        
    }

    return (
        <div className="NotAttemptedShipments">
            {loader && <div className="formLoader"><Spinner /></div>}    
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Not Attempted Shipments</CardTitle>  
                </CardHeader>
                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap w-some">Zoning</th>
                                <th className="text-nowrap">0 Day</th>
                                <th className="text-nowrap">1 Day</th>
                                <th className="text-nowrap">2 Day</th>
                                <th className="text-nowrap">3 Day</th>
                                <th className="text-nowrap">4 Day</th>
                                <th className="text-nowrap">5 Day</th>
                                <th className="text-nowrap">6 Day</th>
                                <th className="text-nowrap">7 Day</th>
                                <th className="text-nowrap">8 Day</th>
                                <th className="text-nowrap">9 Day</th>
                                <th className="text-nowrap">10 Day</th>
                                <th className="text-nowrap">10+ Day</th>
                                <th className="text-nowrap">Total</th>
                            </tr>                            
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-nowrap">Total</th>
                                <th className="text-nowrap">{todayTotal}</th>
                                <th className="text-nowrap">{oneDayTotal}</th>
                                <th className="text-nowrap">{twoDayTotal}</th>
                                <th className="text-nowrap">{threeDayTotal}</th>
                                <th className="text-nowrap">{fourDayTotal}</th>
                                <th className="text-nowrap">{fiveDayTotal}</th>
                                <th className="text-nowrap">{sixDayTotal}</th>
                                <th className="text-nowrap">{sevenDayTotal}</th>
                                <th className="text-nowrap">{eightDayTotal}</th>
                                <th className="text-nowrap">{nineDayTotal}</th>
                                <th className="text-nowrap">{tenDayTotal}</th>
                                <th className="text-nowrap">{tenPlusDayTotal}</th>
                                <th className="text-nowrap"><div className="text-left mx-auto dowbload-con d-flex justify-content-between">{grandTotal}{grandTotal > 0 && <span className="download-icon mx-1" onClick={()=>{exportShipment("",grandTotal)}}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                            </tr> 
                            {checkData(pendingStatus) ? pendingStatus.map((courierItem, index) => {
                                let today = courierItem.today.length > 0 ? eval(courierItem.today.join('+')): 0;
                                let onDay = courierItem.oneDay.length > 0 ? eval(courierItem.oneDay.join('+')): 0;
                                let twoDay = courierItem.twoDay.length > 0 ? eval(courierItem.twoDay.join('+')): 0;
                                let threeDay = courierItem.threeDay.length > 0 ? eval(courierItem.threeDay.join('+')): 0;
                                let fourDay = courierItem.fourDay.length > 0 ? eval(courierItem.fourDay.join('+')): 0;
                                let fiveDay = courierItem.fiveDay.length > 0 ? eval(courierItem.fiveDay.join('+')): 0;
                                let sixDay = courierItem.sixDay.length > 0 ? eval(courierItem.sixDay.join('+')): 0;
                                let sevenDay = courierItem.sevenDay.length > 0 ? eval(courierItem.sevenDay.join('+')): 0;
                                let eightDay = courierItem.eightDay.length > 0 ? eval(courierItem.eightDay.join('+')): 0;
                                let nineDay = courierItem.nineDay.length > 0 ? eval(courierItem.nineDay.join('+')): 0;
                                let tenDay = courierItem.tenDay.length > 0 ? eval(courierItem.tenDay.join('+')): 0;
                                let tenPlusDay = courierItem.tenPlusDay.length > 0 ? eval(courierItem.tenPlusDay.join('+')): 0;
                                let gTotal = today+onDay+twoDay+threeDay+fourDay+fiveDay+sixDay+sevenDay+tenPlusDay
                                   return(
                                    <tr key={index}>
                                        <td>{zone_category_id[courierItem.zone_category_id]}</td>
                                        <td>{today}</td>
                                        <td>{onDay}</td>
                                        <td>{twoDay}</td>
                                        <td>{threeDay}</td>
                                        <td>{fourDay}</td>
                                        <td>{fiveDay}</td>
                                        <td>{sixDay}</td>
                                        <td>{sevenDay}</td>
                                        <td>{eightDay}</td>
                                        <td>{nineDay}</td>
                                        <td>{tenDay}</td>
                                        <td>{tenPlusDay}</td>
                                        <td><div className="text-left mx-auto dowbload-con d-flex justify-content-between">{gTotal}{gTotal > 0 && <span className="download-icon mx-1" onClick={()=>{exportShipment(courierItem.zone_category_id,gTotal)}}><FontAwesomeIcon icon={faDownload} /></span>}</div></td>
                                    </tr>
                                    );
                                }) :
                                loadertable ? <tr>
                                <td colSpan="7">Loading...</td>
                                </tr>
                                :<tr><td colSpan="7" className="text-center">No Data Found</td></tr>
                            }  
                              
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}

export default NotAttemptedShipments;