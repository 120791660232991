import React,{useRef,useState} from 'react';
import { Row, Col, Label, Button,Form, FormGroup, CardTitle, Spinner} from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
// import axios from 'axios';
import axios from '../../../CommonAxios';

const ChangePassword = (props) => {
    const [loader, setLoader] = useState(false);
    const { register:forgotinput, handleSubmit:ForgotPasswordSubmit,watch, formState: { errors }} = useForm();
    const onMerchantForgotPassword = (data) => {
        setLoader(true);
        if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
            data.merchant_id = props.user[0].id
        }else{
        }
            let formData = new FormData();
            formData.append("merchant_id", data.merchant_id);
            formData.append("old_password", data.old_password);
            formData.append("new_password", data.new_password);  
    axios
      .post(process.env.REACT_APP_MERCHANT_CHANGE_PASSWORD, formData)
      .then((res) => {
        setLoader(false);
        if(res.data.error === false){
          Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }
        if(res.data.error === true){
          Swal.fire({
            title: 'Error',
            text: res.data.msg,
            icon: 'error',
          })
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({icon:'error', text:err, title:'Error'})
      });
    };
    const new_password = useRef({});
    new_password.current = watch("new_password", "");
      const onError = (errors, e) => console.log('Error', errors);
    return (
        <>
        {loader && <div className="formLoader"><Spinner /></div>}
        <Form onSubmit={ForgotPasswordSubmit(onMerchantForgotPassword,onError)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Change Password</CardTitle>
            </div>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="old-password">Old Password</Label>
                        <input {...forgotinput("old_password",{ required: 'Please Enter Old Password'})}  type="password" className="form-control" placeholder="Old Password" id="old-password" />
                        {errors.old_password && <span className="text-danger d-block mb-2">{errors.old_password.message}</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>     
                <Col sm={6}>
                    <FormGroup>
                        <Label for="new-password">New Password</Label>
                        <input {...forgotinput("new_password",{ required: 'Please Enter New Password'})}  type="password" className="form-control" placeholder="New Password" id="new-password" />
                        {errors.new_password && <span className="text-danger d-block mb-2">{errors.new_password.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="retype-password">Retype New Password</Label>
                        <input {...forgotinput("confirm_password",{ required: 'Please Enter Confirm Password', validate: value => value === new_password.current || "The passwords do not match"})}  type="password" className="form-control" placeholder="Retype New Password" id="retype-password" />
                        {errors.confirm_password && <span className="text-danger d-block mb-2">{errors.confirm_password.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="btn-sm" color="primary"> Save changes</Button>
                </Col>            
            </Row>
        </Form>
        </>
    );
}

export default ChangePassword;