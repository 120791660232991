import React, {useEffect,useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, Spinner} from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';

import Swal from 'sweetalert2'

const CourierBankDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const [checkCopy, setCheckcopy] = useState({});
  const [bankDetails, setBankDetails] = useState({});

  const handleFileInput = (e) => {
    setCheckcopy(e.target.files[0])
}
    
  const { register:addCourier_bankDetails, handleSubmit:bankDetailsSubmit, formState: { errors }, reset  } = useForm();
  useEffect(() => {
    axios({
        method: "post",
        url: `${process.env.REACT_APP_COURIER_BANKDETAILS}/view`,
        data: {"courier_id": props.courierId !== undefined ? props.courierId:''},
      })
        .then(function (response) {
          if(response.data.length >0){
            let myResponse=response.data[0];
            reset(myResponse);
            setBankDetails(myResponse);
        }
        })
        .catch(function (response) {
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reset])
    const onAddbankDetails = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("courier_id",  props.courierId !== undefined ? props.courierId:'');
        formData.append("account_type", data.account_type);
        formData.append("bank_name", data.bank_name);
        formData.append("account_number", data.account_number);
        formData.append("ifsc_code", data.ifsc_code);
        formData.append("branch", data.branch);
        formData.append("cancel_cheque_copy", checkCopy);
        axios
          .post(process.env.REACT_APP_COURIER_BANKDETAILS, formData)
          .then((res) => {
        setLoader(false);
        if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.message,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
        setLoader(false);
        Swal.fire({title:'Error', text:err, icon:'error'});
          });
      };
  const onError = (errors, e) => console.log('Error', errors);


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={bankDetailsSubmit(onAddbankDetails,onError)}>
                  <Row className="mx-0 pt-3">
                    <Col Col sm={12} md={6} xl={4} className="mb-2"> 
                          <FormGroup>
                            <Label>Account Type</Label>
                            <div>
                              <span className="mr-2">
                                <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Saving" id="saving" />
                                <Label className="mx-2" for="saving">Saving</Label>
                              </span>
                              <span>
                                <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Current" id="current" />
                                <Label className="mx-2" for="current">Current</Label>
                              </span>
                            </div>
                            {errors.account_type && <span className="text-danger d-block mb-2">{errors.account_type.message}</span>}
                          </FormGroup>                  
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="bankName">Enter Bank Name</Label>
                          <input {...addCourier_bankDetails("bank_name",{ required: 'Please Enter Bank Name'})} className="form-control-sm form-control" placeholder="Bank Name" id="bankName" />
                          {errors.bank_name && <span className="text-danger d-block mb-2">{errors.bank_name.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="accountNumber">Enter Account Number</Label>
                          <input {...addCourier_bankDetails("account_number",{ required: 'Please Enter Account Number'})} type="Number" className="form-control-sm form-control" placeholder="Account Number" id="accountNumber" />
                          {errors.account_number && <span className="text-danger d-block mb-2">{errors.account_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="IFSC_Code">Enter IFSC Code</Label>
                          <input {...addCourier_bankDetails("ifsc_code",{ required: 'Please Enter IFSC Code'})} className="form-control-sm form-control" placeholder="IFSC Code" id="IFSC_Code"/>
                          {errors.ifsc_code && <span className="text-danger d-block mb-2">{errors.ifsc_code.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="branchName">Enter Branch Name</Label>
                          <input {...addCourier_bankDetails("branch", { required: 'Please Enter Branch Name'} )} className="form-control-sm form-control" placeholder="Branch" id="branchName" />
                          {errors.branch && <span className="text-danger d-block mb-2">{errors.branch.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">                       
                        <FormGroup>
                          <Label for="uploadCheque">Upload Cheque Book Copy</Label>
                          <input {...addCourier_bankDetails("cheque_copy", { required: 'Please Add Cheque Book Copy'})} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadCheque" />
                          {bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== null && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+bankDetails.cancel_cheque_copy} alt="logo" style={{width:"120px"}} /></div>}
                          {errors.cheque_copy && <span className="text-danger d-block mb-2">{errors.cheque_copy.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn btn-sm" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
        </>
    );
}

export default CourierBankDetails;
