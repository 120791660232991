import React, {useEffect,useState} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Input,FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink,  Modal, ModalHeader, ModalBody, Label, Spinner} from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowUp,faChevronRight,faDownload, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChevronLeft, ChevronRight } from 'react-feather';
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDate, changeIntoDateYmd} from '../../utlis';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const RemittanceTransactions = (props) => {

  const [merchants, setMerchants] = useState([]);
  const [adjustedData, setAdjustedData] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [currentCount,setCurrentCount] = useState(1);
  const [selectperpage,setSelectperpage] = useState('20');
  const [filterDate, setFilterDate] = useState([]);
  const [filterInput, setFilterInput] = useState({});
  const { register, handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [datestate, setDateState] = useState({start: '',end: '',});
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }

  const dropFilterCount = 20
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if(!excelmodal){setExcelFile("");}
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }
  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }     
      return opt_merchants.push(tempObj)
    });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const transactiosLIsting = () => {
    reset();
    setFilterDate([]);
    let data = {}

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    setLoadertable(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE}`,
      data: data,
    }).then((res) => {
      setLoadertable(false)
     if(res.data.error === false){
       setAdjustedData(res.data)
     }
    }).catch((err) => {
      setLoadertable(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }

  useEffect(() => {
    transactiosLIsting();
  }, []);

  const resetFilter = () => {
    reset();
    setMerchants([]);
    transactiosLIsting();
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(pagePerpage)
  }
  function nextPageData() {
    if(checkData(adjustedData.data)&&checkUndeNullBlank(adjustedData.totalPage)){
        if(adjustedData.totalPage > page){
          setCurrentCount(currentCount+Object.entries(adjustedData.data).length)
      setPage(page+1)
      setLoadertable(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(adjustedData.data)
    if(currentCount>Object.entries(adjustedData.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(pagePerpage);
    }
  }
  
  const onpaginationFilter = (pagePerpage) => {
    onCommonFilter(pagePerpage)
  };
  const onSubmit = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.ref_no) || checkData(merchants) || filterDate.length > 0){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage);
  }
  };
  const onCommonFilter = (data, pagePerpage) => {
  let merchantsId = [];
  if(checkData(merchants)){
    merchants.map((item) => (
      merchantsId.push(item.value)
    )); 
  }
  let dateStart = moment(datestate.start).format("YYYY-MM-DD");
  let dateEnd = moment(datestate.end).format("YYYY-MM-DD");

    let byData = {"ref_no":checkUndeNullBlank(data.ref_no)?data.ref_no:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'',"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : ''}
    setLoadertable(true)

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoadertable(false);
            if(res.data.error === false){
              setAdjustedData(res.data)
            }else{
              Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',})
            }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoadertable(false);
      });
  };
  const { register:uploadFreight, handleSubmit:initRemittUploadSubmit,formState: { errors }} = useForm();
  const onInitRemittSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);  
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');  
    setLoader(true);
    axios
      .post(process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE_UPLOAD, formData)
      .then((res) => {
        setLoader(false);
        let errorFile='';
          if(res.data.errorfile !== undefined){
           errorFile = process.env.REACT_APP_BASE_URL+res.data.errorfile;
          }
        if(res.data.error === false){
          Swal.fire({
            title: 'Success',
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
            text: res.data.msg,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              setExcelFile(false);
              transactiosLIsting();
            }
         });
        }else{
          console.log('res', res)
           Swal.fire({
            title: 'Error',
            text: res.data.msg,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              setExcelFile(false);
              transactiosLIsting();
            }
         });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({title:'Error', text:err, icon:'error'});
      });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 CourierRemittance shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="page-title">Remittance Adjusted</CardTitle>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Adjust Remittance</span></div>              
              </div>
            </CardHeader>
            <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Adjust Remittance</ModalHeader>
                <ModalBody>
                  <Form onSubmit={initRemittUploadSubmit(onInitRemittSubmit)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Adjust Remittance via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/remittance_adjust.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Upload Adjust Remittance Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadFreight("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
                  <Row className="mx-0 justify-content-start">  
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>  
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          {/* <DateRangePicker  value={filterDate} onChange={date => setFilterDate(date)}  placeholder="Choose Tran. Date Range" format="DD-MM-YYYY"  ref={dateRangeRef} change={onDateChange} /> */}
                          <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              {datestate.start !== '' && datestate.end !== '' ? 
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                  {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                </div>
                                :
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                  Select Date
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                </div>
                              }
                          </DateRangePicker>
                        </FormGroup>
                    </Col> 
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...register("ref_no")}className="form-control-sm form-control" placeholder="Enter Transaction ID" />
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" onClick={resetFilter} outline>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              <CardBody className="p-0">
              {checkData(adjustedData) && checkData(adjustedData.data) && checkUndeNullBlank(adjustedData.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(adjustedData.data).length)} of {adjustedData.TotalRows} entries
                    </div>
                      {adjustedData.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Merchant Name</th>
                      <th className="text-nowrap">Invoice No.</th>
                      <th className="text-nowrap">Adjusted Date</th>
                      <th className="text-nowrap">Adjusted Amount</th>
                      {/* <th className="text-nowrap">Total Docket(s)</th> */}
                      <th className="text-nowrap">Download</th> 
                    </tr>
                   </thead>
                  <tbody>
                    {checkData(adjustedData.data) ? adjustedData.data.map((item, index) => (
                      <tr key={index}>
                      <td>{item.MerchantName}</td>
                      <td>{item.ref_no}</td>
                      <td>{changeIntoDate(item.trans_date)}</td>
                      <td>{item.credit_amt}</td>
                      {/* <td>{item.MerchantName}</td> */}
                      <td><Button className="btn btn-sm text-nowrap" color="info" outline>Download Excel</Button></td>
                    </tr>
                    )):
                      loadertable ? <tr><td colSpan="8" className="text-center">Loading...</td></tr>
                      :<tr><td colSpan="8" className="text-center">No Data Found</td></tr>}              
                  </tbody>
                </Table>
                {checkData(adjustedData) && checkData(adjustedData.data) && checkUndeNullBlank(adjustedData.TotalRows) ?
                <>
                {adjustedData.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(adjustedData.data).length)} of {adjustedData.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(RemittanceTransactions);