import React from 'react';
import { Row, Col, } from 'reactstrap';

const Footer = (props) => {
    
    return (
        <div className="content overflow-hidden">
          <Row>
           <Col sm={12}>            
                <div className="coy-right text-center py-3">
                    Design & Developed By TekiPost
                </div>
            </Col>
          </Row>
        </div>
    );
}
export default Footer