import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, Label, Spinner, Input} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowUp, faChevronRight, faDownload, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
// import { DatePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDateTime, changeIntoDateYmd, errorHandling } from '../../utlis';
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import { baseString } from '../../config';

const InitiatedRemittance = (props) => {
  const [initRemittance, setInitRemittance] = useState({});
  const [loadertable, setLoadertable] = useState(false);  
  const [loader, setLoader] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [page, setPage] = useState(1)
  const [currentCount,setCurrentCount] = useState(1);
  const [selectperpage,setSelectperpage] = useState('20');
  // const [actionDate, setActionDate] = useState(new Date());
  const [actionDate, setActionDate] = useState();
  const [refNumber, setRefNumber] = useState();
  function onDateChange(event, id) {
    console.log('event', event)
    console.log('id', id)
    // const { name, value } = event.target;
    setActionDate(prevState => ({ ...prevState, [id]: event }));
  }
  function onRefChange(event, id) {
    console.log('event', event)
    console.log('id', id)
    // const { name, value } = event.target;
    setRefNumber(prevState => ({ ...prevState, [id]: event.target.value }));
  }
  const dropFilterCount = 20
  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if(!excelmodal){setExcelFile("");}
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  
  useEffect(() => {
    initRemittanceListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])


  const { register:uploadFreight, handleSubmit:initRemittUploadSubmit,formState: { errors }} = useForm();
  const onInitRemittSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INITIATE_REMITTANCE}`,
      data: formData,
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            initRemittanceListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            initRemittanceListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }


  // merchant freight Listing 
  const initRemittanceListing = () => {
    setLoadertable(true);
    
    fetch(`${process.env.REACT_APP_MERCHANT_REMITTANCE_INITIATED}?page=${page}&per_page=${selectperpage}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
          'x-ref': window.btoa(baseString),
          "Accept":'application/json',
      }, body: JSON.stringify({"admin_id" : props.user[0].id})
    }).then((resp)=>{
      resp.json().then((result)=>{
        setLoadertable(false);
        setInitRemittance(result);
      });
    }).catch(err => { setLoadertable(false);
      Swal.fire({icon:"error", title:"Error", text:err})
    });
  }

  
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(pagePerpage)
  }
  function nextPageData() {
    if(checkData(initRemittance.data)&&checkUndeNullBlank(initRemittance.totalPage)){
        if(initRemittance.totalPage > page){
          setCurrentCount(currentCount+Object.entries(initRemittance.data).length)
      setPage(page+1)
      setLoadertable(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(initRemittance.data)
    if(currentCount>Object.entries(initRemittance.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(pagePerpage);
    }
  }
  
  const onpaginationFilter = (pagePerpage) => {
    onCommonFilter(pagePerpage)
  };

  const onCommonFilter = (pagePerpage) => {
    let byData = {}
    setLoadertable(true)

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_INITIATED}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoadertable(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setInitRemittance(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoadertable(false);
      });
  };

  //save data
  const updateTrans=(index, merchantId, codAmount)=>{
    console.log('index', index)
    let transDate = document.querySelectorAll('#transDate'+index+' span.rs-picker-toggle-value');
    let transDateValue = transDate.length > 0 && document.querySelectorAll('#transDate'+index+' span.rs-picker-toggle-value')[0].innerText;
    let refName = document.getElementById('transId'+index).value;
    console.log('transDateValue',transDateValue)
    console.log('refName',refName)
    if(checkUndeNullBlank(refNumber) && checkUndeNullBlank(refNumber[merchantId])){
      console.log('refNumber[merchantId]', refNumber[merchantId])
    //   Swal.fire({title: 'Error',text:'Please Update Transaction Date and Bank Ref. Number'})
    // }else if(transDate.length === 0){
    //   Swal.fire({title: 'Error',text:'Please Update Transaction Date'})
    // }else if(refName === ""){
    //   Swal.fire({title: 'Error',text:'Please Enter Bank Ref. Number'})
    // }else{
      let byData = {"bank_ref_no":refNumber[merchantId],"trans_date":checkUndeNullBlank(actionDate)&&checkUndeNullBlank(actionDate[merchantId])?changeIntoDateYmd(actionDate[merchantId]):changeIntoDateYmd(new Date()),"merchant_id":merchantId, "trans_amt":codAmount}
    setLoader(true);

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_SAVE_REMITTANCE_INITIATED}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.data.error !== true){
              Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
            }
            else{
              Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',});
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
    }else{
      Swal.fire({title: 'Error',text:'Please Enter Bank Ref. Number'})
    }
  }
  // delete merchant
  const deleteMerchant = (merchantId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this merchant",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        
        let byData = {"merchant_id":merchantId}
    setLoader(true);

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_DELETE_REMITTANCE_INITIATED}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.data.error !== true){
              Swal.fire({title: 'Deleted',text: res.data.msg,icon: 'success',});
            }
            else{
              Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',});
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
      }else{
        Swal.fire(
          'as your wish !'
        )
      }
    })
  }


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 initiatedRemittance content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Manage Initiated COD Remittance</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <div className="btn-text btn-light mx-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Initiate Remittance</span></div>          
              </div>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Initiated COD Remittance</ModalHeader>
                <ModalBody>
                  <Form onSubmit={initRemittUploadSubmit(onInitRemittSubmit)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Initiated COD Remittance via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/remittance.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Upload Initiated COD Remittance Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadFreight("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            </CardHeader>
              <CardBody className="p-0">
              {checkData(initRemittance) && checkData(initRemittance.data) && checkUndeNullBlank(initRemittance.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(initRemittance.data).length)} of {initRemittance.TotalRows} entries
                    </div>
                      {initRemittance.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th>Merchant</th>
                      <th>Total COD</th>
                      <th>Total Orders</th>
                      <th>Initiated Date</th>
                      <th>Transaction Date</th>
                      <th>Bank Ref. Number</th>
                      <th>Action</th>
                    </tr>
                   </thead>
                  <tbody>
                    {checkData(initRemittance.data) ? initRemittance.data.map((item, index) => (
                    <tr>
                      <td>{item.MerchantName}</td>
                      <td>{item.totalCOD}</td>
                      <td>{item.totalOrders}</td>
                      <td>{changeIntoDateTime(item.updated_at)}</td>
                      <td><DatePicker selected={checkUndeNullBlank(actionDate)?actionDate[item.merchant_id] : new Date()} id={`transDate${index+1}`}onChange={date => onDateChange(date, item.merchant_id)}
                          /></td>
                      <td><input type="text" id={`transId${index+1}`} placeholder="Ref. Number" className="form-control d-inline" style={{width:"200px"}} onChange={event => onRefChange(event, item.merchant_id)} /></td>
                      <td>
                        <span className="mr-2"><Button onClick={() => updateTrans(index+1,item.merchant_id,item.totalCOD)} className="btn btn-sm" color="info" outline>Save</Button></span>
                        <span><Button onClick={() => deleteMerchant(item.merchant_id)} className="btn btn-sm" color="danger" outline>Delete</Button></span>
                      </td>
                    </tr>   
                    ))
                    :loadertable ? <tr> <td colSpan="7">Loading...</td> </tr> :
                    <tr> <td colSpan="6">No Data Found</td> </tr> }                 
                  </tbody>
                </Table>
                {checkData(initRemittance) && checkData(initRemittance.data) && checkUndeNullBlank(initRemittance.TotalRows) ?
                <>
                {initRemittance.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(initRemittance.data).length)} of {initRemittance.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default InitiatedRemittance;