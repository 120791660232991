import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Table, CardTitle, FormGroup, Form, CardHeader, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UploadCloud, Download } from 'react-feather';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
// import axios from 'axios';
import axios from '../../CommonAxios';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const CustomInvoice = (props) => {
  const [BillingType, setBillingType] = useState('monthly');
  const [loader, setLoader] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [datestate, setDateState] = useState({ start: '', end: '', });
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const handleCallbackWeek = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    maxSpan: {
      "days": 30
    },
    maxDate: moment()
  }
  const rangesWeek = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    maxSpan: {
      "days": 7
    },
    maxDate: moment()
  }
  console.log('invoiceData', invoiceData)
  useEffect(() => {

  }, [])
  const { register: customInvoicedata, handleSubmit: orderCustomSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();
  const onCustomDataSubmit = (data) => {

    if (BillingType === 'monthly' || BillingType === 'weekly') {
      data.start_date = moment(datestate.start).format("YYYY-MM-DD");
      data.end_date = moment(datestate.end).format("YYYY-MM-DD");
    }

    data.admin_id = props.user[0].id
    axios.post(`${process.env.REACT_APP_GET_CUSTOM_INVOICE_DATA}`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          setInvoiceData(res.data.data[0])
        } else {
          Swal.fire({
            title: 'Error',
            text: res.data.msg,
            icon: 'error',
          })
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({ title: 'Error', icon: 'error', text: err })
      });
  }

  return (
    <div className="content-body overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 utilityTool shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Custom Invoice</CardTitle>
              </CardHeader>
              <CardBody className="pt-0">
                <Row>
                  <Col md={8}>
                    <Form onSubmit={orderCustomSubmit(onCustomDataSubmit)}>
                      <Row className="mx-0 justify-content-center align-items-center pt-2">
                        <Col sm={6} md={6}>
                          <FormGroup className='mb-0'>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" {...customInvoicedata("merchant_id", { required: 'Please Select Merchant' })} className="form-control form-control-sm">
                              <option value="">Select Merchant</option>
                              {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants) ?
                                Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
                                  let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
                                  let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id

                                  return (
                                    <option value={merchantId}>{merchantName}</option>
                                  )
                                }) : ''
                              }
                            </select>
                            {errors3.merchant_id && <span className="text-danger d-block error">{errors3.merchant_id.message}</span>}

                          </FormGroup>
                        </Col>
                        <Col sm={6} md={6}>
                          <FormGroup className='mb-0'>
                            <div className="d-flex flex-wrap align-items-center">
                              <span className="form-check2 mr-2">
                                <input {...customInvoicedata("billing_type", { required: 'Please Select Billing Type' })} type="radio" value="monthly" id="monthlydata" onClick={() => setBillingType('monthly')} defaultChecked />
                                <Label className="mx-2 my-0" for="monthlydata">Monthly</Label>
                              </span>
                              <span className="form-check2">
                                <input {...customInvoicedata("billing_type", { required: 'Please Select Billing Type' })} type="radio" value="weekly" id="weeklydata" onClick={() => { setBillingType('weekly'); }} />
                                <Label className="mx-2 my-0" for="weeklydata">Weekly</Label>
                              </span>
                              <span className="form-check2">
                                <input {...customInvoicedata("billing_type", { required: 'Please Select Billing Type' })} type="radio" value="custom" id="customdata" onClick={() => { setBillingType('custom'); }} />
                                <Label className="mx-2 my-0" for="customdata">Custom AWB</Label>
                              </span>
                            </div>
                            {errors3.billing_type && <span className="text-danger d-block error">{errors3.billing_type.message}</span>}
                          </FormGroup>
                        </Col>
                        {BillingType === 'monthly' &&
                          <Col sm={6} md={6} className="mt-2">
                            <FormGroup>
                              <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                                {datestate.start !== '' && datestate.end !== '' ?
                                  <div className='form-control cursor-pointer d-flex align-items-center'>
                                    {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                  </div>
                                  :
                                  <div className='form-control cursor-pointer d-flex align-items-center'>
                                    Select Date
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  </div>
                                }
                              </DateRangePicker>
                            </FormGroup>
                          </Col>
                        }
                        {BillingType === 'weekly' &&
                          <Col sm={6} md={6} className="mt-2">
                            <FormGroup>
                              <DateRangePicker
                                initialSettings={rangesWeek}
                                onCallback={handleCallbackWeek}
                              >
                                {datestate.start !== '' && datestate.end !== '' ?
                                  <div className='form-control cursor-pointer d-flex align-items-center'>
                                    {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                  </div>
                                  :
                                  <div className='form-control cursor-pointer d-flex align-items-center'>
                                    Select Date
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  </div>
                                }
                              </DateRangePicker>
                            </FormGroup>
                          </Col>
                        }
                        {BillingType === 'custom' &&
                          <Col sm={12} className='mt-3'>
                            <FormGroup>
                              <textarea {...customInvoicedata("custom_awb", { required: 'Please Select Billing Type' })} type="textarea" rows={10} id="exampleText" placeholder="Enter Your AWB Number Comma Seperated" className='form-control' />
                              {errors3.custom_awb && <span className="text-danger d-block error">{errors3.custom_awb.message}</span>}
                            </FormGroup>
                          </Col>
                        }
                        <Col sm={12} className="btn-container text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Get Data</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md={4} className="border-left">
                    <CardTitle className="page-title">Invoice Count</CardTitle>
                    <Table responsive borderless className="customTable">
                      <tbody>
                        <tr>
                          <th>Total Count</th>
                          <th>{checkData(invoiceData)&&checkUndeNullBlank(invoiceData.totalCount)?invoiceData.totalCount:0}</th>
                        </tr>
                        <tr>
                          <th>Total Weight</th>
                          <th>{checkData(invoiceData)&&checkUndeNullBlank(invoiceData.totalWeight)?invoiceData.totalWeight:0}</th>
                        </tr>
                        <tr>
                          <th>Total Amount</th>
                          <th>{checkData(invoiceData)&&checkUndeNullBlank(invoiceData.totalAmt)?invoiceData.totalAmt:0}</th>
                        </tr>
                        <tr>
                          <th>Total Tax</th>
                          <th>{checkData(invoiceData)&&checkUndeNullBlank(invoiceData.totalTax)?invoiceData.totalTax:0}</th>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}


function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(CustomInvoice);