import React,{useEffect, useState} from 'react';
import {Card, Label, FormGroup, CardHeader, CardTitle, CardBody, Form,Row, Col, Button } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
// import axios from 'axios';
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';
import { delivered_order_status, failed_order_status,overall_pending_order_status,pending_order_status, pending_order_status_pie_chart, rto_order_status } from '../../../../config';
import { changeIntoDateYmd, removeObjData } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { Pcart } from './Pcart';

import ShipmentIcon from "../../../../assets/images/shipment_icon.png" ;

const ShipmentStatus = (props) => {
    const [allCourier, setAllCourier] = useState([]);
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [filterDate, setFilterDate] = useState([]);
    const [loadertable, setLoadertable] = useState(false);
    const [pradioVal, setPradioVal] = useState(1);
    const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
    const { start, end } = datestate;
    const handleCallback = (start, end) => {
        setDateState({ start, end });
    };
    const ranges = {
        startDate: start !== '' && start.toDate(),
        endDate: end !== '' && end.toDate(),
        ranges: {
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
        ],
        'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        },
    }
    const performanceRadio = (e) => {
      setPradioVal(e.target.value);
  }
    const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;
    const shipmentStatusData = () => {
      setDateState({start: moment().subtract(29, 'days'),end: moment(),});
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
        setLoadertable(true);
        let data = {"start_date":dateStart,"end_date":dateEnd}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_OVERALL_PERFORMANCE}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });
    }
    useEffect(() => {
        shipmentStatusData();
    }, []);

  // const order_status_pending = removeObjData(pending_order_status,[1,3]);
  const order_status_pending = pending_order_status_pie_chart
    // function findPendingCount(count) {
    //     const pendingDatavalue = order_status_pending.filter((item) => item === count)[0];
    //     return pendingDatavalue
    //   }
    function findPendingCount(count,pending_order_status) {
      const pendingDatavalue = pending_order_status.filter((item) => item === count)[0];
      return pendingDatavalue
  }
    function findRtoCount(count) {
          const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findDeliveredCount(count) {
          const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findFailedCount(count) {
          const pendingDatavalue = failed_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
        let shipmentDelivered= []
        let shipmentPending = []
        let shipmentRto= [];
        let shipmentFailed= [];
        let shipmentIntransit= [];
        checkData(performanceOverviews) && Object.values(performanceOverviews.reduce((osi, item) => {        
        let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
        // let pending = checkUndeNullBlank(findPendingCount(item.order_status_id)) ? item.total : 0;
        let pending=0;
        if(parseInt(pradioVal) === 1){
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,pending_order_status)) ? item.total : 0;
        }else{
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,overall_pending_order_status)) ? item.total : 0;
        }
        let rto=checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        let failed=checkUndeNullBlank(findFailedCount(item.order_status_id)) ? item.total : 0;
        let intransit=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 6 ? item.total : 0;
        shipmentIntransit.push(intransit);
        shipmentDelivered.push(delivered);
        shipmentPending.push(pending);
        shipmentRto.push(rto);
        shipmentFailed.push(failed);
        return osi;
    }, {}));
    let totalShipment = eval(shipmentDelivered.join('+'))+eval(shipmentPending.join('+'))+eval(shipmentRto.join('+'))+eval(shipmentFailed.join('+'));
    let totalDelivered = eval(shipmentDelivered.join('+'));
    let totalPending = eval(shipmentPending.join('+'));
    let totalRto = eval(shipmentRto.join('+'));
    let totalFailed = eval(shipmentFailed.join('+'));    
    let totalIntransit = eval(shipmentIntransit.join('+'));
    let totalDeliveredPercent=0;
    if(parseInt(pradioVal) === 1 ){
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / (totalShipment - totalPending) ) * 100 : 0
    }else{
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / totalShipment ) * 100 : 0      
    }
    let totalPendingPercent = totalPending >0 ? (totalPending / totalShipment ) * 100 : 0
    let totalRtoPercent = totalRto >0 ? (totalRto / totalShipment ) * 100 : 0
    let totalFailedPercent = totalFailed >0 ? (totalFailed / totalShipment ) * 100 : 0

    const allCourierMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "All Couriers"
      }
      // const opt_allCourier = [
      //   { label: "Fedex (Express - 0.5KG)", value: "Fedex (Express - 0.5KG)" },
      // ];

    var opt_allCourier = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
        Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
        let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
        return opt_allCourier.push(tempObj)
        });
    }
    // ChartJS.register(ArcElement, Tooltip, Legend);
   const data = {
      labels: ['Delivered', 'RTO', 'Pending', 'Delivered Failed'],
        datasets: [
        {
          label: '# Shipment Status',
          data: [totalDelivered,totalRto,totalPending,totalFailed],
          backgroundColor: [
            'rgb(57,218,138, 0.3)',
            'rgb(255,91,92, 0.3)',
            'rgb(255,215,0, 0.3)',
            'rgb(253,172,65, 0.3)',
          ],
          borderColor: [
            'rgb(57,218,138, 1)',
            'rgb(255,91,92, 1)',
            'rgb(255,215,0, 1)',
            'rgb(253,172,65, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    
    const { register:filter, handleSubmit:filterPerFormance } = useForm();
    const onFilterPerformance = (data) => {
        setPerformanceOverviews({});
        setLoadertable(true);
        let byData = {}
        let courierId = [];
        if(checkData(allCourier)){
          allCourier.map((item) => (
              courierId.push(item.value)
          )); 
        } 
        if(checkUndeNullBlank(courierId)){
            byData.courier_id=courierId
          }
          let isAttempted = checkUndeNullBlank(pradioVal) ? pradioVal : 1
          let dateStart = moment(datestate.start).format("YYYY-MM-DD");
          let dateEnd = moment(datestate.end).format("YYYY-MM-DD");

        byData={"is_attempted":isAttempted,"courier_id":checkUndeNullBlank(courierId)?courierId:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"start_date":dateStart,"end_date":dateEnd}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            byData.admin_id=props.user[0].id
          }else{
            byData.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_OVERALL_PERFORMANCE}`,
            data: byData,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });  
    };

    return (
        <div className="ShipmentStatus">
            <Card className="border-0 shadow">
               <div className="page-header content-header d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
				    <div className="page-title">
                    <figure><img className="img-fluid" src={ShipmentIcon} alt="img1" /></figure>
					Shipments Status 
					</div>
                </div>
                <Form  onSubmit={filterPerFormance(onFilterPerformance)} className="cardFilter">
                    <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                        <Col sm={6} md={4} lg={4} xl={4}>
                            <FormGroup>
                                {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="DD-MM-YYYY" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} /> */}
                                <DateRangePicker
                                        initialSettings={ranges}
                                        onCallback={handleCallback}
                                    >
                                    {datestate.start !== '' && datestate.end !== '' ? 
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                    </div>
                                    :
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        Select Date
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </div>
                                    }
                                    </DateRangePicker>
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={4} lg={4} xl={4}>
                            <FormGroup>
                                <MultiSelect 
                                options={opt_allCourier}
                                value={allCourier}
                                onChange={(e) => setAllCourier(e)}
                                labelledBy="All Couriers"
                                overrideStrings={allCourierMultiSelectSettings}
                                className={checkData(allCourier) && "active"}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={7} md={5} lg={4} xl={4}>
                            <div className="d-flex flex-wrap mb-2 mb-sm-0">
                                <FormGroup className="mr-sm-2 mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("delivery_type_id")} type="radio" value='1' id="ship_COD" />
                                        <Label className="mx-2" for="ship_COD">COD</Label>
                                    </span>
                                </FormGroup>
                                <FormGroup className="mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("delivery_type_id")} type="radio" value='3' id="ship_Prepaid" />
                                        <Label className="mx-2" for="ship_Prepaid">Prepaid</Label>
                                    </span>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="pl-0">
                                <div className="d-flex flex-wrap">
                                    <FormGroup className="mb-md-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='1' id="isattempted"  onChange={ (e) => performanceRadio(e)} defaultChecked/>
                                            <Label className="mx-2" for="isattempted">Attempted</Label>
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="mr-sm-4 mb-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='0' id="isoverall" onChange={ (e) => performanceRadio(e)} />
                                            <Label className="mx-2" for="isoverall">Overall</Label>
                                        </span>
                                    </FormGroup>
                                </div>
                            </Col>
                        <Col sm={5} md={3} lg={2} xl={2} className="btn-container">
                            <Button className="btn btn-sm" color="primary">Search</Button>
                        </Col>
                    </Row>
                </Form>
                <CardBody>                    
                  {/* <Pcart /> */}
                {!loadertable ? 
                    <Pie data={data} />
                :''}
                </CardBody>
            </Card>            
        </div>
    );
}

function mapDispatchToProps (state) {
  return {
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(ShipmentStatus);