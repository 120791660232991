import http from '../CommonAxios'

const GetState = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/state?page=${limit}`,postParams)
}
const GetCity = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/city?page=${limit}&per_page=${getParams.per_page}`)
}
const GetPincode = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/pincode?page=${limit}&per_page=${getParams.per_page}`)
}
const GetCourier = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/courier?page=${limit}&per_page=${getParams.per_page}`)
}
const GetMerchant = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/merchant?page=${limit}&per_page=${getParams.per_page}&admin_id=${getParams.admin_id}`)
}
const GetSignupMerchant = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/merchant/signup-list?page=${limit}&per_page=${getParams.per_page}&admin_id=${getParams.admin_id}`)
}
const GetMerchantRemittance = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/merchant/remittance/list?page=${limit}&per_page=${getParams.per_page}`,postParams)
}
const GetCourierRemittance = (postParams,getParams) => {
    let limit= getParams.page;
    return http.post(`/courier/remittance/list?page=${limit}&per_page=${getParams.per_page}`,postParams)
}
const GetShipmentMerchantCourier = (postParams,getParams) => {
	//console.log('got the current paramas '+ postParams ) ; 
    return http.post(`/filters/all-shipments`,postParams)
}
const GetWalletBalance = (postParams,getParams) => {
    //console.log("postParams",postParams);
    return http.post(`/merchant/wallet-balance`,postParams)
}
const GetChannelBrand = (postParams,getParams) => {
    return http.post(`/filters/all-channel-orders`,postParams)
}
const MerchantLogin = (username,password) => {
    let data = {"email":username, "password":password}
    return http.post(`merchant/login`,data)
}
const MerchantSignup = (data) => {
    return http.post(`merchant/register`,data)
}
const MerchantForgotPassword = (data) => {
    return http.post(`merchant/forgot-password`,data)
}
const MerchantOtpPassword = (data) => {
    return http.post(`merchant/forgot-password-otp-verify`,data)
}
const MerchantResetPassword = (data) => {
    return http.post(`merchant/reset-password`,data)
}

const AdminLogin = (data) => {
    return http.post(`admin/login`,data)
}
const GetCardOver = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/cards`,postParams)
}
const GetOrderSummary = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/daily-upload-summary`,postParams)
}
const GetPerformanceOverM = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/performance-overview`,postParams)
}
const GetShipmentStatusM = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/overall-performance`,postParams)
}
const GetCityState = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/top-city-state`,postParams)
}
const GetCourierOver = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/courier-performance`,postParams)
}
const GetFrieghtOver = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/freight-summary`,postParams)
}
const GetRemittanceOver = (postParams,getParams) => {
    return http.post(`/merchant/dashboard/remittance-summary`,postParams)
}
const GetDailyShipSummary = (postParams,getParams) => {
    return http.post(`admin/dashboard/daily-upload-summary`,postParams)
}
const GetShipmentSummary = (postParams,getParams) => {
    return http.post(`admin/dashboard/performance-overview`,postParams)
}
const GetDashCourierRmittance = (postParams,getParams) => {
    return http.post(`admin/dashboard/courier-remittance`,postParams)
}
const GetDashFreightRmittance = (postParams,getParams) => {
    return http.post(`admin/dashboard/freight-and-remittance`,postParams)
}
const GetDashPendingSummary = (postParams,getParams) => {
    return http.post(`admin/dashboard/pending-shipments`,postParams)
}
const GetDashPickupPending = (postParams,getParams) => {
    return http.post(`admin/dashboard/pickup-pending-shipments`,postParams)
}
const GetDashNotAttempted = (postParams,getParams) => {
    return http.post(`admin/dashboard/not-attempted-shipments`,postParams)
}
const GetMergeAccountList = (postParams,getParams) => {
    return http.post(`merchant/link/my-accounts`,postParams)
}
const AllApi = {
    GetState,
    GetCity,
    GetPincode,
    GetCourier,
    GetMerchant,
    GetSignupMerchant,
    GetMerchantRemittance,
    GetCourierRemittance,
    GetShipmentMerchantCourier,
    GetWalletBalance,
    GetChannelBrand,
    MerchantLogin,
    MerchantSignup,
    MerchantForgotPassword,
    MerchantOtpPassword,
    MerchantResetPassword,
    AdminLogin,
    GetFrieghtOver,
    GetRemittanceOver,
    GetCardOver,
    GetOrderSummary,
    GetPerformanceOverM,
    GetShipmentStatusM,
    GetCityState,
    GetCourierOver,
    GetDailyShipSummary,
    GetShipmentSummary,
    GetDashCourierRmittance,
    GetDashFreightRmittance,
    GetDashPendingSummary,
    GetDashPickupPending,
    GetDashNotAttempted,
    GetMergeAccountList,
}
export default AllApi