import React, {useEffect, useState} from 'react';
import { Card, CardBody, Table } from 'reactstrap';
// import axios from 'axios';
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import LoaderDefault from '../../../LoaderDefault';
import {connect, useDispatch} from 'react-redux'
import { dashFreightOverView } from '../../../../actions';
import { errorHandling } from '../../../../utlis';
import freight from "../../../../assets/images/freight.png";

const FreightOverview = (props) => {
    const [frieghtView, setFrieghtView] = useState({});
    const [loadertable, setLoadertable] = useState(false);
  const dispatch = useDispatch();
  const listingSummary = () => {
    setLoadertable(true);
    let mypost = {"merchant_id":props.user[0].id}
    let myget = {}
    dispatch(dashFreightOverView(mypost,myget)).then((response) => {
        if(response.error === false){
            setFrieghtView(response.data);
        }
        setLoadertable(false)
      }).catch(function (response) {
        setLoadertable(false);
        errorHandling(response,"reload");
      });
    }
    useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewAllFreightOver)&& checkData(props.state.viewAllFreightOver.data)){
            setFrieghtView(props.state.viewAllFreightOver.data);
          }else{
            listingSummary();
        }
    }, []);
    return (
        <div className="freightoverview">
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title"><figure><img className="img-fluid" src={freight} alt="remittance" /></figure>Freight</div>  
                </div>
                 
				    <div className="block_info m-3 mt-0">
                    <Table responsive borderless className="">
							<tbody>  
							{checkData(frieghtView) ? 
							<React.Fragment>
							<tr>
							<td><i className="bi bi-currency-rupee"></i> Total Freight: </td>
							<td>{checkUndeNullBlank(frieghtView.total_freight) ? Math.round(frieghtView.total_freight) : 0}</td>
							</tr>
							<tr>
							<td><i className="bi bi-wallet2"></i> Freight Paid: </td>
							<td>
							{checkUndeNullBlank(frieghtView.total_freight_paid) ? Math.round(frieghtView.total_freight_paid) : 0}
							</td>
							</tr>	
							<tr>	
							<td><i className="bi bi-clock-history"></i> Freight Due: </td>
							<td>
							{checkUndeNullBlank(frieghtView.total_Freight_due) ? Math.round(frieghtView.total_Freight_due) : 0}
							</td>
							</tr>
							</React.Fragment> :
							loadertable ? <tr>
							<td colSpan="4">Loading...</td>
							</tr>
							:<tr>
							<td>0</td>
							<td>0</td>
							<td>0</td>
							 
							</tr>
							}
							</tbody>
                    </Table>
					</div>
                 
            </Card>
        </div>
    );
}

function mapDispatchToProps (state) {
    return{
      state:state.DataReducer
    }
  }
  export default connect(mapDispatchToProps)(FreightOverview);