import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Collapse, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, Spinner, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Input } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faTimes, faArrowsAltH, faChevronDown, faPlus, faChevronRight, faDownload, faCartPlus, faChevronLeft, faFilter } from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import axios from '../../CommonAxios';
import { changeIntoDate, changeIntoDateYmd } from '../../utlis';
import { delivery_type_id, download_delay } from '../../config';
import EditBulkOrders from './EditBulkOrders';
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const OrderList = (props) => {

  const [deliveryType, setDeliveryType] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [allOrder, setAllOrder] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [cancelmodal, setCancelmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [filterDate, setFilterDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [datestate, setDateState] = useState({ start: moment().subtract(29, 'days'), end: moment(), });
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const changeCourierToggle = () => {
    if (checkboxOrderId.length > 0) {
      setChangeCourierModal(!changeCourierModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const { register: changeCourier, handleSubmit: changeCourierSubmit, formState: { errors: errors5 }, reset: resetChangeCourier } = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios.post(`${process.env.REACT_APP_CHANGE_COURIER}`, data)
      .then((res) => {
        setLoader(false);
        if (res.fatal !== true && res.data.error !== true) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
            if (okay) {
              // bulkOrderListing();
              onCommonFilter();
              setChangeCourierModal(false);
              resetChangeCourier();
            }
          });
        }
        else {
          Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
        }
      }).catch((err) => {
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
        setLoader(false);
      });
  }
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const history = useHistory();

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };

  const selectAll = (value) => {
    let abc = checkData(allOrder.data) && allOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      // checkData(allOrder.data)&&allOrder.data.map((item) => (
      // setCheckboxOrderId(item)
      var opt_order_id = [];
      checkData(allOrder.data) && Object.keys(allOrder.data).map(function (key) {
        let tempObj = allOrder.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };

  const toggleCheck = (utilityCheckbox, event, Id) => {
    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }



  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  // const DeliveryTypeMultiSelectSettings={
  //   "allItemsAreSelected": "All items are selected.",
  //   "clearSearch": "Clear Search",
  //   "noOptions": "No options",
  //   "search": "Search",
  //   "selectAll": "Select All",
  //   "selectSomeItems": "Delivery Type..."
  // }
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = { label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }

  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
      .then((res) => {
        setLoadertable(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setOrderDetail(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false)
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      })
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    orderReset();
  }

  const cancelmodalToggle = () => {
    setCancelmodal(!cancelmodal);
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  useEffect(() => {
    setLoadertable(true)
    bulkOrderListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //listing bulk Order
  const bulkOrderListing = () => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setDateState({ start: moment().subtract(29, 'days'), end: moment(), })
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    let data = { "order_status_id": 1, "start_date": dateStart, "end_date": dateEnd }
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setIsExport(false); reset(); setFilterDate(''); setDeliveryType([]); setSearchValue('');

    axios.post(process.env.REACT_APP_ALL_ORDER,  data )
      .then((res) => {
        setLoadertable(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setAllOrder(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false)
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      })
  }

  const { register: uploadOrderdata, handleSubmit: orderUploadSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();
  const onUploadOrderSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
    formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    axios
      .post(`${process.env.REACT_APP_UPLOAD_ORDER}`, formData)
      .then((res) => {
        setLoader(false);
        let errorFile = '';
        if (res.data.errorfile !== undefined) {
          errorFile = process.env.REACT_APP_BASE_URL + res.data.errorfile;
        }

        setExcelFile(); orderReset();
        setExcelmodal(false);
        if (res.data.error === false) {
          Swal.fire({
            title: 'Success',
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
            text: res.data.msg,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              bulkOrderListing();
            }
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: res.data.msg,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              bulkOrderListing();
            }
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({ title: 'Error', icon: 'error', text: err })
      });
  };
  const placeBulkOrder = () => {
    let data = { "order_ids": checkboxOrderId }
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = parseInt(props.user[0].wallet_balance) > 0 ? props.user[0].wallet_balance : 0
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
        .then((res) => {
          setLoader(false);
          if (res.data.error !== true && res.data.fatal !== true) {
            Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
              if (okay) {
                history.push("/all-orders?o=processing");
              }
            });
          } else {
            Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
          }
        }).catch((err) => {
          setLoader(false);
          Swal.fire({ icon: 'error', text: err, title: 'Error' });
        })
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const exportShipment = () => {
    if (isExport) {
      let data = filterInput
      if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.order_status_id = 1
      data.is_export = 1
      axios.post(process.env.REACT_APP_ALL_ORDER, data)
        .then((res) => {
          setLoader(false)
          if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
            if (parseInt(res.data.errno) > 0) {
              if (res.data.sqlMessage !== undefined) {
                Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
              }
            } else {
              if (res.data.is_export === 1) {
                setTimeout(function () {
                  setLoader(false);
                  history.push("/my-downloads");
                }, download_delay);
              }
            }
          }
        }).catch((err) => {
          setLoader(false)
          Swal.fire({ icon: 'error', text: err, title: 'Error' });
        });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  function handleSelectChange(event) {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    if (checkData(allOrder.data) && checkUndeNullBlank(allOrder.totalPage)) {
      // &&checkUndeNullBlank(allOrder.totalPage).length>page.length
      if (allOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allOrder.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    if (page > 1) {
      setPage(page - 1)
      checkData(allOrder.data)
      if (currentCount > Object.entries(allOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterOrderSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    onCommonFilter(data, pagePerpage)
  };
  const onFilterOrder = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setIsExport(true);
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkUndeNullBlank(datestate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    let byData = {}
    let tmpDeliveryType = []


    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    byData = { "order_status_id": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? data.search_value : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "start_date": dateStart, "end_date": dateEnd }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllOrder(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
      setLoader(false);
    });
  };


  //cancel Order
  const { register: cancelOrder, handleSubmit: cancelOrderSubmit, formState: { errors: errors4 }, reset: resetCancelled } = useForm();
  const onCancelOrderSubmit = data => {
    data.order_ids = checkboxOrderId
    axios.post(process.env.REACT_APP_CANCEL_ORDER, data)
      .then((res) => {
        setLoader(false)
        if (res.data.sqlMessage !== undefined) {
          Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
        }
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg })
          setCancelmodal(false);
          resetCancelled();
          setCheckboxOrderId([]);
          // bulkOrderListing();
          onCommonFilter();
          setCheckedAll(false);
          setChecked(false);
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
        }
        console.log(res)
      }).catch((err) => {
        setLoader(false)
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      });
  }
  const onFilter = () => setIsOpen(!isOpen);


  return (
    <Col sm={12}>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Card className="p-0 my-3 manageOrder content-wrapper border-0">
	  
	<div className="card-title title_icon">
	<i className="bi bi-card-heading"></i> 
	Manage Orders
	<div className="otherlinks"> 
			<div className="card-header-right d-flex align-items-center ml-auto">
			{props.user[0].user_type === "Merchant" &&
			<>
			<div className="btn-text btn-light" onClick={changeCourierToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAltH} /></span><span>Change DSP</span></div>
			<Link to='/create-order' className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Order</span></Link>
			<div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Bulk orders</span></div>
			{checkData(allOrder.data) &&
			<div className="btn-text btn-light" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>Ship Orders</span></div>
			}
			</>
			}
			{checkData(allOrder.data) &&
			<>
			{checkPermission(props.user, props?.user[0]?.permissions?.manage_Orders?.cancel_order) &&
			<div className="btn-text btn-light mx-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Orders</span></div>
			}
			<div className="btn-text btn-light mx-2" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span></div>
			</>
			}
			<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
			</div>
	</div>
		
		  
          <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
            <ModalHeader toggle={changeCourierToggle}>Change Courier</ModalHeader>
            <ModalBody>
              <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                <Row className="m-0 pb-1">
                  <Col sm={12}>
                    <FormGroup className='position-relative'>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...changeCourier("courier_id", { required: 'Please Choose Courier' })} className="form-control-sm form-control border-right-0">
                        <option value="">Select Courier</option>
                        {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => (
                          <option value={item.courier_id}>{item.courierName}</option>
                        ))}
                      </select>
                      {errors5.courier_id && <span className="text-danger d-block error">{errors5.courier_id.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-right">
                    <Button className="btn btn-sm" color="primary">Submit</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={excelmodal} toggle={excelmodal} className="upload_bulk_order">
            <ModalHeader toggle={excelUpload}><i className="bi bi-boxes text-primary"></i> Upload Bulk Orders</ModalHeader>
            <ModalBody>
              <Form onSubmit={orderUploadSubmit(onUploadOrderSubmit)}>
						<ul className="bulk_order_list list_1">
						<li>
						<figure><img src="https://dashboard.tekipost.com/custom_images/upload_1.png" /></figure>
						<p>Upload all Your Orders via XLSX.</p>
						</li>
						<li>
						<figure><img src="https://dashboard.tekipost.com/custom_images/upload_2.png" /></figure>
						<p>Please download below given template.</p>
						</li>
						<li>
						<figure><img src="https://dashboard.tekipost.com/custom_images/upload_3.png" /></figure>
						<p>Remove sample data and upload your data.</p>
						</li>
						<li>
						<figure><img src="https://dashboard.tekipost.com/custom_images/upload_4.png" /></figure>
						<p>Make sure you atleast fill mandatory fields.</p>
						</li>
						</ul>
						
                <Row className="m-0 pb-1">
                  <Col sm={12} className="p-0">
				    
					<p className="text-center"> <Link target="_blank" download to="/format/order.xlsx" className=""><i className="bi bi-file-earmark-arrow-down"></i>  Download
					</Link> Order Format File 
					</p>
				  </Col>
				   
                  <Col sm={12} className="m-auto pt-1 p-0">
                    <FormGroup>
                      <input {...uploadOrderdata("excelUpload", {
                        required: 'Please Upload XLSX File',
                        validate: {
                          lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                          acceptedFormats: files =>
                            ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                              files[0]?.type
                            ) || 'Only .xslx file allowed',
                        },
                      })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                      <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                      {excelFile !== '' && excelFile !== undefined ? "" : errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-right p-0 text-center">
                    <Button className="btn" color="primary">Upload</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={cancelmodal} toggle={cancelmodal} >
            <ModalHeader toggle={cancelmodalToggle}>Cancel Orders</ModalHeader>
            <ModalBody>
              <Form onSubmit={cancelOrderSubmit(onCancelOrderSubmit)}>
                <Row className="m-0 pb-1">
                  <Col sm={12}>
                    <FormGroup>
                      <Label for="remaks">Remarks</Label>
                      <textarea {...cancelOrder("remarks", { required: 'Please Enter Remaks' })} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                      {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-right">
                    <Button className="btn btn-sm" color="primary">Submit</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {checkData(orderDetail) &&
          <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
            <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
            <ModalBody>
              <EditBulkOrders orderDetails={orderDetail} merchantId={merchantId} setOrderDetail={setOrderDetail} user={props.user} setEditmodal={setEditmodal} />
            </ModalBody>
          </Modal>
        }
        <Collapse isOpen={isOpen}>
          <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="filter-form">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
              <Col sm={6} md={5} lg={5} xl={4}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="position-relative p-0">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...filter("search_field")} className="form-control-sm form-control rounded-right-0" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                          <option value="">Search By</option>
                          <option value="customer_name">Customer Name</option>
                          <option value="customer_name">Customer Pincode</option>
                          <option value="customer_mobile">Customer Mobile</option>
                          <option value="order_no">Order No</option>
                          <option value="product_name">Product Name</option>
                        </select>
                      </InputGroupText>
                    </InputGroupAddon>
                    <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={6} md={3} lg={3} xl={2}>
                <FormGroup>
                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...filter("delivery_type_id")} className="form-control-sm form-control">
                    <option value="">Delivery Type</option>
                    {Object.entries(delivery_type_id).map(([key, value]) => (
                      <option value={key}>{value}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={3} xl={3}>
                <FormGroup>
                  {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} /> */}
                  <DateRangePicker
                    initialSettings={ranges}
                    onCallback={handleCallback}
                  >
                    <div className='form-control cursor-pointer d-flex align-items-center'>
                      {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                    </div>
                  </DateRangePicker>
                </FormGroup>
              </Col>
              <Col sm={3} className="filter-btn">
                <Button className="btn btn-sm" color="primary">Search</Button>
                <Button type="reset" className="btn btn-sm mx-2" color="primary" outline onClick={bulkOrderListing}>Reset</Button>
              </Col>
            </Row>
          </Form>
        </Collapse>
        <CardBody className="p-0">
          {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
            <>
              <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                <div className="showing-count">
                  Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder.data).length)} of {allOrder.TotalRows} entries
                </div>
                {allOrder.TotalRows > dropFilterCount ?
                  <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                        <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  : ''}
              </div>
            </>
            : ''}
          <Table responsive borderless className="text-center customTable">
            <thead>
              <tr>
                <th><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /></th>
                {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                  <th>Merchant Name</th>
                }
                <th>Warehouse </th>
                <th>Order No</th>
                <th>Delivery</th>
                <th>Customer Details </th>
                <th className="col-2">Product</th>
                <th>Uploaded</th>
              </tr>
            </thead>
            <tbody>
              {checkData(allOrder.data) ? allOrder.data.map((item, index) => (
                <tr>
                  <td><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} /></td>
                  {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                    <td>{item.merchantName}<br />{item.merchantBrandName}</td>
                  }
                  <td>{item.MerchantPickupPointName}</td>
                  <td><Link to={`/order-detail/${item.id}`} from={"a"}>{item.order_no}</Link></td>
                  <td>{delivery_type_id[item.delivery_type_id]}</td>
                  <td>{item.customer_name}<br />{item.customer_mobile}</td>
                  <td><div className="text-truncate" id={`MoreProduct${item.id}`}>{item.product_name}</div>
                    <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                      {item.product_name}
                    </UncontrolledTooltip>
                  </td>
                  <td className="text-nowrap">{(item.updated_at !== undefined && item.updated_at !== null ? changeIntoDate(item.updated_at) : 'NA')} <span><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span></td>
                </tr>
              )) :
                loadertable ? <tr>
                  <td colSpan="8">Loading...</td>
                </tr>
                  : <tr><td colSpan="8" className="text-center">No Data Found</td></tr>
              }
            </tbody>
          </Table>
          {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
            <>
              {allOrder.TotalRows > dropFilterCount ?
                <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                  <div className="showing-count">
                    Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder.data).length)} of {allOrder.TotalRows} entries
                  </div>
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
                : ''}
            </>
            : ''}
        </CardBody>
      </Card>
    </Col>
  );
}

export default OrderList;
