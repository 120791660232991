import React from 'react';
import { Row, Col, Label, Form, FormGroup, CardSubtitle, CardTitle} from 'reactstrap';

const Profile = (props) => {
    return (
        <Form>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Profile</CardTitle>
            </div>
            <Row className="pt-1">
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Full Name</CardSubtitle>
                        <Label for="full_name">{props.user[0].name}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Email</CardSubtitle>
                        <Label for="full_name">{props.user[0].email || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Mobile Number</CardSubtitle>
                        <Label for="full_name">{props.user[0].mobile || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                {/* <Col sm={12} className="mt-2">                    
                    <Button className="btn-sm" color="primary"> Save changes</Button>
                    <Button className="btn-sm mx-2" color="secondary" outline> Cancel</Button>
                </Col>             */}
            </Row>
            
        </Form>
    );
}

export default Profile;