import React,{useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup,Form, CardHeader, Input,Label, Button, Spinner} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';

import Swal from 'sweetalert2'
import { StateList } from '../masters/StateList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AddPickupPoint = (props) => {
    const { register:addPickupPoint, handleSubmit:pickupPointSubmit, formState: { errors }, setValue, getValues, reset  } = useForm();
    const [returnchecked, setreturnChecked] = useState(true);
    const [pickupchecked, setpickupChecked] = useState(true);
    const [sameAsPickupdtl, setSameAsPickupdtl] = useState(false);
    const [loader, setLoader] = useState(false);
    const {merchantId} = useParams();
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    const onAddPickupPoint = (data) => {
        setLoader(true);
        let formData = new FormData();
      if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
        formData.append("merchant_id", props.user[0].id);
    } else {
        formData.append("merchant_id", merchantId);
    }
      formData.append("contact_name", data.contact_name);
      formData.append("pickup_address_1", data.pickup_address_1);
      formData.append("pickup_address_2", data.pickup_address_2);
      formData.append("pickup_city_name", data.pickup_city_name);
      formData.append("pickup_state_id", data.pickup_state_id);
      formData.append("pickup_pincode", data.pickup_pincode);
      formData.append("pickup_phone", data.pickup_phone);
      formData.append("pickup_details_for_label", data.pickup_details_for_label);
      formData.append("return_address_1", data.return_address_1);
      formData.append("return_address_2", data.return_address_2);
      formData.append("return_city_name", data.return_city_name);
      formData.append("return_state_id", data.return_state_id);
      formData.append("return_pincode", data.return_pincode);
      formData.append("return_details_for_label", data.return_details_for_label);
      formData.append("return_phone", data.return_phone);
      formData.append("address_label_bottom_line", data.address_label_bottom_line);
      formData.append("status", "1");
    
      axios
        .post(process.env.REACT_APP_MERCHANT_PICKUPPOINT_CREATE, formData)
        .then((res) => {
    setLoader(false);
    if(res.data.error === false){
            reset()
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
            })
          }
        }).catch((err) => {
        setLoader(false);
        Swal.fire({title: 'Error', text: err, icon: 'error',})
        });
    };
    const onError = (errors, e) => console.log('Error', errors);

    // ------same as pickup details
    const sameAsPickupDetails = (event) => {
        setSameAsPickupdtl(!sameAsPickupdtl);
        if(!sameAsPickupdtl){
            const {pickup_address_1, pickup_address_2, pickup_pincode, pickup_city_name, pickup_state_id, pickup_phone, pickup_details_for_label } = getValues();
            [{ name: 'return_address_1', value: pickup_address_1 },{ name: 'return_address_2', value: pickup_address_2 },
            { name: 'return_pincode', value: pickup_pincode },{ name: 'return_city_name', value: pickup_city_name },
            { name: 'return_state_id', value: pickup_state_id },{ name: 'return_phone', value: pickup_phone },
            { name: 'return_details_for_label', value: pickup_details_for_label }    
            ].forEach(({ name, value }) => setValue(name, value))
        }else{
            [{ name: 'return_address_1', value: "" },{ name: 'return_address_2', value: "" },{ name: 'return_pincode', value: "" },
            { name: 'return_city_name', value: "" },{ name: 'return_state_id', value: "" },{ name: 'return_phone', value: "" },
            { name: 'return_details_for_label', value: "" }     
            ].forEach(({ name, value }) => setValue(name, value))
        }
    }

    return (
        <div className="content-body overflow-hidden">
    {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>
            <div className="p-0 my-3 addPickupPoints content-wrapper border-0">
            <Form onSubmit={pickupPointSubmit(onAddPickupPoint,onError)}>
                <Card className="shadow border-0">
                 
				<div className="card-body">	
						<div className="card-title title_icon"><i className="bi bi-card-heading"></i>Add New Warehouse  <Link onClick={goToPreviousPath}  className="small_btn"><span className="icon"><ArrowLeft /></span><span>Back to Uploaded List</span></Link> </div>
                     
                    <div className="forms">
                        <FormGroup>
                        <Label for="contactName">Enter Contact Name (Full Name)</Label>
                        <input {...addPickupPoint("contact_name",{ required: 'Please Enter Contact Name'})}  className="form-control-sm form-control" placeholder="Full Name" id="contactName" />
                        {errors.contact_name && <span className="text-danger d-block mb-2">{errors.contact_name.message}</span>}
                        </FormGroup> 
                    </div>   
				</div>
				
                </Card>
				
                <Row>
                    <Col md={6}>
                        <Card className="shadow border-0">
                            <CardBody className="">
							<div className="card-title title_icon"><i className="bi bi-building"></i>Pickup Details</div>
                                <FormGroup>
                                    <Label for="address1_pickup">Enter Address Line 1</Label>
                                    <input {...addPickupPoint("pickup_address_1",{ required: 'Please Enter Address Line 1'})}  className="form-control-sm form-control" placeholder="Address Line 1" id="address1_pickup" />
                                    {errors.pickup_address_1 && <span className="text-danger d-block mb-2">{errors.pickup_address_1.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="address2_pickup">Enter Address Line 2</Label>
                                    <input {...addPickupPoint("pickup_address_2",{ required: 'Please Enter Address Line 2'})}  className="form-control-sm form-control" placeholder="Address Line 2" id="address2_pickup" />
                                    {errors.pickup_address_2 && <span className="text-danger d-block mb-2">{errors.pickup_address_2.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pincode_pickup">Enter Pincode</Label>
                                    <input {...addPickupPoint("pickup_pincode",{ required: 'Please Enter Pincode'})} type="number" className="form-control-sm form-control" placeholder="Pincode" id="pincode_pickup" />
                                    {errors.pickup_pincode && <span className="text-danger d-block mb-2">{errors.pickup_pincode.message}</span>}
                                </FormGroup> 
                                <FormGroup>
                                    <Label for="city_pickup">Enter City Name</Label>
                                    <input {...addPickupPoint("pickup_city_name",{ required: 'Please Enter City Name'})}  className="form-control-sm form-control" placeholder="City Name" id="city_pickup" />
                                    {errors.pickup_city_name && <span className="text-danger d-block mb-2">{errors.pickup_city_name.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="state_pickup">Select State</Label>
                                    <div className="position-relative">
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...addPickupPoint("pickup_state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_pickup">
                                            <option value="">Select State</option>
                                            {StateList.map((item, index) => (
                                                <option value={item.state_id}>{item.state_name}</option>
                                            ))
                                        }
                                        </select>
                                        {errors.pickup_state_id && <span className="text-danger d-block mb-2">{errors.pickup_state_id.message}</span>}
                                    </div>
                                </FormGroup> 
                                <FormGroup>
                                    <Label for="contact_pickup">Enter Mobile Number</Label>
                                    <input {...addPickupPoint("pickup_phone",{ required: 'Please Enter Mobile Number'})} type="number" className="form-control-sm form-control" placeholder="Mobile" id="contact_pickup" />
                                    {errors.pickup_phone && <span className="text-danger d-block mb-2">{errors.pickup_phone.message}</span>}
                                </FormGroup>
                                <FormGroup check className="mb-3">
                                    <Input {...addPickupPoint("samelabelPickup")}  type="checkbox" name="check" id="exampleCheck"  checked={pickupchecked} onChange={e => setpickupChecked(e.target.checked)} />
                                    <Label for="exampleCheck" check>Same as in address label <small>(Paste on label)</small></Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pickupDetails_pickup">Pickup Details</Label>
                                    <textarea {...addPickupPoint("pickup_details_for_label")} disabled={pickupchecked} type="textarea" placeholder="Pickup Details For address label" id="pickupDetails_pickup" className="form-control-sm form-control" />
                                </FormGroup>
                            </CardBody>
                        </Card>                                               
                    </Col>
                    <Col md={6}>
                        <Card className="shadow border-0">
                             
                            <CardBody className="">
							
							<div className="card-title title_icon"><i className="bi bi-building"></i>Return Details  
	
							<div className="pull-right">
                                <FormGroup check>
                                    <Input {...addPickupPoint("sameasPickup")} onChange={ sameAsPickupDetails } type="checkbox" name="check" id="sameasPickup" checked={sameAsPickupdtl}/>
                                    <Label for="sameasPickup" check>Same as Pickup Details</Label>
                                </FormGroup>
						   </div>
						   
						   </div>
								
							 
							
                                <FormGroup>
                                    <Label for="address1_return">Enter Address Line 1</Label>
                                    <input {...addPickupPoint("return_address_1",{ required: 'Please Enter Address Line 1'})} className="form-control-sm form-control" placeholder="Address Line 1" id="address1_return" />
                                    {errors.return_address_1 && <span className="text-danger d-block mb-2">{errors.return_address_1.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="address2_return">Enter Address Line 2</Label>
                                    <input {...addPickupPoint("return_address_2",{ required: 'Please Enter Address Line 2'})} className="form-control-sm form-control" placeholder="Address Line 2" id="address2_return" />
                                    {errors.return_address_2 && <span className="text-danger d-block mb-2">{errors.return_address_2.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pincode_return">Enter Pincode</Label>
                                    <input {...addPickupPoint("return_pincode",{ required: 'Please Enter Pincode'})} type="number" className="form-control-sm form-control" placeholder="Pincode" id="pincode_return" />
                                    {errors.return_pincode && <span className="text-danger d-block mb-2">{errors.return_pincode.message}</span>}
                                </FormGroup> 
                                <FormGroup>
                                    <Label for="city_return">Select City</Label>
                                    <input {...addPickupPoint("return_city_name",{ required: 'Please Enter City Name'})} className="form-control-sm form-control" placeholder="City Name" id="city_return" />
                                    {errors.return_city_name && <span className="text-danger d-block mb-2">{errors.return_city_name.message}</span>}
                                </FormGroup>
                                <FormGroup className="position-relative">
                                    <Label for="state_return">Select State</Label>
                                    <div className="position-relative">                                        
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...addPickupPoint("return_state_id",{ required: 'Please Enter City Name'})} className="form-control-sm form-control" id="state_return">
                                            <option value="">Select State</option>
                                            {StateList.map((item, index) => (
                                                <option value={item.state_id}>{item.state_name}</option>
                                            ))
                                        }
                                        </select>
                                        {errors.return_state_id && <span className="text-danger d-block mb-2">{errors.return_state_id.message}</span>}
                                    </div>
                                </FormGroup> 
                                <FormGroup>
                                    <Label for="contact_return">Enter Mobile Number</Label>
                                    <input {...addPickupPoint("return_phone",{ required: 'Please Enter Mobile Number'})} type="number" className="form-control-sm form-control" placeholder="Mobile" id="contact_return" />
                                    {errors.return_phone && <span className="text-danger d-block mb-2">{errors.return_phone.message}</span>}
                                </FormGroup>
                                <FormGroup check className="mb-3">
                                    <Input {...addPickupPoint("sameAddressReturn")} type="checkbox" name="check" id="exampleCheck2" checked={returnchecked} onChange={e => setreturnChecked(e.target.checked)} />
                                    <Label for="exampleCheck2" check>Same as in address label <small>(Paste on label)</small></Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pickupDetails_return">Pickup Details</Label>
                                    <textarea {...addPickupPoint("return_details_for_label")} disabled={returnchecked} type="textarea" placeholder="Pickup Details For address label" className="form-control-sm form-control" />
                                </FormGroup>
                            </CardBody>
                        </Card>                                               
                    </Col> 
                </Row>
                <Card className="shadow border-0 d-none">
                    <CardHeader><CardTitle className="mb-0">Address Labels Bottom Line</CardTitle><small>(e.g note)</small></CardHeader>
                    <CardBody className="pt-0">
                        <FormGroup>
                        <input {...addPickupPoint("address_label_bottom_line")} type="text" className="form-control-sm form-control" placeholder="Address Lables Bottom Line" />
                        </FormGroup> 
                    </CardBody>
                </Card>
                <Row>
                    <Col sm={12} className="text-right ml-auto filter-btn">
                        <div className="text-center"><Button className="btn" color="primary">Save Warehouse</Button></div>
                    </Col>
                </Row>
            </Form>
            </div>
          </Col>
          
          </Row>
           </div>
    );
}

export default AddPickupPoint;