import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, Container, Col, Input, Table, FormGroup, Row, Form, Pagination, PaginationItem, PaginationLink, CardTitle, Spinner, } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTrash, faAngleDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AddCourier from './AddCourier';
import EditCourier from './EditCourier';
import Status from '../Status';
import { changeIntoDateTime } from '../../utlis';
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';

const Couriers = (props) => {
  const [editCourier, setEditCourier] = useState({});
  const [courier, setCourier] = useState({});
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState(false);
  const [addmodal, setAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const dropFilterCount = 20

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  const toggleAdd = () => setAddModal(!addmodal);
  console.log('totalPages', totalPages)

  const toggleEditclose = () => seteditModal(!editmodal);
  const toggleEdit = (i) => {
    seteditModal(!editmodal)
    fetch(`${process.env.REACT_APP_GET_COURIER}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
      },
    }).then((resp) => {
      resp.json().then((result) => {
        result[0].status = String(result[0].status)
        setEditCourier(result[0])
      });
    });
  };
  const tableDataFetch = () => {
    setLoadertable(true);
    setFilterInput('');
    setCurrentCount(1);
    setPage(1);
    fetch(`${process.env.REACT_APP_GET_COURIER}?page=${page}&per_page=${selectperpage}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
      },
    }).then((resp) => {
      setLoadertable(false);
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setCourier(result)
          setTotalPages(result.totalPage)
        }
      });
    }).catch((err) => {
      setLoadertable(false);
      Swal.fire({ title: 'Error', text: err, icon: 'error' });
    });
  }
  useEffect(() => {
    tableDataFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage]);
  const RemoveCourier = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to delete this record?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_REMOVE_COURIER}/${id}`, {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
          },
        }).then((resp) => {
          resp.json().then((result) => {
            Swal.fire({ title: 'Success', text: result.msg, icon: 'success' })
            tableDataFetch()
          });
        }).catch((err) => {
          Swal.fire({ title: 'Error', text: err, icon: 'error' })
        });
      }
    })
  }
  function nextPageData() {
    if (checkData(courier.data) && checkUndeNullBlank(courier.totalPage)) {
      // &&checkUndeNullBlank(courier.totalPage).length>page.length
      if (courier.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(courier.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(courier.data)
      if (currentCount > Object.entries(courier.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)

  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name) || checkUndeNullBlank(data.email)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_COURIER}?brand_name=${checkUndeNullBlank(data.brand_name) ? data.brand_name : ''}&email=${checkUndeNullBlank(data.email) ? data.email : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        'x-ref': window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setCourier(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      Swal.fire({ icon: "error", title: "Error", text: err })
    });
  };
  return (
    <div className="content-body">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Container fluid>
        <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between">
          <div class="mb-3 mr-3">
            <h4 class="text-black font-w600 mb-0">Couriers</h4>
          </div>
          <div className="modal-btn">
            {checkPermission(props.user, props.user[0]?.permissions?.manage_Couriers?.add) &&
              <Button color="light" onClick={toggleAdd}>Add Courier</Button>
            }
          </div>
          <Modal isOpen={addmodal} toggle={addmodal} size="lg" scrollable="true">
            <ModalHeader toggle={toggleAdd}>Add Courier</ModalHeader>
            <ModalBody>
              <AddCourier tableDataFetch={tableDataFetch} setAddModal={setAddModal} />
            </ModalBody>
          </Modal>
        </div>
        <div className="master-content card border-0">
          <Modal isOpen={editmodal} toggle={editmodal} size="lg" scrollable="true">
            <ModalHeader toggle={toggleEditclose}>Edit Courier</ModalHeader>
            <ModalBody>
              {editCourier !== undefined && editCourier !== null && Object.entries(editCourier).length > 0 &&
                <EditCourier editCourier={editCourier} seteditModal={seteditModal} tableDataFetch={tableDataFetch} />
              }
            </ModalBody>
          </Modal>
          <Row className="justify-content-between card-header align-items-center px-3 pt-3">
            <Col sm={3}><CardTitle>Manage Couriers</CardTitle></Col>
          </Row>
          <div className="filter-form">
            <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
              <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                <Col sm={6} md={4} lg={3} xl={3}>
                  <FormGroup>
                    <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Enter Name" />
                  </FormGroup>

                </Col>
                <Col sm={6} md={4} lg={3} xl={3}>
                  <FormGroup>
                    <input {...register("email")} type="email" className="form-control-sm form-control" placeholder="Enter Email" />
                  </FormGroup>
                </Col>
                <Col sm={3} className="filter-btn">
                  <Button className="btn  btn-sm" color="primary">Search</Button>
                  <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={tableDataFetch}>Reset</Button>
                </Col>
              </Row>
            </Form>
          </div>
          {checkData(courier) && checkData(courier.data) && checkUndeNullBlank(courier.TotalRows) ?
            <>
              <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                <div className="showing-count">
                  Showing {currentCount} to {(currentCount - 1) + (Object.entries(courier.data).length)} of {courier.TotalRows} entries
                </div>
                {courier.TotalRows > dropFilterCount ?
                  <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                        <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  : ''}
              </div>
            </>
            : ''}
          <Table responsive borderless className="text-center customTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Logo</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {checkData(courier.data) ? courier.data.map((item, index) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.full_name}</td>
                  <td>{item.email}</td>
                  <td><div className="courier-logo text-center"><img src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="Courier Logo" style={{ width: '60px' }} /></div></td>
                  <td>{item.created_at !== undefined && item.created_at !== null ? changeIntoDateTime(item.created_at) : 'NA'}</td>
                  <td className="text-nowrap">
                    <span className="mr-2"><Status statusUrl={process.env.REACT_APP_GET_COURIER} valStatus={item.status} reListing={tableDataFetch} data={{ "courier_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>

                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_Couriers?.edit) &&
                      <span><Button outline className="btn btn-sm light ml-2" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                    }
                    <span><Button outline className="btn btn-sm light ml-2 d-none" color="danger" onClick={() => RemoveCourier(item.id)}><FontAwesomeIcon icon={faTrash} /> </Button></span>
                    <span><UncontrolledDropdown className="d-inline-block">
                      <DropdownToggle outline className="btn btn-sm ml-2 btn-more" color="secondary">
                        More<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link to={`/CourierDetails/${item.id}`} className="dropdown-item">Courier Details</Link>
                        {checkPermission(props.user, props.user[0]?.permissions?.courier_Zone?.zoning) &&
                          <Link to={`/zoning/${item.id}?name=${item.brand_name}`} className="dropdown-item">Zoing</Link>
                        }
                        {checkPermission(props.user, props.user[0]?.permissions?.courier_Pricing?.courier_pricing) &&
                          <Link to={`/courier-pricing/${item.id}?name=${item.brand_name}&type=${item.movement_type_id}`} className="dropdown-item">Pricing</Link>
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </span>
                  </td>
                </tr>
              ))
                : loadertable ? <tr> <td colSpan="6">Loading...</td> </tr> :
                  <tr> <td colSpan="6">No Data Found</td> </tr>}
            </tbody>
          </Table>
          {checkData(courier) && checkData(courier.data) && checkUndeNullBlank(courier.TotalRows) ?
            <>
              {courier.TotalRows > dropFilterCount ?
                <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                  <div className="showing-count">
                    Showing {currentCount} to {(currentCount - 1) + (Object.entries(courier.data).length)} of {courier.TotalRows} entries
                  </div>
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
                : ''}
            </>
            : ''}
        </div>
      </Container>
    </div>
  )
}
export default Couriers