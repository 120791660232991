import React,{useRef,useState} from 'react';
import { Row, Col, Label, Button,Form, FormGroup, Spinner} from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
// import axios from 'axios';
import axios from '../../CommonAxios';

const MerchnatResetPassword = (props) => {
    const [loader, setLoader] = useState(false);
    const { register:reasetPassword, handleSubmit:reasetPasswordSubmit, watch, formState: { errors: errors4 } } = useForm();
    const onResetPasswordSetails = (data) => {  
        setLoader(true);
        axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_RESETPASSWORD,
        data: {"merchant_id":props.merchantId !== undefined ? props.merchantId:'',"password":data.new_password},
      })
      .then((res) => {
        if(res.data.error === false){
        setLoader(false);
        Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }
      }).catch((err) => {
        setLoader(false);
        Swal.fire({icon:"error", title:"Error", text:err});
      });
  };
    const new_password = useRef({});
    new_password.current = watch("new_password", "");
    const onresetPasswordError = (errors4, e) => console.log('Error', errors4);
    return (
        <>
        {loader && <div className="formLoader"><Spinner /></div>}
        <Form onSubmit={reasetPasswordSubmit(onResetPasswordSetails,onresetPasswordError)}>
                <Row>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                        <Label for="password">Enter New Password</Label>
                        <input {...reasetPassword("new_password",{ required: 'Please Enter New Password'})} type="password" className="form-control-sm form-control" placeholder="Enter New Password" id="password" />
                        {errors4.new_password && <span className="text-danger d-block mb-2">{errors4.new_password.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                        <Label for="confirmPass">Enter Confirm Password</Label>
                        <input {...reasetPassword("confirm_password",{ required: 'Please Enter Confirm Password', validate: value => value === new_password.current || "The passwords do not match"})} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password" id="confirmPass" />
                        {errors4.confirm_password && <span className="text-danger d-block mb-2">{errors4.confirm_password.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} className="mt-2 text-right">                    
                        <Button className="btn-sm" color="primary"> Save</Button>
                    </Col> 
                </Row>
            </Form>
        </>
    );
}

export default MerchnatResetPassword;