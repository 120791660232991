import React from 'react';
import FreightAndRemittance from './FreightAndRemittance';

const FreightAndRemittancePage = (props) => {

    return (
        <div className="FreightAndRemittancePage">
            <div className="mb-4"><FreightAndRemittance user={props.user} /></div>
        </div>
    );
}

export default FreightAndRemittancePage;