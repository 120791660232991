import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Collapse,  FormGroup,InputGroup, InputGroupAddon,InputGroupText, Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink, DropdownMenu, UncontrolledDropdown, DropdownToggle, Input, } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faAngleDown, faChevronLeft, faChevronRight, faFilter} from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { changeIntoDateYmd } from '../../utlis';
import { connect } from 'react-redux';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { download_delay } from '../../config';

const Rto = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [allRtoShipment, setAllRtoShipment] = useState({});
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [searchValue, setSearchValue] = useState("Search By");
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const [isOpen, setIsOpen] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const history = useHistory();

  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  
  const allRtoShipmentListing = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    setIsExport(false);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1);
    setFilterDate('');
    setAllCourier([]);setMerchants([]);setSearchValue('');
    reset();
    let data = {"order_status_id":[13], "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=1
    }else{
      data.merchant_id=props.user[0].id
    }
    
    setLoader(true);
  
    axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
    .then((res) => {
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setAllRtoShipment(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }
  useEffect(() => {
    allRtoShipmentListing();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }     
      return opt_merchants.push(tempObj)
    });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(allRtoShipment.data)&&checkUndeNullBlank(allRtoShipment.totalPage)){
    // &&checkUndeNullBlank(allRtoShipment.totalPage).length>page.length
      if(allRtoShipment.totalPage > page){
        setCurrentCount(currentCount+Object.entries(allRtoShipment.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(allRtoShipment.data)
  if(currentCount>Object.entries(allRtoShipment.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  // filter Bulk Order
  const { register:filter, handleSubmit:filterRtoSubmit, reset} = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onFilterRto = (data,pagePerpage) => {
    setIsExport(true);
    pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkUndeNullBlank(datestate) || checkData(merchants) || checkUndeNullBlank(data.delivery_type_id) || checkData(allCourier)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data,pagePerpage) => {
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setFilterInput(data);
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];

    if(checkData(allCourier)){
      allCourier.map((item) => (
        courierId.push(item.value)
      )); 
    } 

    if(checkData(merchants)){
      merchants.map((item) => (
        merchantsId.push(item.value)
      )); 
    } 

    byData={"order_status_id":[13],"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'', "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setAllRtoShipment(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
  const exportRto = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = 1
      }else{
        data.merchant_id = props.user[0].id
      }
      data.order_status_id=[13]
      data.is_export=1
      setLoader(true);
      axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          setLoader(false)
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setLoader(false);
    });
  }
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
    <div className="content-body content overflow-hidden">
        <div className="background-strip"></div>
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 master-content shadow content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="mb-0 page-title">Manage RTO Shipments</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                {checkData(allRtoShipment.data) && <div className="btn-text btn-light" onClick={exportRto}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download RTO Shipments</span>
                </div>}
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
              </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <Form onSubmit={filterRtoSubmit(onFilterRto)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center">
                    <Col sm={6} md={5} lg={4} xl={3}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...filter("search_field")} className="form-control form-control-sm" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                                <option value="">Search By</option>
                                <option value="awb_number">AWB Number</option>
                                <option value="order_no">Order Number</option>
                                <option value="system_order_no">Tekipost Order No.</option>
                              </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>  
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} /> */}
                          <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              <div className='form-control cursor-pointer d-flex align-items-center'>
                                {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                              </div>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>

                    {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>}
                    
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...filter("delivery_type_id")} className="form-control form-control-sm">
                            <option value="">Select Payment Mode</option>
                            <option value="1">COD</option>
                            <option value="3">Prepaid</option>
                            </select>
                        </FormGroup>
                    </Col>  
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={allRtoShipmentListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
            </Collapse>
              <CardBody className="px-0">
              {checkData(allRtoShipment) && checkData(allRtoShipment.data) && checkUndeNullBlank(allRtoShipment.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(allRtoShipment.data).length)} of {allRtoShipment.TotalRows} entries
                    </div>
                      {allRtoShipment.TotalRows > dropFilterCount ?
                    <Form>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                              <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                          </Input>
                      </FormGroup>
                    </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th>Mobile</th>                      
                      <th>Courier</th>
                      <th>AWB Number</th>
                      <th>Order Number</th>
                      <th>Cod Value</th>
                      <th>Customer Name</th>
                      {/* <th>Action</th> */}
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(allRtoShipment.data) ? allRtoShipment.data.map((item, index) => (
                    <tr>
                      <td>{item.customer_mobile}</td>
                      <td>{item.courierName}</td>
                      <td>{item.awb_no}</td>
                      <td><Link to={`/order-detail/${item.id}`}>{item.order_no}</Link></td>
                      <td>{item.product_cod_value}</td>
                      <td className="text-nowrap">{item.customer_name}</td>
                      {/* <td className="text-nowrap">
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle className="btn btn-sm" outline color="secondary" >
                            Request for POD<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link href="#" className="dropdown-item">Shipment Not Received</Link>
                            <Link href="#" className="dropdown-item">Reasion 2</Link>
                            <Link href="#" className="dropdown-item">Reasion 3</Link>
                            <Link href="#" className="dropdown-item">Reasion 4</Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
                    </tr>
                  )):
                  loader ? <tr><td colSpan="7" className="text-center">Loading...</td></tr>
                  :<tr><td colSpan="7" className="text-center">No Data Found</td></tr>}
               </tbody>
                </Table>
                {checkData(allRtoShipment) && checkData(allRtoShipment.data) && checkUndeNullBlank(allRtoShipment.TotalRows) ?
                <>
                {allRtoShipment.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(allRtoShipment.data).length)} of {allRtoShipment.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Rto);