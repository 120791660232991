import React, {useState} from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import classnames from 'classnames';
import { User, Lock, Bell,Map } from 'react-feather';
import Profile from './Profile';
import BankDetail from './BankDetail';
import ChangePassword from './ChangePassword';
import KYCDetail from './KYCDetail';
import BillingDetail from './BillingDetail';
import EmailNotification from './EmailNotification';
import LabelSetting from './LabelSetting';
import CourierSetting from './CourierSetting';
import MyCouriers from './MyCouriers';
import { checkData } from '../../../ObjectExist';

const MerchantAccountSetting = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [bankDetails, setBankDetails] = useState();
    const [kycDetails, setKycDetails] = useState({});
    const [billingDetails, setBillingDetails] = useState({});
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    return (
        <div className="content-body overflow-hidden">
          <Row>
           <Col sm={12}>            
            <div className="p-0 my-3 MerchantSetting content-wrapper">
                <div className="content-header mb-4">
                    <div className="page-title">Setting</div>
                </div>
                <Row>
                    <Col md={12}>
                        <Nav tabs pills className="mb-3 master-tabs border-0 justify-content-center">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><User /></span>
                                        <span>My Profile</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Lock /></span>
                                        <span>Bank Account</span>
                                        {checkData(bankDetails) ?
                                        <span className={`ml-auto ${activeTab === '2' ? 'text-light' : 'text-success'}`}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '2' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Bell /></span>
                                        <span>KYC Detail</span>
                                        {checkData(kycDetails) ?
                                        <span className={`ml-auto ${activeTab === '3' ? 'text-light' : 'text-success'}`}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '3' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><FontAwesomeIcon icon={faFileAlt} /></span>
                                        <span>Billing Detail</span>
                                        {checkData(billingDetails) ?
                                        <span className={`ml-auto ${activeTab === '4' ? 'text-light' : 'text-success'}`}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '4' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Bell /></span>
                                        <span>Email Notification</span>
                                    </div>
                                </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Map /></span>
                                        <span>Label Setting</span>
                                    </div>
                                </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Bell /></span>
                                        <span>Courier Setting</span>
                                    </div>
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Lock /></span>
                                        <span>Change Password</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '9' })} onClick={() => { toggle('9'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Bell /></span>
                                        <span>My Couriers</span>
                                    </div>
                                </NavLink>
                            </NavItem> */}
                        </Nav>
                    </Col>
                    <Col md={12}>
                        {props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" &&
                        <Card className="shadow border-0">
                            <CardBody className="p-4"> 
                                <TabContent activeTab={activeTab} className="pb-0">
                                    <TabPane tabId="1">
                                        {activeTab === '1' &&
                                            <Profile user={props.user} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {/* {activeTab === '2' && */}
                                            <BankDetail user={props.user} setBankDetails={setBankDetails} />
                                        {/* } */}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {/* {activeTab === '3' && */}
                                            <KYCDetail user={props.user} setKycDetails={setKycDetails} />
                                        {/* } */}
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {/* {activeTab === '4' && */}
                                            <BillingDetail user={props.user} setBillingDetails={setBillingDetails} />
                                        {/* } */}
                                    </TabPane>
                                    <TabPane tabId="5">
                                        {activeTab === '5' &&
                                            <EmailNotification user={props.user} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="6">
                                        {activeTab === '6' &&
                                            <LabelSetting user={props.user} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="7">
                                        {activeTab === '7' &&
                                            <CourierSetting user={props.user} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="8">
                                        {activeTab === '8' &&
                                            <ChangePassword user={props.user} />
                                        }
                                    </TabPane>
                                    <TabPane tabId="9">
                                        {activeTab === '9' &&
                                            <MyCouriers user={props.user} />
                                        }
                                    </TabPane>
                                </TabContent> 
                            </CardBody>    
                        </Card> 
                        }                 
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}

export default MerchantAccountSetting;