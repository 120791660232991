import React, {useEffect, useState} from 'react';
import { Card, CardBody, Label, FormGroup, Table,Form, Row, Col,Button, Spinner } from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
// import axios from 'axios';
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDateYmd, errorHandling } from '../../../../utlis';
// import LoaderDefault from '../../../LoaderDefault';
// import NodataFound from '../../../NodataFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory} from 'react-router-dom';
import { download_delay } from '../../../../config';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import Logo1 from "../../../../assets/images/order_icon.png" ;


const OrderSummary = (props) => {
    const [filterDate, setFilterDate] = useState([new Date(),new Date()]);
    const [uploadedSummary, setUploadedSummary] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [loader, setLoader] = useState(false);
    const history = useHistory();  
    const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
    const { start, end } = datestate;
    const handleCallback = (start, end) => {
        setDateState({ start, end });
    };
    const ranges = {
        startDate: start !== '' && start.toDate(),
        endDate: end !== '' && end.toDate(),
        ranges: {
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
        ],
        'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        },
    }
    // const [summaryType, setSummaryType] = useState('today');
    // const summaryChange = (e) => {
    //     setSummaryType(e.target.value)
    //     setUploadedSummary({});
    //     let type = e.target.value
    //     listingSummary(type)
    // }
    const listingSummary = (type) => {
        setLoadertable(true);
        // let data = {"type":checkUndeNullBlank(type) ?type: summaryType }
        // setDateState({start: moment().subtract(29, 'days'),end: moment(),});
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");

        let data = {"start_date":dateStart,"end_date":dateEnd}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: { "x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" }, // this line is added as on Oct 18,2023 //
            url: `${process.env.REACT_APP_DASHBOARD_DAILY_UPLOAD_SUMMARY}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setUploadedSummary(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            errorHandling(err,"reload")
          });
    }
    useEffect(() => {
        listingSummary();
    }, []);


    let totalcourier_id = [];
    let totalCod = 0;
    let totalPrepaid = 0;
    let subtotal = 0;
    checkData(uploadedSummary) && uploadedSummary.map((item, index) => {
        totalcourier_id.push(item.courier_id);
        totalCod += item.delivery_type_id === 1 ? item.total : 0;
        totalPrepaid += item.delivery_type_id === 3 ? item.total : 0;
        subtotal += (item.delivery_type_id === 1 ? item.total : 0) + (item.delivery_type_id === 3 ? item.total : 0);
        return(
            item
        );
    })

    const exportShipment = (courierid,total) => {
        let courierId = [];
        courierid.forEach(item => {
            courierId.push(item)
        });

        let data = {"all_shipment":1,"start_date":filterDate.length > 0 ? changeIntoDateYmd(filterDate[0]) : '',"end_date":filterDate.length > 0 ? changeIntoDateYmd(filterDate[1]) : ''}
        
        data.courier_id = courierId;
        data.total = checkUndeNullBlank(total) ? total : "";

        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id = props.user[0].id
        }else{
            data.merchant_id = props.user[0].id
        }
        data.is_export=1
        setLoader(true);
        axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
        .then((res) => {
        if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
            if(parseInt(res.data.errno)>0){
                    setLoader(false);
                    if(res.data.sqlMessage!==undefined){
                    Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
                }
            }else{
                if(res.data.is_export === 1){
                    setTimeout(function(){ 
                      setLoader(false);
                      history.push("/my-downloads"); 
                    },download_delay);
                  }
            }
        }}).catch((err) => {
            errorHandling(err,"")
        });
        
    }
    // ChartJS.register(
    //     CategoryScale,
    //     LinearScale,
    //     BarElement,
    //     Title,
    //     Tooltip,
    //     Legend
    //   );
      const options = {
        indexAxis: 'y',
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness : 15
            },
            y: {
                stacked: true,
                barThickness : 15
            }
        },
        plugins: {
          title: {
            display: true,
            text: 'Courier Wise Status Performance'
          },
          legend: {
            display: false,
            position: "top",
         }
        },
        maintainAspectRatio: false 
       
      };
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [1,2,3,4],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Dataset 2',
            data: [1,2,3,4],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    return (
        <div className="orderSummary">            
            {loader && <div className="formLoader"><Spinner /></div>}
            {/* <Bar options={options} data={data} /> */}
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                    <div className="page-title">
					<figure><img className="img-fluid" src={Logo1} alt="img1" /></figure>
					Order Summary
					</div>  
                    <Form className="filter-form">   
                        <Row className="mx-sm-0 justify-content-end align-items-center">
                            <Col sm={9}>
                            <FormGroup className='mb-sm-0'>
                                {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placement="bottomEnd" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="DD-MM-YYYY" /> */}
                                <DateRangePicker
                                        initialSettings={ranges}
                                        onCallback={handleCallback}
                                    >
                                    {datestate.start !== '' && datestate.end !== '' ? 
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                    </div>
                                    :
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        Select Date
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </div>
                                    }
                                    </DateRangePicker>
                            </FormGroup>
                            </Col>
                            <Col sm={3} className="">
                                <Button className="ctm-btn btn-sm" color="primary" onClick={listingSummary}>Search</Button>
                            </Col>
                        </Row>
                    </Form>               
                    {/* <div className="d-flex flex-wrap">
                        <FormGroup className="mr-sm-4 mb-0">
                            <span className="form-check2">
                                <input name="summary" type="radio" id="today" value="today" defaultChecked onChange={(e) => summaryChange(e)}/>
                                <Label className="mx-2" for="today">Today</Label>
                            </span>
                        </FormGroup>
                        <FormGroup className="mr-sm-4 mb-0">
                            <span className="form-check2">
                                <input name="summary" type="radio" id="Total" value="total" onChange={(e) => summaryChange(e)} />
                                <Label className="mx-2" for="Total">Total</Label>
                            </span>
                        </FormGroup>
                        <FormGroup className="ml-sm-4 mb-0 d-none">
                            <span className="form-check2">
                                <input name="summary" type="radio" id="Custom" disabled/>
                                <Label className="mx-2" for="Custom">Custom</Label>
                            </span>
                        </FormGroup>
                    </div>    */}
                </div>
                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="col-1">Sno.</th>
                                <th className="col-4">Couriers</th>
                                <th>COD</th>
                                <th>Prepaid</th>
                                <th>Total</th>
                            </tr>
                            {checkData(uploadedSummary) &&
                                <tr>
                                    <th className="text-nowrap">Total </th>
                                    <th className="text-nowrap"></th>
                                    <th className="text-nowrap">{totalCod}</th>
                                    <th className="text-nowrap">{totalPrepaid}</th>
                                    <th className="text-nowrap">
                                        <div className="text-left mx-auto dowbload-con d-flex justify-content-between">{subtotal}{subtotal > 0 && 
                                            <Link to="#" className="download-icon mx-1" onClick={()=>exportShipment(totalcourier_id,subtotal)}><FontAwesomeIcon icon={faDownload} /></Link>}
                                        </div>
                                    </th>
                                </tr>
                            }
                        </thead>
                        <tbody>                            
                            {checkData(uploadedSummary) ? uploadedSummary.map((item, index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{item.courierName}</td>
                                    <td>{item.delivery_type_id === 1 ? item.total : 0}</td>
                                    <td>{item.delivery_type_id === 3 ? item.total : 0}</td>
                                    <td>
                                        <div className="text-left mx-auto dowbload-con d-flex justify-content-between">
                                            {(item.delivery_type_id === 1 ? item.total : 0) + (item.delivery_type_id === 3 ? item.total : 0)}
                                            {(item.delivery_type_id === 1 ? item.total : 0) + (item.delivery_type_id === 3 ? item.total : 0) > 0 && <Link to="#" className="download-icon mx-1" onClick={()=>exportShipment([item.courier_id],(item.delivery_type_id === 1 ? item.total : 0) + (item.delivery_type_id === 3 ? item.total : 0))}><FontAwesomeIcon icon={faDownload} /></Link>}
                                        </div>
                                    </td>
                                </tr>
                                )) :
                                loadertable ? <tr>
                                <td colSpan="5">Loading</td>
                                </tr>
                                :''
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}

export default OrderSummary;