export const VIEW_STATE = "VIEW_STATE"
export const VIEW_CITY = "VIEW_CITY"
export const VIEW_PINCODE = "VIEW_PINCODE"
export const MERCHANT_LOGIN = "MERCHANT_LOGIN"  
export const MERCHANT_SIGNUP = "MERCHANT_SIGNUP"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const OTP_PASSWORD = "OTP_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const ADMIN_LOGIN = "ADMIN_LOGIN"
export const VIEW_COURIER = "VIEW_COURIER"
export const VIEW_MERCHANT = "VIEW_MERCHANT"
export const SIGNUP_MERCHANT = "SIGNUP_MERCHANT"
export const VIEW_MERCHANT_REMMITANCE = "VIEW_MERCHANT_REMMITANCE"
export const VIEW_COURIER_REMMITANCE = "VIEW_COURIER_REMMITANCE"
export const VIEW_MERCHANT_COURIER = "VIEW_MERCHANT_COURIER"
export const VIEW_CHANNEL_BRAND = "VIEW_CHANNEL_BRAND"
export const VIEW_FREIGHTOVER = "VIEW_FREIGHTOVER"
export const VIEW_REMITTANCEOVER = "VIEW_REMITTANCEOVER"
export const RESET = "RESET"
export const WALLET_BALANCE = "WALLET_BALANCE"
export const VIEW_CARDOVER = "VIEW_CARDOVER"
export const VIEW_ORDERSUMMARY = "VIEW_ORDERSUMMARY"
export const VIEW_M_PERFORMANCEOVERVIEW = "VIEW_M_PERFORMANCEOVERVIEW"
export const VIEW_M_SHIPMENTSTAUS = "VIEW_M_SHIPMENTSTAUS"
export const VIEW_CITY_STATE = "VIEW_CITY_STATE"
export const VIEW_COURIER_OVER = "VIEW_COURIER_OVER"
export const VIEW_DAILY_SHIP_SUMMARY = "VIEW_DAILY_SHIP_SUMMARY"
export const VIEW_SHIPMENT_SUMMARY = "VIEW_SHIPMENT_SUMMARY"
export const VIEW_DASH_COURIER_REMITTANCE = "VIEW_DASH_COURIER_REMITTANCE"
export const VIEW_DASH_FREIGHT_REMITTANCE = "VIEW_DASH_FREIGHT_REMITTANCE"
export const VIEW_DASH_PENDING_SHIPMENT = "VIEW_DASH_PENDING_SHIPMENT"
export const VIEW_DASH_PICKUP_PENDING = "VIEW_DASH_PICKUP_PENDING"
export const VIEW_DASH_NOT_ATTEMPTED = "VIEW_DASH_NOT_ATTEMPTED"
export const VIEW_MERGE_ACCOUNT = "VIEW_MERGE_ACCOUNT"