import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Collapse, Spinner,UncontrolledTooltip, Input,FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown, faArrowUp, faChevronRight, faDownload, faChevronLeft, faFilter} from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { changeIntoDateTime, changeIntoDateYmd, copyToClipBoard, firstDayOftheMonth} from '../../utlis';
import Swal from 'sweetalert2';
import { creditNote_head_id, download_delay } from '../../config';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { Copy, ChevronLeft, ChevronRight } from 'react-feather';
// import axios from 'axios';
import axios from '../../CommonAxios';
import { toast,ToastContainer } from 'react-toastify';
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const MerchantCreditNote = (props) => {
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [creditNote, setCreditNote] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState('20');
  const [currentCount,setCurrentCount] = useState(1);
  const [filterDate, setFilterDate] = useState('');
  const [filterInput, setFilterInput] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [datestate, setDateState] = useState({start: '',end: '',});
  const dropFilterCount = 20
  const history = useHistory(); 
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if(!excelmodal){
      setExcelFile("");
    }
  }

  const handleFileInput = (e) => {setExcelFile(e.target.files[0]);}

  useEffect(() => {
    creditNoteListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])

  const creditNoteListing = () => {
    setLoadertable(true);
    reset();
      setDateState({start: moment(),end: moment(),});
      setFilterDate([firstDayOftheMonth(),new Date()]);
    let data = {"start_date":moment().format('YYYY-MM-DD'),"end_date":moment().format('YYYY-MM-DD')}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    axios.post(`${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_LIST}?page=${page}&per_page=${selectperpage}`, data)
    .then((res) => {
          setLoadertable(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setCreditNote(res.data);
        }
      }
    }).catch((err) => {
        setLoadertable(false);
        Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }

  // filter Credit Note
  const { register:filter, handleSubmit:filterCreditNoteSubmit, reset } = useForm();

  const { register:uploadCreditNote, handleSubmit:creditNoteSubmit,formState: { errors}} = useForm();
  const onUploadCreditNote = () =>{
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    axios
      .post(`${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_UPLOAD}`, formData)
      .then((res) => {
          setLoader(false);
          let errorFile='';
          if(res.data.errorfile !== undefined){
           errorFile = checkUndeNullBlank(res.data.errorfile)?process.env.REACT_APP_BASE_URL+res.data.errorfile:'';
          }
          setExcelFile();
          setExcelmodal(false);
          if(res.data.error === false){
          Swal.fire({
            title: 'Success',
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
            text: res.data.msg,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              creditNoteListing();
           }
         });
        }else{
           Swal.fire({
            title: 'Error',
            text: res.data.msg,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              setExcelFile(false);
              creditNoteListing();
           }
         });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({title:'Error', icon:'error', text:err})
      });
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }

  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(creditNote.data)
    if(currentCount>Object.entries(creditNote.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }

  function nextPageData() {
    if(checkData(creditNote.data)&&checkUndeNullBlank(creditNote.totalPage)){
        if(creditNote.totalPage > page){
          setCurrentCount(currentCount+Object.entries(creditNote.data).length)
      setPage(page+1)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }

  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };

  const onCreditNote = (data,pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page=1
    if((checkUndeNullBlank(data.ref_no) || checkUndeNullBlank(data.awb_no)) || checkUndeNullBlank(data.merchant_name) || checkUndeNullBlank(filterDate)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }
  };

  const onCommonFilter = (data,pagePerpage) => {
    let byData = {} 
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");   
    setLoader(true)
    byData={"ref_no":checkUndeNullBlank(data.ref_no)?data.ref_no:'',"awb_no":checkUndeNullBlank(data.awb_no)?data.awb_no:'',"merchant_name":checkUndeNullBlank(data.merchant_name)?data.merchant_name:'',"start_date":dateStart,"end_date":dateEnd}
    setFilterInput(byData);

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_LIST}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setCreditNote(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
  const exportCreditNote = (credit_note_id) => {    
    let data = {}    
    data.credit_note_id = checkUndeNullBlank(credit_note_id) ? credit_note_id : '';

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios.post(process.env.REACT_APP_MERCHANT_CREDIT_NOTE_DOWNLOAD, data)
    .then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
                setLoader(false);
                if(res.data.sqlMessage!==undefined){
                Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
        }else{
            if(res.data.error === false){
                setTimeout(function(){ 
                  setLoader(false);
                  history.push("/my-downloads"); 
                },download_delay);
            }
        }
    }}).catch((err) => {
        setLoader(false);
        Swal.fire({icon:'error',text:err,title:'Error'});
    });
    
  }

  //copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
      if(result === true){
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
       <Col sm={12}>
      {loader && <div className="formLoader"><Spinner /></div>}
        <Card className="p-0 my-3 MerchantCreditNote content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Manage Credit Note</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <Link className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Credit Note</span></Link>
                <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Credit Note</span></div>              
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
              </div>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Credit Note</ModalHeader>
                <ModalBody>
                  <Form onSubmit={creditNoteSubmit(onUploadCreditNote)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Credit Note via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/credit_note.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Credit Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadCreditNote("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            </CardHeader> 
            <Collapse isOpen={isOpen}>
              <Form onSubmit={filterCreditNoteSubmit(onCreditNote)} className="filter-form">
                <Row className="mx-0 pt-1 justify-content-start justify-content-md-center"> 
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <input {...filter("ref_no")} type="text" placeholder="Enter Ref. No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <input {...filter("awb_no")} type="text" placeholder="Enter AWB No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                        <input {...filter("merchant_name")} type="text" placeholder="Enter Merchant Name" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>}
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tra. Date Range" format="DD-MM-YYYY" ref={dateRangeRef} change={onDateChange} /> */}
                      <DateRangePicker
                            initialSettings={ranges}
                            onCallback={handleCallback}
                          >
                          {datestate.start !== '' && datestate.end !== '' ? 
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                        </DateRangePicker>
                    </FormGroup>                      
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {creditNoteListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>            
            </Collapse>
              <CardBody className="p-0">
              {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(creditNote.data).length)} of {creditNote.TotalRows} entries
                    </div>
                      {creditNote.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                  <tr>
                      {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <th className="text-nowrap">Merchant</th>}
                      <th className="text-nowrap w-some">Head</th>
                      <th className="text-nowrap">Credit Note No.</th>
                      <th className="text-nowrap">Transaction Date</th>
                      <th className="text-nowrap">Credit Amount</th>
                      <th className="text-nowrap w-some">Item Detail</th>
                      <th className="text-nowrap w-some">Download</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(creditNote.data) ? creditNote.data.map((item, index) => (
                    <tr>
                    {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <td>{item.MerchantName}</td>
                    }
                      <td>{creditNote_head_id[item.head_id]}</td>
                      <td>
                        <div className="text-nowrap"><Link to={`/credit-note-detail/${item.id}`}>{item.credit_note_no}</Link>
                          <span onClick={() => copied(item.credit_note_no)} className="ml-1 copy-btn d-none" id={`copyOdr${item.id}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span> 
                        </div>
                      </td>
                      <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                      <td>{item.amount}</td>
                      <td>{item.note_detail}</td>
                      <td><Button className="btn ctm-btn btn-sm text-nowrap" color="info" outline onClick={() => {exportCreditNote(item.id)}}>Download Excel</Button></td>
                    </tr>
                      ))
                      :loadertable ? <tr> <td colSpan="7">Loading...</td> </tr> :
                      <tr> <td colSpan="7">No Data Found</td> </tr> }
                   </tbody>
                </Table>
                <ToastContainer style={{width: "200px"}}/>
                {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                <>
                {creditNote.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(creditNote.data).length)} of {creditNote.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
  );
}

export default MerchantCreditNote;