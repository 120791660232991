import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Badge, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMoneyBillAlt,faCubes, faCube, faThLarge, faTh, faCreditCard } from '@fortawesome/free-solid-svg-icons';
 
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDatedmY, firstDayOftheMonth, errorHandling } from '../../../../utlis';
import {Link} from 'react-router-dom';

import Logo1 from "../../../../assets/images/dashboard_icon3.png" ;
import Logo2 from "../../../../assets/images/dashboard_icon4.png" ; 
import Logo3 from "../../../../assets/images/dashboard_icon5.png" ;
import Logo4 from "../../../../assets/images/dashboard_icon6.png" ;


const Cards = (props) => {
    const [cardsOverView, setCardsOverView] = useState({});
    const [loader, setLoader] = useState(false);

    const listingSummary = () => {
        setLoader(true);
        let data = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: { "x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" }, // this line is added as on Oct 18,2023 //
            url: `${process.env.REACT_APP_DASHBOARD_CARDS}`,
            data: data,
          }).then((res) => {
            setLoader(false)
            if(res.data.error === false){
                setCardsOverView(res.data.data);
            }
          }).catch((err) => {
            setLoader(false)
            errorHandling(err,"reload")
          });
    }
    useEffect(() => {
        listingSummary();
    }, []);
    const CardData = [
      { icon: faCubes, text: "Today's Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_shipments) ? cardsOverView.today_shipments : '0', link:`/allshipments?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "info" },
      { icon: faMoneyBillAlt, text: "Today's Failed", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_failed_shipments) ? cardsOverView.today_failed_shipments : '0', link:`/all-orders?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "primary" },
      { icon: faCube, text: "Total Pickup Pending", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_pickuppending_shipments) ? cardsOverView.today_pickuppending_shipments : '0', link:"/allshipments?s=label-manifest", color: "warning" },
      { icon: faThLarge, text: "This Month Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.this_month_shipments) ? cardsOverView.this_month_shipments : '0', link:`/allshipments?start_date=${changeIntoDatedmY(firstDayOftheMonth())}&end_date=${changeIntoDatedmY(new Date())}`, color: "danger" },
      { icon: faTh, text: "Total Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_shipments) ? cardsOverView.total_shipments : '0', link:"/allshipments?total=allShipments", color: "success" },
      { icon: faCreditCard, text: "Active Couriers", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_active_couriers) ? cardsOverView.total_active_couriers : '0', link:"#", color: "danger" },
    ];

    return (
        <Row>
        {loader && <div className="formLoader"><Spinner /></div>}
         
		<Col md={7}>
			<Row>
				<Col md={6}>
					<div className="dash-card1">
					 <Link to={CardData[0].link}>{CardData[0].value}</Link>
					 <p className="text-muted">{CardData[0].text}</p>
					</div> 
				</Col>
				 <Col md={6}>
					<div className="dash-card2">
					<Link to={CardData[1].link}>{CardData[1].value}</Link>
					<p className="text-muted">{CardData[1].text}</p>
					</div> 
				</Col>
		   </Row>
 	   </Col>
	   
	  
	   
	   <Col md={5}>
	    <Row>
		    <Col md={6}>
			<div className="dash-card3">
			 <figure><img className="img-fluid" src={Logo1} alt="img1" /></figure>
			<Link to={CardData[2].link}>{CardData[2].value}</Link>
			<p className="text-muted">{CardData[2].text}</p>
			</div> 
			</Col>
			<Col md={6}>
			<div className="dash-card4">
			 <figure><img className="img-fluid" src={Logo2} alt="img2" /></figure>
			<Link to={CardData[3].link}>{CardData[3].value}</Link>
			<p className="text-muted">{CardData[3].text}</p>
			</div> 
			</Col>
			<Col md={6}>
			<div className="dash-card5">
			 <figure><img className="img-fluid" src={Logo3} alt="img3" /></figure>
			<Link to={CardData[4].link}>{CardData[4].value}</Link>
			<p className="text-muted">{CardData[4].text}</p>
			</div> 
			</Col>
			<Col md={6}>
			<div className="dash-card6">
			<figure><img className="img-fluid" src={Logo4} alt="img4" /></figure>
			<Link to={CardData[5].link}>{CardData[5].value}</Link>
			<p className="text-muted">{CardData[5].text}</p>
			</div> 
			</Col>
		</Row>
 	   </Col>
	   
	   
			
        </Row>
    );
}

export default Cards;