export function getTodayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();

    if (date === start && date === end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get1DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 1);

    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get2DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 2);
    end.setDate(end.getDate() - 1);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get3DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 3);
    end.setDate(end.getDate() - 2);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get4DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 4);
    end.setDate(end.getDate() - 3);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get5DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 5);
    end.setDate(end.getDate() - 4);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get6DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 6);
    end.setDate(end.getDate() - 5);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get7DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 7);
    end.setDate(end.getDate() - 6);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get8DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 8);
    end.setDate(end.getDate() - 7);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get9DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 9);
    end.setDate(end.getDate() - 8);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }
  export function get10DayData(date) {
    let sameDaye=false;
    const start = new Date();
    const end = new Date();
    start.setDate(start.getDate() - 10);
    end.setDate(end.getDate() - 9);
    if (date > start && date < end) {
    sameDaye = true
    }
    return sameDaye; 
  }