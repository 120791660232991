import React from "react";
import {Row, Col, FormGroup, Button, Form, Label } from "reactstrap";
import { StateList } from "./StateList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { baseString } from "../../config";

const AddCity = (props) => {

  const { register:addCity, handleSubmit:citySubmit, formState: { errors }} = useForm();
  const onAddCitySubmit = (data) => {
    let dataAdd={"name":data.city,"state_id":data.state_id,"is_metro":data.is_metro};
      fetch(`${process.env.REACT_APP_ADD_CITY}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          'x-ref': window.btoa(baseString)
        },
        body:JSON.stringify(dataAdd)
      }).then((resp)=>{
        resp.json().then((result)=>{
          if(result.error === false){
            props.setDataAdd(false);
            Swal.fire({
              title: 'Success',
              text: result.message,
              icon: 'success',
            })
          }
        });
      }).catch((err) =>{
        Swal.fire({title: 'Error', text: err, icon: 'error'})
      });
  };
    const onError = (errors, e) => console.log('Error', errors);
 
    return(
      <Form onSubmit={citySubmit(onAddCitySubmit,onError)}>
      <Row className="mx-0 justify-content-center addModal">
          <Col sm={12}>
          <FormGroup>
          <Label for="state_add">Select State</Label>
          <div className="position-relative">
              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
              <select {...addCity("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_add">
                  <option value="">Select State</option>
                  {StateList.map((item, index) => (
                      <option value={item.state_id}>{item.state_name}</option>
                  ))
              }
              </select>
              {errors.state_id && <span className="text-danger d-block mb-2">{errors.state_id.message}</span>}
          </div>
          </FormGroup>
          </Col>
          <Col sm={12}>
              <FormGroup>
              <Label for="city_add">Enter City</Label>
              <input {...addCity("city",{ required: 'Please Enter City'})}  className="form-control-sm form-control" placeholder="Enter City" id="city_add"/>
              {errors.city && <span className="text-danger d-block mb-2">{errors.city.message}</span>}
              </FormGroup>
          </Col>
          <Col sm={12}>
              <FormGroup className="mb-0">
              <Label>Is Metro</Label>
              <div className="d-flex">
                  <span className="mr-2">
                  <input {...addCity("is_metro", { required: true })} type="radio" value="1" id="active_add" defaultChecked/>
                  <Label className="mx-2" for="active_add">Yes</Label>
                  </span>
                  <span>
                  <input {...addCity("is_metro", { required: true })} type="radio" value="0" id="inactive_add" />
                  <Label className="mx-2" for="inactive_add">No</Label>
                  </span>
              </div>
              </FormGroup>                          
          </Col>
          <Col sm={12} className="text-right">
          <Button className="btn btn-sm" color="primary">Save</Button>
          </Col>
      </Row>
      </Form>  
    )
}
export default AddCity