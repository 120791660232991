import React, {useState} from 'react';
import { Card, CardBody, CardHeader, Badge, ListGroup, ListGroupItem, Progress, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMapMarkerAlt, faCalendarAlt, faComment} from '@fortawesome/free-solid-svg-icons';
import { Bell, RefreshCcw} from 'react-feather';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDateTime, daysCount } from '../../utlis';
import { order_status } from '../../config';
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';

const TrackOrder = (props) => {
  const [loader, setLoader] = useState(false);
  const handleClick = event => {
    props.closeTracker(event.target.value);
  }
  const onStatusRecheck = () => {
    let data = {}
    if(props.statusRecheckOrderId .length > 0){
    // setLoader(true);
    if(props.statusRecheckOrderId.length > 0){
      data.order_ids=props.statusRecheckOrderId
    }
  axios.post(process.env.REACT_APP_STATUS_RECHECK, data)
  .then((res) => {
    setLoader(false)
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
        if(res.data.error === false){
          Swal.fire({icon:'success', title:'Success', text:res.data.msg});
          props.orderTrack();
        }else{
          setLoader(false)
          Swal.fire({icon:'error', title:'Error', text:res.data.msg})
        }
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
  }else{
    Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
  }
  }
  const shipStart = (checkData(props.orderTracking)) ? (checkUndeNullBlank(props.orderTracking.booking_date)) ? (props.orderTracking.booking_date || (checkData(props.orderTracking.created_at))) : props.orderTracking.created_at:''
  const shipEnd = (checkData(props.orderTracking.log)) && (checkData(props.orderTracking.log[0].courier_event_date_time)) ? props.orderTracking.log[0].courier_event_date_time:''
  const totalDays = daysCount(shipEnd,shipStart)
  console.log('shipStart', shipStart,'shipEnd', shipEnd)
  return (
    <div className={`right-side-anim position-${props.position} overflow-auto ${props.toggleTrack}`}> 
      {loader && <div className="formLoader"><Spinner /></div>}
      <Card className="border-0 main-card">
        <CardHeader tag="h5" className={`px-4 py-3 border-bottom bg-white ${props.toggleTrack}`}><span className="m-0">Track Order </span>{props.toggleTrack ==="show" && <span className="btn custom-close" onClick = {handleClick}><FontAwesomeIcon icon={faTimes} /></span> }</CardHeader>                    
        <CardBody className="px-4">
          <div className="card-content">
            <div className="d-none pb-2 position-relative">
              {/* <span className="fsc-2">Total Attempt</span><br/>  */}
              {/* <span className="fsc-3 text-primary">{checkData(props.orderTracking) && props.orderTracking.total_attempt} Attempt(s)</span>                       */}
              {/* <span className='refresh-tracking' onClick={() => onStatusRecheck()}> </span> */}
            </div>
            <div className="d-none d-flex border border-2 border-primary p-2 shadow rounded progress-area">
                {/* <span className="p-3 icon">
                  <Bell />                           
                </span> */}
                <div className="activity-progress flex-grow-1">
                  <small className="font-medium-1 d-inline-block mb-1"> {checkUndeNullBlank(props.lastStatus)?order_status[props.lastStatus]:''}</small>
                  {/* <Badge className="float-right" color="primary" pill> {totalDays !== undefined ?  totalDays: 'NA'} days</Badge> */}
                  {/* <Progress color="primary" value="50" style={{height:".4rem"}} /> */}
                </div>
            </div>
            {checkData(props.orderTracking.log)?
            <>
            <ListGroup className="widget-timeline border-0 mt-1">
              {checkData(props.orderTracking) && checkData(props.orderTracking.log) ? props.orderTracking.log.map((item, index) => (
              <ListGroupItem className={`${(item.tk_status_id===12) || (item.tk_status_id === 13) ? 'timeline-icon-danger' : (item.tk_status_id===11) ?'timeline-icon-warning border border-danger p-75': (item.tk_status_id===10) ?'timeline-icon-success': 'timeline-icon-info'}`}>
                <div className="d-flex align-items-start justify-content-between">
                  <h6 className="timeline-title">{order_status[item.tk_status_id]}</h6>
                  <span className="text-nowrap"><span className="icon"><FontAwesomeIcon icon={faCalendarAlt} /></span>{changeIntoDateTime(item.courier_event_date_time)}</span>
                </div>
                <div className="timeline-content"><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>{item.current_location}</div>
                {item.tk_status_id===11 && 
                <div className="timeline-content font-weight-bold mt-1"><span className="icon"><FontAwesomeIcon icon={faComment} /></span><span className='failed-track-msg'>{item.courier_msg}</span></div>
                }
              </ListGroupItem>
              )):''}
            </ListGroup>
            </>:
            <h6 className="text-center mt-3">No Tracking Log Found</h6>}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default TrackOrder;

TrackOrder.defaultProps = {
  position: "relative",
  toggleTrack: ""
};