import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, Collapse, CardTitle, Spinner,UncontrolledTooltip, Input,FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown, faArrowUp, faChevronRight, faDownload, faChevronLeft, faFilter} from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { changeIntoDateTime, changeIntoDateYmd, copyToClipBoard, firstDayOftheMonth} from '../../utlis';
import Swal from 'sweetalert2';
import { debitNote_head_id,status } from '../../config';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { Copy } from 'react-feather';
// import axios from 'axios';
import axios from '../../CommonAxios';
import { toast,ToastContainer } from 'react-toastify';

const MerchantDebitNote = (props) => {
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [debitNote, setDebitNote] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState('20');
  const [currentCount,setCurrentCount] = useState(1);
  const [filterDate, setFilterDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filterInput, setFilterInput] = useState({});
  const dropFilterCount = 20

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if(!excelmodal){
      setExcelFile("");
    }
  }

  const handleFileInput = (e) => {setExcelFile(e.target.files[0]);}

  useEffect(() => {
    debitNoteListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])

  const debitNoteListing = () => {
    setLoadertable(true);
    reset();
    setFilterDate([firstDayOftheMonth(),new Date()]);
    let data = {"start_date":changeIntoDateYmd(firstDayOftheMonth()),"end_date":changeIntoDateYmd(new Date())}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    axios.post(`${process.env.REACT_APP_MERCHANT_DEBIT_NOTE_LIST}?page=${page}&per_page=${selectperpage}`, data)
    .then((res) => {
          setLoadertable(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setDebitNote(res.data);
        }
      }
    }).catch((err) => {
        setLoadertable(false);
        Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }

  // filter Debit Note
  const { register:filter, handleSubmit:filterDebitNoteSubmit, reset } = useForm();

  const { register:uploadDebitNote, handleSubmit:debitNoteSubmit,formState: { errors}} = useForm();
  const onUploadDebitNote = () =>{}

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }

  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(debitNote.data)
    if(currentCount>Object.entries(debitNote.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }

  function nextPageData() {
    if(checkData(debitNote.data)&&checkUndeNullBlank(debitNote.totalPage)){
        if(debitNote.totalPage > page){
          setCurrentCount(currentCount+Object.entries(debitNote.data).length)
      setPage(page+1)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }

  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };

  const onDebitNote = (data,pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page=1
    if((checkUndeNullBlank(data.ref_no) || checkUndeNullBlank(data.awb_no)) || checkUndeNullBlank(data.merchant_name) || checkUndeNullBlank(filterDate)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }
  };

  const onCommonFilter = (data,pagePerpage) => {
    let byData = {}    
    setLoader(true)
    byData={"ref_no":checkUndeNullBlank(data.ref_no)?data.ref_no:'',"awb_no":checkUndeNullBlank(data.awb_no)?data.awb_no:'',"merchant_name":checkUndeNullBlank(data.merchant_name)?data.merchant_name:'',"start_date":filterDate !== '' ? changeIntoDateYmd(filterDate[0]) : '',"end_date":filterDate !== '' ? changeIntoDateYmd(filterDate[1]) : ''}
    setFilterInput(byData);

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_DEBIT_NOTE_LIST}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setDebitNote(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };


  //copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
      if(result === true){
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
       <Col sm={12}>
      {loader && <div className="formLoader"><Spinner /></div>}
        <Card className="p-0 my-3 MerchantDebitNote content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Manage Debit Note</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <Link className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Debit Note</span></Link>
                <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Debit Note</span></div>              
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
              </div>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Debit Note</ModalHeader>
                <ModalBody>
                  <Form onSubmit={debitNoteSubmit(onUploadDebitNote)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Debit Note via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/debit_note.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Debit Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadDebitNote("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            </CardHeader> 
            <Collapse isOpen={isOpen}>
              <Form onSubmit={filterDebitNoteSubmit(onDebitNote)} className="filter-form">
                <Row className="mx-0 pt-1 justify-content-start justify-content-md-center"> 
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <input {...filter("ref_no")} type="text" placeholder="Enter Ref. No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <input {...filter("awb_no")} type="text" placeholder="Enter AWB No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                        <input {...filter("merchant_name")} type="text" placeholder="Enter Merchant Name" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>}
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tra. Date Range" format="DD-MM-YYYY" ref={dateRangeRef} change={onDateChange} /> */}
                    </FormGroup>                      
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {debitNoteListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>            
            </Collapse>
              <CardBody className="p-0">
              {checkData(debitNote) && checkData(debitNote.data) && checkUndeNullBlank(debitNote.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(debitNote.data).length)} of {debitNote.TotalRows} entries
                    </div>
                      {debitNote.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <th>Merchant</th>}
                      <th>Head</th>
                      <th>AWB No.</th>
                      <th>Debit Note Ref.</th>
                      <th>Transaction Date</th>
                      <th>Debit Amount</th>
                      <th>Item Detail</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(debitNote.data) ? debitNote.data.map((item, index) => (
                    <tr>
                    {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <td>{item.MerchantName}</td>
                    }
                      <td>{debitNote_head_id[item.head_id]}</td>
                      <td>
                        <div><span>{item.awb_no}</span>
                          <span onClick={() => copied(item.awb_no)} className="ml-25 copy-btn d-none" id={`copy${item.awb_no}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copy${item.awb_no}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div><small>{status[item.status]}</small>
                      </td>
                      <td><Link to={`/invoice-detail?id=${item.ref_no}`}>{item.ref_no}</Link></td>
                      <td className="text-nowrap">{changeIntoDateTime(item.created)}</td>
                      <td>{item.amount}</td>
                      <td>{item.note_detail}</td>
                    </tr>
                      ))
                      :loadertable ? <tr> <td colSpan="7">Loading...</td> </tr> :
                      <tr> <td colSpan="7">No Data Found</td> </tr> }
                   </tbody>
                </Table>
                <ToastContainer style={{width: "200px"}}/>
                {checkData(debitNote) && checkData(debitNote.data) && checkUndeNullBlank(debitNote.TotalRows) ?
                <>
                {debitNote.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(debitNote.data).length)} of {debitNote.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
  );
}

export default MerchantDebitNote;