import React, {useState, useEffect} from 'react';
import { Row, Col, CardTitle, ListGroup, ListGroupItem, CardHeader, Media, Form, Button} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt} from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
import axios from '../../CommonAxios';

import Swal from 'sweetalert2';
const CourierPriority = (props) => {

    const [courierPriority, setCourierPriority] = useState([]);
console.log('courierPriority', courierPriority)
useEffect(() =>{
      axios({
          method: "post",
          url: `${process.env.REACT_APP_MERCHANT_COURIERPRIORITY}/view`,
          data: {"user_type": props.user !== undefined && props.user !== null && props.user[0].user_type === "Admin" ?"Admin":'',"service_id":props.serviceType, "merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:props.merchantId,},
        })
          .then(function (response) {
            let myResponse=response.data;
            setCourierPriority(myResponse);
            setExpressCod(myResponse.cod);
            setExpressPrepaid(myResponse.preapid);
          }).catch((err) => Swal.fire({title: 'Error', text: err, icon: 'error',}));
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceType])
      const submitCourierSetting = () => {

        let data = {"merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:props.merchantId, "setting_id":'2', "courierPriority" : {"cod" :expressCod,"prepaid":expressPrepaid},"service_id" : props.serviceType, "setting_value" : props.shipMethod}
        axios.post(`${process.env.REACT_APP_MERCHANT_UPDATE_COURIERPRIORITY}`, data)
        .then((res) => {
          if(res.data !== undefined && res.data.fatal !== true){
            if(parseInt(res.data.errno)>0){
              if(res.data.sqlMessage!==undefined){
                Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
              }
            }else{
              if(res.data.error === false){
                props.setCourierAdd !== undefined && props.setCourierAdd(false)
                Swal.fire({title:'Successful', text:res.data.msg, icon:'success'})
              }else{
                Swal.fire({title:'Error', text:res.data.msg, icon:'error'})
              }
            }
          }
        }).catch((err) => {
          Swal.fire({icon:'error',text:err,title:'Error'});
        });
      }
      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
      }
      const [expressCod, setExpressCod] = useState({});
      const [expressPrepaid, setExpressPrepaid] = useState({});
      const onDragEndCOD = result => {
        if (!result.destination) {
          return
        }
        const items = reorder(
          expressCod,
          result.source.index,
          result.destination.index
        )
        setExpressCod(items);
      }
     const onDragEndPrepaid = result => {
        if (!result.destination) {
          return
        }
        const items = reorder(
          expressPrepaid,
          result.source.index,
          result.destination.index
        )
        setExpressPrepaid(items);
      }
    return (
      <Form>
        <Row>
            <Col sm={6}>
                <CardHeader className="border-bottom bg-light"><CardTitle className="mb-0">COD Couriers</CardTitle></CardHeader>
                <ListGroup id="list-group-dnd-cod">
                <DragDropContext onDragEnd={onDragEndCOD}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                        { expressCod !== undefined && expressCod !== null && Object.entries(expressCod).length >0 && expressCod.map((item, index) => (
                            <Draggable
                            key={String(item.courier_id)}
                            draggableId={String(item.courier_id)}
                            index={index}
                            >
                            {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drag-wrapper"
                            >
                                <ListGroupItem>
                                <Media className="align-items-center">
                                    <Media left tag="div">
                                    <Media
                                        object
                                        src={process.env.REACT_APP_IMAGE_COURIER+item.logo}
                                        className="rounded-circle mr-2"
                                        alt="Generic placeholder image"
                                        height="50"
                                        width="50"
                                    />
                                    </Media>
                                    <Media body>
                                    <h5 className="my-0">{item.CourierName}</h5>
                                    </Media>
                                    <span className="dragicon position-absolute" style={{right:'10px'}}><FontAwesomeIcon icon={faArrowsAlt} /></span>
                                </Media>
                                </ListGroupItem>
                            </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    </DragDropContext>
                </ListGroup>
                </Col>
                <Col sm={6}>
                <CardHeader className="border-bottom bg-light"><CardTitle className="mb-0">Prepaid Couriers</CardTitle></CardHeader>
                <ListGroup id="list-group-dnd-prepaid">
                <DragDropContext onDragEnd={onDragEndPrepaid}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                        { expressPrepaid !== undefined && expressPrepaid !== null && Object.entries(expressPrepaid).length >0 && expressPrepaid.map((item, index) => (
                            <Draggable
                            key={String(item.courier_id)}
                            draggableId={String(item.courier_id)}
                            index={index}
                            >
                            {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drag-wrapper"
                            >
                                <ListGroupItem>
                                <Media className="align-items-center">
                                    <Media left tag="div">
                                    <Media
                                        object
                                        src={process.env.REACT_APP_IMAGE_COURIER+item.logo}
                                        className="rounded-circle mr-2"
                                        alt="Generic placeholder image"
                                        height="50"
                                        width="50"
                                    />
                                    </Media>
                                    <Media body>
                                    <h5 className="my-0">{item.CourierName}</h5>
                                    </Media>
                                    <span className="dragicon position-absolute" style={{right:'10px'}}><FontAwesomeIcon icon={faArrowsAlt} /></span>
                                </Media>
                                </ListGroupItem>
                            </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    </DragDropContext>
                </ListGroup>
                </Col>
                <Col sm={12} className="text-right">
                    <Button className="btn btn-sm" color="primary" onClick={submitCourierSetting}>Save</Button>
                </Col>
          </Row>  
        </Form>      
    );
}

export default CourierPriority;