import React from 'react';
// import { Nav, NavItem } from 'reactstrap';
import {  Navbar,  Nav,  NavItem,  NavLink, DropdownToggle, DropdownMenu, } from 'reactstrap';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faBalanceScale, faBook, faCube, faHandshake, faLayerGroup, faShoppingBag, faSitemap, faSnowflake, faTachometerAlt, faTruck, faBars} from '@fortawesome/free-solid-svg-icons';

import { Tool, UserPlus } from 'react-feather';
import { useLocation } from 'react-router';

const Sidebar = (props) => {
    const urlPath = useLocation();

    return (
        <div class="teckieMain techieadmin">
            
			 
            <div class="teckieMain-scroll mm-active ps ps--active-y">
                <Nav vertical>
					
					<NavItem>
					<div className="dashboard_bar mr-3" onClick={() => props.sideNavToggle()}>
                    <i className="bi bi-list"></i> 
					</div>
					</NavItem>

                    <NavItem>
                        <Link to='/' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faTachometerAlt} /></span>
                            <span className="navContent">Dashboard</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/master' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/master' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faLayerGroup} /></span>
                            <span className="navContent">Manage Masters</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/couriers' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/couriers' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faTruck} /></span>
                            <span className="navContent">Couriers</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/merchants' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/merchants' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faHandshake} /></span>
                            <span className="navContent">Merchants</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/all-orders' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/all-orders' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faShoppingBag} /></span>
                            <span className="navContent">Orders</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/allshipments' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/allshipments' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faCube} /></span>
                            <span className="navContent">Manage Shipment</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/rto' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/rto' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faArrowCircleDown} /></span>
                            <span className="navContent">Manage RTO</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/remittance' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/remittance' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faSnowflake} /></span>
                            <span className="navContent">COD Remittance</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/ndr' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/ndr' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faBook} /></span>
                            <span className="navContent">Manage NDR</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/sales' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/sales' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faSnowflake} /></span>
                            <span className="navContent">Sales</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/weight-mismatch' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/weight-mismatch' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faBalanceScale} /></span>
                            <span className="navContent">Weight Mismatch</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/credit-note' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/credit-note' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faBook} /></span>
                            <span className="navContent">Credit Note</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/debit-note' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/debit-note' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faBook} /></span>
                            <span className="navContent">Debit Note</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/serviceability' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/serviceability' && 'active'}`}>
                            <span className="navIcon"><FontAwesomeIcon icon={faSitemap} /></span>
                            <span className="navContent">Serviceability</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to='/utility' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/utility' && 'active'}`}>
                            <span className="navIcon"><Tool /> </span>
                            <span className="navContent">Utility Tool</span>
                        </Link>
                    </NavItem>
                    {parseInt(props.user.id) === 1 &&
                    <NavItem>
                        <Link to='/users' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/users' && 'active'}`}>
                            <span className="navIcon"><UserPlus /> </span>
                            <span className="navContent">Manage Admin</span>
                        </Link>
                    </NavItem>
                }
                </Nav>
            </div>
        </div>
    )
}

export default Sidebar
