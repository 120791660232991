import React from 'react';
// import axios from 'axios';
import axios from '../CommonAxios';
import Swal from 'sweetalert2'
import { Button } from 'reactstrap';
const Status = (props) => {
    const StatusUpdate = () => {
        axios({
                method: "post",
                url: `${props.statusUrl}/status`,
                data: props.data
            })
            .then((res) => {
                if (res.data.error === false) {
                    props.reListing()
                    Swal.fire({
                        title: 'Success',
                        text: res.data.msg,
                        icon: 'success',
                    })
                }
            })
            .catch((err) => Swal.fire({title:'Error', icon:'error', text:err}));
    };

    return ( 
        <Button outline color = { `${props.valStatus===1 ? "success": "danger"}` }
        className = "btn btn-sm btn-active"
        onClick = { StatusUpdate } > { props.valStatus === 1 ? 'Active' : 'InActive' } </Button>
    );
}

export default Status;