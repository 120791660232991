import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,Form, CardHeader, Table, Badge,Pagination, PaginationItem, PaginationLink, Spinner, Input} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
// import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faChevronLeft, faChevronRight, faPlus, faWallet} from '@fortawesome/free-solid-svg-icons';
import { CreditCard} from 'react-feather';
import AddPassbook from './AddPassbook';
import { download_delay, pay_status,baseString, wallet_head } from '../../../config';
import { changeIntoDateTime, firstDayOftheMonth, errorHandling,changeIntoDateYmd } from '../../../utlis';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
 

const Passbook = (props) => {
  const [walletMdl, setWalletMdl] = useState(false);
  const toggleWallet = () => setWalletMdl(!walletMdl);
  const [filterDate, setFilterDate] = useState('');

  const { merchantId } = useParams();

  const [passbook, setPassbook] = useState({})
  const [amount, setAmount] = useState('')
  const [loader, setLoader] = useState(false);
  const [tableloader, setTbaleLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [isExport, setIsExport] = useState(false);
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const dropFilterCount = 20
    const history = useHistory();

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  useEffect(() => {
    passbookListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const passbookListing = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");

    setTbaleLoader(true);
    let data = {"start_date":dateStart,"end_date":dateEnd}
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.merchant_id= merchantId; data.admin_id=props.user[0].id
      }if(props.user[0].user_type === "Merchant"){
        data.merchant_id= props.user[0].id
      }
    reset();
    setFilterDate('');
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    setIsExport(false);
    axios.post(`${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?start_date=${dateStart}&end_date=${dateEnd}&page=${1}&per_page=${20}`,data)
      .then((res) => {
      setTbaleLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setPassbook(res.data);
            setAmount(res.data.passbookSum[0]);
          }
          else{
            Swal.fire({
              title: 'Error',
              text: res.msg,
              icon: 'error',
            })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setTbaleLoader(false);
    });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(passbook.data)&&checkUndeNullBlank(passbook.totalPage)){
    // &&checkUndeNullBlank(passbook.totalPage).length>page.length
      if(passbook.totalPage > page){
        setCurrentCount(currentCount+Object.entries(passbook.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(passbook.data)
  if(currentCount>Object.entries(passbook.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register, handleSubmit, reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onSubmit = (data,pagePerpage) => {
    setIsExport(true);
    pagePerpage.page=1
    if(checkUndeNullBlank(data.txnid) || checkUndeNullBlank(data.head_id) || checkUndeNullBlank(data.pay_status) || checkUndeNullBlank(datestate)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  };
  const onCommonFilter = (data,pagePerpage) => {
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setLoader(true)
    let byData = {txnid:checkUndeNullBlank(data.txnid)?data.txnid:'',head_id:checkUndeNullBlank(data.head_id)?data.head_id:'',pay_status:checkUndeNullBlank(data.pay_status)?data.pay_status:''}
    if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
      byData.merchant_id=props.user[0].id
    }else{
      byData.merchant_id=merchantId
      byData.admin_id=props.user[0].id
    }
    setFilterInput(byData);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${dateStart}&end_date=${dateEnd}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then(function (response) {
          setLoader(false);
          if(response.data.fatal !== true){
            setPassbook(response.data);
            setAmount(response.data.passbookSum[0]);
          }
        }).catch(function (response) {
          setLoader(false);
          Swal.fire({title:'Error', icon:'error', text:response})
        });
  };
  
  const exportShipment = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      data.is_export=1
      setLoader(true);  
      // axios.post(`${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${page}&per_page=${selectperpage}`, data)
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${page}&per_page=${selectperpage}`,
        data: data,
        headers: {"token":window.btoa(baseString)}
        }).then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          setLoader(false);
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"")
    });
    }else{
      Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
    }
  }
 
  return (
    <div className="content-body content overflow-hidden manage-passbook">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 master-content shadow content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title pb-2 font-weight-bold border-short">Manage Passbook </CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                {props.user !== undefined ? (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") && 
                <><Link to='/merchants' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Merchant List</span></Link>
                <div className="btn-text btn-light" onClick={toggleWallet}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add</span></div> 
                </>: ''}              
                
                {checkData(passbook.data) && <div className="btn-text btn-light" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Passbook</span></div>}            
              </div>
              {props.user !== undefined ? (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
              <AddPassbook passbookListing={passbookListing} user={props.user} isOpen={walletMdl} setWalletMdl={setWalletMdl} toggle={toggleWallet}/>
              :''}
            </CardHeader>
                <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
                  <Row className="mx-0 pt-1 justify-content-center">
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...register("txnid")} className="form-control-sm form-control" placeholder="Enter Trans. ID" />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("pay_status")}  className="form-control form-control-sm">
                            <option value="">Pay Status</option>
                            <option value="1">Paid</option>
                            <option value="0">Unpaid</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("head_id")}  className="form-control form-control-sm">
                            <option value="">Select Head</option>
                            {Object.entries(wallet_head).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tran. Date Range" format="DD-MM-YYYY" ref={dateRangeRef} change={onDateChange} /> */}
                            <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              <div className='form-control cursor-pointer d-flex align-items-center'>
                                {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                              </div>
                            </DateRangePicker>
                        </FormGroup>                      
                    </Col>                                      
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={passbookListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <div className="statistics my-4 d-none"> 
                    <Row className="mx-auto" style={{maxWidth:"600px"}}>
                          <Col sm={4}>
                            <div className="media">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-success rounded-circle">
                                  <CreditCard />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0">{amount !== '' && amount !== undefined && amount !== 'null' ? <> ₹ {amount.CreditAmt} </> : 'NA'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Credit asdasd</p>
                                </div>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="media">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-danger rounded-circle">
                                  <CreditCard />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0">{ amount !== undefined && amount.DebitAmt!==undefined && parseFloat(amount.DebitAmt)>0 ? <> ₹ {amount.DebitAmt} </> : '₹ 0'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Debit</p>
                                </div>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="media">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-info rounded-circle">
                                  <FontAwesomeIcon icon={faWallet} />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0">{amount !== '' && amount !== undefined && amount !== 'null' ? <>₹ {(amount.CreditAmt-amount.DebitAmt).toFixed(2)} </>: 'NA'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Ballance</p>
                                </div>
                            </div>
                          </Col>
                    </Row>
                </div>
              <CardBody className="p-0">
              {checkData(passbook) && checkData(passbook.data) && checkUndeNullBlank(passbook.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(passbook.data).length)} of {passbook.TotalRows} entries
                    </div>
                      {passbook.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
              <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th>Head</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                      <th>Pay Status</th>
                      <th>Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checkData(passbook.data) ? passbook.data.map((item, index) => (
                    <tr key={index} className="a">
                      <td>{wallet_head[item.head_id]}</td>
                      <td>{item.txnid}</td>
                      <td>{item.credit_amt > item.debit_amt ? item.credit_amt : item.debit_amt}</td>
                      
					  <td>
					  <span className={`item_paystatus paystatus_${item.pay_status}`}> {pay_status[item.pay_status]}</span> 
					  </td>
                        
					  <td>
					  {moment(moment(item.created_at)).subtract({hours:5, minutes:30}).format('MMM DD YYYY, h:mm:ss a')}
					  
					  </td> 
                    </tr>
                  ))
                  :tableloader ?<td colSpan="5">Loading...</td>: <td colSpan="5" className="text-center">No Data Found</td>}
                   </tbody>
                </Table>
                {checkData(passbook) && checkData(passbook.data) && checkUndeNullBlank(passbook.TotalRows) ?
                <>
                {passbook.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(passbook.data).length)} of {passbook.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default Passbook;