import { faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, Row, Col, Input, CardTitle, FormGroup, Form, Pagination, PaginationItem, PaginationLink, Spinner, } from "reactstrap";
import { useForm } from "react-hook-form";
import EditPincode from './EditPincode';
import Swal from "sweetalert2";
import { changeIntoDateTime } from "../../utlis";
import { checkData, checkPermission, checkUndeNullBlank } from "../../ObjectExist";
import { connect, useDispatch } from "react-redux";
import { AllPincode } from "../../actions";
import { baseString } from "../../config";

const Pincode = (props) => {
  const [pincode, setPincode] = useState({});
  const [stateCity, setStateCity] = useState({});
  const [editPin, setEditPin] = useState({});
  const [editmodal, seteditModal] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const dropFilterCount = 20
  const dispatch = useDispatch();

  const toggleEditclose = () => seteditModal(!editmodal);
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  const toggleEdit = (i) => {
    setLoader(true);
    seteditModal(!editmodal)
    fetch(`${process.env.REACT_APP_GET_PINCODE}/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
      },
    }).then((resp) => {
      setLoader(false);
      resp.json().then((result) => {
        result[0].status = String(result[0].status)
        setEditPin(result[0])
        fetch(`${process.env.REACT_APP_GET_CITY_STATE}?state_id=${result[0].state_id}`, {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            'x-ref': window.btoa(baseString)
          },
        }).then((resp) => {
          resp.json().then((result) => {
            setStateCity(result)
          });
        }).catch((err) => {
          setLoader(false);
          Swal.fire({ title: 'Error', text: err, icon: 'error' });
        });
      });
    });
  };
  const tableDataFetch = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = {}
    let myget = { "page": page, "per_page": selectperpage };
    dispatch(AllPincode(mypost, myget)).then((response) => {
      setPincode(response)
      setLoadertable(false);
    }).catch((err) => {
      setLoadertable(false);
      Swal.fire({ title: 'Error', text: err, icon: 'error' })
    });
  }
  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.viewAllPincode)) {
      setPincode(props.state.viewAllPincode);
    } else {
      tableDataFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectperpage]);
  const RemovePincode = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to delete this record?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_REMOVE_PINCODE}/${id}`, {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
          },
        }).then((resp) => {
          resp.json().then((result) => {
            Swal.fire({ title: 'Success', text: result.msg, icon: 'success' })
            tableDataFetch();
          });
        }).catch((err) => {
          Swal.fire({ title: 'Error', text: err, icon: 'error' })
        });
      }
    })
  }
  function nextPageData() {
    if (checkData(pincode.data) && checkUndeNullBlank(pincode.totalPage)) {
      // &&checkUndeNullBlank(pincode.totalPage).length>page.length
      if (pincode.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(pincode.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(pincode.data)
      if (currentCount > Object.entries(pincode.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)

  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.state) || checkUndeNullBlank(data.city) || checkUndeNullBlank(data.pincode)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_PINCODE}?state=${checkUndeNullBlank(data.state) ? data.state : ''}&city=${checkUndeNullBlank(data.city) ? data.city : ''}&pincode=${checkUndeNullBlank(data.pincode) ? data.pincode : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        'x-ref': window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setPincode(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      Swal.fire({ icon: "error", title: "Error", text: err })
    });
  };

  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Modal isOpen={editmodal} toggle={editmodal} >
        <ModalHeader toggle={toggleEditclose}>Edit Pincode</ModalHeader>
        <ModalBody>
          {Object.entries(editPin).length > 0 &&
            <EditPincode editPin={editPin} stateCity={stateCity} seteditModal={seteditModal} tableDataFetch={tableDataFetch} />
          }
        </ModalBody>
      </Modal>
      <Row className="justify-content-between card-header align-items-center px-3 pt-3">
        <Col sm={3}><CardTitle>Manage Pincode</CardTitle></Col>
        <Col sm={3}>
          {checkData(pincode) && checkData(pincode.data) && checkUndeNullBlank(pincode.TotalRows) ?
            <>
              {pincode.TotalRows > dropFilterCount ?
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                      <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Input>
                  </FormGroup>
                </Form>
                : ''}
            </>
            : ''}
        </Col>
      </Row>
      <div className="filter-form">
        <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
          <Row className="mx-0 pt-1 justify-content-start justify-content-lg-center">
            <Col sm={6} md={4} lg={3} xl={3}>
              <FormGroup>
                <input {...register("state")} className="form-control-sm form-control" placeholder="Enter State" />
              </FormGroup>

            </Col>
            <Col sm={6} md={4} lg={3} xl={3}>
              <FormGroup>
                <input {...register("city")} className="form-control-sm form-control" placeholder="Enter City" />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3} xl={3}>
              <FormGroup>
                <input {...register("pincode")} className="form-control-sm form-control" placeholder="Enter Pincode" />
              </FormGroup>
            </Col>
            <Col sm={3} className="filter-btn">
              <Button className="btn btn-sm" color="primary">Search</Button>
              <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={tableDataFetch}>Reset</Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Table responsive borderless className="text-center customTable">
        <thead>
          <tr>
            <th>State</th>
            <th>City</th>
            <th>Pincode</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {checkData(pincode.data) ? pincode.data.map((item, index) => (
            <tr>
              <td>{item.state}</td>
              <td>{item.name}</td>
              <td>{item.pincode}</td>
              <td>{item.created_at !== undefined && item.created_at !== null ? changeIntoDateTime(item.created_at) : 'NA'}</td>
              {checkPermission(props.user, props?.user[0]?.permissions?.manage_Pincodes?.edit) ?
                <td>
                  <span><Button outline className="btn btn-sm light ml-2" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                  <span><Button outline className="btn btn-sm light ml-2" color="danger" onClick={() => RemovePincode(item.id)}><FontAwesomeIcon icon={faTrash} /> </Button></span>
                </td>:
                <td>NA</td>
              }
            </tr>
          ))
            : loadertable ? <tr><td colSpan={5}>Loading...</td></tr> : <tr><td colSpan={5}>No Data Found</td></tr>}
        </tbody>
      </Table>
      {checkData(pincode) && checkData(pincode.data) && checkUndeNullBlank(pincode.TotalRows) ?
        <>
          {pincode.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
                Showing {currentCount} to {(currentCount - 1) + (Object.entries(pincode.data).length)} of {pincode.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}>Previous</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}>Next</PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
            : ''}
        </>
        : ''}
    </>
  )
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(Pincode);