import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Input, FormGroup, Form, Collapse, CardHeader, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowUp, faFilter, faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString, download_delay } from '../../config';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import moment from "moment";
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { changeIntoDateTime, changeIntoDate, errorHandling, firstDayOftheMonth } from '../../utlis';

const RemittanceTransactions = (props) => {

  const [merchants, setMerchants] = useState([]);
  const [datestate, setDateState] = useState({ start: moment().subtract(29, 'days'), end: moment(), });
  const [transactions, setTransactions] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const { start, end } = datestate;
  const dropFilterCount = 20
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const history = useHistory();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(transactions.data) && checkUndeNullBlank(transactions.totalPage)) {
      if (transactions.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(transactions.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(transactions.data)
      if (currentCount > Object.entries(transactions.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register:filter, handleSubmit:filtermerchRemitSubmit, reset } = useForm();
  const onFilterMerchRemit = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkData(merchants) || checkUndeNullBlank(data.pay_status) || checkUndeNullBlank(data.ref_no) || checkUndeNullBlank(datestate) || checkData(datestate) ){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data, pagePerpage) => {
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    // let tmpallCourier = [];
    // allCourier.map((item) => (
    //   tmpallCourier.push(item.value)      
    // ));


    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "pay_status": checkUndeNullBlank(data.pay_status) ? data.pay_status : '', "ref_no": checkUndeNullBlank(data.ref_no) ? data.ref_no : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": dateStart, "end_date": dateEnd }
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    setFilterInput(byData);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setTransactions(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
      setLoader(false);
    });
  };

  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }

  const exportRemmitance = (date, mer_id, tran_id) => {
    var dates = date.split("-");
    var dates2 = dates[2] + "-" + dates[1] + "-" + dates[0];

    let data = { "pay_status": 1, "trans_date": checkUndeNullBlank(date) ? dates2 : '' }

    data.merchant_id = checkUndeNullBlank(mer_id) ? mer_id : '';
    data.ref_no = checkUndeNullBlank(tran_id) ? tran_id : '';


    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    data.is_export = 1
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.is_export === 1) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
    });

  }

  const transactiosLIsting = () => {
    let data = {}

    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}`,
      data: data,
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        console.log('ress', res.data)
        setTransactions(res.data)
      }
    }).catch((err) => {
      setLoadertable(false)
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
    });
  }

  useEffect(() => {
    transactiosLIsting();
  }, [])
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }
      return opt_merchants.push(tempObj)
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
    <div className="app-content content overflow-hidden">
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 CourierRemittance content-wrapper border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">COD Remittance Transactions</CardTitle>
              <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
              <Form onSubmit={filtermerchRemitSubmit(onFilterMerchRemit)} className="filter-form">
                <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      {/* <DateRangePicker placeholder="Choose Tran. Date Range" format="DD-MM-YYYY" /> */}
                      <DateRangePicker
                        initialSettings={ranges}
                        onCallback={handleCallback}
                      >
                        {datestate.start !== '' && datestate.end !== '' ?
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                      </DateRangePicker>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("ref_no")} className="form-control-sm form-control" placeholder="Enter Transaction ID" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...filter("pay_status")} className="form-control-sm form-control">
                          <option value="">Payment Status</option>
                          <option value="1">Paid</option>
                          <option value="0">Unpaid</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
            </Collapse>
            <CardBody className="p-0">
              {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                <>
                  <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                    <div className="showing-count">
                      Showing {currentCount} to {(currentCount - 1) + (Object.entries(transactions.data).length)} of {transactions.TotalRows} entries
                    </div>
                    {transactions.TotalRows > dropFilterCount ?
                      <Form>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </FormGroup>
                      </Form>
                      : ''}
                  </div>
                </>
                : ''}
              <Table responsive borderless className="text-center customTable">
                <thead>
                  <tr>
                    <th>Client</th>
                    <th>Transaction ID</th>
                    <th>Transaction Date</th>
                    <th>COD Remittance Amount</th>
                    {/* <th>Total Docket(s)</th> */}
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {checkData(transactions.data) ? transactions.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.MerchantName}</td>
                      <td>{item.ref_no}</td>
                      <td>{changeIntoDateTime(item.trans_date)}</td>
                      <td>{item.credit_amt}</td>
                      {/* <td>{item.MerchantName}</td> */}
                      <td><Button className="btn btn-sm text-nowrap" color="info" outline onClick={() => { exportRemmitance(changeIntoDate(item.trans_date), item.merchant_id, item.ref_no) }}>Download Excel</Button></td>
                    </tr>
                  )) :
                    loadertable ? <tr><td colSpan="8" className="text-center">Loading...</td></tr>
                      : <tr><td colSpan="8" className="text-center">No Data Found</td></tr>}
                </tbody>
              </Table>
              {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                <>
                  {transactions.TotalRows > dropFilterCount ?
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                        Showing {currentCount} to {(currentCount - 1) + (Object.entries(transactions.data).length)} of {transactions.TotalRows} entries
                      </div>
                      <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                          <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                    : ''}
                </>
                : ''}
            </CardBody>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(RemittanceTransactions);