import React, {useState, useEffect} from "react";
import {Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col} from 'reactstrap';
import classnames from 'classnames';
import MerchantDebitNote from "./MerchantDebitNote";

const DebitNote = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
//   const toggleDataAdd = () => setDataAdd(!dataAdd);
  useEffect(() => {
}, []);

    return(
        <div className="content-body">
        <div className="background-strip"></div>
            <Container fluid>
                <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
                    <div class="mb-3 mr-3">
                        <h4 class="text-white font-w600 mb-0">Manage Debit Note</h4>
                    </div>
                    <Nav tabs className="master-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                    <span className="text-white">Merchant Debit Note</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                <span className="text-white">Courier Debit Note</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="master-content card border-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {activeTab === '1' &&
                                        <MerchantDebitNote user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {activeTab === '2' &&
                                        'Courier Debit'
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    )
}
export default DebitNote