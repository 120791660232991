import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
// import axios from 'axios';
import axios from '../../../CommonAxios';
import { useForm } from 'react-hook-form';

const EmailNotification = (props) => {
  const [loader, setLoader] = useState(false);
    const { register: addEmailNotification, handleSubmit: merchantEmailSubmit, reset: emailNotiReset } = useForm();

    useEffect(() => {
        axios({
                method: "post",
                url: `${process.env.REACT_APP_MERCHANT_NOTIFICATION}/view`,
                data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id :props.merchantId !== undefined ? props.merchantId:'' },
            })
            .then(function(response) {
                let myResponse = response.data[0];
                emailNotiReset(myResponse);
            }).catch((err) => {
                Swal.fire({icon:'error',text:err,title:'Error'});
              });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onEmailNotification = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id :props.merchantId !== undefined ? props.merchantId:'');
        formData.append("accounts_email", data.accounts_email);
        formData.append("ndr_email", data.ndr_email);
        formData.append("order_summary_email", data.order_summary_email);
        formData.append("weight_alert_email", data.weight_alert_email);

        axios
            .post(process.env.REACT_APP_MERCHANT_NOTIFICATION, formData)
            .then((res) => {
                setLoader(false);
                if (res.data.error === false) {
                    Swal.fire({
                        title: 'Success',
                        text: res.data.msg,
                        icon: 'success',
                    })
                }
            }).catch((err) => {
                setLoader(false);
                Swal.fire({icon:'error',text:err,title:'Error'});
              });
    };
    return ( 
        <>
      {loader && <div className="formLoader"><Spinner /></div>}
    <Form onSubmit = { merchantEmailSubmit(onEmailNotification)} >
        <div className = "sub-page-header" >
        <CardTitle tag="h4" className="sub-page-title" > Email Notification </CardTitle></div> 
        <Row>
        <Col sm={6}>
        <FormGroup>
        <Label for="acont_mail" > Accounts Mail </Label> 
        <input {...addEmailNotification("accounts_email")} type = "email" className = "form-control" placeholder = "Accounts Mail" id="acont_mail" />
        </FormGroup> 
        </Col> 
        <Col sm={ 6 }>
        <FormGroup>
        <Label for="ndr_mail"> NDR Mail </Label>
         < input {...addEmailNotification("ndr_email")} type = "email" className = "form-control" placeholder = "NDR Mail" id = "ndr_mail" />
        </FormGroup> 
        </Col>
        <Col sm={6}>
        <FormGroup>
        <Label for="order_summary"> Order Summary </Label> 
        <input {...addEmailNotification("order_summary_email")} type = "email" className = "form-control" placeholder = "Order Summary" id = "order_summary" />
        </FormGroup> 
        </Col> 
        <Col sm ={6}>
        <FormGroup>
        <Label for = "weight_alert" > Weight Alert </Label> 
        < input {...addEmailNotification("weight_alert_email")} type = "email" className = "form-control" placeholder = "Weight Alert" id = "weight_alert" />
        </FormGroup> 
        </Col> 
        <Col sm={12} className = "mt-2 text-right">
        <Button className = "btn-sm" color = "primary" > Save changes </Button> 
        </Col>             
        </Row> 
        </Form>
        </>
    );
}

export default EmailNotification;