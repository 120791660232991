import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button, Spinner, FormGroup, Form, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
// import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { checkData } from '../../ObjectExist';

const EditAdmin = (props) => {
    const [loader, setLoader] = useState(false);
    const password = useRef({});


    useEffect(() => {
        reset(props.editData)
        // setCheckboxValue(ModuleList);
    }, [])
    const { register: speakerInput, handleSubmit: speakerSubmit, formState: { errors }, reset, watch } = useForm();
    password.current = watch("password");

    const onAddAdmin = (data) => {
        setLoader(true);
        // data.permissions = checkboxValue
        data.admin_id = checkData(props.user) ? props.user.id : ''
        data.id = props.editData.id

        http.post(`admin/edit`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg, });
                props.setEditmodal(false); props.onApiInteg(); reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    // console.log('props.permission.data', props.permission.data)
    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg" className='addModal'>
                <ModalHeader toggle={props.toggleAdd}>Edit Admin</ModalHeader>
                <ModalBody>
                    <Form onSubmit={speakerSubmit(onAddAdmin)} >
                        <Row className="mx-0  addModal">
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="name">Enter Name<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("name", { required: 'Please Enter Name' })} className="form-control-sm form-control" placeholder="Enter Name*" id="name" />
                                    {errors.name && <span className="text-danger d-block error">{errors.name.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="email">Enter Email<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("email", { required: 'Please Enter Email' })} type="email" className="form-control-sm form-control" placeholder="Enter Email*" id="email" />
                                    {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="password">Enter Password<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("password", { required: 'Please Enter Password' })} type="password" className="form-control-sm form-control" placeholder="Enter Password*" id="password" />
                                    {errors.password && <span className="text-danger d-block error">{errors.password.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="conformpassword">Enter Confirm Password<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("confirm_password", { required: 'Please Enter Confirm Password', validate: value => value === password.current || "Please Enter Same as Password"  })} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password*" id="conformpassword" />
                                    {errors.confirm_password && <span className="text-danger d-block error">{errors.confirm_password.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="permission mb-3">Select Module</Label>
                                    {checkData(props.permission) && checkData(props.permission.data) && Object.keys(props.permission.data).map((key) => {
                                        return (
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label className="mx-2 my-0 form-check-label1 mb-2" >{key}</Label>
                                                        <Row>
                                                            {props.permission.data[key].actions.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Col md={2}>
                                                                            <FormGroup>
                                                                                <div className="form-check d-flex flex-wrap align-items-center">
                                                                                    <input {...speakerInput(`permissions.${key}.${item}`)} type="checkbox" id={key + index + item} className='form-check-input' />
                                                                                    <Label className="mx-2 my-0 form-check-label2" for={key + index + item} >{item}</Label>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </>
                                                                )
                                                            })}
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    )}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormGroup check className="pl-0">
                                        <Label>Status<span className="text-danger">*</span></Label>
                                        <div>
                                            <span className="form-check2 me-3">
                                                <input className="mx-2" {...speakerInput("status", { required: true })} type="radio" value="1" id="active_add" />
                                                <Label for="active_add">Active</Label>
                                            </span>
                                            <span className="form-check2">
                                                <input className="mx-2" {...speakerInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                <Label for="inactive_add">InActive</Label>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            </Col>

                            <Col sm={12} className="text-right">
                                <Button className="vs-btn border-0" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditAdmin