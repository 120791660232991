import React, {useEffect,useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, Spinner} from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { StateList } from '../masters/StateList';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CourierAddressDetails = (props) => {
  const [loader, setLoader] = useState(false);

    
    const { register:addCourier_address, handleSubmit:courierAddressSubmit, formState: { errors: errors2 }, reset:reset2 } = useForm();
  useEffect(() => {
    axios({
        method: "post",
        url: `${process.env.REACT_APP_COURIER_ADDRESSADD}/view`,
        data: {"courier_id":props.courierId !== undefined ? props.courierId:''},
      })
        .then(function (response) {
          let myResponse=response.data[0];
          reset2(myResponse);
        })
        .catch(function (response) {
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reset2])
    const onAddcourierAddress = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("courier_id", props.courierId !== undefined ? props.courierId:'');
        formData.append("address", data.address);
        formData.append("pincode", data.pincode);
        formData.append("city", data.city);
        formData.append("state_id", data.state_id);
        formData.append("contact_number", data.contact_number);
      
        axios
          .post(process.env.REACT_APP_COURIER_ADDRESSADD, formData)
          .then((res) => {
        setLoader(false);
        if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.message,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
        setLoader(false);
        Swal.fire({title:'Error', text:err, icon:'error'});
          });
      };
      const onaddressError = (errors2, e) => console.log('Error', errors2);


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={courierAddressSubmit(onAddcourierAddress,onaddressError)}>
                <Row className="mx-0 pt-3">
                <Col sm={12} md={6} xl={4} className="mb-2">
                    <FormGroup>
                        <Label for="address">Enter Address</Label>
                        <input {...addCourier_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
                        {errors2.address && <span className="text-danger d-block mb-2">{errors2.address.message}</span>}
                    </FormGroup>                      
                </Col>
                <Col sm={12} md={6} xl={4} className="mb-2">
                    <FormGroup>
                        <Label for="city">Enter City</Label>
                        <input {...addCourier_address("city", { required: 'Please Enter City' })}  className="form-control-sm form-control" placeholder="City" id="city" />
                        {errors2.city && <span className="text-danger d-block mb-2">{errors2.city.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={4} className="mb-2">
                    <FormGroup>
                    <Label for="state">Select State</Label>
                    <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...addCourier_address("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state">
                            <option value="">Select State</option>
                            {StateList.map((item, index) => (
                                <option value={item.state_id}>{item.state_name}</option>
                            ))
                        }
                        </select>
                        {errors2.state_id && <span className="text-danger d-block mb-2">{errors2.state_id.message}</span>}
                    </div>
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={4} className="mb-2">
                    <FormGroup>
                        <Label for="pincode">Enter Pincode</Label>
                        <input {...addCourier_address("pincode", { required: 'Please Enter Pincode' })}  className="form-control-sm form-control" placeholder="Pincode" id="pincode"/>
                        {errors2.pincode && <span className="text-danger d-block mb-2">{errors2.pincode.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} xl={4} className="mb-2">
                    <FormGroup>
                        <Label for="contact">Enter Contact Number</Label>
                        <input {...addCourier_address("contact_number", { required: 'Please Enter Contact Number' })}  className="form-control-sm form-control" placeholder="Contact Number" id="contact" />
                        {errors2.contact_number && <span className="text-danger d-block mb-2">{errors2.contact_number.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="text-right">
                    <Button className="btn btn-sm save" color="primary">Save</Button>
                </Col>
                </Row>
            </Form>
        </>
    );
}

export default CourierAddressDetails;
