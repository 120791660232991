import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Button, CardHeader, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Logo from "../../assets/images/logo_new.png";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { encryptStorageLogin } from '../../EncStorage';
import Footer from '../Footer';
const Signin = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    encryptStorageLogin.removeItem('userenc')
    //   }
  }, [])
  const { register: signin, handleSubmit: signinSubmit, formState: { errors } } = useForm();
  const onSignin = (data) => {
    let loginData = { "email": data.email, "password": data.password };
    setLoader(true);
    fetch(`${process.env.REACT_APP_ADMIN_LOGIN}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
      },
      body: JSON.stringify(loginData)
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        if (Object.entries(result).length > 0) {
          if (result.error === false) {
            // localStorage.setItem("user", JSON.stringify(result));
            result.data[0].permissions = JSON.parse(result.data[0].permissions);
            console.log('result', result)
            encryptStorageLogin.setItem('userenc', JSON.stringify(result));
            history.push("/");
          }
        } if (result.error === true) {
          Swal.fire({ icon: 'error', text: "Username Or Password Wrong", title: 'Error' });
        }
      });
    }).catch(err => {
      setLoader(false);
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
    });
  };
  const onError = (errors, e) => console.log('Error', errors);
  return (
    <div className="app-content m-0 p-0 content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <div className="p-0 LoginPage content-wrapper">
            <Row>
              <Col sm={12} lg={12} className="d-lg-flex align-items-center auth-left p-0 auth-bg position-relative">
                <div className="d-lg-flex justify-content-center align-items-center w-100 credintials">
                  {/* <img className="img-fluid" src={loginBg} alt="img" /> */}
                  <Card className="border-0 px-xl-3 mx-auto col-md-6 col-lg-4">
                    <CardHeader className="my-3">
                      <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3"><img className="img-fluid logoimg" src={Logo} alt="img" /></CardTitle></Link>
                    </CardHeader>
                    <CardTitle tag="h4">Welcome</CardTitle>
                    <CardBody className="align-items-center py-3">
                      <p className="card-text">Sign in your account.</p>
                      <Form onSubmit={signinSubmit(onSignin, onError)}>
                        <Row>
                          <Col sm={12}>
                            <FormGroup>
                              <Label><strong>Email</strong></Label>
                              <input {...signin("email", { required: 'Please Enter Email' })} type="email" class="form-control" placeholder="Enter Email"></input>
                              {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <Label><strong>Password</strong></Label>
                              <input {...signin("password", { required: 'Please Enter Password' })} type="password" class="form-control" placeholder="Enter Password"></input>
                              {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <Button className="btn-sm w-100" color="primary"> Sign in</Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                    <Footer />
                  </Card>
                </div>
                <div className="bg-overlay bg-primary"></div>
                <ul className="bg-bubbles">
                  <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                </ul>
              </Col>
            </Row>

          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Signin