import React, {useState, useRef} from 'react';
import { Row, Col, Button, FormGroup,Form, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';

import Swal from 'sweetalert2';
import { checkData } from '../../ObjectExist';
const AddMerchant = (props) => {
  const [logo, setLogo] = useState();

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
}
 


  const { register:addMerchant, handleSubmit:MerchantSubmit,watch:watchAddpswd, formState: { errors } } = useForm();
 
  const password = useRef({});
  password.current = watchAddpswd("password", "");
  const onAddMerchantSubmit = (data) => {
    let formData = new FormData();
    formData.append("admin_id", checkData(props.user[0]) ? props.user[0].id : '');
    formData.append("company_name", data.company_name);
    formData.append("brand_name", data.brand_name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("billing", data.billing);
    formData.append("freight_type", data.freight_type);
    formData.append("status", data.status);
    formData.append("mobile", data.mobile);
    formData.append("wallet_balance", data.wallet_balance);
    formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
    formData.append("max_cod_value_per_order", data.max_cod_value_per_order);
    formData.append("remittance_tat_period", data.remittance_tat_period);
    formData.append("refer_by", data.refer_by);
    formData.append("logo", logo);
  
    axios
      .post(process.env.REACT_APP_ADD_MERCHANT, formData)
      .then((res) => {
        if(res.data.error === false){
            props.setAddModal(false);
            props.tableDataFetch();
          Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }
      })
      .catch((err) => Swal.fire({title: 'Error', text: err, icon: 'error',}));
  };
    const onError = (errors, e) => console.log('Error', errors);
  return (
        <Form onSubmit={MerchantSubmit(onAddMerchantSubmit,onError)}>
        <Row className="mx-0 justify-content-center addModal">
            <Col sm={6}>
                <FormGroup>
                <Label for="companyName_add">Enter Company Name</Label>
                <input {...addMerchant("company_name",{ required: 'Please Enter Company Name'})} className="form-control-sm form-control" placeholder="Enter Company Name*" id="companyName_add" />
                {errors.company_name && <span className="text-danger d-block mb-2">{errors.company_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="email_add">Enter Email</Label>
                <input {...addMerchant("email",{ required: 'Please Enter Email'})} type="email" className="form-control-sm form-control" placeholder="Enter Email*"  id="email_add"/>
                {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="brandName_add">Enter Stores Name</Label>
                <input {...addMerchant("brand_name",{ required: 'Please Stores Name'})} className="form-control-sm form-control" placeholder="Enter Stores Name*" id="brandName_add" />
                    {errors.brand_name && <span className="text-danger d-block mb-2">{errors.brand_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="contact_add">Enter Phone Number</Label>
                <input {...addMerchant("mobile",{ required: 'Please Enter Phone Number'})} type="number" className="form-control-sm form-control" placeholder="Enter Phone Number*" id="contact_add" />
                    {errors.mobile && <span className="text-danger d-block mb-2">{errors.mobile.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="walletBalance_add">Enter Wallet Balance</Label>
                <input {...addMerchant("wallet_balance",{ required: 'Please Wallet Balance'})} type="number" className="form-control-sm form-control" placeholder="Enter Wallet Balance*" id="walletBalance_add" />
                    {errors.wallet_balance && <span className="text-danger d-block mb-2">{errors.wallet_balance.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="maxLiabShip_add">Enter Max Liability Shipment</Label>
                <input {...addMerchant("max_liability_per_shipment",{ required: 'Please Enter Max Liability Shipment'})} type="number" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiabShip_add" />
                    {errors.max_liability_per_shipment && <span className="text-danger d-block mb-2">{errors.max_liability_per_shipment.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
                    <input {...addMerchant("remittance_tat_period",{ required: 'Please Enter Remittance TAT Period'})} className="form-control-sm form-control" placeholder="Enter Remittance TAT Period*" defaultValue="5" id="remittance_tat_period_add" />
                    {errors.remittance_tat_period && <span className="text-danger d-block error">{errors.remittance_tat_period.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="refer_by_add">Refer By</Label>
                <input {...addMerchant("refer_by")} className="form-control-sm form-control" placeholder="Enter Refer By" id="refer_by_add" />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
                    <input {...addMerchant("max_cod_value_per_order",{pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Max COD Value'}})} type="text" className="form-control-sm form-control" placeholder="Enter Max COD Value*" id="maxCodVal_add" />
                    {errors.max_cod_value_per_order && <span className="text-danger d-block error">{errors.max_cod_value_per_order.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="password_add">Enter Password</Label>
                <input {...addMerchant("password", { required: 'Please Enter Password'})} type="password" className="form-control-sm form-control" placeholder="Enter Password*" id="password_add" />
                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="confirmpass_add">Enter Confirm Password</Label>
                <input {...addMerchant("confirmPassword", { required: 'Please Enter Confirm', validate: value => value === password.current || "The passwords do not match"})} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password*" id="confirmpass_add" />
                    {errors.confirmPassword && <span className="text-danger d-block mb-2">{errors.confirmPassword.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="uploadLogo_add">Upload Stores logo</Label>
                <input {...addMerchant("brandlogo", { required: 'Please Add Logo'})} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                    {errors.brandlogo && <span className="text-danger d-block mb-2">{errors.brandlogo.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                <Label>Billing</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="2" id="preBill_add" defaultChecked />
                        Prepaid Billing
                        </Label> 
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="1" id="postBill_add" />
                        Postpaid Billing
                        </Label>
                    </span>
                    </div>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                    <Label>Freight Type</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2"  {...addMerchant("freight_type", { required: true })} type="radio" value="1" id="fixCharge_add" defaultChecked />
                        Fixed Charge
                        </Label>
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...addMerchant("freight_type", { required: true })} type="radio" value="2" id="varCharge_add" />
                        Variables Charge
                        </Label>
                    </span>
                    </div>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                <Label>Status</Label>
                <div>
                    <span className="mr-2">
                    <Label check>
                        <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                        Active
                    </Label>
                    </span>
                    <span>
                    <Label check>
                        <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="0" id="inactive_add" />
                        InActive
                    </Label>
                    </span>
                </div>
                </FormGroup>
            </Col>
            <Col sm={12} className="text-right">
            <Button className="btn btn-sm" color="primary">Save</Button>
            </Col>
        </Row>
        </Form>  
                
  );
}

export default AddMerchant;