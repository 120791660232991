import React, {useEffect,useState} from 'react';
import {Card, Button, FormGroup,CardBody, Table, CardHeader,CardTitle, Form, Row, Col, Spinner } from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import { MultiSelect, } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { delivered_order_status, pending_order_status, rto_order_status, download_delay } from '../../../../config';
import { changeIntoDate,changeIntoDateYmd, errorHandling } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import NodataFound from '../../../NodataFound';
// import LoaderDefault from '../../../LoaderDefault';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const ShipmentsSummary = (props) => {
    const [merchants, setMerchants] = useState([]);
    const [allCourier, setAllCourier] = useState([]);
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [filterDate, setFilterDate] = useState([]);
    const history = useHistory();  
    const [datestate, setDateState] = useState({start: moment(),end: moment(),});
    const { start, end } = datestate;
    const handleCallback = (start, end) => {
        setDateState({ start, end });
      };
    const ranges = {
        startDate: start !== '' && start.toDate(),
        endDate: end !== '' && end.toDate(),
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
          ],
          'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
        },
      }
    const merchantsMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select Merchant"
    }
    var opt_merchants = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
        Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
        let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
        let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
        let tempObj = {} 
        if(merchantName && merchantId){
            tempObj = {label: merchantName, value: merchantId }
            opt_merchants.push(tempObj)   
        }
        return
        });
    }

    const allCourierMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select Couriers"
    }
    var opt_allCourier = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
        Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
        let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
        return opt_allCourier.push(tempObj)
        });
    }

    const listingPerformance = () => {
        setDateState({start: moment(),end: moment(),});
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
        setLoadertable(true);
        let data = {"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : ''}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMIN_PERFORMANCE_OVERVIEW}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            if(String(err) === "Error: Network Error"){
                Swal.fire({icon:'error',html:'Network Error<br/>Please reload and try again!',title:'Error'});
            }else{
                Swal.fire({icon:'error',text:err,title:'Error'});
            }
          });
    }
    useEffect(() => {
        listingPerformance();
    }, []);
    function findPendingCount(count) {
          const pendingDatavalue = pending_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findRtoCount(count) {
          const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findDeliveredCount(count) {
          const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
      let totalBooked = 0
      let totalDelivered = 0
      let totalPending = 0
      let totalRtoPending = 0

    let performanceStatus = checkData(performanceOverviews) && Object.values(performanceOverviews.reduce((osi, item) => {
        let shipmentDate = changeIntoDate(item.dated)
        if (!osi[shipmentDate])
        osi[shipmentDate] = {date: shipmentDate, shipmentDelivered: [], shipmentPending: [], shipmentRto: []};
        let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
        let pending = checkUndeNullBlank(findPendingCount(item.order_status_id)) ? item.total : 0;
        let rto=checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        osi[shipmentDate].shipmentDelivered.push(delivered);
        osi[shipmentDate].shipmentPending.push(pending);
        osi[shipmentDate].shipmentRto.push(rto);
        
        totalDelivered += delivered;
        totalPending += pending;
        totalRtoPending += rto;
        totalBooked = totalDelivered + totalPending + totalRtoPending
        return osi;
    }, {}));

    let totalDeliveredPercent = (totalDelivered / totalBooked ) * 100
    let totalPendingPercent = (totalPending / totalBooked ) * 100
    let totalRtoPendingPercent = (totalRtoPending / totalBooked ) * 100

    const exportShipment = (date,total) => {
        var orderstatusids = [];
        var dates = date.split("-");
        var dates = dates[2]+"-"+dates[1]+"-"+dates[0];
        var orderstatusids = orderstatusids.concat(delivered_order_status, pending_order_status, rto_order_status);
        let data = {"all_shipment":1,"start_date":checkUndeNullBlank(date) ? dates : '',"end_date":checkUndeNullBlank(date) ? dates : ''}
        
        data.order_status_ids = checkData(orderstatusids) ? orderstatusids : '';
        data.total = checkUndeNullBlank(total) ? total : "";

        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id = props.user[0].id
        }else{
            data.merchant_id = props.user[0].id
        }
        data.is_export=1
        setLoader(true);
        axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
        .then((res) => {
        if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
            if(parseInt(res.data.errno)>0){
                    setLoader(false);
                    if(res.data.sqlMessage!==undefined){
                    Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
                }
            }else{
                if(res.data.is_export === 1){
                    setTimeout(function(){ 
                      setLoader(false);
                      history.push("/my-downloads"); 
                    },download_delay);
                }
            }
        }}).catch((err) => {
            errorHandling(err,"")
        });
        
    }
    const { register:filter, handleSubmit:filterShipmentSummarySubmit, reset, } = useForm();

    const onFilterShipmentSummary = (data) => {
        if(checkData(allCourier) || checkData(merchants) || checkData(datestate)){
            onCommonFilter(data)
        }
    };

    const onCommonFilter = (data) => {
        let byData = {}
        let courierId = [];
        let merchantsId = [];
        if(checkData(allCourier)){
          allCourier.map((item) => (
            courierId.push(item.value)
          )); 
        } 
        if(checkData(merchants)){
          merchants.map((item) => (
            merchantsId.push(item.value)
          )); 
        } 
        if(checkUndeNullBlank(courierId)){
          byData.courier_id=courierId
        }
        setLoader(true);
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");

        byData={"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'',"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : ''}
        
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
          byData.admin_id = props.user[0].id
        }else{
          byData.merchant_id = props.user[0].id
        }
          axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMIN_PERFORMANCE_OVERVIEW}`,
            data: byData,
          }).then((res) => {
            setLoader(false);
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            errorHandling(err,"search")
            setLoader(false);
          });
    };

    return (
        <div className="ShipmentsSummary">
            {loader && <div className="formLoader"><Spinner /></div>} 
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Shipments Summary</CardTitle>  
                </CardHeader> 
                <Form onSubmit={filterShipmentSummarySubmit(onFilterShipmentSummary)} className="filter-form mb-3 mb-sm-0">   
                    <Row className="mx-0 justify-content-start justify-content-md-center">
                        <Col md={3} sm={6}>
                            <FormGroup>
                                {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Date Range" format="DD-MM-YYYY" showOneCalendar={props.oneCalendar} /> */}
                                <DateRangePicker
                                    initialSettings={ranges}
                                    onCallback={handleCallback}
                                >
                                {datestate.start !== '' && datestate.end !== '' ? 
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                    {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                </div>
                                :
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                    Select Date
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                </div>
                                }
                                </DateRangePicker>
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6}>
                            <FormGroup>
                                <MultiSelect 
                                    options={opt_merchants}
                                    value={merchants}
                                    onChange={setMerchants}
                                    labelledBy="Select Merchant"
                                    overrideStrings={merchantsMultiSelectSettings}
                                    />
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6}>
                            <FormGroup>
                                <MultiSelect 
                                    options={opt_allCourier}
                                    value={allCourier}
                                    onChange={setAllCourier}
                                    labelledBy="All Couriers"
                                    overrideStrings={allCourierMultiSelectSettings}
                                    />
                            </FormGroup>
                        </Col>
                        <Col sm={3} className="btn-container">
                            <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                        </Col>
                    </Row>
                </Form>
                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Month Till Date</th>
                                <th className="text-nowrap">Shipments Booked (Total)</th>
                                <th className="text-nowrap">Delivered</th>
                                <th className="text-nowrap">Pending</th>
                                <th className="text-nowrap">Return to Origin</th>
                            </tr> 
                            <tr>
                                <th>Total</th>
                                <th><div className="text-left mx-auto dowbload-con d-flex justify-content-center">{totalBooked}{totalBooked > 0 && <span className="download-icon mx-1" onClick={()=>{exportShipment("",totalBooked)}}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                <th>{totalDelivered} <span className="text-success">({totalDeliveredPercent.toFixed(2)}%)</span></th>
                                <th>{totalPending} <span>({totalPendingPercent.toFixed(2)}%)</span></th>
                                <th>{totalRtoPending} <span className="text-danger">({totalRtoPendingPercent.toFixed(2)}%)</span></th>
                            </tr>                           
                        </thead>
                        <tbody> 
                        {checkData(performanceStatus) ? performanceStatus.reverse().map((item, index) => {
                            let todayTotal = eval(item.shipmentDelivered.join('+'))+eval(item.shipmentRto.join('+'))+eval(item.shipmentPending.join('+'));
                            let todayDelivered = eval(item.shipmentDelivered.join('+'));
                            let todayPending = eval(item.shipmentPending.join('+'));
                            let todayRto = eval(item.shipmentRto.join('+'));
                            let todayDeliveredPercent = todayDelivered > 0 ? (todayDelivered / todayTotal ) * 100 : 0;
                            let todayPendingPercent = todayPending > 0 ? (todayPending / todayTotal ) * 100 : 0;
                            let todayRtoPercent = todayRto > 0 ? (todayRto / todayTotal ) * 100 : 0;
                            return(<tr>
                                <td>{item.date}</td>
                                <td><div className="text-left mx-auto dowbload-con d-flex justify-content-center">{todayTotal}{todayTotal > 0 && <span className="download-icon mx-1" onClick={()=>{exportShipment(item.date, todayTotal)}}><FontAwesomeIcon icon={faDownload} /></span>}</div></td>
                                <td>
                                    <div className="d-flex justify-content-center">
                                        <span className="d-flex flex-column w-50">
                                            <span>{todayDelivered}</span>
                                            <span className="text-success"><small className="font-weight-500">{todayDeliveredPercent.toFixed(2)}%</small></span>
                                        </span>
                                        <span className="w-50"><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>(0)</span>
                                    </div>
                                </td>
                                <td>{todayPending}<br /> <span><small className="font-weight-500">{todayPendingPercent.toFixed(2)}%</small></span></td>
                                <td>
                                    <div className="d-flex justify-content-center">
                                        <span className="w-50">{todayRto}</span>
                                        <span className="text-success"><small className="font-weight-500">{todayRtoPercent.toFixed(2)}%</small></span>
                                        <span className="w-50"><small className="text-danger mx-1"><FontAwesomeIcon icon={faArrowDown} /></small>(0)</span>
                                    </div>
                                </td>
                            </tr>)
                            }) :
                            loadertable ? <tr>
                            <td colSpan="5">Loading...</td>
                            </tr>
                            :<tr><td colSpan="5" className="text-center">No Data Found</td></tr>
                        }   
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}

function mapDispatchToProps (state) {
    return {
      state:state.DataReducer
    }
}

export default connect(mapDispatchToProps)(ShipmentsSummary);