import React from 'react';
import { Alert,Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

const Notfound = () => {
  return (
  <>
    <div className="content-body content overflow-hidden">
      <Row>
          <Col sm="6" className="m-auto text-center">
              <Alert color="warning">
                  <h4 className="alert-heading">OOPS <FontAwesomeIcon icon={faExclamation} /></h4>
                  <p>
                  404 - Page Not Found!
                  </p>
              </Alert>
          </Col>
      </Row>
    </div>
  </>
  );
}

export default Notfound;