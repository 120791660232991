import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle,CardHeader, Table, Spinner} from 'reactstrap';
import { ArrowLeft, Download} from 'react-feather';
import {useHistory, useParams} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import {errorHandling, changeIntoDate, changeIntoDateYmd} from '../../utlis'; 
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ToWords } from 'to-words';
import { download_delay, creditNote_head_id } from '../../config';
import moment from 'moment';
const MerchantCreditNoteDatail = (props) => {
  const [creditNoteDetail,setCreditNoteDetail] = useState({});
  const [loader, setLoader] = useState(false);
  const {creditNoteId} = useParams();
  const toWords = new ToWords();
  useEffect(()=> {
    viewCreditNoteDetail();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[creditNoteId])
  const viewCreditNoteDetail = () =>{
    setLoader(true);
    let data = {}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data={"credit_note_id":creditNoteId,"admin_id":1}
    }
    axios.post(process.env.REACT_APP_MERCHANT_CREDIT_NOTE_DETAIL, data)
    .then((res)=>{
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setCreditNoteDetail(res.data.data);
        }
      }
    })
    .catch((err)=>{
      setLoader(false)
      errorHandling(err,"reload");
    });
  }


  let history = useHistory();
  const goToPreviousPath = () => {
      history.goBack()
  }
  const exportCreditNotePDF = () => {
      let data = {"credit_note_id":creditNoteId}
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      setLoader(true);
      axios.post(process.env.REACT_APP_MERCHANT_INVOICE_DOWNLOAD_PDF, data).then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
            setLoader(false);
            if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.error === false){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"");
    });
  }
  const exportCreditExcel = () => {
    let data = {}    
    data.credit_note_id = checkUndeNullBlank(creditNoteId) ? creditNoteId : '';

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios.post(process.env.REACT_APP_MERCHANT_CREDIT_NOTE_DOWNLOAD, data)
    .then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
                setLoader(false);
                if(res.data.sqlMessage!==undefined){
                Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
        }else{
            if(res.data.error === false){
                setTimeout(function(){ 
                  setLoader(false);
                  history.push("/my-downloads"); 
                },download_delay);
            }
        }
    }}).catch((err) => {
        setLoader(false);
        errorHandling(err,"")
    });
  }

  const isNotLostShipment = checkUndeNullBlank(creditNoteDetail.invoice_data) && checkUndeNullBlank(creditNoteDetail.invoice_data[0].head_id) && creditNote_head_id[creditNoteDetail.invoice_data[0].head_id] !== 'Shipment Lost';

  return (
    <div className="content-body overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 creditNoteDetail shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="mb-0 page-title">Credit Note Details</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <div onClick={exportCreditNotePDF} className="btn-text btn-light d-none"><span className="icon"><Download /></span><span>Download PDF</span></div>         
                <div onClick={exportCreditExcel} className="btn-text btn-light"><span className="icon"><Download /></span><span>Download Excel</span></div>         
                <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Credit Note</span></div>         
              </div>
            </CardHeader>            
            <CardBody className="pt-0">
            {checkData(creditNoteDetail.invoice_data) ?
            <div className='creditNoteDtl-table-con'>
              <Table className='invoice-table custom-table mb-0 border-bottom-0' bordered>
                <thead>
                  <tr>
                    <th>
                      <div className="logo-wrapper">
                        <img src={Logo} alt="logo" style={{maxWidth:'150px'}} />
                      </div>
                    </th>
                    <th>
                      <h3>Credit Note</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3} className="col-4">
                      <div>
                        <div className="ivc_box">
                          <span className="ivc_ttl d-block">{checkData(creditNoteDetail.company) && creditNoteDetail.company.name}</span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.address}</span>
                        </div>
                        <div className="ivc_box">
                          <span className="ivc_ttl mr-1">GSTIN/UIN : </span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.GSTIN}</span>
                        </div>
                        <div className="ivc_box">
                          <span className="ivc_ttl mr-1">State Name : </span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.state},</span>                          
                          <span className="ivc_ttl mr-1">CODE : </span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.code}</span>                          
                        </div>
                        <div className="ivc_box">
                          <span className="ivc_ttl mr-1">PAN No. : </span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.pan}</span>                          
                        </div>
                        <div className="ivc_box">
                          <span className="ivc_ttl mr-1">CIN : </span>
                          <span className="ivc-text">{checkData(creditNoteDetail.company) && creditNoteDetail.company.CIN}</span>                          
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Credit Note No.</span>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].credit_note_no}</span>                          
                      </div>
                    </td>
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Credit Note Date</span>
                        <span className="ivc-text">{changeIntoDate(creditNoteDetail.invoice_data[0].credit_note_date)}</span>                          
                      </div>
                    </td>
                  </tr>
                  <tr>                    
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Reverse Charge</span>
                        <span className="ivc-text">N</span>                          
                      </div>
                    </td>
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Due Date </span>
                        <span className="ivc-text">{changeIntoDate(creditNoteDetail.invoice_data[0].due_date)}</span>                          
                      </div>
                    </td>
                  </tr>
                  <tr>                    
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Mode of Payment</span>
                        <span className="ivc-text">{checkData(creditNoteDetail.invoice) && creditNoteDetail.invoice.mode}</span>                          
                      </div>
                    </td>
                    <td>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Payment Terms</span>
                        <span className="ivc-text">{checkData(creditNoteDetail.invoice) && creditNoteDetail.invoice.payment_terms}</span>                          
                      </div>
                    </td>
                  </tr>  
                  <tr className='bg-light'>
                    <th><div>Details of Receiver (Billed to)</div></th>
                    <th colSpan={2}><div>Details of Consignee (Shipped to)</div></th>
                  </tr>                
                  <tr>
                    <td>
                      <div style={{minHeight:"100px"}}>
                        <span className="ivc_ttl d-block">{creditNoteDetail.invoice_data[0].company_name}</span>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].contact_number}</span><br/>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].address}</span><br/>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].city}</span>,
                        <span className="ivc-text">{checkUndeNullBlank(creditNoteDetail.state_name) && creditNoteDetail.state_name}</span>,
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].pincode}</span><br/>
                        <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].gst_number}</span><br/>
                        <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].pan_card_no}</span>
                      </div>                                                    

                    </td>
                    <td colSpan={2}>
                      <div style={{minHeight:"100px"}}>
                        <span className="ivc_ttl d-block">{creditNoteDetail.invoice_data[0].company_name}</span>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].contact_number}</span><br/>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].address}</span><br/>
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].city}</span>,
                        <span className="ivc-text">{checkUndeNullBlank(creditNoteDetail.state_name) && creditNoteDetail.state_name}</span>,
                        <span className="ivc-text">{creditNoteDetail.invoice_data[0].pincode}</span><br/>
                        <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].gst_number}</span><br/>
                        <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].pan_card_no}</span>
                      </div>
                    </td>
                  </tr>               
                </tbody>
              </Table>
              <Table className='invoice-table custom-table align-top mb-0' bordered>                
                <tbody>                   
                  <tr className='bg-light'>
                    <th className='col-1'>S No.</th>
                    <th className='col-4'>Particulars</th>
                    {isNotLostShipment &&
                    <th className='col-2'>GST Rate</th>}
                    <th className='col-2'></th>
                    <th className='col-3'>Amount (₹)</th>
                  </tr>  
                  <tr>
                    <td rowSpan={2}>
                      <div>1</div>       
                    </td>
                    <td rowSpan={2}>
                      <div>Forward and RTO Freight</div>
                    </td>
                    {isNotLostShipment &&
                    <td rowSpan={2}>
                      <div>{checkData(creditNoteDetail.invoice) && creditNoteDetail.invoice.gst_rate}</div>
                    </td>}
                    <td></td>
                    <td>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].sub_total}</td>
                  </tr>             
                  <tr>
                    <th>
                      <span className="ivc_ttl d-block">Assessable Value</span>
                      {checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].state_id !== 86 ?
                        isNotLostShipment && <span className="ivc_ttl d-block">IGST(18%)</span>
                        :
                        <>
                          {isNotLostShipment && <span className="ivc_ttl d-block">CGST(9%)</span>}
                          {isNotLostShipment && <span className="ivc_ttl d-block">SGST(9%)</span>}
                        </>}
                    </th>
                    <th>
                      <span className="ivc_ttl d-block">{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].sub_total}</span>
                      {checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].state_id !== 86 ?
                      <span className="ivc_ttl d-block">{isNotLostShipment && creditNoteDetail.invoice_data[0].total_gst.toFixed(2)}</span>
                      :
                      <>
                      <span className="ivc_ttl d-block">{isNotLostShipment && (creditNoteDetail.invoice_data[0].total_gst/2).toFixed(2)}</span>
                      <span className="ivc_ttl d-block">{isNotLostShipment && (creditNoteDetail.invoice_data[0].total_gst/2).toFixed(2)}</span>
                      </>}
                    </th>
                  </tr>             
                  <tr className='bg-light'>
                    <th colSpan={isNotLostShipment ? 4 : 3} className="text-center">Total Credit Note Value (INR)</th>
                    <th className="text-left">{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].net_amt}</th>
                  </tr>             
                  <tr>
                    <td colSpan={isNotLostShipment ? 4 : 3}>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-block">Amount Chargeable (in words) </span>
                        <span className="ivc-text">{checkUndeNullBlank(creditNoteDetail.invoice_data) && toWords.convert(creditNoteDetail.invoice_data[0].net_amt, { currency: true, ignoreZeroCurrency: true })} /-</span>                          
                      </div>
                    </td>
                    <td>E. & E.O</td>
                  </tr>            
                </tbody>
              </Table>
              <Table className='invoice-table custom-table align-top' bordered>                
                <tbody>  
                  {isNotLostShipment && <>              
                  <tr className='bg-light'>
                    <th className='col-4 text-center' rowSpan={isNotLostShipment ? 2 : ''}>HSN/SAC</th>
                    <th className='col-2 text-center' rowSpan={isNotLostShipment ? 2 : ''}>Assessable Value</th>
                    {isNotLostShipment && <th colSpan={3} className="text-center">Rate @ {checkData(creditNoteDetail.invoice) && creditNoteDetail.invoice.gst_rate}</th>}
                  </tr>
                  {isNotLostShipment &&  
                  <tr>
                    <th>CGST </th>  
                    <th>SGST</th>  
                    <th>IGST</th>  
                  </tr>}
                  <tr>
                    <td>{checkData(creditNoteDetail.invoice) && creditNoteDetail.invoice.HSN}</td>  
                    <td>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].sub_total}</td>
                    {isNotLostShipment && <>
                    <td>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].state_id === 86 ? (creditNoteDetail.invoice_data[0].total_gst/2).toFixed(2) : 0}</td>  
                    <td>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].state_id === 86 ? (creditNoteDetail.invoice_data[0].total_gst/2).toFixed(2) : 0}</td>  
                    <td>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].state_id !== 86 ? creditNoteDetail.invoice_data[0].total_gst.toFixed(2) : 0}</td>
                    </>}  
                  </tr>      
                  <tr>
                    <th className='text-right'>Total</th>  
                    <th>{checkUndeNullBlank(creditNoteDetail.invoice_data) && creditNoteDetail.invoice_data[0].net_amt}</th>  
                    <td colSpan={3}></td>   
                  </tr>      
                  <tr>
                    <td colSpan={5}>
                      <div className="ivc_box">
                        <span className="ivc_ttl">Tax Amount (in words) : </span>
                        <span className="ivc-text">{checkUndeNullBlank(creditNoteDetail.invoice_data) && toWords.convert(creditNoteDetail.invoice_data[0].total_gst, { currency: true, ignoreZeroCurrency: true })} /-</span>                          
                      </div>  
                    </td>     
                  </tr></>}      
                  <tr className='bg-light'>
                    <td colSpan={isNotLostShipment && 2} className={!isNotLostShipment ? 'col-6' : ''}>
                      <div className="ivc_box">
                        <span className="ivc_ttl">Declaration : </span> 
                      </div>                     
                    </td>     
                    <td colSpan={isNotLostShipment && 3} className={!isNotLostShipment ? 'col-6' : ''}>
                      <div className="ivc_box">
                        <span className="ivc_ttl">Company Bank Details : </span>                         
                      </div>  
                    </td>     
                  </tr>      
                  <tr>
                    <td colSpan={isNotLostShipment && 2} rowSpan={2}>
                      <div className="ivc_box">
                        {/* <span className="ivc_ttl d-block">Declaration : </span> */}
                        <div>
                          <span className="ivc-text d-block">1. We declare that this credit note shows the actual price of service described and that all particulars are true and correct.</span>                          
                          <span className="ivc-text d-block">2. Any discrepancy found in the credit note must be raised within 7 working days of receipt of this credit note and subject to realisation of balance undisputed amount .</span>                          
                          <span className="ivc-text d-block">3. This is computer generated credit note and does not require any stamp or signature</span>                          
                        </div>
                      </div>  
                    </td>     
                    <td colSpan={isNotLostShipment && 3}>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-inline-block" style={{minWidth:'120px'}}>Bank Name : </span>
                        {/* <span className="ivc-text">Induslnd Bank</span>*/}
                        <span className="ivc-text">{checkData(creditNoteDetail.bank_details) && creditNoteDetail.bank_details.name}</span>                          
                      </div>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-inline-block" style={{minWidth:'120px'}}>A/c No. : </span>
                        {/* <span className="ivc-text">201001656627</span>*/}
                        <span className="ivc-text">{checkData(creditNoteDetail.bank_details) && creditNoteDetail.bank_details.account_no}</span>                          
                      </div>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-inline-block" style={{minWidth:'120px'}}>Branch & IFSC : </span>
                        {/* <span className="ivc-text">Mohan Co-Operate</span>  */}
                        <span className="ivc-text">{checkData(creditNoteDetail.bank_details) && creditNoteDetail.bank_details.branch}</span>                          
                      </div>
                      <div className="ivc_box">
                        <span className="ivc_ttl d-inline-block" style={{minWidth:'120px'}}>Code : </span>
                        {/* <span className="ivc-text">INDB0000735</span>*/}
                        <span className="ivc-text">{checkData(creditNoteDetail.bank_details) && creditNoteDetail.bank_details.Code}</span>                          
                      </div>
                    </td>     
                  </tr>  
                  <tr>
                    <td className='text-right' colSpan={isNotLostShipment && 3}>
                      <div className="ivc_box">
                        <span className='signature d-block' style={{minHeight:"40px"}}></span>
                        <span className="ivc-text">Authorised Signatory</span>                          
                      </div>
                    </td>
                  </tr> 
                  <tr>
                    <td colSpan={5} className="text-center">
                    <span className="ivc-text">**This is computer generated credit note**</span>
                    </td>  
                  </tr>   
                </tbody>
              </Table>
              </div>
              :loader?<div className="w-100 text-center"><Spinner /></div> :''} 
            </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default MerchantCreditNoteDatail;