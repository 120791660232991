import React, { useState, useEffect }from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup,Collapse, Form, InputGroup,Input, InputGroupAddon, InputGroupText, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown , faArrowDown, faArrowUp,faChevronRight, faDownload, faChevronLeft, faFilter} from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import { connect } from 'react-redux';
import { ChevronLeft, ChevronRight, Copy} from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
// import LoaderDefault from '../LoaderDefault';
// import NodataFound from '../NodataFound';
import { useForm } from "react-hook-form";
import { useHistory, Link } from 'react-router-dom';
import { errorHandling, remExtraSpace , changeIntoDateYmd, firstDayOftheMonth, copyToClipBoard, changeIntoDateTime, moreText} from '../../utlis';
import { download_delay,weight_mismatch_status } from '../../config';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';

const WeightMismatch = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [Status, setStatus] = useState([]);
  const [searchValue, setSearchValue] = useState(' ');
  const [weightMismatch, setWeightMismatch] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [filterDate, setFilterDate] = useState([new Date(),new Date()]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropFilterCount = 20;
  const history = useHistory();  
  const [datestate, setDateState] = useState({start: moment().subtract(30, 'days'),end: moment(),});
  
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
      setDateState({ start, end });
  };
  const ranges = {
      startDate: start !== '' && start.toDate(),
      endDate: end !== '' && end.toDate(),
      ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
          moment().subtract(1, 'days').toDate(),
          moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
          moment().subtract(6, 'days').toDate(),
          moment().toDate(),
      ],
      'Last 30 Days': [
          moment().subtract(29, 'days').toDate(),
          moment().toDate(),
      ],
      'This Month': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
      ],
      'Last Month': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
      ],
      },
  }
  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetWghtMtchfile();
    if(!excelmodal){setExcelFile("");}
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
      Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {} 
      if(merchantName && merchantId){
          tempObj = {label: merchantName, value: merchantId }
          opt_merchants.push(tempObj)   
      }
      return true;
      });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const opt_Status = [
    { label: "Discrepency", value: 1 },
    { label: "Dispute Raised", value: 2 },
    { label: "Accepted by Courier", value: 3 },
    { label: "Rejected by Courier", value: 4 },
    { label: "Auto Accepted", value: 5 },
    { label: "Accepted by Merchant", value: 6 },
  ];
  const StatusMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Status"
  }
  console.log("weightMismatch calling 123123");

  useEffect(() => {
    console.log("weightMismatch calling");
    mismatchWeightListing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])


  const mismatchWeightListing = () => {
    setIsExport(false);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1);
    setFilterDate([firstDayOftheMonth(),new Date()]);setMerchants([]);setSearchValue(' ');
    setDateState({start: moment().subtract(30, 'days'),end: moment(),});
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    reset();
    let data = {"start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    
    setLoader(true);
  
    axios.post(process.env.REACT_APP_WEIGHT_MISMATCH_LIST, data)
    .then((res) => {
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setWeightMismatch(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"reload");
    });
  }


  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
    if(checkData(weightMismatch.data)&&checkUndeNullBlank(weightMismatch.totalPage)){
        if(weightMismatch.totalPage > page){
          setCurrentCount(currentCount+Object.entries(weightMismatch.data).length)
      setPage(page+1)
      setLoadertable(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(weightMismatch.data)
  if(currentCount>Object.entries(weightMismatch.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register:filter, handleSubmit:filterWeightMismatchSubmit,reset, } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onFilterWeightMismatch = (data,pagePerpage) => {
    setIsExport(true);
    pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(Status) || checkData(merchants) || checkData(datestate)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  };
  const onCommonFilter = (data,pagePerpage) => {
    let byData = {}
    let statusId = [];
    let merchantsId = [];
    if(checkData(Status)){
      Status.map((item) => (
        statusId.push(item.value)
      )); 
    } 
    if(checkData(merchants)){
      merchants.map((item) => (
        merchantsId.push(item.value)
      )); 
    } 
    setLoader(true)

    let searchVal = checkUndeNullBlank(data.search_value)?remExtraSpace(data.search_value):'';
    let sId = checkUndeNullBlank(statusId)?statusId:'';
    let mId = checkUndeNullBlank(merchantsId)?merchantsId:'';
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page
    let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage

    byData={"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":searchVal,"status_ids":sId,"merchant_ids":mId,"start_date":dateStart,"end_date":dateEnd,}
   
    setFilterInput(byData);
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }else{
      byData.merchant_id = props.user[0].id
    }
    
      axios({
        method: "post",
        url: `${process.env.REACT_APP_WEIGHT_MISMATCH_LIST}?page=${pageNumber}&per_page=${perPageCount}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data.error === false){
          setWeightMismatch(res.data);
        }
        else{
          Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
        }
      }).catch((err) => {
        errorHandling(err,"")
        setLoader(false);
      });
  };

  const { register:uploadWeightMismatch, handleSubmit:weightMismatchUploadSubmit,formState: { errors }, reset : resetWghtMtchfile} = useForm();
  const onWeightMismatchSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);  
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');  
    setLoader(true);
    axios
      .post(process.env.REACT_APP_WEIGHT_MISMATCH_UPLOAD, formData)
      .then((res) => {
        setLoader(false);
        let errorFile='';
          if(res.data.errorfile !== undefined){
           errorFile = checkUndeNullBlank(res.data.errorfile)?process.env.REACT_APP_BASE_URL+res.data.errorfile:'';
          }
        if(res.data.error === false){
          Swal.fire({
            title: 'Success',
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
            text: res.data.msg,
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              setExcelFile(false);
              mismatchWeightListing();
            }
         });
        }else{
          console.log('res', res)
           Swal.fire({
            title: 'Error',
            text: res.data.msg,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(okay => {
            if (okay) {
              setExcelFile(false);
              mismatchWeightListing();
            }
         });
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err,"");
      });
  }

  const exportWeightMM = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      data.is_export=1
      setLoader(true);
      axios.post(process.env.REACT_APP_WEIGHT_MISMATCH_LIST, data)
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          setLoader(false)
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"")
    });
    }else{
      Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
    }
  }

  const toggleView = (i) => {
    setViewModal(!viewModal);
  }  

  // copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
      if(result === true){
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const onFilter = () => setIsOpen(!isOpen);
  
  return (
    <div className="content-body overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
	   
 	    <div className="p-0 my-3 master-content content-wrapper border-0 card">
        
                 
				<div className="page-header content-header">
				 
				<div className="card-title title_icon">
				<i className="bi bi-card-heading"></i> 
				Manage Weight Disputes
				 
				 <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">                
                <div className="btn-text btn-light mx-2" onClick={exportWeightMM}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Data</span></div>
                {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                <div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Weight Mismatch File</span></div>              
                }
                <div className="btn-text btn-light d-none"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Status</span></div>              
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
				
				</div>
		 
              </div>
			  
			  
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Weight Mismatch</ModalHeader>
                <ModalBody>
                  <Form onSubmit={weightMismatchUploadSubmit(onWeightMismatchSubmit)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Weight Mismatch File via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/weight_mismatch.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Upload Weight Mismatch Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadWeightMismatch("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
              {/* <Modal isOpen={viewModal} toggle={viewModal} scrollable={true} size="lg">
                <ModalHeader toggle={toggleView}>View History</ModalHeader>
                <ModalBody>
                  <Row className="mx-0 mb-2">
                    <Col sm={4}>
                      <span>07-02-2022</span>
                    </Col>
                    <Col sm={4}>
                      <span>Discrepency</span>
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col sm={4}>
                      <span>07-02-2022</span>
                    </Col>
                    <Col sm={4}>
                      <span>Auto Accepted</span>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal> */}
            </div>
            <Collapse isOpen={isOpen}>
              <Form onSubmit={filterWeightMismatchSubmit(onFilterWeightMismatch)} className="filter-form">
                <Row className="mx-0 pt-1 justify-content-start justify-content-md-center"> 
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text);}else{setSearchValue('')}}}>
                              <option value="Search By">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="order_no">Order No</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" showOneCalendar={props.oneCalendar} format="DD-MM-YYYY"/> */}
                      <DateRangePicker
                          initialSettings={ranges}
                          onCallback={handleCallback}
                        >
                        {datestate.start !== '' && datestate.end !== '' ? 
                        <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                        </div>
                        :
                        <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        </div>
                        }
                        </DateRangePicker>
                    </FormGroup>                      
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <MultiSelect
                            options={opt_Status}
                            value={Status}
                            onChange={setStatus}
                            labelledBy="Select Status"
                            overrideStrings={StatusMultiSelectSettings}
                            className={checkData(Status) && "active"}
                          />
                      </FormGroup>
                  </Col>
                  
                  {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                  <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                            options={opt_merchants}
                            value={merchants}
                            onChange={setMerchants}
                            labelledBy="Select Merchant"
                            overrideStrings={merchantsMultiSelectSettings}
                            className={checkData(merchants) && "active"}
                          />
                      </FormGroup>
                  </Col>}

                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={mismatchWeightListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
            </Collapse>
              <CardBody className="p-0">
                {checkData(weightMismatch) && checkData(weightMismatch.data) && checkUndeNullBlank(weightMismatch.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(weightMismatch.data).length)} of {weightMismatch.TotalRows} entries
                    </div>
                      {weightMismatch.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={250}>250</option>
                            <option value={500}>500</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                    {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th>Merchant</th>
                    }
                      <th>Shipment</th>
                      <th><div>Product</div></th>
                      <th>Your Weight</th>
                      <th>Courier Weight</th>
                      <th>Difference</th>
                      <th>Date</th>
                      <th>Status</th>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th>Processing Status</th>}
                      {/* <th>Action</th> */}
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(weightMismatch.data) ? weightMismatch.data.map((item, index) => (
                    <tr key={index}>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <td className="w-some">{item.merchantName}</td>
                     }
                      <td>
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-icon">
                            <div className="avatar">
                              {item.logo !== null && item.logo !== undefined ?
                                <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER+item.logo} alt="logo" width="60" />
                              : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                            </div>
                          </div>
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{(item.courierName)}</p>                             
                            </div>
                            <div className="d-flex">
                              <span className="mb-1 d-block text-primary text-left text-nowrap">AWB - {item.awb_no}</span>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn d-none" id={`copy${index}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${index}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>                             
                            <div className="d-flex">
                              <span className="mb-1 d-block text-left text-nowrap">Order No - <Link to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></span>
                              <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn d-none" id={`copyawb${index}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copyawb${index}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>                             
                          </div>
                        </div>
                      </td>
                      <td>
                        <div><div className="w-some" id={`MoreProduct${index}`}>{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`MoreProduct${index}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                        </div>
                        <div>Product Category : {item.product_category}</div>
                        <div>QTY : {item.product_qty}</div>
                      </td>
                      <td>{checkUndeNullBlank(item.charged_weight)?item.charged_weight+'kg':''}</td>
                      <td>{checkUndeNullBlank(item.courier_weight)?item.courier_weight+'Kg':''}</td>
                      <td className="text-nowrap">{(parseFloat(item.courier_weight - item.charged_weight).toFixed(2) +'Kg')}</td>
                      <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                      <td>{weight_mismatch_status[item.status]}</td>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <td>{checkUndeNullBlank(item.is_process) ? item.is_process === 1 ? "Processing.." : item.is_process === 0 ? "Completed" : '':''}</td>
                      }
                      {/* <td>
                        <div className="btn-group-ctm text-nowrap">
                          <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleView(item.id)}>View History</Button></span>
                        </div>
                      </td> */}
                    </tr>
                  )) :loadertable ? <tr><td colSpan="8" className="text-center">Loading...</td></tr>
                      :<tr><td colSpan="8" className="text-center">No Data Found</td></tr>
                      }                      
                  </tbody>
                </Table>
                <ToastContainer className="copied-con" />
                {checkData(weightMismatch) && checkData(weightMismatch.data) && checkUndeNullBlank(weightMismatch.TotalRows) ?
                <>
                {weightMismatch.TotalRows > dropFilterCount ?
                  <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(weightMismatch.data).length)} of {weightMismatch.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
               :''}
               </>
               :''}
              </CardBody>
            
		</div>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(WeightMismatch);