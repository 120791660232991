import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,InputGroup,Collapse,Alert,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Badge, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Label, Spinner, Input} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { Link, useHistory, useLocation, URLSearchParams } from 'react-router-dom';
// import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown,faChevronLeft,faChevronRight,faEye,faFilter,faTimes} from '@fortawesome/free-solid-svg-icons';
import { Copy } from 'react-feather';
// import TrackOrder from "../orders/TrackOrder";
// import axios from 'axios';
import axios from '../../CommonAxios';
import { useForm } from "react-hook-form";
import { delivery_type_id, download_delay, movement_type, order_status, order_status_badge } from '../../config';
import { changeIntoDate, changeIntoDateYmd, copyToClipBoard, removeObjData, firstDayOftheMonth, changeIntoDateTime} from '../../utlis';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast,ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const AllShipments = (props) => {
   
	const params =  window.location.search ; 
	
   let srch_key = params.toString().split('=');	
	 
  let searchKeyword = (srch_key[1] !='') ? srch_key[1] : '' ; 
  let searchedValue = '' ; 
   
  const [sideAnim, setSideAnim] = useState(false);
  const [allShipment, setAllShipment] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(),new Date()]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderStatusLog, setOrderStatusLog] = useState({});
  // const [orderTracking, setOrderTracking] = useState({});
  const [orderLogLoader, setOrderLogLoader] = useState(false);
  const [shipmentinfo, setShipmentinfo] = useState('');
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [cancelmodal, setCancelmodal] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [courierMerchant, setCorierMerchant] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [merchants, setMerchants] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [datestate, setDateState] = useState({start: '',end: '',});
  const [eventDatestate, setEventDateState] = useState({eventstart: '',eventend: '',});
  const [isOpen, setIsOpen] = useState(false);
  const orderStatusObj = order_status;
  const dropFilterCount = 20;
  const history = useHistory();
  const { eventstart, eventend } = eventDatestate;
  const eventhandleCallback = (eventstart, eventend) => {
    setEventDateState({ eventstart, eventend });
  };
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  
  const handleSearchValue = (e) => {
	  setSearchValue(e.target.value) ;
	  
	  if(e.target.value !='')
	  searchedValue = e.target.value ;
  } ;
  
  const eventranges = {
    startDate: eventstart !== '' && eventstart.toDate(),
    endDate: eventend !== '' && eventend.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const ranges = {
    startDate: start !== '' && start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
    const onFilter = () => setIsOpen(!isOpen);
    const selectAll = (value) => {
    setOverallChecked(false);
    let abc = checkData(allShipment.data)&&allShipment.data.map((key) => (
      key===false
    ));
    if(value){
    var opt_order_id = [];
      checkData(allShipment.data) && Object.keys(allShipment.data).map(function(key){
      let tempObj = allShipment.data[key].id    
        opt_order_id.push(tempObj)
      return setCheckboxOrderId(opt_order_id)
    });
    // ));
    }else{
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }
  
  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
  var opt_order_id = checkboxOrderId;
  setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if(event.target.checked){
      opt_order_id.push(Id)
    setCheckboxOrderId(opt_order_id)
    }else{
    function arrayRemove(arr, value) { 
        return arr.filter(function(ele){ 
            return ele !== value; 
        });
    }
    var result = arrayRemove(checkboxOrderId, Id);
    setCheckboxOrderId(result)
    };
  };
  const closeChangeStatus = (orderId) => {
    setChangeStatusModal(false);
    reset2()
}
  const toggleChangeStatus = (orderId) => {
    removeObjData(orderStatusObj,[1,3,8,9,25]);
    setOrderLogLoader(true);
  setChangeStatusModal(!changeStatusModal);
  let data = {"order_id":orderId}
  axios.post(process.env.REACT_APP_ORDER_STATUS_LOG, { headers: {"x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" }, data })
    .then((res) => {
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setOrderStatusLog(res.data.data);
          setOrderLogLoader(false)
        }
      }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
      setOrderLogLoader(false)
    });
  }
  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }     
      return opt_merchants.push(tempObj)
    });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant 9"
  }
  const OrderStatusMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Order Status"
  }

  const order_status_list = removeObjData(orderStatusObj,[1,3]);
  if(!changeStatusModal){
    orderStatusObj[8]='Order Cancelled'
    orderStatusObj[9]='Shipment Cancelled'
    orderStatusObj[25]='Order Processing'
  }
  // console.log('orderStatusObj',orderStatusObj[9]);
  const opt_orderStatus = [];
  for (const property in order_status_list) {
    let tempObj = {label: `${order_status_list[property]}`, value: property };
    opt_orderStatus.push(tempObj);
  }

  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
    // var opt_allCourier = [];
    // checkData(courierMerchant.couriers) && Object.keys(courierMerchant.couriers).map(function(key){
    //   let tempObj = {label: courierMerchant.couriers[key].courierName, value: courierMerchant.couriers[key].courier_id }     
    //   return opt_allCourier.push(tempObj)
    // });
    var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }


  
  
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = {label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  // const closeTracker = (data) => {
  //   setSideAnim(!sideAnim) 
  // }

  useEffect(() => {
    allShipmentListing();
	
	 
	
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier)){
      setCorierMerchant(props.state.shipmentMerchantCourier)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword])
  
//All Shipment Listing
const allShipmentListing = () => {
    setFilterDate([firstDayOftheMonth(),new Date()]);
    setEventDateState({eventstart: '',eventend: '',});
    setDateState({start: '',end: '',});
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setIsExport(false);
    setCurrentCount(1)
    // let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    // let dateEnd = moment().format("YYYY-MM-DD");

  let data = {"all_shipment":1}
  
  // if(checkData(searchKeyword)){
  // data.start_date = data.end_date=''
  // var searchAwb = searchKeyword.split(',')
  //   data.awb_no=searchKeyword
  // }
  if(searchKeyword!=''){
    setDateState({start: '',end: '',});
    data.search_keyword = searchKeyword;
  }
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
    data.admin_id=props.user[0].id
  }else{
    data.merchant_id=props.user[0].id
  }
  
  setLoadertable(true);
  reset();setOrderStatus([]);setMerchants([]);setAllCourier([]);setDeliveryType([]);setSearchValue('');
  setFilterInput('')

  // axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
  axios({
    method: "post",
    url: `${process.env.REACT_APP_SHIPMENTS_COD_DETAILS}?page=${page}&per_page=${selectperpage}`,
	headers: {"x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" },
    data: data,
  }).then((res) => {
	  
  });	  

  
  axios({
    method: "post",
    url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${page}&per_page=${selectperpage}`,
	headers: {"x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" },
    data: data,
  }).then((res) => {
    setLoadertable(false)
    if(res.data !== undefined && res.data.fatal !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        setAllShipment(res.data);
      }
    }
  }).catch((err) => {
    setLoadertable(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
}

// filter Bulk Order
const { register:changeStatus, handleSubmit:changeStatusSubmit, reset:reset2 } = useForm();
const onChangeStatus = data => {
  data.awb_no = shipmentinfo.awb_no
  data.courier_id = shipmentinfo.courier_id
  data.old_order_status_id = shipmentinfo.old_order_status
  data.order_id = shipmentinfo.order_id
  data.merchant_id = shipmentinfo.merchant_id
  data.user_name = checkData(props.user) && props.user[0].name
  data.admin_id = checkData(props.user) && props.user[0].id
  axios.post(`${process.env.REACT_APP_CHANGE_ORDER_STATUS}`,data)
  .then((res) => {
    setLoader(false);
    if(res.data !== undefined && res.data.fatal !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.fatal !== true && res.error !== true){
          Swal.fire({icon:'success',text:res.data.msg,title:'Success'});
          allShipmentListing();
          setChangeStatusModal(false);
        }
        else{
          Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
        }
      }
    }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
}
const orderTrack = (orderId) => {
  let data = {"order_id":orderId}
  axios.post(`${process.env.REACT_APP_TRACK_ORDER}`,data)
  .then((res) => {
    setLoader(false);
    if(res.data !== undefined && res.data.fatal !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.fatal !== true && res.error !== true){
          //console.log('trackk', res.data)
          // setOrderTracking(res.data.data)
        }
        else{
          Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
        }
      }
    }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
}
//console.log('filter', filterInput)
const exportShipment = () => {
  if(isExport || searchKeyword!=''){
      let data = {}
      
	  data = filterInput ;
	  
	  if(checkUndeNullBlank(searchKeyword)) {
      data.search_value = searchKeyword
      } 
	   if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = 1
    }else{
      data.merchant_id = props.user[0].id
    }
    data.is_export=1
    setLoader(true);
    axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
  .then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          setLoader(false);
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.is_export === 1){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
      }
      }
    }
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });  
  
  }else{
    Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
  }
}

function handleSelectChange(event) {
  setSelectperpage(event.target.value);
  setLoadertable(true)
  setPage(1)
  setCurrentCount(1)
  let pagePerpage = {"page":1, "per_page":event.target.value};
  onpaginationFilter(filterInput,pagePerpage)
}
function nextPageData() {
if(checkData(allShipment.data)&&checkUndeNullBlank(allShipment.totalPage)){
  // &&checkUndeNullBlank(allShipment.totalPage).length>page.length
    if(allShipment.totalPage > page){
      setCurrentCount(currentCount+Object.entries(allShipment.data).length)
  setPage(page+1)
  setLoadertable(true)
    let pagePerpage = {"page":page+1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
}
}
function previousPageData() {
if(page >1){
setPage(page-1)
checkData(allShipment.data)
if(currentCount>Object.entries(allShipment.data).length){
  setCurrentCount(currentCount-selectperpage)
}else{
  setCurrentCount(1)
}
let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
  onpaginationFilter(filterInput,pagePerpage);
}
}
const { register:filter, handleSubmit:filterAllShipmentSubmit, reset, setValue } = useForm();
 
const onpaginationFilter = (data,pagePerpage) => {
  onCommonFilter(data,pagePerpage)
};
const onFilterAllShipment = (data,pagePerpage) => {
  setIsExport(true);
  pagePerpage.page=1
  
  if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || filterDate.length > 0 || checkData(eventDatestate)){
  setCurrentCount(1);
  setSelectperpage(selectperpage);
  setFilterInput(data);
  onCommonFilter(data,pagePerpage)
}
};
const onCommonFilter = (data,pagePerpage) => {
  setOverallChecked(false);
  setCheckedAll(false);
  setChecked(false);
  setCheckboxOrderId([]);
  let byData = {}
  let tmpDeliveryType = []
  let tmpOrderStatus = [];
  let courierId = [];
  let merchantsId = [];
  if(checkData(allCourier)){
    allCourier.map((item) => (
      courierId.push(item.value)
    )); 
  } 
  if(checkData(merchants)){
    merchants.map((item) => (
      merchantsId.push(item.value)
    )); 
  } 
  deliveryType.map((item) => (
    tmpDeliveryType.push(item.value)
  ));  

  orderStatus.map((item) => (
    tmpOrderStatus.push(parseInt(item.value))
  ));
  if(checkUndeNullBlank(courierId)){
    byData.courier_id=courierId
  }
  setLoader(true)
  if(data === undefined){
    data = filterInput
  }
  let dateStart = moment(datestate.start).format("YYYY-MM-DD");
  let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
  let eventDateStart = moment(eventDatestate.eventstart).format("YYYY-MM-DD");
  let eventDateEnd = moment(eventDatestate.eventend).format("YYYY-MM-DD");
  

  byData={"all_shipment":1,"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"order_status_ids":checkUndeNullBlank(tmpOrderStatus)?tmpOrderStatus:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"movement_type_id":checkUndeNullBlank(data.movement_type_id)?data.movement_type_id:'',"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : '',"event_start_date":eventDatestate.eventstart !== '' ? eventDateStart : '',"event_end_date":eventDatestate.eventend !== '' ? eventDateEnd : ''}
  
  if(checkData(searchKeyword)){
    byData.search_field = 'awb_number';
    byData.search_value = searchKeyword;
  }
  setFilterInput(byData);
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
    byData.admin_id = 1
  }else{
    byData.merchant_id = props.user[0].id
  }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setAllShipment(res.data);
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setLoader(false);
    });
};
 

// cancel shipment
const cancelmodalToggle = () => {
  if(checkboxOrderId.length > 0){
    setCancelmodal(!cancelmodal);
  }else{
    Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
  }
}
const onStatusRecheck = () => {
  let data = {}
  if(checkboxOrderId.length > 0){
  setLoader(true);
  data.order_ids=checkboxOrderId
axios.post(process.env.REACT_APP_STATUS_RECHECK, data)
.then((res) => {
  setLoader(false)
      if(res.data.sqlMessage!==undefined){
        Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
      }
      if(res.data.error === false){
        Swal.fire({icon:'success', title:'Success', text:res.data.msg}).then(okay => {
          if (okay) {
            setCancelmodal(false);
            resetCancelled();
            setCheckboxOrderId([]);
            // allShipmentListing();
            onCommonFilter();
            setCheckedAll(false);
            setChecked(false);
         }
       });
      }else{
        setLoader(false)
        Swal.fire({icon:'error', title:'Error', text:res.data.msg})
      }
}).catch((err) => {
  setLoader(false)
  Swal.fire({icon:'error',text:err,title:'Error'});
});
}else{
  Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
}
}
const { register:cancelOrder, handleSubmit:cancelShipmentSubmit,formState: { errors: errors4 }, reset:resetCancelled} = useForm();
const onCancelShipmentSubmit = data => {
    setLoader(true);
    data.order_ids=checkboxOrderId
  axios.post(process.env.REACT_APP_CANCEL_SHIPMENT, data)
  .then((res) => {
    setLoader(false)
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
        if(res.data.error === false){
          Swal.fire({icon:'success', title:'Success', text:res.data.msg})
          setCancelmodal(false);
          resetCancelled();
          setCheckboxOrderId([]);
          allShipmentListing();
          setCheckedAll(false);
          setChecked(false);
        }else{
          setLoader(false)
          Swal.fire({icon:'error', title:'Error', text:res.data.msg})
        }
        //console.log(res)
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
}

// copy text
const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="master-content content-wrapper border-0 mb-3 p-3">
		 
                <div className="">
				 <div className="card-title title_icon">
				<i className="bi bi-card-heading"></i> 
				All Shipments
				
				
					<div className=""> 
					{checkData(allShipment.data) && 
					<>
					<div className="btn-text btn-light" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span></div>
					{(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
					<>
					<div className="btn-text btn-light mx-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Shipment</span></div>
					<div className="btn-text btn-light mx-2" onClick={onStatusRecheck}><span className="icon mr-2"><FontAwesomeIcon icon={faEye} /></span><span>Update Status</span></div>
					</>}
					</>}                
					<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
					</div>
					</div>

				</div>


			  
			  
			
			
            <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                <ModalHeader toggle={cancelmodalToggle}>Cancel Shipment</ModalHeader>
                <ModalBody>
                  <Form onSubmit={cancelShipmentSubmit(onCancelShipmentSubmit)}>
                   <Row className="m-0 pb-1">                      
                     <Col sm={12}>
                        <FormGroup>
                            <Label for="remaks">Remarks</Label>
                            <textarea {...cancelOrder("remarks", {required : 'Please Enter Remaks'})} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                            {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Submit</Button>
                    </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
              <Collapse isOpen={isOpen}>
               <Form onSubmit={filterAllShipmentSubmit(onFilterAllShipment)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text);history.push(`/allshipments`);}else{setSearchValue('')}}}>
                              <option value="Search By">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value= "customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control"  placeholder={searchValue} onChange={(e) => history.push(`/allshipments`)} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant 99"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                        </FormGroup>
                    </Col>}

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_orderStatus}
                              value={orderStatus}
                              onChange={(e) => setOrderStatus(e)}
                              labelledBy="Order Status"
                              overrideStrings={OrderStatusMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>   
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={(e) => setAllCourier(e)}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("delivery_type_id")} className="form-control-sm form-control">
                              <option value="">Delivery Type</option>
                              {Object.entries(delivery_type_id).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                            </select>
                      </FormGroup>
                    </Col>                    
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <DateRangePicker
                            initialSettings={eventranges}
                            onCallback={eventhandleCallback}
                          >
                          {eventDatestate.eventstart !== '' && eventDatestate.eventend !== '' ? 
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(eventDatestate.eventstart).format("DD-MM-YYYY")} to {moment(eventDatestate.eventend).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Event Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                        </DateRangePicker>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <DateRangePicker
                            initialSettings={ranges}
                            onCallback={handleCallback}
                          >
                          {datestate.start !== '' && datestate.end !== '' ? 
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                        </DateRangePicker>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("movement_type_id")} className="form-control-sm form-control">
                              <option value="">Movement Type</option>
                              {Object.entries(movement_type).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                            </select>
                      </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {allShipmentListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
                </Collapse>
              <CardBody className="p-0">
              {checkboxOrderId.length > 0 && !overallchecked &&
                  <Alert color="success" className='py-1' fade={false}>
                  <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer'>Select all <span className='font-weight-bold'>{checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows}</span> items across all pages</span>}
                  </Alert>
                }
                {overallchecked && 
                  <Alert color="success" className='py-1' fade={false}>
                  <span className='mx-1'>Total {checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows} items are Selected.</span>
                  </Alert>
                }
                {checkData(allShipment) && checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(allShipment.data).length)} of {allShipment.TotalRows} entries
                    </div>
                      {allShipment.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={250}>250</option>
                            <option value={500}>500</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                  <th><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)}  value="checkedall"/></th>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th className="text-nowrap">Merchant Name</th>
                    }
                      <th className="col-2 d-none">Warehouse</th>
                      <th className="text-nowrap">Order</th>
                      <th className="text-nowrap">AWB Number</th>
                      <th className="text-nowrap">Method</th>
                      <th className="text-nowrap">Payment</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">Customer</th>
                      <th className="text-nowrap">Product</th>
                      <th className="text-nowrap">Status</th>
                      <th className="text-nowrap">Date</th>
                      <th className="text-nowrap">Updated</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                   </thead>
                  <tbody>
                    {checkData(allShipment.data) ? allShipment.data.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle"><input checked={checked !== undefined&&checked[index]} name={'check'+index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index,event,item.id)} /></td>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <td className="align-middle">{item.merchantName}<br /> {item.merchantBrandName}</td>
                    }
                      <td className="align-middle d-none">{item.MerchantPickupPointName}</td>
                      <td className="align-middle"><Link to={`/order-detail/${item.id}`}>{item.order_no}</Link></td>
                      <td className='align-middle'>
                      <div className="d-flex"><span className="mb-1 d-block text-left text-nowrap">{item.awb_no}</span>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn d-none" id={`copy${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                      </td>
                      <td className='align-middle'>{delivery_type_id[item.delivery_type_id]}</td>
                      <td className='align-middle'>{item.product_cod_value}</td>
                      <td className="align-middle text-left col-2">
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-icon">
                            <div className="avatar d-none">
                              {item.logo !== null && item.logo !== undefined ?
                                <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER+item.logo} alt="logo" width="60" />
                              : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">M</span>}
                            </div>
                          </div>
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{(item.courierName)}</p>                             
                            </div>
                            </div>
                        </div>
                        
                      </td>
                      <td className="align-middle">{item.customer_name}</td>
                      <td className="align-middle"> <div  className="min-w-100px" id={`addressTooltip${item.id}`}>{item.product_name}</div> 
                          <UncontrolledTooltip placement="top" target={`addressTooltip${item.id}`}>
                          {item.product_name}
                          </UncontrolledTooltip>
                      </td>
                      <td className="align-middle">
                       
					  <span className={`status_order status_${item.order_status_id}`}> {order_status[item.order_status_id]}</span></td>
					  
                      <td className="align-middle text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                      <td className="align-middle text-nowrap">{changeIntoDateTime(item.updated_at)}</td>
                      <td className="align-middle">{(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <>
                      <br/> <Button className="btn btn-sm text-nowrap" color="primary" outline  onClick={() => {toggleChangeStatus(item.id); setShipmentinfo({"order_id":item.id, "old_order_status":item.order_status_id, "courier_id":item.courier_id, "awb_no":item.awb_no,"merchant_id": item.merchant_id})}}> Change Status</Button>
                      </>
                      }</td>
                      </tr>
                    )):
                      loadertable ? <tr><td colSpan="11" className="text-center">Loading...</td></tr>
                      :<tr><td colSpan="11" className="text-center">No Data Found</td></tr>}
                   </tbody>
                </Table>
                <ToastContainer style={{width: "200px"}}/>
                {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                <Modal isOpen={changeStatusModal} toggle={changeStatusModal} scrollable={true} size="lg">
                <ModalHeader toggle={closeChangeStatus}>Change Status</ModalHeader>
                <ModalBody>
                  <Form onSubmit={changeStatusSubmit(onChangeStatus)} className="filter-form">
                    <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
                      <Col sm={12}>
                        <FormGroup>                      
                          <Label>Select Status</Label>
                          <div className="position-relative">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...changeStatus("new_order_status_id")} className="form-control-sm form-control">
                                {Object.entries(orderStatusObj).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                          <FormGroup>
                            <Label>Remarks</Label>
                            <textarea {...changeStatus("remarks")} className="form-control-sm form-control" placeholder="Enter Remarks" />
                          </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <Table responsive borderless className="text-center customTable">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>Old Status</th>
                              <th>New Status</th>
                              <th>Remarks</th>
                              <th>Date-Time</th>
                            </tr>
                          </thead>
                          <tbody>
                          {checkData(orderStatusLog) ? orderStatusLog.map((item, index) => (
                            <tr>
                              <td>{item.submit_by}</td>
                              <td>{order_status[item.old_order_status_id]}</td>
                              <td>{order_status[item.new_order_status_id]}</td>
                              <td>{item.remarks}</td>
                              <td>{changeIntoDate(item.created_at)}</td>
                            </tr>
                          )): orderLogLoader ? <tr><td colSpan="5">Loading...</td></tr> : <tr><td colSpan="5">No Data Found</td></tr>}
                          </tbody>
                        </Table>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Save</Button>
                      </Col>
                      </Row>
                    </Form>
                 </ModalBody>
              </Modal>
            }
              {/* {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack = {sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed"  />} */}
              {checkData(allShipment) && checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) ?
                <>
                {allShipment.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(allShipment.data).length)} of {allShipment.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
        </Card>
      </Col>
      </Row>
       </div>
  );
}

function mapDispatchToProps (state) {
  return {
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(AllShipments);