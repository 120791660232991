import React from 'react';
import DailyShipmentSummary from './DailyShipmentSummary';
import Performance from './Performance';

const DailySummary = (props) => {

    return (
        <div className="DailySummary">
            <div className="mb-4"><DailyShipmentSummary user={props.user} /></div>
            <div className="mb-4"><Performance user={props.user} /></div>
        </div>
    );
}

export default DailySummary;