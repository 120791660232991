import React, {useState} from "react";
import {Row, Col, FormGroup, Button, Form } from "reactstrap";
import { StateList } from "./StateList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';

const AddPincode = (props) => {
  const [stateCity, setStateCity] = useState({});

    const handleStateChange =(e) => {
        // setSelectedClient(event.target.value);
          fetch(`${process.env.REACT_APP_GET_CITY_STATE}?state_id=${e.target.value}`,{
            method:'POST',
            headers:{
              "Content-Type":'application/json',
              "Accept":'application/json',
            },
          }).then((resp)=>{
            resp.json().then((result)=>{
              setStateCity(result)
            });
          });
    }
    const { register:addPincode, handleSubmit:pincodeSubmit, formState: { errors } } = useForm();
    const onAddPincodeSubmit = (data) => {
      let dataAdd={"city_id":data.city_id,"state_id":data.state_id,"pincode":data.pincode, "status":data.status};
        fetch(`${process.env.REACT_APP_ADD_PINCODE}`,{
          method:'POST',
          headers:{
            "Content-Type":'application/json',
            "Accept":'application/json',
          },
          body:JSON.stringify(dataAdd)
        }).then((resp)=>{
          resp.json().then((result)=>{
            if(result.error === false){
              props.setDataAdd(false);
              Swal.fire({
                title: 'Success',
                text: result.message,
                icon: 'success',
              })
            }
          });
        });
    };
    const onError = (errors, e) => console.log('Error', errors);
 
    return(
        <Form onSubmit={pincodeSubmit(onAddPincodeSubmit,onError)}>
            <Row className="mx-0 pt-3 justify-content-center addModal">
                <Col sm={12}>
                <FormGroup className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addPincode("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" onChange={handleStateChange}>
                        <option value="">Select State</option>
                        {StateList.map((item, index) => (
                        <option value={item.state_id}>{item.state_name}</option>
                        ))
                    }
                    </select>
                {errors.state_id && <span className="text-danger d-block mb-2">{errors.state_id.message}</span>}
                </FormGroup>
                </Col>
                {Object.entries(stateCity).length >0 &&
                <Col sm={12}>
                <FormGroup className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addPincode("city_id",{ required: 'Please Select City'})}  className="form-control-sm form-control">
                        <option value="">Select City</option>
                        {Object.entries(stateCity).length >0 && stateCity !== undefined ? stateCity.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                        ))
                    :''}
                    </select>
                {errors.city_id && <span className="text-danger d-block mb-2">{errors.city_id.message}</span>}
                </FormGroup>
                </Col>
                }
                <Col sm={12}>
                    <FormGroup>
                    <input {...addPincode("pincode",{ required: 'Please Enter Picode'})} type="number" className="form-control-sm form-control" placeholder="Enter Pincode" />
                    {errors.pincode && <span className="text-danger d-block mb-2">{errors.pincode.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup className="mb-0">
                    <label>Status</label>
                    </FormGroup>
                    <FormGroup>
                    <input {...addPincode("status", { required: true })} type="radio" value="1" checked/>
                    <span className="mx-3">
                        Active
                    </span>
                    <input {...addPincode("status", { required: true })} type="radio" value="0" />
                    <span className="mx-3">
                        InActive
                    </span>
                    </FormGroup>
                </Col>
                <Col sm={12} className="text-right">
                <Button className="btn  btn-sm search" color="primary">Save</Button>
                </Col>
            </Row>
        </Form> 
    )
}
export default AddPincode