import React, {useEffect, useState} from 'react';
import { Row, Col,FormGroup,Form, Label,Card,CardBody, CardSubtitle, Button, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
// import axios from 'axios';
import axios from '../../CommonAxios';
import { checkData } from '../../ObjectExist';

const EditBulkOrders = (props) => {
  const [orderDetail, setOrderDetail] = useState({})
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    props.orderDetails.orderDetail[0].service_id = String(props.orderDetails.orderDetail[0].service_id);
    reset2(props.orderDetails.orderDetail[0])
    setOrderDetail(props.orderDetails.orderDetail[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { register:editOrderDetails, handleSubmit:orderDetailEditSubmit, formState: { errors: errors2 }, reset:reset2} = useForm();
  const onEditOrderDetailSubmit = (data) => {
    data.merchant_id=props.merchantId
    data.order_id= data.id
    setLoader(true);
    axios
      .post(`${process.env.REACT_APP_EDIT_ORDER}`, data)
      .then((res) => {
        setLoader(false);
        if(res.data.error === false){
          props.setEditmodal !== undefined && props.setEditmodal(false);
          props.setOrderDetail !== undefined && props.setOrderDetail({});
          reset2();
          Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }else{
        setLoader(false);
        Swal.fire({icon:"error", title:"Error", text:res.data.msg});
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
      });
  };
const onErrorUpdate = (errors2, e) => console.log('Error', errors2);
 

  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
    <Form onSubmit={orderDetailEditSubmit(onEditOrderDetailSubmit,onErrorUpdate)}>
      <Row className="mx-0 justify-content-center addModal">                        
        <Col lg={4}>
          <Card>
            <CardBody>
              <CardSubtitle tag="h6" className="mb-3">Order Detail</CardSubtitle>
              <FormGroup>
                <Label for="Courier">Choose Courier*</Label>
                <div className="position-relative">
                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...editOrderDetails("courier_id",{ required: 'Please Choose Courier Name'})} className="form-control-sm form-control">
                      <option value="">Choose Courier</option>
                      {checkData(props.orderDetails.merchantCouriers) && props.orderDetails.merchantCouriers.map((item, index) => (
                      <option value={item.id}>{item.CourierName}</option>
                      ))}
                  </select>
                </div>             
                {errors2.courier_id && <span className="text-danger d-block error">{errors2.courier_id.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="pickup_point">Choose Warehouse*</Label>                
                <div className="position-relative">
                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...editOrderDetails("pickup_point_id",{ required: 'Please Choose Warehouse'})} className="form-control-sm form-control">
                      <option value="">Choose Warehouse</option>
                      {checkData(props.orderDetails.merchantPickupPoints) && props.orderDetails.merchantPickupPoints.map((item, index) => (
                      <option value={item.id}>{item.MerchantPickupPointName}</option>
                      ))}
                  </select>

                </div>
                {errors2.pickup_point_id && <span className="text-danger d-block error">{errors2.pickup_point_id.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="brand">Choose Stores*</Label>                
                <div className="position-relative">
                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...editOrderDetails("brand_id",{ required: 'Please Choose Stores Name'})} id="brand" className="form-control-sm form-control">
                      <option value="">Choose Stores</option>
                      {checkData(props.orderDetails.merchantBrands) && props.orderDetails.merchantBrands.map((item, index) => (
                      <option value={item.id}>{item.MerchantBrandName}</option>
                      ))}
                  </select>
                </div>
                {errors2.brand_id && <span className="text-danger d-block error">{errors2.brand_id.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="order_no">Enter Order Number*</Label>                
                <input  {...editOrderDetails("order_no",{ required: 'Please Enter Order Number' })} className="form-control-sm form-control" placeholder="Enter Order Number" id="order_no" />
                {errors2.order_no && <span className="text-danger d-block error">{errors2.order_no.message}</span>}
              </FormGroup>
              {checkData(orderDetail) && orderDetail.awb_no === '' && (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
              <FormGroup>      
                <Label for="awb_number">AWB Number*</Label>                
                <input  {...editOrderDetails("awb_no",{ required: 'Please Enter AWB Number'})} className="form-control-sm form-control" placeholder="Enter AWB Number" id="awb_no" />
                {errors2.awb_no && <span className="text-danger d-block error">{errors2.awb_no.message}</span>}
              </FormGroup>
              }
              <FormGroup className="mb-0">
                <Label for="service">Service*</Label>   
                <div className="d-flex flex-wrap">
                    <span className="mr-2">
                        <input  {...editOrderDetails("service_id",{ required: true})} type="radio" value="7" id="surface"/>
                        <Label className="mx-2" for="surface">Surface</Label>
                    </span>
                    <span>
                        <input  {...editOrderDetails("service_id",{ required: true})} type="radio" value="1" id="express" />
                        <Label className="mx-2" for="express">Express</Label>
                    </span>
                </div>
                {errors2.service_id && <span className="text-danger d-block error">{errors2.service_id.message}</span>}
              </FormGroup> 
            </CardBody>
          </Card>          
        </Col>
        <Col lg={4}>
          <Card>
            <CardBody>
              <CardSubtitle tag="h6" className="mb-3">Product Detail</CardSubtitle>
              <FormGroup>      
                <Label for="product_name">Enter Product Name*</Label>                
                <input  {...editOrderDetails("product_name",{ required: 'Please Enter Product Name'})}  className="form-control-sm form-control" placeholder="Enter Product Name" id="product_name" />
                {errors2.product_name && <span className="text-danger d-block error">{errors2.product_name.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="qty">Enter Product Quantity*</Label>                
                <input  {...editOrderDetails("product_qty",{ required: 'Please Enter Quantity',
                pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Quantity'}})}  className="form-control-sm form-control" placeholder="Enter Product Quantity" id="qty" />
                {errors2.product_qty && <span className="text-danger d-block error">{errors2.product_qty.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="invoice_value">Enter Invoice Value*</Label>                
                <input  {...editOrderDetails("product_invoice_value",{ required: 'Please Enter Invoice Value',
                pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Invoice Value'}})}  className="form-control-sm form-control" placeholder="Enter Invoice Value" id="invoice_value" />
                {errors2.product_invoice_value && <span className="text-danger d-block error">{errors2.product_invoice_value.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="COD_value">Enter COD Value*</Label>                
                <input  {...editOrderDetails("product_cod_value",{ required: 'Please Enter COD Value',
                pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid COD Value'}})}  className="form-control-sm form-control" placeholder="Enter COD Value" id="COD_value" />
                {errors2.product_cod_value && <span className="text-danger d-block error">{errors2.product_cod_value.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="product_weight">Enter Product Weight(KGS)*</Label>                
                <input  {...editOrderDetails("product_weight_in_kgs",{ required: 'Please Enter Product Weight(KGS)',
                pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Product Weight(KGS)'}})}  className="form-control-sm form-control" placeholder="Enter Product Weight" id="product_weight" />
                {errors2.product_weight_in_kgs && <span className="text-danger d-block error">{errors2.product_weight_in_kgs.message}</span>}
              </FormGroup>
              <FormGroup className="mb-0">
                  <Label for="product_dimension">Enter Product Dimension(CMS)*</Label>
                  <div className="d-flex align-items-center">
                      <Label for="prod_l" className="mx-2">L</Label>
                      <input  {...editOrderDetails("length_in_cms",{ required: 'Please Enter Product Length',
                      pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Product Length'}})} className="form-control-sm form-control " id="prod_l" />
                      <Label for="prod_b" className="mx-2">B</Label>
                      <input  {...editOrderDetails("breadth_in_cms",{ required: 'Please Enter Product Breadth',
                      pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Product Breadth'}})} className="form-control-sm form-control " id="prod_b" />
                      <Label for="prod_h" className="mx-2">H</Label>
                      <input  {...editOrderDetails("height_in_cms",{ required: 'Please Enter Product Height',
                      pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Product Height'}})} className="form-control-sm form-control " id="prod_h" />
                  </div>
                  {errors2.length_in_cms && <span className="text-danger d-block error">{errors2.length_in_cms.message}</span>}
                  {errors2.breadth_in_cms && <span className="text-danger d-block error">{errors2.breadth_in_cms.message}</span>}
                  {errors2.height_in_cms && <span className="text-danger d-block error">{errors2.height_in_cms.message}</span>}
              </FormGroup>
            </CardBody>
          </Card>          
        </Col>
        <Col lg={4}>
          <Card>
            <CardBody>
              <CardSubtitle tag="h6" className="mb-3">Customer Detail</CardSubtitle>
              <FormGroup>      
                <Label for="customer_name">Customer Name*</Label>                
                <input {...editOrderDetails("customer_name",{ required: 'Please Enter Customer Name',
                pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid Customer Name'}})}  className="form-control-sm form-control" placeholder="Customer Name" id="customer_name" />
                {errors2.customer_name && <span className="text-danger d-block error">{errors2.customer_name.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="address1">Address 1*</Label>                
                <textarea {...editOrderDetails("customer_address_1",{ required: 'Please Enter Address 1'})} type="textarea" className="form-control-sm form-control" placeholder="Address 1" rows="2" id="address1" />
                {errors2.customer_address_1 && <span className="text-danger d-block error">{errors2.customer_address_1.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="address2">Address 2</Label>                
                <textarea {...editOrderDetails("customer_address_2")} className="form-control-sm form-control" placeholder="Address 2" rows="2" id="address2" />
              </FormGroup>
              <FormGroup>      
                <Label for="city">Enter City*</Label>                
                <input  {...editOrderDetails("customer_city",{ required: 'Please Enter City Name',
                pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid City Name'}})}  className="form-control-sm form-control" placeholder="Enter City" id="city" />
                {errors2.customer_city && <span className="text-danger d-block error">{errors2.customer_city.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="state">Enter State*</Label>                
                <input  {...editOrderDetails("customer_state",{ required: 'Please  Enter State Name',
                pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid State Name'}})}  className="form-control-sm form-control" placeholder="Enter State" id="state" />
                {errors2.customer_state && <span className="text-danger d-block error">{errors2.customer_state.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="mobile">Enter Mobile No.*</Label>                
                <input {...editOrderDetails("customer_mobile",{ required: 'Please Enter Mobile No.',
                pattern: {value:/^(\+\d{1,3}[- ]?)?\d{10}$/,message:'Please Enter Valid Mobile No.'}})}  className="form-control-sm form-control" placeholder="Enter Mobile No." id="mobile" />
                {errors2.customer_mobile && <span className="text-danger d-block error">{errors2.customer_mobile.message}</span>}
              </FormGroup>
              <FormGroup>      
                <Label for="alt_no">Enter Alternate No.</Label>                
                <input {...editOrderDetails("customer_alt_no",{pattern: {value:/^(\+\d{1,3}[- ]?)?\d{10}$/,message:'Please Enter Valid Alternate No.'}})} className="form-control-sm form-control" placeholder="Enter Alternate No." id="alt_no" />
                {errors2.customer_alt_no && <span className="text-danger d-block error">{errors2.customer_alt_no.message}</span>}
              </FormGroup>
              <FormGroup className="mb-0">      
                <Label for="pincode">Enter Pincode*</Label>                
                <input {...editOrderDetails("destination_pincode",{ required: 'Please Enter Pincode',
                minLength: { value: 6, message: "at least 6 digits"},
                maxLength: { value: 6, message: "maximum 6 digits"},
                pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Pincode'}})} className="form-control-sm form-control" placeholder="Enter Pincode" id="pincode" />
                {errors2.destination_pincode && <span className="text-danger d-block error">{errors2.destination_pincode.message}</span>}
              </FormGroup>
            </CardBody>
          </Card>          
        </Col>
        <Col sm={12} className="text-right mt-3">
          <Button className="btn btn-sm" color="primary">Save</Button>
        </Col>
      </Row>
    </Form>
    </>
  );
}

export default EditBulkOrders;