import React, {useState,useEffect} from 'react';
import { Modal, Form, ModalFooter, Button, ModalHeader, ModalBody,InputGroup, InputGroupAddon, InputGroupText, FormGroup, Label, Navbar,  Nav,  NavItem,  NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu,  Badge, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faBell, faCalculator, faChevronDown,faComment, faDownload, faSearch, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {makePaymentpayu} from '../../payu/Payu'
import { encryptStorageLogin } from '../../../EncStorage';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import 'font-awesome/css/font-awesome.min.css';
import Logo from "../../../assets/images/logo.png";
 

const Header = (props) => {
  let searchKeyword = (new URLSearchParams(window.location.search)).get("search_keyword");
  const logOutUser = () =>{
    // localStorage.removeItem("user");
    encryptStorageLogin.removeItem('userenc')
    history.push("/my-signin");
  }
  const [rechargeMdl, setRechargeMdl] = useState(false);
  const [internetFee, setInternetFee] = useState('');
  const [totalFee, setTotalFee] = useState('');
  const toggleRecharge = () => setRechargeMdl(!rechargeMdl);
  
  const [recharge_amt, setRchargeAmt] = useState('') ; 
  const [isLiActive, setLiActive] = useState('');
  
	const handleChange = (e) => {
		let handlingFee = 0;
		setInternetFee(handlingFee)
		setTotalFee(parseInt(e.target.value)+handlingFee);
		setRchargeAmt( parseInt(e.target.value)+handlingFee );
		
	} ;

	const toggleClass = (e) => {
		let handlingFee = 0;
		setInternetFee(handlingFee)
		setTotalFee(parseInt(e.currentTarget.dataset.id)+handlingFee)
		setRchargeAmt( parseInt(e.currentTarget.dataset.id)+handlingFee );
		setLiActive(e.currentTarget.dataset.id);
	}; 
 
	 
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    let callbackURLPAYU = process.env.REACT_APP_PAYU_CALLBACK_SEND_URL 
    fetch(process.env.REACT_APP_PAYU_HASH,{
      method:'POST',
      headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
      },
      body:JSON.stringify({merchant_amt:parseFloat(totalFee).toFixed(2), merchant_email:props.user[0].email, merchant_id: props.user[0].id, merchant_name: props.user[0].name,})
      }).then((resp)=>{
      resp.json().then((result)=>{
          if(result.error===false){
            let param = {key:result.key,
              hash:result.hash,
              txnid:result.txnid,
              amount:parseFloat(totalFee).toFixed(2),
              productinfo:result.productinfo,
              country:'India',
              firstname:props.user[0].name,
              udf1:props.user[0].id,
              phone:'9876543210',
              surl:callbackURLPAYU,
              furl:callbackURLPAYU,
              email:props.user[0].email,
              service_provider:'payu_paisa'}
              console.log("param",param);
          makePaymentpayu(param);
          }
        });
    })
  };
  const { register: register2, handleSubmit:handleSubmit2, getValues, setValue} = useForm();
  useEffect(() => {
    if(checkData(props.user) && props.user[0].user_type === "Merchant"){
      props.walletBalanceUpdate();
    }
    if(checkData(searchKeyword)){
      [ 
         { name: 'search_keyword', value: searchKeyword },
      ].forEach(({ name, value }) => setValue(name, value));
   }else{
    [ 
      { name: 'search_keyword', value: '' },
   ].forEach(({ name, value }) => setValue(name, value));
   }
   }, [searchKeyword]);
  const onSearchNo = (data) => {
    const searchTrack = getValues("search_keyword");
    searchTrack.length > 0 && history.push(`/allshipments?search_keyword=${searchTrack}`);
  }
  
  
  
  
  
  
  
  return (
    <div className="header">
      <Navbar light expand="md" className="header-navbar topNav d-flex justify-content-between">
          <div className="header-left">
            <Nav className="mr-auto" navbar>
                 
			<NavItem>
				<Link to="/" className="nav-link"><img className="img-fluid logosidebar" src={Logo} alt="img" /></Link>
			</NavItem>
            
			
              <NavItem>
              <Form onSubmit={handleSubmit2(onSearchNo)}>
                  <div className="input-group search-area d-xl-inline-flex d-none">
                  <input type="text" {...register2('search_keyword')} className="autocomplete-search form-control" placeholder="Enter AWB/Order ID" />
                  <div className="input-group-append">
                    <span className="input-group-text py-0">
                      {/* <a href="javascript:void(0)"> */}
                      <Button className="btn btn-sm px-0 py-0" color="primary"><FontAwesomeIcon icon={faSearch} /></Button>
                    
                  {/* </a> */}
                  </span>
                  </div>
                </div>
                </Form>
              </NavItem>  
            </Nav>
          </div>
          <div className="d-flex header-right">
            <Nav className="mr-auto align-items-center position-relative" navbar>
            <NavItem>
                <NavLink href="#" className=" text-center py-0 px-2" onClick={toggleRecharge}><i className="bi bi-cash-stack"></i> Recharge</NavLink>
              </NavItem>
              <NavItem>
                <Link to="/my-downloads" className="py-0 nav-link">
                  <i className="bi bi-file-earmark-arrow-down-fill"></i> 
                </Link>
              </NavItem>
			   
              <NavItem>
                <NavLink href="/components" className="py-0">
					<i className="bi bi-bell-fill"></i>
				</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/components" className="py-0">
					<i className="bi bi-chat-left-dots-fill"></i>
				</NavLink>
              </NavItem>
              <NavItem className="navPrice">
                ₹ {checkUndeNullBlank(props.userBalance) ? props.userBalance.toFixed(2) : '0.00'}
              </NavItem>              
            </Nav>   
            <Nav>
              <NavItem>
                <NavLink href="#" className="py-0 header-profile">
                  <UncontrolledDropdown setActiveFromChild >
                    <DropdownToggle caret className="py-0 px-2 d-flex align-items-center">
                    <div className="avatar">
					{props.user[0].brand_name} <i className="bi bi-person-circle"></i> </div>
                    </DropdownToggle>
                    <DropdownMenu className="mt-2">
                      <Link to="/profile" className="dropdown-item"><span className="mr-2"><i className="bi bi-person"></i> </span>My Account</Link>
                      <Link to="/passbook" className="dropdown-item"><span className="mr-2"><i className="bi bi-wallet"></i></span>Passbook</Link>
			
					<Link to="/price-calculator" className="py-0 nav-link"><span className="mr-2"><i className="bi bi-calculator"></i></span> Calculator </Link>
					 

                   <NavLink href="/logout" className="dropdown-item"  onClick={logOutUser}><span className="mr-2">
                     <i className="bi bi-power"></i>
                   </span>Logout</NavLink>
				   
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem> 
            </Nav>       
          </div>
      </Navbar>
      <Modal isOpen={rechargeMdl} toggle={toggleRecharge} className="wallet_summary">
              <Form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader toggle={toggleRecharge}><i className="bi bi-cash-stack text-primary"></i> Wallet Summary</ModalHeader>
            <ModalBody>
                <FormGroup className="d-flex justify-content-between custom_inp_amount">
					<div className="model_label">Add Amount to Wallet</div>
                   <div className="inputs_amount">      
					 <input type="text" {...register('amount')} className="form-control-sm form-control" placeholder="Enter Amount to add in wallet" value={recharge_amt} onChange={handleChange} />
					</div>
					<div className="other_way">
						<div className="model_label"> Or Select from below </div>
						 <div className="other_reacharges">
							 <ul className="recharge_amounts">
							 <li onClick={toggleClass} className={isLiActive ===500 ? 'amt-active' : ''} data-id="500"><span>500</span></li>
							 <li onClick={toggleClass} className={isLiActive ===1000 ? 'amt-active' : ''} data-id="1000"><span>1000</span></li>
							 <li onClick={toggleClass} className={isLiActive ===2500 ? 'amt-active' : ''} data-id="2500"><span>2500</span></li>
							 <li onClick={toggleClass} className={isLiActive===5000 ? 'amt-active' : ''} data-id="5000"><span>5000</span></li>
							 <li onClick={toggleClass} className={isLiActive===7500 ? 'amt-active' : ''} data-id="7500"><span>7500</span></li>
							 <li onClick={toggleClass} className={isLiActive===10000 ? 'amt-active' : ''} data-id="10000"><span>10000</span></li>
							 <li onClick={toggleClass} className={isLiActive===25000 ? 'amt-active' : ''} data-id="25000"><span>25000</span></li>
							 </ul>
						 </div>
					</div>
                </FormGroup>
				
                <FormGroup className="d-none d-flex justify-content-between">
                  <Label>Internet Handling Fees</Label>
                  <div>Rs. <span>{internetFee}</span></div>
                </FormGroup>
                <div className="dropdown-divider"></div>
                <FormGroup className="d-flex justify-content-between">
                  <Label>Total Amount Payable</Label>
                  <div>Rs. <span>{totalFee}</span></div>
                </FormGroup>
             </ModalBody>
            <ModalFooter>
              <Button color="primary">Pay- <span>{totalFee}</span></Button>
            </ModalFooter>
              </Form>
          </Modal>
    </div>
  );
}
 
  
export default Header;