import React, {useState, useEffect} from 'react';
import {Card, FormGroup,CardBody, Table, CardHeader,CardTitle, Form,Row, Col, Spinner, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { arrayValueSum, errorHandling } from '../../../../utlis';
import { download_delay, pendency_pending_order_status, order_status, zone_category_id} from '../../../../config';

const PendingSummary = (props) => {

    const [summaryList, setSummaryList] = useState({});
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const [reportType, setReportType] = useState("is_courier_wise");
    const history = useHistory();  
    const reportTypeChange = (e) => {
        setReportType(e.target.value);
        listingSummary(e.target.value)
    }
    const listingSummary = (rType) => {
        setLoadertable(true);
        setSummaryList({})
        let data = {"report_type":checkUndeNullBlank(rType) ? rType : reportType}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_PENDING_SHIPMENT}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setSummaryList(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            errorHandling(err,"reload")
          });
    }
    useEffect(() => {
        listingSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getWeekFirstData(date) {
        let weekTotal=false;
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 7);

        if (date > start && date < end) {
        weekTotal = true
        }
        return weekTotal; 
      }
      function getWeekSecondData(date) {
        let weekTotal=false;
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 14);
        end.setDate(end.getDate() - 7);

        if (date > start && date < end) {
        weekTotal = true
        }
        return weekTotal; 
      }
      function getWeekThirdData(date) {
        let weekTotal=false;
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 21);
        end.setDate(end.getDate() - 14);

        if (date > start && date < end) {
        weekTotal = true
        }
        return weekTotal; 
      }
      function getWeekFourthData(date) {
        let weekTotal=false;
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 28);
        end.setDate(end.getDate() - 21);

        if (date > start && date < end) {
        weekTotal = true
        }
        return weekTotal; 
      }
      function getWeekFourthPlusData(date) {
        let weekTotal=false;
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 90);
        end.setDate(end.getDate() - 28);

        if (date > start && date < end) {
        weekTotal = true
        }
        return weekTotal; 
      }
      let weekOneTotal = 0
      let weekTwoTotal = 0
      let weekThreeTotal = 0
      let weekFourTotal = 0
      let weekFourPlusTotal = 0
      let grandTotal = 0

    let pendingStatus = checkData(summaryList) && Object.values(summaryList.reduce((osi, item) => {
        let pendingData = checkUndeNullBlank(item.courierName) && item.courierName
        if(checkUndeNullBlank(item.order_status_id) && reportType === "status_wise"){
            pendingData = item.order_status_id
        }else if(checkUndeNullBlank(item.zone_category_id) && reportType === "zone_wise"){
            pendingData = item.zone_category_id
        }else{
            pendingData = checkUndeNullBlank(item.courierName) && item.courierName
        }
        if (!osi[pendingData])
        osi[pendingData] = {date: [],total: [], weekOne: [], weekTwo: [], weekTree: [], weekFour: [], weekFourPlus : []};
        let dates = checkUndeNullBlank(item.dateOn) ? item.dateOn : 0;
        let totals = checkUndeNullBlank(item.total) ? item.total : 0;
        osi[pendingData].date.push(dates);
        osi[pendingData].total.push(totals);
        if(checkUndeNullBlank(item.order_status_id) && reportType === "status_wise"){
            osi[pendingData].order_status_id = checkUndeNullBlank(item.order_status_id) && item.order_status_id
        }else if(checkUndeNullBlank(item.zone_category_id) && reportType === "zone_wise"){
            osi[pendingData].zone_category_id = checkUndeNullBlank(item.zone_category_id) && item.zone_category_id
        }else{
            osi[pendingData].courier_name = checkUndeNullBlank(item.courierName) && item.courierName
            osi[pendingData].courier_id = checkUndeNullBlank(item.courier_id) && item.courier_id
        }
        if(getWeekFirstData(new Date(item.dateOn))){
            osi[pendingData].weekOne.push(totals);
            weekOneTotal += totals;
        }
        if(getWeekSecondData(new Date(item.dateOn))){
            osi[pendingData].weekTwo.push(totals)
            weekTwoTotal += totals;
        }
        if(getWeekThirdData(new Date(item.dateOn))){
            osi[pendingData].weekTree.push(totals)
            weekThreeTotal += totals;
        }
        if(getWeekFourthData(new Date(item.dateOn))){
            osi[pendingData].weekFour.push(totals)
            weekFourTotal += totals;
        }
        if(getWeekFourthPlusData(new Date(item.dateOn))){
            osi[pendingData].weekFourPlus.push(totals)
            weekFourPlusTotal += totals;
        }
        grandTotal = weekOneTotal+weekTwoTotal+weekThreeTotal+weekFourTotal+weekFourPlusTotal
        return osi;
    }, {}));
    
        // const date = new Date('2017-4-28');

    const exportShipment = (courierid,total) => {

        let data = {"all_shipment":1,"is_pending":1,"order_status_ids":pendency_pending_order_status}
        
        data.courier_id = checkData(courierid) ? courierid : '';
        data.total = checkUndeNullBlank(total) ? total : "";

        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id = props.user[0].id
        }else{
            data.merchant_id = props.user[0].id
        }
        data.is_export=1
        setLoader(true);
        axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
        .then((res) => {
        if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
            if(parseInt(res.data.errno)>0){
                    setLoader(false);
                    if(res.data.sqlMessage!==undefined){
                    Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
                }
            }else{
                if(res.data.is_export === 1){
                    setTimeout(function(){ 
                      setLoader(false);
                      history.push("/my-downloads"); 
                    },download_delay);
                }
            }
        }}).catch((err) => {
            errorHandling(err,"")
        });
        
    }

    const { register:filter, handleSubmit:filterPendingSummarySubmit} = useForm();
    const onFilterPendingSummary = (data) => {};
        
    return (
        <div className="PendingSummary">    
            {loader && <div className="formLoader"><Spinner /></div>}        
            <Card className="border-0 shadow">
                <CardHeader className="page-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Pending Summary</CardTitle>  
                </CardHeader>
                <Form onSubmit={filterPendingSummarySubmit(onFilterPendingSummary)} className="cardFilter mb-3 mb-sm-0">   
                    <Row className="mx-0 justify-content-start justify-content-md-center">
                        <Col md={5} lg={5} className="pl-0 mb-3">
                            <div className="d-flex flex-wrap">
                                <FormGroup className="mb-md-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("report_type")} type="radio" value='is_courier_wise' id="courierwise"  onChange={ (e) => reportTypeChange(e)} defaultChecked/>
                                        <Label className="mx-2" for="courierwise">Courier Wise</Label>
                                    </span>
                                </FormGroup>
                                <FormGroup className="mr-sm-4 mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("report_type")} type="radio" value='status_wise' id="statuswise"  onChange={ (e) => reportTypeChange(e)} />
                                        <Label className="mx-2" for="statuswise">Status Wise</Label>
                                    </span>
                                </FormGroup>
                                <FormGroup className="mr-sm-4 mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("report_type")} type="radio" value='zone_wise' id="zoningwise"  onChange={ (e) => reportTypeChange(e)} />
                                        <Label className="mx-2" for="zoningwise">Zone Wise</Label>
                                    </span>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Couriers</th>
                                <th className="text-nowrap">Week 1</th>
                                <th className="text-nowrap">Week 2</th>
                                <th className="text-nowrap">Week 3</th>
                                <th className="text-nowrap">Week 4</th>
                                <th className="text-nowrap">Week 4+</th>
                                <th className="text-nowrap">Total</th>
                            </tr>                            
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-nowrap">Total</th>
                                <td className="text-nowrap">{weekOneTotal}</td>
                                <td className="text-nowrap">{weekTwoTotal}</td>
                                <td className="text-nowrap">{weekThreeTotal}</td>
                                <th className="text-nowrap">{weekFourTotal}</th>
                                <td className="text-nowrap">{weekFourPlusTotal}</td>
                                <td className="text-nowrap"><div className="text-left mr-auto dowbload-con d-flex justify-content-between">{grandTotal}<span className="download-icon mx-1" onClick={()=>{exportShipment([],grandTotal)}}><FontAwesomeIcon icon={faDownload} /></span></div></td>
                            </tr>  
                            {checkData(pendingStatus) ? pendingStatus.map((courierItem, index) => {
                                let wOne = courierItem.weekOne.length > 0 ? arrayValueSum(courierItem.weekOne): 0;
                                let wTwo = courierItem.weekTwo.length > 0 ? arrayValueSum(courierItem.weekTwo): 0;
                                let wThree = courierItem.weekTree.length > 0 ? arrayValueSum(courierItem.weekTree): 0;
                                let wFour = courierItem.weekFour.length > 0 ? arrayValueSum(courierItem.weekFour): 0;
                                let wFourPlus = courierItem.weekFourPlus.length > 0 ? arrayValueSum(courierItem.weekFourPlus): 0;
                                let gTotal = wOne+wTwo+wThree+wFour+wFourPlus;
                                   return(
                                    <tr key={index}>
                                        <td className="w-some">{checkUndeNullBlank(courierItem.courier_name) ? courierItem.courier_name : checkUndeNullBlank(courierItem.order_status_id) ? order_status[courierItem.order_status_id] : checkUndeNullBlank(courierItem.zone_category_id) ? zone_category_id[courierItem.zone_category_id] : ''}</td>
                                        <td>{wOne}</td>
                                        <td>{wTwo}</td>
                                        <td>{wThree}</td>
                                        <td>{wFour}</td>
                                        <td>{wFourPlus}</td>
                                        <td><div className="text-left mr-auto dowbload-con d-flex justify-content-between">{gTotal}{gTotal > 0 && <span className="download-icon mx-1" onClick={()=>exportShipment([courierItem.courier_id],gTotal)}><FontAwesomeIcon icon={faDownload} /></span>}</div></td>
                                    </tr>
                                    );
                                }) :
                                loadertable ? <tr>
                                <td colSpan="7">loading...</td>
                                </tr>
                                :<tr><td colSpan="7" className="text-center">No Data Found</td></tr>
                            }  
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}

export default PendingSummary;