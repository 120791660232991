import React, { useState, useEffect } from "react";
import { Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Button, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import City from './City';
import State from "./State";
import Pincode from "./Pincode";
import AddCity from "./AddCity";
import AddPincode from "./AddPincode";
import { useParams, useHistory } from "react-router-dom";
import { checkPermission } from "../../ObjectExist";

const Master = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [dataAdd, setDataAdd] = useState(false);

    const DEFAULT_ACTIVE_TAB = "city";
    const { active_tab } = useParams();
    const history = useHistory();
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        if (tab === "1") {
            history.push(`/master/city`);
        } else if (tab === "2") {
            history.push(`/master/state`);
        } else if (tab === "3") {
            history.push(`/master/pincode`);
        }
    }
    const toggleDataAdd = () => setDataAdd(!dataAdd);
    useEffect(() => {
        if (!active_tab) {
            history.push(`master/${DEFAULT_ACTIVE_TAB}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="content-body">
            <div className="background-strip"></div>
            <Container fluid>
                <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
                    <div class="mb-3 mr-3">
                        <h4 class="text-white font-w600 mb-0">Manage Masters</h4>
                    </div>
                    <Nav tabs className="master-tabs">

                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_City?.list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <span className="text-white">City</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_State?.list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    <span className="text-white">State</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_Pincodes?.list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    <span className="text-white">Pincode</span>
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                    <div className="modal-btn">
                        {activeTab !== '2' &&
                            <>
                                {activeTab === '1' && checkPermission(props.user, props?.user[0]?.permissions?.manage_City?.add) && <Button color="light" onClick={toggleDataAdd}>Add City</Button>}
                                {activeTab === '3' && checkPermission(props.user, props?.user[0]?.permissions?.manage_Pincodes?.add) && <Button color="light" onClick={toggleDataAdd}>Add Pincode</Button>}
                            </>
                        }
                    </div>
                    <Modal isOpen={dataAdd} toggle={dataAdd} >
                        <ModalHeader toggle={toggleDataAdd}>Add {activeTab === '1' && 'City'} {activeTab === '2' && 'State'} {activeTab === '3' && 'Pincode'}</ModalHeader>
                        <ModalBody>
                            {activeTab === '1' && <AddCity setDataAdd={setDataAdd} />}
                            {activeTab === '3' && <AddPincode setDataAdd={setDataAdd} />}
                        </ModalBody>
                    </Modal>
                </div>
                <div className="master-content card border-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_City?.list) &&
                                        <City user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_State?.list) &&
                                        <State />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_Pincodes?.list) &&
                                        <Pincode user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    )
}
export default Master