import React, {useState, useEffect} from "react";
import { Table,Pagination, PaginationItem, PaginationLink, CardTitle,Button, Form,Input, FormGroup, Row, Col, Spinner,  } from "reactstrap";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { changeIntoDateTime } from "../../utlis";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { connect, useDispatch} from "react-redux";
import { AllState } from "../../actions";
import Swal from "sweetalert2";
import { baseString } from "../../config";

const State = (props) => {
  const [state, setState] = useState({});
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const dropFilterCount = 20
  const dispatch = useDispatch();

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
}
  const tableDataFetch = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = {}
      let myget = {"page":page, "per_page":selectperpage};
      dispatch(AllState(mypost,myget)).then((response) => {
        setState(response)
        setLoadertable(false);
      }).catch((err) =>{
        setLoadertable(false);
        Swal.fire({title: 'Error', text: err, icon: 'error'})
        });
  }
  useEffect(() => {
    if(checkData(props.state) && checkData(props.state.viewAllState)){
      setState(props.state.viewAllState);
    }else{
    tableDataFetch();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  function nextPageData() {
    if(checkData(state.data)&&checkUndeNullBlank(state.totalPage)){
      // &&checkUndeNullBlank(state.totalPage).length>page.length
        if(state.totalPage > page){
          setCurrentCount(currentCount+Object.entries(state.data).length)
      setPage(page+1)
      setLoader(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(state.data)
    if(currentCount>Object.entries(state.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
    
  };
  const onSubmit = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.state)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
      fetch(`${process.env.REACT_APP_GET_STATE}?state=${checkUndeNullBlank(data.state)?data.state:''}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          'x-ref': window.btoa(baseString)
        },
      }).then((resp)=>{
        resp.json().then((result)=>{
          if(result.fatal !== true){
            setLoader(false)
            setState(result)
          }
        });
      }).catch((err) => {
        setLoader(false);
        Swal.fire({icon:"error", title:"Error", text:err})
      });
  };

    return(
      <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row className="justify-content-between card-header align-items-center px-3 pt-3">
          <Col sm={3}><CardTitle>Manage State</CardTitle></Col>
          <Col sm={3}>
          {checkData(state) && checkData(state.data) && checkUndeNullBlank(state.TotalRows) ?
                <>
                {state.TotalRows > dropFilterCount ?
            <Form>
              <FormGroup>
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                  <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      </Input>
              </FormGroup>
            </Form>
            :''}
            </>
            :''}
          </Col>
        </Row>
        <div className="filter-form">
          <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
            <Row className="mx-0 pt-1 justify-content-start justify-content-lg-center">
              <Col sm={6} md={4} lg={3} xl={3}>
                <FormGroup>
                  <input {...register("state")}  className="form-control-sm form-control" placeholder="Enter State" />
                </FormGroup>
              </Col>
              <Col sm={3} className="filter-btn">
                <Button className="btn btn-sm" color="primary">Search</Button>
                <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={tableDataFetch}>Reset</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Table responsive borderless className="text-center customTable">
            <thead>
            <tr>
            <th>ID</th>
            <th>State</th>
            <th>Created</th>
            </tr>
            </thead>
            <tbody>
            {checkData(state.data) ? state.data.map((item, index) => (
            <tr>
                <td>{item.id}</td>
                <td>{item.state}</td>
                <td>{item.created_at !== undefined && item.created_at !== null ? changeIntoDateTime(item.created_at):'NA'}</td>
                </tr>
            ))
        :loadertable ? <td colSpan="4">Loading...</td>:
        <td colSpan="4">No Data Found</td> }
            </tbody>
        </Table>
        {checkData(state) && checkData(state.data) && checkUndeNullBlank(state.TotalRows) ?
                <>
                {state.TotalRows > dropFilterCount ?
        <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
          <div className="showing-count">
          Showing {currentCount} to {(currentCount-1)+(Object.entries(state.data).length)} of {state.TotalRows} entries
          </div>
          <Pagination aria-label="Page navigation example">
              <PaginationItem>
              <PaginationLink previous href="#" onClick={previousPageData}>Previous</PaginationLink> 
              </PaginationItem>
              <PaginationItem>
              <PaginationLink next href="#" onClick={nextPageData}>Next</PaginationLink>
              </PaginationItem>
          </Pagination>
        </div>
        :''}
        </>
        :''}
        </>
    )
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(State);