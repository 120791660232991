import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup,Form, CardHeader, Label, Button, Table, Collapse, Spinner} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ArrowLeft } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from '../../CommonAxios';
// import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { states } from '../../config';
import { randomNumberByDate } from '../../utlis';
import { useHistory } from 'react-router-dom';
import no_record from "../../assets/images/no_record.png";

const CreateOrder = (props) => {

    const [isCod, setIsCod] = useState(false);
    const [kyc, setKyc] = useState("");
    const [kycNoValid, setKycNoValid] = useState(false);
    const [isWalkinCustomer, setIsWalkinCustomer] = useState(false);
    const [loader, setLoader] = useState(false);
    const [courierwithPrice, setCourierwithPrice] = useState({});
    const [pickupLocation, setPickupLocation] = useState({});
    const [pickupKey, setPickupKey] = useState('');
    const [zoneAndPrice, setZoneAndPrice] = useState({});
    const [quantity, setQuantity] = useState('');
    const [unitprice, setUnitprice] = useState('');
    const [destinationDetailsInput, setDestinationDetailsInput] = useState({});
    const [walkinDetailsInput, setWalkinDetailsInput] = useState({});
    console.log('zoneAndPrice', zoneAndPrice);
    const handlePickuplocation =(e) => {
        let index =  e.target.selectedIndex;
         var optionElement= e.target.childNodes[index];
         var pickupIndex= optionElement.getAttribute('data-id');
         setPickupKey(pickupIndex)
    }
    const history = useHistory();
    const inputChange = (e) => {
        [ 
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        // if(parseInt(e.target.value) === 3){
        //     [ 
        //         { name: 'cod_value', value: '' }
        //     ].forEach(({ name, value }) => setValue(name, value)) 
        // }
        setZoneAndPrice({});
        setCourierwithPrice({});
   }
   const inputChangeDtype = (e) => {
    [
        { name: 'courier_id', value: '' }
    ].forEach(({ name, value }) => setValue(name, value));
    if (parseInt(e.target.value) === 3) {
        [
            { name: 'cod_value', value: '' }
        ].forEach(({ name, value }) => setValue(name, value))
    }
    setZoneAndPrice({});
    setCourierwithPrice({});
}
    const { register, handleSubmit, formState: { errors }, setValue} = useForm();
    const walkinDetail = (e) => {
        setWalkinDetailsInput({});
        if(e.target.value.length === 10){
        let mydata = {}
    if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    mydata = {"merchant_id":props.user[0].id,"search_mobile":e.target.value}
    // walkin_mobile
    }
    axios({
        method: "post",
        url:`${process.env.REACT_APP_WALKIN_CUST_DETAIL}`,
        data: mydata,
      })
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
            if(res.data.error === false && checkData(res.data.data)){
                let myResponse = {}
                // myResponse = {"walkin_mobile":res.data.data[0].mobile,"walkin_full_name":res.data.data[0].name,"walkin_email":res.data.data[0].email,"walkin_kyc":res.data.data[0].kyc_type_id,"walkin_kyc_no":res.data.data[0].kyc_no }
                myResponse = {"walkin_mobile":checkUndeNullBlank(res.data.data[0].mobile)?res.data.data[0].mobile:"","walkin_full_name":checkUndeNullBlank(res.data.data[0].name)?res.data.data[0].name:"","walkin_email":checkUndeNullBlank(res.data.data[0].email)?res.data.data[0].email:"","walkin_kyc":checkUndeNullBlank(res.data.data[0].kyc_type_id)?res.data.data[0].kyc_type_id:"","walkin_kyc_no":checkUndeNullBlank(res.data.data[0].kyc_no)?res.data.data[0].kyc_no:"" }
            // reset(myResponse)
                setWalkinDetailsInput(myResponse)
                if(checkData(myResponse)){
                    [ 
                        { name: 'walkin_full_name', value: myResponse.walkin_full_name },
                        { name: 'walkin_email', value: myResponse.walkin_email },
                        { name: 'walkin_kyc', value: myResponse.walkin_kyc },
                        { name: 'walkin_kyc_no', value: myResponse.walkin_kyc_no }
                    ].forEach(({ name, value }) => setValue(name, value))
                }
            }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
    }
    }


    const destinationDetails = (e) =>{
        setDestinationDetailsInput({})
        if(e.target.value.length === 10){
        console.log('eee', e.target.value)
        let mydata = {}
    if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    mydata = {"merchant_id":props.user[0].id,"search_mobile":e.target.value}
    // walkin_mobile
    }
    axios({
        method: "post",
        url:`${process.env.REACT_APP_DEST_CUST_DETAIL}`,
        data: mydata,
      })
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
            if(res.data.error === false && checkData(res.data.data)){
            let myResponse = {}
            myResponse = {"customer_name":res.data.data[0].customer_name,"customer_mobile":e.target.value,"customer_address_1":res.data.data[0].customer_address_1,"customer_address_2":res.data.data[0].customer_address_2,"destination_pincode":res.data.data[0].destination_pincode,"customer_city":res.data.data[0].customer_city,"customer_state":res.data.data[0].customer_state,"customer_email":res.data.data[0].customer_email }
            // reset(myResponse)
            setDestinationDetailsInput(myResponse)
            if(checkData(myResponse)){
                [ 
                    { name: 'customer_name', value: myResponse.customer_name },
                    { name: 'customer_address_1', value: myResponse.customer_address_1 },
                    { name: 'customer_address_2', value: myResponse.customer_address_2 },
                    { name: 'destination_pincode', value: myResponse.destination_pincode },
                    { name: 'customer_city', value: myResponse.customer_city },
                    { name: 'customer_state', value: myResponse.customer_state },
                    { name: 'customer_emai', value: myResponse.customer_emai }
                 ].forEach(({ name, value }) => setValue(name, value))
            }
        }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
    }
    }


    useEffect(() => {
    let mydata = {}
  if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
   mydata = {"merchant_id":props.user[0].id}
  }
    axios({
        method: "post",
        url:`${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
        data: mydata,
      })
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
            if(res.data.error === false){
            setPickupLocation(res.data.data);
        }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
   
    const onSubmitPrice = (data) => {
        // setInvoiceValue(data.product_qty*data.product_unit_price)
        [ 
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value))
        setZoneAndPrice({});
        setCourierwithPrice({});
        setLoader(true);
        let formData = new FormData();
        if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
        formData.append("merchant_id", props.user[0].id);
        formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
        }
        formData.append("delivery_type_id", data.delivery_type_id);
        formData.append("cod_value", checkUndeNullBlank(data.cod_value) ? data.cod_value : '');
        formData.append("source_pincode", pickupLocation[pickupKey].pickup_pincode);
        formData.append("destination_pincode", data.destination_pincode);
        formData.append("weight_in_kgs", data.weight_in_kgs);
        formData.append("length_in_cms", data.length_in_cms);
        formData.append("breadth_in_cms", data.breadth_in_cms);
        formData.append("height_in_cms", data.height_in_cms);
        formData.append("service_id", data.service_id);
     axios({
       method: "post",
       url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
       data: formData,
     })
       .then(function (response) {
        setLoader(false);
        if(response.data.error === false){
            setCourierwithPrice(response.data.data)
          }
        if(response.data.error === true){
            Swal.fire({
              title: 'Error',
              text: response.data.msg,
              icon: 'error',
            })
          }
       })
       .catch(function (response) {
        setLoader(false);
        Swal.fire({title:'Error', text:response, icon:'error'})
       });
    }
    const onSubmitOrder = (data) => {
        if(data.service_id === "6"){
            data.movement_type_id = '2'
        }else{
            data.movement_type_id = '1'
        }
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
            data.admin_id = process.env.REACT_APP_ADMIN_ID
        } else {
            data.admin_id = props.user[0].id
        }
        setLoader(true);
        if(checkData(data.courier_id)){

            let diffrence_balance = (parseInt(zoneAndPrice.total_price)>0 &&  parseInt(props.user[0].wallet_balance)>0)?(parseInt(zoneAndPrice.total_price) - parseInt(props.user[0].wallet_balance)):0;
            if(checkData(props.user) && props.user[0].wallet_balance >= zoneAndPrice.total_price){
            data.merchant_wallet_balance=props.user[0].wallet_balance
            data.invoice_value = data.product_qty*data.product_unit_price
            data.all_charges = zoneAndPrice.all_prices
            data.freight = zoneAndPrice.total_price
            data.zone_category_id = zoneAndPrice.zone_id
            data.pickup_pincode = pickupLocation[pickupKey].pickup_pincode!==undefined?pickupLocation[pickupKey].pickup_pincode:'';
            data.customer_type = 1
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CREATE_SINGEL_ORDER}`,
                data: data,
            })
            .then(function (response) {
                setLoader(false);
                if(response.data.error === false){
                    Swal.fire({title: 'Success', text: response.data.msg, icon: 'success', })
                    if(response.data.status === 'assigned'){
                    history.push(`/order-detail/${response.data.order_id}`);
                }if(response.data.status === 'failed'){
                    history.push(`/failed-orders}`);
                }if(response.data.status === 'processing'){
                    history.push(`/processing-orders}`);
                }
                }
                if(response.data.error === true){
                    Swal.fire({title: 'Error', text: response.data.msg, icon: 'error',allowOutsideClick: false, allowEscapeKey: false });
                }
            })
            .catch(function (response) {
                setLoader(false);
                Swal.fire({title:'Error', text:response, icon:'error'})
            });
        }
        else{
            setLoader(false);
            Swal.fire({title:'Error', text:`Please Recharge Your Wallet With Amount ${diffrence_balance.toFixed(2)}. Your Current Balance is  ${props.user[0].wallet_balance} `, icon:'error'})
        }
    }else{
        setLoader(false);
        Swal.fire({title:'Error', text:`Please Choose Courier`, icon:'error'})
    }
    }

    // console.log(watch("customer_type"));
    const onErrorUpdate = (errors, e) => console.log('Error', errors);
    // console.log(errors)

    const getKycId = (e) =>{
        setKyc(e.target.selectedOptions[0].text);
        if(kyc !== "Select Kyc" || kyc !== ""){
            setKycNoValid(true);            
        }
    }
	
  const handleServiceChange = (e) => {
    //setInternetFee(handlingFee)
	setValue('service_id', e.target.value) ;
  }
  

    console.log("kyc",kyc)
    return (
          <div className="content-body content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
        <div className="background-strip"></div>
          <Row>
           
            
               <Col sm={6}>
			   
			   <div className="CreateOrder">
						<Form>
					
						<Card className="shadow border-0 mb-3 card">
						<div className="card-body">
						<div className="card-title title_icon"><i className="bi bi-card-heading"></i> Order Information   <Link to='/all-orders' className="small_btn"><span className="icon"><ArrowLeft /></span><span>Back to Uploaded List</span></Link> </div>

						<div className="forms">
						<Row>
						<Col md={12}>
						<CardHeader className="p-0"><CardTitle ></CardTitle></CardHeader>
						<FormGroup className='m-0'> 
						<label>Order Type</label>                                           
						<div className="d-flex">
						<span className="mr-2">
						<input {...register("delivery_type_id", { required: true })} type="radio" value="3" id="prepaid"  defaultChecked onClick={() => setIsCod(false)} onChange={(e) => inputChangeDtype(e)}/>
						<Label className="mx-2" for="prepaid">Prepaid</Label>
						</span>
						<span className="mr-2">
						<input {...register("delivery_type_id", { required: true })} type="radio" value="1" id="cod"  onClick={() => setIsCod(true)} onChange={(e) => inputChangeDtype(e)} />
						<Label className="mx-2" for="cod">COD</Label>
						</span>
						<Collapse isOpen={isCod}>
						{isCod &&
						<input {...register("cod_value", { required: 'Please Enter COD Amount' })} className="form-control-sm form-control" placeholder="Enter COD Amount" id="cod_amount" style={{width: "170px"}}/>
						}
						</Collapse> 
						</div>
						{errors.cod_value && <span className="text-danger d-block error">{errors.cod_value.message}</span>}
						</FormGroup>                                     
						</Col>
						<Col md={6}>
						<FormGroup className='m-0'> 
						<label>Order Number</label> 
						<input {...register("order_no", { required: 'Please Enter Order Number' })} className="form-control-sm form-control" defaultValue={randomNumberByDate()} id="order_no" />
						{errors.order_no && <span className="text-danger d-block error">{errors.order_no.message}</span>}
						</FormGroup>
						</Col>
						<Col md={6}>                  
						<FormGroup> 
						<label>Service Type</label>                                      
						<div className="d-flex flex-wrap">
						<select {...register("service_id")} className="form-control-sm form-control" onChange={handleServiceChange}>
						<option value="1">Express</option>
						<option value="6">Reverse</option>
						<option value="7">Surface</option>
						</select>
						 	
						</div>
						</FormGroup>                                      
						</Col>
						</Row> 
						</div> 
						</div>
						</Card>

						{isWalkinCustomer &&
						<Collapse isOpen={isWalkinCustomer}>
						<Card className="shadow border-0 mb-1">
						<CardBody>
						<Row>
						<Col sm={12}>
						<CardHeader className="px-0"><CardTitle>Walk In Customer Details</CardTitle></CardHeader>
						</Col>
						<Col md={4}>
						<FormGroup>
						 
						<input {...register("walkin_mobile", { required: 'Please Enter Mobile No.'})} className="form-control-sm form-control" placeholder="Enter Mobile, eg.987654321" id="mobile" onChange={walkinDetail} />
						{errors.walkin_mobile && <span className="text-danger d-block error">{errors.walkin_mobile.message}</span>}
						</FormGroup>
						</Col>
						<Col md={4}>
						<FormGroup>
						 
						<input {...register("walkin_full_name", { required: 'Please Enter Full Name',
						pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid Name'} })} className="form-control-sm form-control" placeholder="Full Name" id="walkin_full_name" />
						{errors.walkin_full_name && <span className="text-danger d-block error">{errors.walkin_full_name.message}</span>}
						</FormGroup>
						</Col>
						<Col md={4}>
						<FormGroup>
						 
						<input {...register("walkin_email", {pattern: {value:/\S+@\S+\.\S+/,message:'Please Enter Valid Email'} })} className="form-control-sm form-control" placeholder="Enter Email" id="email" />
						{errors.walkin_email && <span className="text-danger d-block error">{errors.walkin_email.message}</span>}
						</FormGroup>
						</Col>
						<Col md={6}>
						<FormGroup>
						<Label for="kyc">KYC <small>eg.Choose Adhar,Voter ID, Driving License</small></Label>
						<div className="position-relative">                                        
						<span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
						<select {...register("walkin_kyc")} className="form-control-sm form-control" id="kyc" onChange={(e) => getKycId(e)} >
						<option value="">Select Kyc</option>
						<option value="1">Adhar Card</option>
						<option value="2">Voter ID</option>
						<option value="3">Driving License</option>
						</select>
						</div>
						</FormGroup>
						</Col>
						<Col md={6}>
						<FormGroup>
						<Label for="valid_id">Enter Valid {kyc !== "" ? kyc : "KYC Number"}</Label>
						<input {...register("walkin_kyc_no", {required: kycNoValid ? 'Please Select Pickup Location' : false  })} className="form-control-sm form-control" placeholder={kyc} id="walkin_kyc_no" />
						{errors.walkin_kyc_no && <span className="text-danger d-block error">{errors.walkin_kyc_no.message}</span>}                                           
						</FormGroup>
						</Col>
						</Row>
						</CardBody>
						</Card>
						</Collapse>
						}
						
						
						
						<Card className="shadow border-0 p-3 mb-3">
						 
						<div className="card-title title_icon"><i className="bi bi-building"></i>Warehouse Details</div>
						<FormGroup>
						 
						<div className="position-relative">                                        
						<span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
						<select {...register("pickup_point_id", { required: 'Please Select Pickup Location' })} className="form-control-sm form-control" id="pickup_point_id" onChange={handlePickuplocation}>
						<option value="">Select Pickup Location</option>
						{pickupLocation !== undefined && pickupLocation !== null && Object.entries(pickupLocation).length >0 ? pickupLocation.map((item, index)=>(
						<option data-id={index} value={item.id}>{item.contact_name}</option>
						))
						:''}
						</select>
						</div>
						{errors.pickup_point_id && <span className="text-danger d-block error">{errors.pickup_point_id.message}</span>}
						</FormGroup>

						{ /* <div className="card-title title_icon"><i className="bi bi-geo"></i> Pickup Address</div> */ }
						<FormGroup>
						{checkData(pickupLocation) && checkData(pickupKey) ?
						<p className="mb-0">{checkData(pickupLocation[pickupKey].contact_name) && pickupLocation[pickupKey].contact_name},<br/><span className="mr-3">{checkData(pickupLocation[pickupKey].pickup_address_1) && pickupLocation[pickupKey].pickup_address_1}</span>{checkData(pickupLocation[pickupKey].pickup_city_name) && pickupLocation[pickupKey].pickup_city_name} <br/> {states[pickupLocation[pickupKey].pickup_state_id]} , {pickupLocation[pickupKey].pickup_pincode}</p>:'Please Choose Pickup Location First'
						}
						</FormGroup>
						 
						</Card>

						
						<Card className="shadow border-0 p-3 mb-3">
						<div className="card-title title_icon"><i className="bi bi-person-vcard"></i> Customer Details</div>
						<FormGroup>
						 
						<input {...register("customer_mobile", { required: 'Please Enter Mobile Number',
						pattern: {value:/^(\+\d{1,3}[- ]?)?\d{10}$/,message:'Please Enter Valid Mobile Number'}})} className="form-control-sm form-control" placeholder="Contact Mobile eg.987654321" id="customer_mobile" onChange={destinationDetails} />
						{errors.customer_mobile && <span className="text-danger d-block error">{errors.customer_mobile.message}</span>}
						</FormGroup>

						<FormGroup>
						
						<input {...register("customer_name", { required: 'Please Enter Contact Name',
						pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid Contact Name'} })} className="form-control-sm form-control" placeholder="Contact/Full Name" id="customer_name" />
						{errors.customer_name && <span className="text-danger d-block error">{errors.customer_name.message}</span>}
						</FormGroup>

						<FormGroup>
						
						<input {...register("customer_address_1", { required: 'Please Enter Address 1.'})} className="form-control-sm form-control" placeholder="Enter Address 1" id="customer_address_1" />
						{errors.customer_address_1 && <span className="text-danger d-block error">{errors.customer_address_1.message}</span>}
						</FormGroup>

						<FormGroup>
						 
						<input {...register("customer_address_2")} className="form-control-sm form-control" placeholder="Enter Address 2 eg.Area name" id="address2" />
						</FormGroup>
						
						<Row> <Col sm={6}>
						<FormGroup>
						<input {...register("destination_pincode", { required: 'Please Enter Pincode.',
						minLength: { value: 6, message: "at least 6 digits"},
						maxLength: { value: 6, message: "maximum 6 digits"},
						pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Pincode'} })} className="form-control-sm form-control" placeholder="Enter Pincode" id="pincode" />
						{errors.destination_pincode && <span className="text-danger d-block error">{errors.destination_pincode.message}</span>}
						</FormGroup>
						</Col>
						
						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("customer_city", { required: 'Please Enter City.',
						pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid City'} })} className="form-control-sm form-control" placeholder="Enter City" id="city" />
						{errors.customer_city && <span className="text-danger d-block error">{errors.customer_city.message}</span>}
						</FormGroup>
                        </Col>
						</Row>
						 
						<Row> <Col sm={6}> 
						<FormGroup>
						 
						<input {...register("customer_state", { required: 'Please Enter State.',
						pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid State'} })} className="form-control-sm form-control" placeholder="Enter State" id="state" />
						{errors.customer_state && <span className="text-danger d-block error">{errors.customer_state.message}</span>}
						</FormGroup>
						</Col>
						
						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("customer_email", {pattern: {value:/\S+@\S+\.\S+/,message:'Please Enter Valid Email'} })} className="form-control-sm form-control" placeholder="Enter Email" id="email" />
						{errors.customer_email && <span className="text-danger d-block error">{errors.customer_email.message}</span>}
						</FormGroup>
						</Col>
						</Row>
						
						</Card>
						
						<Card className="shadow border-0 p-3 mb-3">
						<Row className="align-items-baseline">
						<Col sm={12}><div className="card-title title_icon">
						<i className="bi bi-box"></i> Product Information</div>
						</Col>

						<Col sm={12}>
						<FormGroup>
						<input {...register("product_name", { required: 'Please Enter Product Name'})} className="form-control-sm form-control" placeholder="Enter Product Name" id="product_name" />
						{errors.product_name && <span className="text-danger d-block error">{errors.product_name.message}</span>}
						</FormGroup>
						</Col>
						
						<Col sm={12}>
						<Row> <Col sm={6}> 	
						<FormGroup>
						 
						<input {...register("product_qty", { required: 'Please Enter Quantity',
						pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Quantity'} })} className="form-control-sm form-control" placeholder="Enter Product QTY" id="quantity" onChange={e => setQuantity(e.target.value)} />
						{errors.product_qty && <span className="text-danger d-block error">{errors.product_qty.message}</span>}
						</FormGroup>
						</Col>
						
						<Col sm={6}>
						
						<FormGroup>
						 
						<input {...register("product_unit_price", { required: 'Please Enter Unit Value',
						pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Unit Value'} })} className="form-control-sm form-control" placeholder="Enter Unit Value" id="product_unit_price"  onChange={e => setUnitprice(e.target.value)} />
						{errors.product_unit_price && <span className="text-danger d-block error">{errors.product_unit_price.message}</span>}
						</FormGroup>
						</Col>
						</Row>
						<Row>
						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("weight_in_kgs", { required: 'Please Enter Weight',
						pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Weight'} })} className="form-control-sm form-control" placeholder="Package Weight In KGS,  eg.0.5" id="weight_in_kgs" />
						{errors.weight_in_kgs && <span className="text-danger d-block error">{errors.weight_in_kgs.message}</span>}
						</FormGroup>
						</Col>

						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("length_in_cms", { required: 'Please Enter Length',
						pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Length'} })} className="form-control-sm form-control" id="length_in_cms" placeholder="Dimension (Length In CMS)" />
						{errors.length_in_cms && <span className="text-danger d-block error">{errors.length_in_cms.message}</span>}
						</FormGroup>
						</Col>
						</Row>
						<Row>
						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("breadth_in_cms", { required: 'Please Enter Breadth ',
						pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Breadth '} })} className="form-control-sm form-control" id="breadth_in_cms" placeholder="Dimension (Breadth In CMS)" />
						{errors.breadth_in_cms && <span className="text-danger d-block error">{errors.breadth_in_cms.message}</span>}
						</FormGroup>
						</Col>

						<Col sm={6}>
						<FormGroup>
						 
						<input {...register("height_in_cms", { required: 'Please Enter Height',
						pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Height '} })} className="form-control-sm form-control" id="height_in_cms" placeholder="Dimension (Height In CMS)" />
						{errors.height_in_cms && <span className="text-danger d-block error">{errors.height_in_cms.message}</span>}
						</FormGroup>
						</Col>
						</Row>
	
						{quantity !== '' && unitprice !== '' &&
						<Col sm={12} className="d-flex">
						<FormGroup className="font-weight-500 ml-auto">
						<span>Invoice Value :</span>
						<span>  {quantity*unitprice}</span>
						</FormGroup>
						</Col>
						}
						
						</Col>
						</Row>
						
						
						<Button className="btn btn-sm" color="primary" onClick={handleSubmit(onSubmitPrice, onErrorUpdate)}>Get Courier</Button>
						 
						</Card>
						</Form>
			   </div>
			   
			   </Col>
			  
               <Col sm={6}>
			     
                <Form className="create_order_block">
						<Card className="shadow border-0 mb-1 minheight">
						<CardBody>
						<div className="card-title title_icon"><i className="bi bi-truck"></i>Choose A Courier </div>
						<Row>
						   { checkData(courierwithPrice) ? courierwithPrice.map((item, index)=>( 
						   
							<Col md={6}>
								<div className="table_block">
									<ul>
									
									<li><strong>Choose Courier</strong><span><input type="radio" disabled={(checkData(item.price)&&checkUndeNullBlank(item.price.total)&&item.price.total >= 0?false:true)} {...register("courier_id")} value={item.courier_id} onClick={() => setZoneAndPrice({"zone_id" : item.zone_category_id, "total_price" : checkData(item.price)&&checkUndeNullBlank(item.price.total)&&item.price.total, "all_prices":item.price, "courier_id":item.courier_id})} /></span></li>
									
									<li><strong>Courier</strong><span>{item.courier_name}</span></li>
									<li><strong>Charged Weight<br/><small> (In KGS.)</small></strong><span>{item.charged_weight_in_kgs || 'NA'}</span></li>
									<li><strong>Price</strong> <span>{checkData(item.price) && checkUndeNullBlank(item.price.total)&&item.price.total.toFixed(2) || 'NA'}</span></li>
									<li><strong>TAT</strong><span>{item.tat_in_days || 'NA'}</span></li>
									</ul>
								</div>
							</Col>
							  ))
								:loader ? <div className="text-center"> Loading...</div> :
							    <div className="text-center"><br/> <img className="" src={no_record} alt="no record found" /> <br/> <br/> No Data Found </div> }
							
						</Row>
						
						{ /* <Table responsive borderless className="text-center customTable">
						<thead>
						<tr>
						<th>Choose Courier</th>
						<th>Courier</th>
						<th>Charged Weight In KGS.</th>
						<th>Price</th>
						<th>TAT</th>
						</tr>
						</thead>
						<tbody> 
						{checkData(courierwithPrice) ? courierwithPrice.map((item, index)=>(                        
						<tr>
						<td><input type="radio" disabled={(checkData(item.price)&&checkUndeNullBlank(item.price.total)&&item.price.total >= 0?false:true)} {...register("courier_id")} value={item.courier_id} onClick={() => setZoneAndPrice({"zone_id" : item.zone_category_id, "total_price" : checkData(item.price)&&checkUndeNullBlank(item.price.total)&&item.price.total, "all_prices":item.price, "courier_id":item.courier_id})} /><br />
						</td>
						<td>{item.courier_name}</td>
						<td>{item.charged_weight_in_kgs || 'NA'}</td>
						<td>{checkData(item.price) && checkUndeNullBlank(item.price.total)&&item.price.total.toFixed(2) || 'NA'}</td>
						<td>{item.tat_in_days || 'NA'}</td>
						</tr>
						))
						:loader ? <tr> <td colSpan="7">Loading...</td> </tr> :
						<tr> <td colSpan="6">No Data Found</td> </tr> }
						</tbody>
						</Table> */ }
						{checkData(courierwithPrice) &&     
						<Row>
						<Col sm={12} className="text-right">
						<Button className="btn btn-sm" color="primary" onClick={handleSubmit(onSubmitOrder, onErrorUpdate)}>Place Order</Button>
						</Col>
						</Row> 
						}
						</CardBody>
						</Card>
				 </Form> 
				   
				</Col> 
           
			</Row>
			</div>
    );
}

export default CreateOrder;