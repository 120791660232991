import React, {useEffect,useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, Spinner} from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'

const CourierSpocDetails = (props) => {
  const [loader, setLoader] = useState(false);

    
  const { register:spocDetails, handleSubmit:spocSubmit, formState: { errors: errors4 }, reset:reset4 } = useForm();
  useEffect(() => {
    axios({
        method: "post",
        url: `${process.env.REACT_APP_COURIER_SPOCDETAILS}/view`,
        data: {"courier_id": props.courierId !== undefined ? props.courierId:''},
      })
        .then(function (response) {
          let myResponse=response.data[0];
          reset4(myResponse);
        })
        .catch(function (response) {
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reset4]);
    const onSpocSetails = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("courier_id",  props.courierId !== undefined ? props.courierId:'');
        formData.append("remittance_email", data.remittance_email);
        formData.append("pendency_email", data.pendency_email);
      
        axios
          .post(process.env.REACT_APP_COURIER_SPOCDETAILS, formData)
          .then((res) => {
        setLoader(false);
        if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.message,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
        setLoader(false);
        Swal.fire({title:'Error', text:err, icon:'error'});
          });
      };
     
      const onspocError = (errors4, e) => console.log('Error', errors4);


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={spocSubmit(onSpocSetails,onspocError)}>
                  <Row className="mx-0 pt-3">
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="remittanceEmail">Enter Remittance Email <small>(Enter Coma Separated)</small></Label>
                          <input {...spocDetails("remittance_email",{ required: 'Please Enter Remittance Email'})} className="form-control-sm form-control" placeholder="Enter Remittance Email" id="remittanceEmail"/>
                          {errors4.remittance_email && <span className="text-danger d-block mb-2">{errors4.remittance_email.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="pendencyEmail">Enter Pendency Email <small>(Enter Coma Separated)</small></Label>
                          <input {...spocDetails("pendency_email",{ required: 'Please Enter Pendency Email'})} className="form-control-sm form-control" placeholder="Enter Pendency Email" id="pendencyEmail"/>
                          {errors4.pendency_email && <span className="text-danger d-block mb-2">{errors4.pendency_email.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn  btn-sm save" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
        </>
    );
}

export default CourierSpocDetails;
