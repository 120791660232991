import React, {useState} from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Key } from 'react-feather';
import ChangePassword from './ChangePassword';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZoneCode from './ZoneCode';

const AccountSetting = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="content-body overflow-hidden pr-3">
          <Row>
           <Col sm={12}>            
            <div className="p-0 my-3 adminAccountSetting content-wrapper">
                <div className="content-header mb-4">
                    <div className="page-title">Account Setting</div>
                </div>
                <Row>
                    <Col md={12}>
                        <Nav tabs pills className="mb-3 master-tabs border-0 justify-content-center">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Key /></span>
                                        <span>Change Password</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2 d-flex"><FontAwesomeIcon icon={faMap} /></span>
                                        <span>Zone Code</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col md={12}>
                        <Card className="shadow border-0">
                            <CardBody className="p-4">
                                <TabContent activeTab={activeTab} className="pb-0">
                                    <TabPane tabId="1">
                                        <ChangePassword user={props.user} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <ZoneCode user={props.user} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>                          
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}

export default AccountSetting;