import React, {useState, useEffect} from 'react';
import { Card, CardBody,Button, FormGroup, Table, Form, Label,Col, Row } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowUp, faArrowDown, faDownload, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import axios from '../../../../CommonAxios'; 
import Swal from 'sweetalert2';
import { delivered_order_status, overall_pending_order_status,pending_order_status, rto_order_status,failed_order_status } from '../../../../config';
import { firstDayOftheMonth, changeIntoDate, changeIntoDateYmd } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
// import NodataFound from '../../../NodataFound';
// import LoaderDefault from '../../../LoaderDefault';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import Performance_Icon from "../../../../assets/images/perpefmance_icon.png" ;

const PerformanceOverview = (props) => {
    const [allCourier, setAllCourier] = useState([]);
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [filterDatePerf, setFilterDatePerf] = useState([]);
    const [pradioVal, setPradioVal] = useState(1);
    const [datestate, setDateState] = useState({start: moment().subtract(31, 'days'),end: moment(),});
    const performanceRadio = (e) => {
        setPradioVal(e.target.value);
    }
    const { start, end } = datestate;
    const handleCallback = (start, end) => {
        setDateState({ start, end });
    };
    const ranges = {
        startDate: start !== '' && start.toDate(),
        endDate: end !== '' && end.toDate(),
        ranges: {
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
        ],
        'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        },
    }
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;
   
    const allCourierMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "All Couriers"
    }
    // const opt_allCourier = [
    //     { label: "Fedex (Express - 0.5KG)", value: "Fedex (Express - 0.5KG)" },
    // ];

    var opt_allCourier = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
        Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
        let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
        return opt_allCourier.push(tempObj)
        });
    }


    const listingPerformance = () => {
        setLoadertable(true);
        var date = new Date();
        
        setFilterDatePerf([date.setDate(date.getDate() - 31),new Date()]);
        setDateState({start: moment().subtract(31, 'days'),end: moment(),});
        // setDateState({start: date.setDate(date.getDate() - 31),end: new Date(),})
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
        // let data = {}
        let data = {"is_attempted":1,"start_date":dateStart,"end_date":dateEnd}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: {  'x-ref': 'YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl' },
            url: `${process.env.REACT_APP_DASHBOARD_PERFORMANCE_OVERVIEW}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });
    }
    useEffect(() => {
        listingPerformance();
    }, []);
    function findPendingCount(count,pending_order_status) {
          const pendingDatavalue = pending_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findRtoCount(count) {
          const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findDeliveredCount(count) {
          const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
      function findFailedCount(count) {
        const failedDatavalue = failed_order_status.filter((item) => item === count)[0];
        return failedDatavalue
    }
   
    let totalBooked = 0
    let totalDelivered = 0
    let totalPending = 0
    let totalRtoPending = 0
    let totalIntransit = 0
    let totalFailed = 0
    let allShipments = 0
    let dateArray = []
    const currentMoment = moment().subtract(31, 'days');
    const endMoment = moment().add(1, 'days');
    while (currentMoment.isBefore(endMoment, 'day')) {
    dateArray.push(changeIntoDate(currentMoment.format()))
    currentMoment.add(1, 'days');
    }
    let performanceStatus = checkData(performanceOverviews) && checkData(performanceOverviews.data) &&  Object.values(performanceOverviews.data.reduce((osi, item) => {
        let shipmentDate = changeIntoDate(item.dated)
        if (!osi[shipmentDate])
        osi[shipmentDate] = {date: shipmentDate, shipmentDelivered: [], shipmentPending: [], shipmentRto: [], shipmentIntransit: [], shipmentFailed: [], };
        let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
        let pending=0;
        if(parseInt(pradioVal) === 1){
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,pending_order_status)) ? item.total : 0;
        }else{
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,pending_order_status)) ? item.total : 0;
        }
     
        let fOrder=checkUndeNullBlank(findFailedCount(item.order_status_id)) ? item.total : 0;
        
        let rto=checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        let intransit=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 6 ? item.total : 0;
        osi[shipmentDate].shipmentDelivered.push(delivered);
        osi[shipmentDate].shipmentPending.push(pending);
        osi[shipmentDate].shipmentRto.push(rto);
        osi[shipmentDate].shipmentIntransit.push(intransit);
        osi[shipmentDate].shipmentFailed.push(fOrder);
        
        totalIntransit += intransit;
        totalDelivered += delivered;
        totalFailed += fOrder;
        totalPending += pending;
        totalRtoPending += rto;
        totalBooked = totalDelivered + totalPending + totalRtoPending + totalFailed
        return osi;
    }, {}));
    // console.log('performanceStatus', performanceStatus)
    let performanceStatusGraph = checkData(performanceOverviews) && checkData(performanceOverviews.today) &&  Object.values(performanceOverviews.today.reduce((osi, item) => {
        let todayshipmentDate = changeIntoDate(item.dated)
        let tDelivered=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 10 ? item.total : 0;
        let tRto=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 13 ? item.total : 0;
        let tFailed=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 11 ? item.total : 0;
        if (!osi[todayshipmentDate])
        osi[todayshipmentDate] = {date: todayshipmentDate, todayDelivered: [], todayRto: [], todayFailed: [], };
        
        osi[todayshipmentDate].todayDelivered.push(tDelivered)
        osi[todayshipmentDate].todayRto.push(tRto)
        osi[todayshipmentDate].todayFailed.push(tFailed)
        
        return osi;
    }, {}));
    
    
    let totalDeliveredPercent=0;
    if(parseInt(pradioVal) === 1 ){
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / (totalBooked - totalPending) ) * 100 : 0
    }else{
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / totalBooked ) * 100 : 0      
    }

    let totalPendingPercent = totalPending >0 ? (totalPending / totalBooked ) * 100 : 0
    let totalRtoPendingPercent = totalRtoPending >0 ? (totalRtoPending / totalBooked ) * 100 : 0
    let totalFailedPercent = totalFailed >0 ? (totalFailed / totalBooked ) * 100 : 0

    const { register:filter, handleSubmit:filterPerformanceOverview } = useForm();
    const onFilterPerformanceOverview = (data) => {
        setPerformanceOverviews({});
        setLoadertable(true);
        let byData = {}
        let courierId = [];

        if(checkData(allCourier)){
            allCourier.map((item) => (
                courierId.push(item.value)
            )); 
        } 

        if(checkUndeNullBlank(courierId)){
            byData.courier_id=courierId
          }

          let isAttempted = checkUndeNullBlank(pradioVal) ? pradioVal : 1

          let dateStart = moment(datestate.start).format("YYYY-MM-DD");
          let dateEnd = moment(datestate.end).format("YYYY-MM-DD");

        byData={"is_attempted":isAttempted, "courier_id":checkUndeNullBlank(courierId)?courierId:'',"start_date":dateStart,"end_date":dateEnd}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            byData.admin_id=props.user[0].id
          }else{
            byData.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: {  'x-ref': 'YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl' },
            url: `${process.env.REACT_APP_DASHBOARD_PERFORMANCE_OVERVIEW}`,
            data: byData,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });  
    };
    let booked = [1,2,3,4,5,6,7,8,9];
    let todayDelivered = [];
    let todayFailed = [];
    let todayPending = [];
    let todayRto = [];
    let todayDeliveredbg = [];
    let todayFailedbg = [];
    let todayPendingbg = [];
    let todayRtobg = [];
    // let todayTotal = [];
    if(dateArray.length > 0){
        dateArray.reverse().map((item, index) => {
    let obj = checkData(performanceStatus) && performanceStatus.find(o => o.date ===item);
    let shipmentCheck = checkUndeNullBlank(obj) ? obj : ''
    let todayDeliveredship = shipmentCheck.shipmentDelivered;
    let todayFailedship = shipmentCheck.shipmentFailed;
    let todayPendingship = shipmentCheck.shipmentPending;
    let todayRtoship = shipmentCheck.shipmentRto;
    todayDelivered.push(checkUndeNullBlank(todayDeliveredship) ? eval(todayDeliveredship.join('+')) : 0);
    todayFailed.push(checkUndeNullBlank(todayFailedship) ? eval(todayFailedship.join('+')) : 0);
    todayPending.push(checkUndeNullBlank(todayPendingship) ? eval(todayPendingship.join('+')) : 0);
    todayRto.push(checkUndeNullBlank(todayRtoship) ? eval(todayRtoship.join('+')) : 0);
    todayDeliveredbg.push('rgb(57,218,138)');
    todayFailedbg.push('rgb(253,172,65)');
    todayPendingbg.push('rgb(255,215,0)');
    todayRtobg.push('rgb(255,91,92)');
    // todayTotal.push(todayDelivered+todayFailed+todayPending+todayRto);
    // let todayDeliveredPercent = todayDelivered > 0 ? (todayDelivered / todayTotal ) * 100 : 0;
    // let todayPendingPercent = todayPending > 0 ? (todayPending / todayTotal ) * 100 : 0;
    // let todayRtoPercent = todayRto > 0 ? (todayRto / todayTotal ) * 100 : 0;
    // let todayFailedPercent = todayFailed > 0 ? (todayFailed / todayTotal ) * 100 : 0;
    return []
})
    }
    const data = {
        labels: dateArray,
        datasets: [
        //     {
        //       label: 'Shipments Booked',
        //       data: todayTotal,
        //       backgroundColor: [
        //       'rgba(0,123,243,0.5)',
        //       ],
        //   },
          {
            label: 'Delivered',
            data: todayDelivered,
            backgroundColor: todayDeliveredbg,
            
          },
          {
              label: 'Failed',
              data: todayFailed,
              backgroundColor: todayFailedbg,
            },
          {
              label: 'Pending',
              data: todayPending,
              backgroundColor: todayPendingbg,
            },
          {
              label: 'Return To Origin',
              data: todayRto,
              backgroundColor: todayRtobg,
            },
          ],
      };
    const  options = {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart',
          },
        },
        maintainAspectRatio: true 
      };
    //   const options = {
    //     indexAxis: '',
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //             barThickness : 15
    //         },
    //         y: {
    //             stacked: true,
    //             barThickness : 15
    //         }
    //     },
    //     plugins: {
    //       title: {
    //         display: true,
    //         text: 'Courier Wise Status Performance'
    //       },
    //       legend: {
    //         display: false,
    //         position: "top",
    //      }
    //     },
    //     maintainAspectRatio: false 
       
    //   };
    return (
        <div className="PerformanceOverview">
            <Card className="shadow border-0">
					<div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
					<div className="page-title mb-3 mb-xl-0">
					<figure><img className="img-fluid" src={Performance_Icon} alt="img_performance" /></figure> 
					Performance Overview
					</div> 
                    </div>
			<div className="perfor_chart_controls">		
			 <Col xl={12} className="pl-0">
                    <Form onSubmit={filterPerformanceOverview(onFilterPerformanceOverview)}>
                        <div className="d-flex flex-wrap align-items-center">
                            <Col md={4} lg={4} className="pl-0">
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <FormGroup className="mb-md-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='1' id="attempted"  onChange={ (e) => performanceRadio(e)} defaultChecked/>
                                            <Label className="mx-2" for="attempted">Attempted</Label>
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="mr-sm-4 mb-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='0' id="overall" onChange={ (e) => performanceRadio(e)} />
                                            <Label className="mx-2" for="overall">Overall</Label>
                                        </span>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col md={4} lg={3}>
                                <FormGroup className="mb-md-0">
                                    <MultiSelect 
                                    options={opt_allCourier}
                                    value={allCourier}
                                    onChange={(e) => setAllCourier(e)}
                                    labelledBy="All Couriers"
                                    overrideStrings={allCourierMultiSelectSettings}
                                    className={checkData(allCourier) && "active"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4} lg={3}>
                                <FormGroup className="mb-md-0">
                                    {/* <DateRangePicker value={filterDatePerf} onChange={date => setFilterDatePerf(date)} placement="bottomEnd" placeholder="Choose Date Range" format="DD-MM-YYYY"  ref={dateRangeRef} showOneCalendar={props.oneCalendar} /> */}
                                    <DateRangePicker
                                        initialSettings={ranges}
                                        onCallback={handleCallback}
                                    >
                                    {datestate.start !== '' && datestate.end !== '' ? 
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                    </div>
                                    :
                                    <div className='form-control cursor-pointer d-flex align-items-center'>
                                        Select Date
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </div>
                                    }
                                    </DateRangePicker>
                                </FormGroup>
                            </Col>
                            <Col lg={2} className="pr-0">
                                <FormGroup className="mb-0">
                                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </Form> 
                    </Col>
				</div>
				
                <CardBody className="p-0">
                    {/* <div style={{width: "100%",height: "300px"}}> */}
                        <Bar data={data} options={options}/>
                    {/* </div> */}
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps (state) {
    return {
      state:state.DataReducer
    }
  }
  export default connect(mapDispatchToProps)(PerformanceOverview);