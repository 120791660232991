import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle,Input,Collapse, FormGroup,InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, CardSubtitle,CardText, Pagination,PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Spinner} from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import {Link, useHistory} from 'react-router-dom';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faMoneyBillAlt, faArrowUp, faChevronRight, faDownload, faChevronLeft, faFilter } from '@fortawesome/free-solid-svg-icons';
import {Copy, ChevronLeft, ChevronRight, Layers} from 'react-feather';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
// import axios from 'axios';
import axios from '../../CommonAxios';
import { copyToClipBoard, changeIntoDateTime, changeIntoDateYmd, firstDayOftheMonth, errorHandling, remExtraSpace} from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast,ToastContainer } from 'react-toastify';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Swal from 'sweetalert2';
// import LoaderDefault from '../LoaderDefault';
// import NodataFound from '../NodataFound';
import { delivery_type_id, download_delay, zone_category_id} from '../../config';
import moment from "moment";


const MerchantFreight = (props) => {
const httpClient = axios.create()
httpClient.defaults.timeout = 300000;

  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [zoneCategory, setZoneCategory] = useState([]);
  const [excelmodal, setExcelmodal] = useState(false);
  const [zoneCorrmodal, setZoneCorrmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [merchantFreight, setMerchantFreight] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [page, setPage] = useState(1)
  const [currentCount,setCurrentCount] = useState(1);
  const [selectperpage,setSelectperpage] = useState('20');
  const [filterInput, setFilterInput] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [eventDatestate, setEventDateState] = useState({eventstart: '',eventend: '',});
  const [datestate, setDateState] = useState({start: '',end: '',});
  const { eventstart, eventend } = eventDatestate;
  const eventhandleCallback = (eventstart, eventend) => {
    if(eventstart._isValid && eventend._isValid ){
      setEventDateState({ eventstart, eventend });
    }else{
      setEventDateState({eventstart: '',eventend: '',});
    }
  };
  const eventranges = {
    startDate: eventstart !== '' && eventstart.toDate(),
    endDate: eventend !== '' && eventend.toDate(),
    ranges: {
      None: [null, null],
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    if(start._isValid && end._isValid ){
      setDateState({ start, end });
    }else{
      setDateState({start: '',end: '',});
    }
  };
  const ranges = {
    startDate: start !== '' &&  start.toDate(),
    endDate: end !== '' && end.toDate(),
    ranges: {
      None: [null, null],
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const history = useHistory();

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetfreightFile();
    if(!excelmodal){setExcelFile();}
  }

  const zoneUpload = () => {
    setZoneCorrmodal(!zoneCorrmodal);
    resetzoneFile();
    if(!zoneCorrmodal){setExcelFile();}
  }

  const handleFileInput = (e) => {
    e.preventDefault();
    setExcelFile(e.target.files[0]);
  }
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  const { register:uploadFreight, handleSubmit:freightUploadSubmit,formState: { errors }, reset : resetfreightFile} = useForm();
  const { register:uploadZone, handleSubmit:zoneUploadSubmit,formState: { errors: errors2 }, reset : resetzoneFile} = useForm();

 

  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
      Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {} 
      if(merchantName && merchantId){
          tempObj = {label: merchantName, value: merchantId }
          opt_merchants.push(tempObj)   
      }
      return
      });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  const allZoneCategorySettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Zone Category"
  }
  var opt_zoneCategory = [];
  Object.keys(zone_category_id).map(function(key){
    let tempObj = {label: zone_category_id[key], value: parseInt(key)}     
    return opt_zoneCategory.push(tempObj);
  });

  // copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
    if(result === true){
        toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    })
  }


  useEffect(() => {
    merchantFreightListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])

  // merchant freight Listing 
  const merchantFreightListing = () => {
    setLoadertable(true);
    setDateState({start: moment().subtract(29, 'days'),end: moment(),});
    setEventDateState({eventstart: '',eventend: '',});
    reset();setFilterDate([firstDayOftheMonth(),new Date()]);setMerchants([]);setAllCourier([]);setSearchValue(' ');setIsExport(false);
    let data = {"start_date":changeIntoDateYmd(moment().subtract(29, 'days')),"end_date":changeIntoDateYmd(moment())}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    httpClient.post(`${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${page}&per_page=${selectperpage}`, data)
    .then((res) => {
          setLoadertable(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setMerchantFreight(res.data);
        }
      }
    }).catch((err) => {
        setLoadertable(false);
        errorHandling(err,"reload")
    });
  }
  const onFreightSubmit = (data) => {
    setLoader(true);
        let formData = new FormData();
        formData.append("file", excelFile);  
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
          formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');  
        }else{
          formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');  
          formData.append("admin_id", process.env.REACT_APP_ADMIN_ID); 
        } 
        // axios
        httpClient.post(`${process.env.REACT_APP_FREIGHT_CORRECTION}`, formData)
          // axios({
          //   method: "post",
          //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
          //   data: formData,
          // })
          .then((res) => {
              let errorFile='';
              if(res.data.errorfile !== undefined){
               errorFile = checkUndeNullBlank(res.data.errorfile)?process.env.REACT_APP_BASE_URL+res.data.errorfile:'';
              }
              setLoader(false);
              setExcelFile();
              setExcelmodal(false);
              if(res.data.error === false){
                Swal.fire({
                  title: 'Success',text: res.data.msg,icon: 'success',allowOutsideClick: false,allowEscapeKey: false,
                  html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
                }).then(okay => {
                  if (okay) {
                    merchantFreightListing();
                 }
               });
              }else{
                 Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',allowOutsideClick: false,allowEscapeKey: false,
                 html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
                }).then(okay => {
                  if (okay) {
                    merchantFreightListing();
                 }
               });
              }
          }).catch((err) => {
            setLoader(false);
            errorHandling(err,"");
          });
  }

  const onZoneSubmit = (data) => {
      setLoader(true);
        let formData = new FormData();
        formData.append("file", excelFile);  
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
          formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');  
        }else{
          formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');  
          formData.append("admin_id", process.env.REACT_APP_ADMIN_ID); 
        } 
        // axios
        httpClient.post(`${process.env.REACT_APP_ZONE_CORRECTION}`, formData)
          // axios({
          //   method: "post",
          //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
          //   data: formData,
          // })
          .then((res) => {
              let errorFile='';
              if(res.data.errorfile !== undefined){
               errorFile = checkUndeNullBlank(res.data.errorfile)?process.env.REACT_APP_BASE_URL+res.data.errorfile:'';
              }
              setLoader(false);
              setExcelFile();
              setZoneCorrmodal(false);
              if(res.data.error === false){
                Swal.fire({
                  title: 'Success',text: res.data.msg,icon: 'success',allowOutsideClick: false,allowEscapeKey: false,
                  html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
                }).then(okay => {
                  if (okay) {
                    merchantFreightListing();
                 }
               });
              }else{
                 Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',allowOutsideClick: false,allowEscapeKey: false,
                 html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
                }).then(okay => {
                  if (okay) {
                    merchantFreightListing();
                 }
               });
              }
          }).catch((err) => {
            setLoader(false);
            errorHandling(err,"");
          });
  }
  const exportShipment = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      data.is_export=1
      setLoader(true);
      axios.post(process.env.REACT_APP_MERCHANT_FREIGHT_LIST, data)
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          setLoader(false)
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"")
    });
    }else{
      Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
    if(checkData(merchantFreight.data)&&checkUndeNullBlank(merchantFreight.totalPage)){
        if(merchantFreight.totalPage > page){
          setCurrentCount(currentCount+Object.entries(merchantFreight.data).length)
      setPage(page+1)
      setLoadertable(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
    setPage(page-1)
    checkData(merchantFreight.data)
    if(currentCount>Object.entries(merchantFreight.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }

  const { register:filter, handleSubmit:filterMerchantFreight, reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onMerchantFreight = (data,pagePerpage) => {
    setIsExport(true);  
    pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(allCourier) || checkData(merchants) || checkData(zoneCategory) || checkUndeNullBlank(data.movement_type) || checkUndeNullBlank(datestate)|| checkData(eventDatestate)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }
  };
  const onCommonFilter = (data,pagePerpage) => {
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    let zoneCategoryId = [];
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    let eventDateStart = moment(eventDatestate.eventstart).format("YYYY-MM-DD");
    let eventDateEnd = moment(eventDatestate.eventend).format("YYYY-MM-DD");

    if(checkData(allCourier)){
      allCourier.map((item) => (
        courierId.push(item.value)
      )); 
    } 
    if(checkData(merchants)){
      merchants.map((item) => (
        merchantsId.push(item.value)
      )); 
    } 
    if(checkData(zoneCategory)){
      zoneCategory.map((item) => (
        zoneCategoryId.push(item.value)
      )); 
    } 
    
    if(checkUndeNullBlank(courierId)){
      data.courier_id=courierId
    }
    setLoadertable(true)
   
    byData={"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?remExtraSpace(data.search_value):'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'',"zone_category_id":checkUndeNullBlank(zoneCategoryId)?zoneCategoryId:'',"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : '',"booking_start_date":eventDatestate.eventstart !== '' ? eventDateStart : '',"booking_end_date":eventDatestate.eventend !== '' ? eventDateEnd : ''}
    setFilterInput(byData);
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoadertable(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setMerchantFreight(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoadertable(false);
      });
  };
  const onFilter = () => setIsOpen(!isOpen);


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="merchantFreight content-wrapper">
          <Card className="border-0">
            <div className="page-header d-flex flex-wrap justify-content-between">
              <div className="page-title">Manage Freight</div>
 			  
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light mx-2" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Freight</span></div>
                {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                <>
                  <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Freight Correction</span></div>              
                  <div className="btn-text btn-light" onClick={zoneUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Zone Correction</span></div>                        
                </>
                }
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
                </div>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Freight Correction</ModalHeader>
                <ModalBody>
                  <Form onSubmit={freightUploadSubmit(onFreightSubmit)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Freight Correction via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/freight_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Update Freight Correction Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadFreight("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" multiple onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
              <Modal isOpen={zoneCorrmodal} toggle={zoneCorrmodal} scrollable={true} >
                <ModalHeader toggle={zoneUpload}>Upload Zone Correction</ModalHeader>
                <ModalBody>
                  <Form onSubmit={zoneUploadSubmit(onZoneSubmit)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Zone Correction via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/zone_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Update Zone Correction Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadZone("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors2.excelUpload && <span className="text-danger d-block error">{errors2.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            </div> 
            <Collapse isOpen={isOpen}>
                <Form onSubmit={filterMerchantFreight(onMerchantFreight)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center"> 
                    <Col sm={6} md={5} lg={4} xl={3}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...filter("search_field")} className="form-control form-control-sm" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                                <option value="">Search By</option>
                                <option value="awb_number">AWB Number</option>
                                <option value="order_no">Order Number</option>
                                <option value="system_order_no">TekiPost Order No.</option>
                              </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>  
                    {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>}

                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                        </FormGroup>
                    </Col> 
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <DateRangePicker
                            initialSettings={eventranges}
                            onCallback={eventhandleCallback}
                          >
                          {eventDatestate.eventstart !== '' && eventDatestate.eventend !== '' ? 
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(eventDatestate.eventstart).format("DD-MM-YYYY")} to {moment(eventDatestate.eventend).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Booking Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                        </DateRangePicker>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="DD-MM-YYYY" ref={dateRangeRef} change={onDateChange} /> */}
                          <DateRangePicker
                            initialSettings={ranges}
                            onCallback={handleCallback}
                          >
                          {datestate.start !== '' && datestate.end !== '' ? 
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                          :
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            Select Date
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          </div>
                        }
                        </DateRangePicker>
                        </FormGroup>
                    </Col>                      
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {merchantFreightListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
            </Collapse>
                {/* <Row className="mx-0 my-3 justify-content-center">
                  <Col sm={6} md={3}>
                    <Card className="border shadow-sm">                      
                      <CardBody className="d-flex justify-content-between align-items-center">
                        <span className="text-white d-flex align-items-center justify-content-center bg-primary" style={{width: "50px",height: "50px", fontSize: "25px"}}><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
                        <div>
                          <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalFreight) ? "₹ "+merchantFreight.TotalData[0].totalFreight : "NA" }</CardSubtitle>
                          <CardText>Total Freight (Inc% tax)</CardText>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={6} md={3}>
                    <Card className="border shadow-sm">                      
                      <CardBody className="d-flex justify-content-between align-items-center">
                        <span className="text-white bg-primary d-flex justify-content-center align-items-center" style={{width: "50px",height: "50px", fontSize: "25px"}}><Layers style={{width: "30px",height: "30px"}} /></span>
                        <div>
                          <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalShipments) ? merchantFreight.TotalData[0].totalShipments : "NA" }</CardSubtitle>
                          <CardText>Shipments Count</CardText>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
              <CardBody className="p-0">
              {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(merchantFreight.data).length)} of {merchantFreight.TotalRows} entries
                    </div>
                      {merchantFreight.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
              <div className="">
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Courier Details</th>
                      <th className="text-nowrap">Charged Weight</th>
                      <th className="text-nowrap">FORWARD</th>
                      <th className="text-nowrap">RTO</th>
                      <th className="text-nowrap">COD</th>
                      <th className="text-nowrap">TAX</th>
                      <th className="text-nowrap">Total</th>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th className="text-nowrap">Merchant</th>}
                      <th className="text-nowrap">Created</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(merchantFreight.data) ? merchantFreight.data.map((item, index) => (
                    <tr>
                    <td className="align-middle text-left col-2">
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{item.CourierName}</p>                             
                            </div>
                            <div className="d-flex"><div className="mb-1 d-block text-left text-nowrap">AWB - {item.awb_no}</div>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn d-none" id={`copy${item.order_id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.order_id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <div>Order No - <Link to={`/order-detail/${item.order_id}`} className="text-nowrap">{item.order_no}</Link></div>
                          </div>
                        </div>                                                
                      </td>
                      <td>{item.charged_weight}</td>
                      <td>{item.forward_charge ? item.forward_charge : 'NA' }</td>
                      <td>{item.rto_charge ? item.rto_charge : 'NA' }</td>
                      <td>{item.cod_charge ? item.cod_charge : 'NA' }</td>
                      <td>{item.tax_amt}</td>
                      <td>{item.total_amt}</td>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <>
                      <td>{item.MerchantName}</td>
                      </>
                      }
                      <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                    </tr>
                    ))
                    :loadertable ? <tr> <td colSpan="7">Loading...</td> </tr> :
                    <tr> <td colSpan="6">No Data Found</td> </tr> }
                   </tbody>
                </Table>
                <ToastContainer style={{width: "200px"}}/>
                </div>

                {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                <>
                {merchantFreight.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(merchantFreight.data).length)} of {merchantFreight.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(MerchantFreight);