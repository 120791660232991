import React, {useEffect, useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Spinner} from 'reactstrap';
import Swal from 'sweetalert2'
// import axios from 'axios';
import axios from '../../../CommonAxios';
import { useForm } from 'react-hook-form';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StateList } from '../../masters/StateList';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';

const BillingDetail = (props) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register:addMerchant_address, handleSubmit:merchantAddressSubmit, formState: { errors: errorMerchantAdrs }, reset:merchantAdrsReset } = useForm();

    useEffect(() =>{
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_ADDRESSADD}/view`,
            data: {"merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:props.merchantId !== undefined ? props.merchantId:checkUndeNullBlank(props.merchantId)?props.merchantId:''},
          })
            .then(function (response) {
              let myResponse=response.data[0];
              if(checkData(myResponse)){
                setFormDisabled(true);
            }
              merchantAdrsReset(myResponse);
              props.setBillingDetails !== undefined && props.setBillingDetails(myResponse);
            }).catch((err) => Swal.fire({title: 'Error', text: err, icon: 'error',}));
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantAdrsReset])
    const onAddMerchantAddress = (data) => {
      setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:props.merchantId !== undefined ? props.merchantId:checkUndeNullBlank(props.merchantId)?props.merchantId:'');
        formData.append("address", data.address);
        formData.append("pincode", data.pincode);
        formData.append("city", data.city);
        formData.append("state_id", data.state_id);
      
        axios
          .post(process.env.REACT_APP_MERCHANT_ADDRESSADD, formData)
          .then((res) => {
            setLoader(false);
            if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
            setLoader(false);
            Swal.fire({icon:'error', text:err, title:'Error'})
          });
      };
  const onaddressError = (errorMerchantAdrs, e) => console.log('Error', errorMerchantAdrs);

    return (
      <>
      {loader && <div className="formLoader"><Spinner /></div>}
        <Form onSubmit={merchantAddressSubmit(onAddMerchantAddress,onaddressError)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Billing Detail</CardTitle>
            </div>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="bill_adrs">Billing Address</Label>
                        <input disabled={formDisabled} {...addMerchant_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
                          {errorMerchantAdrs.address && <span className="text-danger d-block mb-2">{errorMerchantAdrs.address.message}</span>}
                        </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <input disabled={formDisabled} {...addMerchant_address("city", { required: 'Please Enter City' })}  className="form-control-sm form-control" placeholder="City" id="city" />
                          {errorMerchantAdrs.city && <span className="text-danger d-block mb-2">{errorMerchantAdrs.city.message}</span>}
                        </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="state">Select State</Label>
                        <div className="position-relative">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select disabled={formDisabled} {...addMerchant_address("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state">
                                <option value="">Select State</option>
                                {StateList.map((item, index) => (
                                    <option value={item.state_id}>{item.state_name}</option>
                                ))
                            }
                            </select>
                            {errorMerchantAdrs.state_id && <span className="text-danger d-block mb-2">{errorMerchantAdrs.state_id.message}</span>}
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="pincode">Pincode</Label>
                        <input disabled={formDisabled} {...addMerchant_address("pincode", { required: 'Please Enter Pincode' })}  className="form-control-sm form-control" placeholder="Pincode" id="pincode"/>
                          {errorMerchantAdrs.pincode && <span className="text-danger d-block mb-2">{errorMerchantAdrs.pincode.message}</span>}
                        </FormGroup>
                </Col>
                {!formDisabled &&
                  <Col sm={12} className="mt-2 text-right">                    
                      <Button className="btn-sm" color="primary"> Save changes</Button>
                  </Col>  
                }           
            </Row>
        </Form>
        </>
    );
}

export default BillingDetail;
