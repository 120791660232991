import React, {useEffect, useState} from 'react';
import {Card,CardBody, Table, CardHeader,CardTitle } from 'reactstrap';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { errorHandling } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import {connect, useDispatch} from 'react-redux'
import { dashCourierRmittance } from '../../../../actions';

const CourierRemittance = (props) => {
    const [remittanceList, setRemittanceList] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const dispatch = useDispatch();

    const listingRemittance = (rType) => {
        setRemittanceList({});
        setLoadertable(true);
        let mypost = {}
        let myget = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            mypost.admin_id=props.user[0].id
          }else{
            mypost.merchant_id=props.user[0].id
          }
        dispatch(dashCourierRmittance(mypost,myget)).then((response) => {
            setLoadertable(false)
            if(response.error === false){
                setRemittanceList(response.data);
            }
        }).catch(function (response) {
            setLoadertable(false)
            errorHandling(response,"reload");
        });

    }
    useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewDashCourierRemittance) && checkData(props.state.viewDashCourierRemittance.data)){
            setRemittanceList(props.state.viewDashCourierRemittance.data);
          }else{
            listingRemittance();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let dueRemittance = remittanceList.RemittanceDueSqlArr
    let remittanceReceivedAWB = remittanceList.remittanceReceivedAWBSqlArr
    let remittanceReceivedBank = remittanceList.remittanceReceivedSqlArr
    return (
        <div className="CourierRemittance">
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title">Courier Remittance</CardTitle>  
                </CardHeader>
                <CardBody className="p-0">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap text-left">Couriers</th>
                                <th className="text-nowrap">Total Remittance</th>
                                <th className="text-nowrap">Total AWB Wise Received</th>
                                <th className="text-nowrap">Total Received in Bank Account</th>
                                <th className="text-nowrap">Total Due</th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)  ? props.state.shipmentMerchantCourier.couriers.map((item, index) => {
                                let objremittancedue = checkData(dueRemittance) && dueRemittance.find(o => o.courier_id === item.courier_id);
                                let objremittancedueCheck = checkUndeNullBlank(objremittancedue) ? objremittancedue : ''
                                let objremittanceAwb = checkData(remittanceReceivedAWB) && remittanceReceivedAWB.find(o => o.courier_id === item.courier_id);
                                let objremittanceAwbCheck = checkUndeNullBlank(objremittanceAwb) ? objremittanceAwb : ''
                                let objremittanceBank = checkData(remittanceReceivedBank) && remittanceReceivedBank.find(o => o.courier_id === item.courier_id);
                                let objremittanceBankCheck = checkUndeNullBlank(objremittanceBank) ? objremittanceBank : ''
                                let totalDue = checkUndeNullBlank(objremittancedueCheck) && checkUndeNullBlank(objremittancedueCheck.totalAmt) ?  objremittancedueCheck.totalAmt : 0;
                                let totalAwbReceived = checkUndeNullBlank(objremittanceAwbCheck) && checkUndeNullBlank(objremittanceAwbCheck.totalAmt) ?  objremittanceAwbCheck.totalAmt : 0;
                                let totalBankReceived = checkUndeNullBlank(objremittanceBankCheck) && checkUndeNullBlank(objremittanceBankCheck.totalAmt) ?  objremittanceBankCheck.totalAmt : 0;
                                return(
                                    <tr>
                                        <td className="text-left">{item.courierName}</td>
                                        {/* <td>{totalDue}<Link href="##" className="mx-1"><FontAwesomeIcon icon={faDownload} /></Link></td> */}
                                        <td><div className="text-left mr-auto dowbload-con d-flex justify-content-between" style={{width:"100px"}}>{totalDue}<span className="download-icon ml-2"><FontAwesomeIcon icon={faDownload} /></span></div></td>
                                        <td>{totalAwbReceived}</td>
                                        <td>{totalBankReceived}</td>
                                        <td>{totalDue-totalBankReceived}</td>
                                    </tr>
                                )}):loadertable? <tr><td colSpan="4">Loading...</td></tr> : <tr><td colSpan="4">No Data Found</td></tr>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>            
        </div>
    );
}
function mapDispatchToProps (state) {
    return {
      state:state.DataReducer
    }
}
export default connect(mapDispatchToProps)(CourierRemittance);