import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import {Form, Row, Col, FormGroup, Button, Label} from 'reactstrap'
//import axios from 'axios';
import axios from '../../CommonAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
const configData = require("../../config");

const AddCourier = (props) => {
    const [logo, setLogo] = useState();
    const handleFileInput = (e) => {
        setLogo(e.target.files[0])
    }
    const { register:addCourier, handleSubmit:courierSubmit, formState: { errors }  } = useForm();
  const onAddCourierSubmit = (data) => {
    let formData = new FormData();
  formData.append("full_name", data.full_name);
  formData.append("brand_name", data.brand_name);
  formData.append("email", data.email);
  formData.append("tat_in_days", data.tat_in_days);
  formData.append("aggreator_courier_id", data.aggreator_courier_id);
  formData.append("volumetric_value", data.volumetric_value);
  formData.append("service_id", data.service_id);
  formData.append("weight_category", data.weight_category);
  formData.append("movement_type_id", data.movement_type_id);
  formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
  formData.append("logo", logo);
  formData.append("status", data.status);

  axios
    .post(`${process.env.REACT_APP_ADD_COURIER}`, formData)
    .then((res) => {
        props.tableDataFetch !== undefined && props.tableDataFetch();
        props.setAddModal !== undefined && props.setAddModal(false);
      if(res.data.error === false){
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    })
    .catch((err) => alert("Somthing went wrong"));
  };
  const onError = (errors, e) => console.log('Error', errors);
    return(
        <Form onSubmit={courierSubmit(onAddCourierSubmit,onError)}>
            <Row className="mx-0 justify-content-center addModal">
                <Col sm={6}>
                    <FormGroup>
                    <Label for="Courier Name">Enter Courier Name</Label>
                    <input {...addCourier("full_name",{ required: 'Please Enter Courier Name'})}  className="form-control-sm form-control" placeholder="Enter Courier Name*" id="Courier Name" />
                        {errors.full_name && <span className="text-danger d-block mb-2">{errors.full_name.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label for="email">Enter Email</Label>
                    <input {...addCourier("email",{ required: 'Please Enter Email'})} type="email"  className="form-control-sm form-control" placeholder="Enter Email*" id="email" />
                    {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label for="brand_name">Enter Stores Name</Label>
                    <input {...addCourier("brand_name",{ required: 'Please Enter Stores Name'})} className="form-control-sm form-control" placeholder="Enter Brand Name*" id="brand_name" />
                    {errors.brand_name && <span className="text-danger d-block mb-2">{errors.brand_name.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="tat_in_days">TAT In Days</Label>
                        <input {...addCourier("tat_in_days",{ required: 'Please Enter TaT In Days'})} className="form-control-sm form-control" placeholder="Enter TAT In Days*" id="tat_in_days" />
                        {errors.tat_in_days && <span className="text-danger d-block error">{errors.tat_in_days.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="aggreator_courier_id">Aggreator Courier Id</Label>
                        <input {...addCourier("aggreator_courier_id",{pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Aggreator Courier Id'}})} className="form-control-sm form-control" placeholder="Enter Aggreator Courier Id" id="aggreator_courier_id" />
                        {errors.aggreator_courier_id && <span className="text-danger d-block error">{errors.aggreator_courier_id.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label for="brandLogo">Upload Logo</Label>
                    <input {...addCourier("brandlogo",{ required: 'Please Add Courier Logo'})} type="file" onChange={handleFileInput} className="form-control-sm form-control" id="brandLogo" />
                    {errors.brandlogo && <span className="text-danger d-block mb-2">{errors.brandlogo.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                <FormGroup>
                <Label for="selectCourier">Select Service</Label>
                <div className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addCourier("service_id",{ required: 'Please Select Surface'})} className="form-control-sm form-control">
                        <option value="">Selcet Service</option>
                        {Object.entries(configData.service).map(([key,value]) => (
                        <option value={key}>{value}</option>
                        ))}
                    </select>
                </div>
                {errors.service_id && <span className="text-danger d-block mb-2">{errors.service_id.message}</span>}
                </FormGroup>
                </Col>
                <Col sm={6}>
                <FormGroup>
                <Label for="selectCourier">Weight Category <small> (In Kgs.)</small></Label>
                <div className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addCourier("weight_category",{ required: 'Please Select Weight Category'})} className="form-control-sm form-control">
                        <option value="">Selcet Weight Category</option>
                        {Object.entries(configData.weight).map(([key,value]) => (
                        <option value={value}>{value}</option>
                        ))}
                    </select>
                    {errors.weight_category && <span className="text-danger d-block mb-2">{errors.weight_category.message}</span>}
                </div>
                </FormGroup>
                </Col>
                <Col sm={6}>
                <FormGroup>
                <Label for="selectCourier">Movement Type</Label>
                <div className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addCourier("movement_type_id",{ required: 'Please Select Movement Type'})} className="form-control-sm form-control">
                        <option value="">Selcet Movement Type</option>
                        {Object.entries(configData.movement_type).map(([key,value]) => (
                        <option value={key}>{value}</option>
                        ))}
                    </select>
                    {errors.movement_type_id && <span className="text-danger d-block mb-2">{errors.movement_type_id.message}</span>}
                </div>
                </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label for="volumetric_value">Enter Volumetric Value</Label>
                    <input {...addCourier("volumetric_value",{ required: 'Please Enter Volumetric Value'})}  className="form-control-sm form-control" placeholder="Enter Volumertic Value*" id="volumetric_value" />
                    {errors.volumetric_value && <span className="text-danger d-block mb-2">{errors.volumetric_value.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label for="maxLiaShip">Enter Max Liability Shipment</Label>
                    <input {...addCourier("max_liability_per_shipment",{ required: 'Please Enter Max Liability Shipment'})} type="number" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiaShip" />
                        {errors.max_liability_per_shipment && <span className="text-danger d-block mb-2">{errors.max_liability_per_shipment.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                    <Label>Status</Label>
                    <div>
                        <span className="mr-2">
                        <input {...addCourier("status", { required: true })} type="radio" value="1" id="active" checked/>
                        <Label className="mx-2" for="active">Active</Label>
                        </span>
                        <span>
                        <input {...addCourier("status", { required: true })} type="radio" value="0" id="inactive" />
                        <Label className="mx-2" for="inactive">InActive</Label>
                        </span>
                    </div>
                    </FormGroup>
                </Col>
                <Col sm={6} className="text-right">
                <Button className="btn btn-sm" color="primary">Save</Button>
                </Col>
            </Row>
        </Form> 
        
    )
}
export default AddCourier