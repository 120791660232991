import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Label, Spinner, Collapse, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import axios from '../../CommonAxios';
import { changeIntoDate } from '../../utlis';
import Status from '../Status';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';

const MerchantBrands = (props) => {
  const [addmodal, setAddmodal] = useState(false);
  const [brandList, setBrandList] = useState({})
  const { merchantId } = useParams();
  const [logo, setLogo] = useState('');
  const [editmodal, setEditmodal] = useState(false);
  const [editMerchantBrands, setEditMerchantBrands] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropFilterCount = 20

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
  }

  const toggleAdd = () => setAddmodal(!addmodal);
  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (i) => {
    setEditmodal(!editmodal);
    setLoader(true);
    fetch(`${process.env.REACT_APP_MERCHANT_BRANDS}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
		"x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl",
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        let myResponse = result[0];
        myResponse.status = String(myResponse.status)
        reset2(myResponse);
        setEditMerchantBrands(result[0])
      });
    });
  }
  const brandPointListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let data = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data = { "merchant_id": props.user[0].id }
    } else {
      data = { "merchant_id": merchantId }
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
    })
      .then(function (response) {
        setLoadertable(false);
        if (response.data.fatal !== true) {
          setBrandList(response.data)
        }
      })
      .catch(function (response) {
        setLoadertable(false);
        Swal.fire({ title: 'Error', text: response, icon: 'error' })
      });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(brandList.data) && checkUndeNullBlank(brandList.totalPage)) {
      // &&checkUndeNullBlank(brandList.totalPage).length>page.length
      if (brandList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(brandList.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(brandList.data)
      if (currentCount > Object.entries(brandList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  useEffect(() => {
    brandPointListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId])
  const { register: addMerchantBrands, handleSubmit: MerchantBrandSubmit, formState: { errors }, reset } = useForm();
  const onAddMerchantBrandsSubmit = (data) => {
    setLoader(true);
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data.merchant_id = props.user[0].id
    } else {
      data.merchant_id = merchantId
    }
    let formData = new FormData();
    formData.append("merchant_id", data.merchant_id);
    formData.append("brand_name", data.brand_name);
    formData.append("brand_website", data.brand_website);
    formData.append("status", data.status);
    formData.append("logo", logo);

    axios
      .post(process.env.REACT_APP_MERCHANT_BRANDS_CREATE, formData)
      .then((res) => {
        if (res.data.error === false) {
          setAddmodal(false);
          setLoader(false)
          reset();
          brandPointListing();
          Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }
      })
      .catch(function (response) {
        setLoader(false);
        Swal.fire({ title: 'Error', text: response, icon: 'error' })
      });
  };

  const { register: editMerchantBranddata, handleSubmit: merchantBrandsEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const onEditMerchantBrandsSubmit = (data) => {
    setLoader(true);
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data.merchant_id = props.user[0].id
    } else {
      data.merchant_id = merchantId
    }
    let formData = new FormData();
    formData.append("merchant_id", data.merchant_id);
    formData.append("brand_name", data.brand_name);
    formData.append("brand_website", data.brand_website);
    formData.append("status", data.status);
    formData.append("logo", logo !== '' ? logo : editMerchantBrands !== undefined && editMerchantBrands.logo !== undefined && editMerchantBrands.logo !== "" && editMerchantBrands.logo !== null ? editMerchantBrands.logo : 'NA');

    axios
      .post(`${process.env.REACT_APP_MERCHANT_BRANDS_UPDATE}/${editMerchantBrands.id}`, formData)
      .then((res) => {
        if (res.data.error === false) {
          setEditmodal(false);
          setLoader(false);
          setLogo('');
          brandPointListing();
          Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          })
        }
      })
      .catch(function (response) {
        setLoader(false);
        Swal.fire({ title: 'Error', text: response, icon: 'error' })
      });
  };
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    } else {
      mydata = { "merchant_id": merchantId }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_BRANDS}?brand_name=${checkUndeNullBlank(data.brand_name) ? data.brand_name : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: mydata,
    }).then(function (response) {
      setLoader(false);
      if (response.data.fatal !== true) {
        setBrandList(response.data);
      }
    }).catch(function (response) {
      setLoader(false);
      Swal.fire({ title: 'Error', icon: 'error', text: response })
    });
  };
  const onFilter = () => setIsOpen(!isOpen);

  return (
    <div className="content-body content overflow-hidden">
       
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
           <Card className="p-0 my-3 master-content content-wrapper border-0">
           <div className="page-header content-header"> 
              
			<div className="card-title title_icon">
			<i className="bi bi-card-heading"></i> 
			Manage Stores   
			 
				<div className="card-header-right d-flex align-items-center ml-auto">
				{checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
				  <Link to='/merchants' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Merchant List</span></Link>
				}
				{(checkPermission(props.user, props?.user[0]?.permissions?.merchant_Brands?.add) || props.user[0].user_type === "Merchant") &&
				  <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Stores</span></div>
				}
				<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
				</div>
						  
			</div>
			  
			  
              <Modal isOpen={addmodal} toggle={addmodal}>
                <ModalHeader toggle={toggleAdd}>Add Merchant Stores</ModalHeader>
                <ModalBody>
                  <Form onSubmit={MerchantBrandSubmit(onAddMerchantBrandsSubmit)}>
                    <Row className="mx-0 justify-content-center addModal">
                      <Col sm={12}>
                        <FormGroup>
                          <Label for="brandName_add">Enter Stores Name</Label>
                          <input {...addMerchantBrands("brand_name", { required: 'Please Enter Stores Name' })} className="form-control-sm form-control" placeholder="Stores Name" id="brandName_add" />
                          {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup>
                          <Label for="website_add">Enter Website URL</Label>
                          <input {...addMerchantBrands("brand_website", {
                            required: 'Please Enter Stores Website',
                            pattern: { value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, message: 'Please Enter Valid Website URL' }
                          })} className="form-control-sm form-control" placeholder="Stores Website" id="website_add" />
                          {errors.brand_website && <span className="text-danger d-block error">{errors.brand_website.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup>
                          <Label for="brandLogo_add">Upload Logo</Label>
                          <input {...addMerchantBrands("logo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="brandLogo_add" />
                          {errors.logo && <span className="text-danger d-block error">{errors.logo.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-0">
                          <Label>Status</Label>
                          <div>
                            <span className="mr-2">
                              <input {...addMerchantBrands("status", { required: 'Please Add Status' })} type="radio" value="1" id="active_add" defaultChecked />
                              <Label className="mx-2" for="active_add">Active</Label>
                            </span>
                            <span>
                              <input {...addMerchantBrands("status", { required: 'Please Add Status' })} type="radio" value="0" id="inactive_add" />
                              <Label className="mx-2" for="inactive_add">InActive</Label>
                            </span>
                          </div>
                          {errors.status && <span className="text-danger d-block error">{errors.status.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Save</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal isOpen={editmodal} toggle={editmodal}>
                <ModalHeader toggle={toggleEditClose}>Edit Merchant</ModalHeader>
                <ModalBody>
                  {checkData(editMerchantBrands) &&
                    <Form onSubmit={merchantBrandsEditSubmit(onEditMerchantBrandsSubmit)}>
                      <Row className="mx-0 justify-content-center addModal">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="brandName_edit">Enter Stores Name</Label>
                            <input {...editMerchantBranddata("brand_name", { required: 'Please Enter Stores Name' })} className="form-control-sm form-control" placeholder="Stores Name" id="brandName_edit" />
                            {errors2.brand_name && <span className="text-danger d-block error">{errors2.brand_name.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="websiteUrl_edit">Enter Website URL</Label>
                            <input {...editMerchantBranddata("brand_website", {
                              required: 'Please Enter Brand Website',
                              pattern: { value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, message: 'Please Enter Valid Website URL' }
                            })} className="form-control-sm form-control" placeholder="Brand Website" id="websiteUrl_edit" />
                            {errors2.brand_website && <span className="text-danger d-block error">{errors2.brand_website.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="uploadLogo_edit">Upload Logo</Label>
                            <input {...editMerchantBranddata("brandLogo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_edit" />
                            {editMerchantBrands.logo !== '' && editMerchantBrands.logo !== undefined && editMerchantBrands.logo !== null && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT + editMerchantBrands.logo} alt="logo" style={{ width: "150px" }} /></div>
                            }
                            {errors2.brandLogo && <span className="text-danger d-block error">{errors2.brandLogo.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <Col sm={12}>
                            <FormGroup className="mb-0">
                              <Label>Status</Label>
                              <div>
                                <span className="mr-2">
                                  <input {...editMerchantBranddata("status", { required: 'Please Add Status' })} type="radio" value="1" id="active_add" />
                                  <Label className="mx-2" for="active_add">Active</Label>
                                </span>
                                <span>
                                  <input {...editMerchantBranddata("status", { required: 'Please Add Status' })} type="radio" value="0" id="inactive_add" />
                                  <Label className="mx-2" for="inactive_add">InActive</Label>
                                </span>
                              </div>
                              {errors.status && <span className="text-danger d-block error">{errors.status.message}</span>}
                            </FormGroup>
                          </Col>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn  btn-sm search" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  }
                </ModalBody>
              </Modal>
            
			
            <Collapse isOpen={isOpen}>
              <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
                <Row className="mx-0 pt-1 justify-content-start justify-content-sm-center">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("brand_name")} type="text" className="form-control-sm form-control" placeholder="Stores Name" />
                    </FormGroup>
                  </Col>
                  <Col sm={2} className="filter-btn">
                    <Button className="btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={brandPointListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
            </Collapse>
            <CardBody className="p-0">
              {checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
                <>
                  <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                    <div className="showing-count">
                      Showing {currentCount} to {(currentCount - 1) + (Object.entries(brandList.data).length)} of {brandList.TotalRows} entries
                    </div>
                    {brandList.TotalRows > dropFilterCount ?
                      <Form>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </FormGroup>
                      </Form>
                      : ''}
                  </div>
                </>
                : ''}
              <Table responsive borderless className="text-center customTable">
                <thead>
                  <tr>
                    <th>Stores ID</th>
                    <th>Stores Name</th>
                    <th className="col-2">Stores Website</th>
                    <th>Logo</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {checkData(brandList.data) ? brandList.data.map((item, index) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.brand_name}</td>
                      <td><Link to={{ pathname: item.brand_website }} target="_blank" className="text-truncate">{item.brand_website}</Link></td>
                      <td><img src={process.env.REACT_APP_IMAGE_MERCHANT + item.logo} alt="blue logo" style={{ width: '100px' }} /></td>
                      <td>{(item.updated_at !== undefined && item.updated_at !== null ? changeIntoDate(item.updated_at) : 'NA')}</td>
                      <td>{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                      <td className="text-nowrap"><span className="mr-2"><Status statusUrl={process.env.REACT_APP_MERCHANT_BRANDS} listId={item.id} valStatus={item.status} reListing={brandPointListing} data={{ "brand_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>
                        {(checkPermission(props.user, props?.user[0]?.permissions?.merchant_Brands?.edit) || props.user[0].user_type === "Merchant") &&
                          <span><Button outline className="btn btn-sm ml-2" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                        }
                      </td>
                    </tr>
                  ))
                    : loadertable ? <tr><td colSpan="7">Loading...</td> </tr> :
                      <tr><td colSpan="7">No Data Found</td> </tr>}
                </tbody>
              </Table>
              {checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
                <>
                  {brandList.TotalRows > dropFilterCount ?
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                        Showing {currentCount} to {(currentCount - 1) + (Object.entries(brandList.data).length)} of {brandList.TotalRows} entries
                      </div>
                      <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                          <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                    : ''}
                </>
                : ''}
            </CardBody>
			</div>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default MerchantBrands;