import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Collapse,Label,Modal,ModalHeader,ModalBody, FormGroup,InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, Input} from 'reactstrap';
import { MultiSelect } from "react-multi-select-component";
// import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faFilter, faCartPlus, faTimes, faArrowsAltH} from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { delivery_type_id } from '../../config';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDate, changeIntoDateYmd } from '../../utlis';
import { Link, useHistory } from 'react-router-dom';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import EditBulkOrders from './EditBulkOrders';

const ProcessingOrder = (props) => {
  const [deliveryType, setDeliveryType] = useState([]);
  const [filterDate, setFilterDate] = useState('');
  const [allprocessingOrder, setAllprocessingOrder] = useState({});
  const [tableLoader, setTableLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [courierList, setCourierList] = useState({});
  const [checked, setChecked] = useState(false);
  const [cancelmodal, setCancelmodal] = useState(false);
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const [orderDetail, setOrderDetail] = useState({});
  const [editmodal, setEditmodal] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropFilterCount = 20
  const [loader, setLoader] = useState(false);
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const history = useHistory();
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }

  const toggleEditClose = () => setEditmodal(false); 
  const toggleEdit = (productId, merchantId) => {
    setLoader(true);
    setMerchantId(merchantId)
    let data = {}
    data = {"order_id":productId, "merchant_id":merchantId, "is_edit":1}
    setEditmodal(!editmodal); 
    axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
  .then((res) => {
    setLoader(false);
    if(res.data !== undefined && res.data.fatal !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        setOrderDetail(res.data);
      }
    }
  }).catch((err) => {
    setLoader(false);
    Swal.fire({icon:'error',text:err,title:'Error'});
  })
  }
  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const couurierListing = () => {
    if(checkData(props.user)&&props.user[0].user_type === "Merchant"){
    setLoader(true)
    axios({
      method: "post",
      url : process.env.REACT_APP_MERCHANT_COURIER_LIST,
      data: {"merchant_id" : checkData(props.user)&&props.user[0].id}
    }).then(function (response) {
      setLoader(false)
      if(response.data.error===false){
        setCourierList(response.data.data.courierList)
      }else{
        Swal.fire({ title: 'Error',text: response.data.msg?response.data.msg:'Something went wrong',icon: 'error'});
      }
      
    }).catch(function (err){
      setLoader(false);
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
  }
}
  const changeCourierToggle = () => {
    if(checkboxOrderId.length > 0){
      setChangeCourierModal(!changeCourierModal);
    }else{
      Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
    }
  }

  const processingOrderListing = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    setFilterInput('')
    // setCurrentCount(1)
    // setPage(1)
    let data = {"order_status_id":25, "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=1
    }else{
      data.merchant_id=props.user[0].id
    }
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setFilterDate('');setDeliveryType([]);setSearchValue('');

    axios.post(`${process.env.REACT_APP_ALL_ORDER}?page=${page}&per_page=${selectperpage}`, data)
    .then((res) => {
      setTableLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setAllprocessingOrder(res.data);
        }
      }
    }).catch((err) => {
      setTableLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
  }
  useEffect(() =>{
    setTableLoader(true)
    processingOrderListing();
    couurierListing();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectAll = (value) => {
    setOverallChecked(false);

    let abc = checkData(allprocessingOrder.data)&&allprocessingOrder.data.map((key) => (
      key===false
    ));
    if(value){
    var opt_order_id = [];
      checkData(allprocessingOrder.data) && Object.keys(allprocessingOrder.data).map(function(key){
      let tempObj = getMinutes(allprocessingOrder.data[key].updated_at) > 2 && allprocessingOrder.data[key].id    
      tempObj && opt_order_id.push(tempObj);
        
      return setCheckboxOrderId(opt_order_id)
    });
    // ));
    }else{
      setCheckboxOrderId([])
    }
    
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);

    var opt_order_id = checkboxOrderId;
  
      setChecked((prevState) => {
        const newState = { ...prevState };
        newState[utilityCheckbox] = !prevState[utilityCheckbox];
        return newState;
      });
      if(event.target.checked){
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
      }else{
      function arrayRemove(arr, value) { 
        return arr.filter(function(ele){ 
          return ele !== value; 
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
        setCheckboxOrderId(result)
      };
    }
  const DeliveryTypeMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Delivery Type..."
  }
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = {label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(allprocessingOrder.data)&&checkUndeNullBlank(allprocessingOrder.totalPage)){
    // &&checkUndeNullBlank(allprocessingOrder.totalPage).length>page.length
      if(allprocessingOrder.totalPage > page){
        setCurrentCount(currentCount+Object.entries(allprocessingOrder.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(allprocessingOrder.data)
  if(currentCount>Object.entries(allprocessingOrder.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  // filter Bulk Order
  const { register:filter, handleSubmit:filterOrderSubmit} = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onFilterOrder = (data,pagePerpage) => {
    pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(deliveryType) || checkUndeNullBlank(datestate)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data,pagePerpage) => {
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setFilterInput(data);
    setLoader(true)
    let byData = {}
    let tmpDeliveryType = []
  
    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    byData={"order_status_id":25,"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"delivery_type_id":checkData(tmpDeliveryType)?tmpDeliveryType:'', "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setAllprocessingOrder(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
  let getMinutes = function(date){
    var endTime = new Date();
    var startTime = new Date(date); 
    var timeStart = new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);
    
    var difference = endTime.getTime() - timeStart.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  }


  
   // cancel shipment
   const cancelmodalToggle = () => {
    if(checkboxOrderId.length > 0){
      setCancelmodal(!cancelmodal);
    }else{
      Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
    }
  }
  const placeBulkOrder = (oid, mid, sid) => {
    let orderIds = checkUndeNullBlank(oid) ? [oid] : checkboxOrderId.length > 0 ? checkboxOrderId : '';
    let orderStatusId = (checkUndeNullBlank(sid)) ? sid : 25
    let data = {"order_ids":orderIds, "order_status_id":orderStatusId}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
      data.merchant_wallet_balance=props.user[0].wallet_balance
    }
    if(checkUndeNullBlank(oid) || checkboxOrderId.length > 0){
      setLoader(true);
    axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
    .then((res) => {
      setLoader(false);
      if(res.data.error !== true && res.data.fatal !== true){
        Swal.fire({icon:'success',text:res.data.msg,title:'Success',allowOutsideClick: false,allowEscapeKey: false}).then(okay => {
          if (okay) {
            setOverallChecked(false);
            setCheckboxOrderId([]);
            setCheckedAll(false);
            setChecked(false);
            processingOrderListing();
         }
       });
      }else{
            Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoader(false);
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
    }else{
      Swal.fire({icon:'warning', title:'Warning', text:'Please Select The Order First'})
    }
  }
  const { register:cancelOrder, handleSubmit:cancelOrderSubmit,formState: { errors: errors4 }, reset:resetCancelled} = useForm();
  const onCancelOrderSubmit = data => {
      setLoader(true);
      data.order_ids=checkboxOrderId
    axios.post(process.env.REACT_APP_CANCEL_ORDER, data)
    .then((res) => {
      setLoader(false)
          if(res.data.error === false){
            Swal.fire({icon:'success', title:'Success', text:res.data.msg})
            setCancelmodal(false);
            resetCancelled();
            setCheckboxOrderId([]);
            processingOrderListing();
            // onCommonFilter();
            setCheckedAll(false);
            setChecked(false);
          }else{
            Swal.fire({icon:'error', title:'Error', text:res.data.msg})
          }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }
  const { register:changeCourier, handleSubmit:changeCourierSubmit,formState: { errors: errors5 }, reset:resetChangeCourier} = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids=checkboxOrderId
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
  axios.post(`${process.env.REACT_APP_CHANGE_COURIER}`,data)
  .then((res) => {
    setLoader(false);
    if(res.data.error === false){
      Swal.fire({icon:'success',text:res.data.msg,title:'Success'});
      setChangeCourierModal(false);
      if(checkData(filterInput)){
        onCommonFilter();
      }else{
        processingOrderListing();
      }
      
      resetChangeCourier();
    }
    else{
      Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',})
    }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
  }
  const onFilter = () => setIsOpen(!isOpen);
  
  return (
       <Col sm={12}>
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderDetail) &&
        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditBulkOrders orderDetails={orderDetail} setEditmodal={setEditmodal}  merchantId={merchantId} setOrderDetail={setOrderDetail} user={props.user} processingOrderListing={processingOrderListing} /> 
          </ModalBody>
        </Modal>
        }
        <Card className="p-0 my-3 processingOrder content-wrapper border-0">
          <Card className="border-0">
              
			<div className="card-title title_icon">
			<i className="bi bi-card-heading"></i> Manage Processing Orders 
			 
			<div className=""> 
			<div className="card-header-right d-flex align-items-center ml-auto">
			{checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 && checkData(allprocessingOrder) && checkData(allprocessingOrder.data) &&
			  <>
			<div className="btn-text btn-light mx-2" onClick={() => placeBulkOrder()}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>REPROCESS</span></div>  
			<div className="btn-text btn-light mx-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Shipment</span></div>
			<div className="btn-text btn-light" onClick={changeCourierToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAltH} /></span><span>Change Courier</span></div>
			</>}
			<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
			</div> 

			</div> 
			</div>
			 
             <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                <ModalHeader toggle={cancelmodalToggle}>Cancel Shipment</ModalHeader>
                <ModalBody>
                <Form onSubmit={cancelOrderSubmit(onCancelOrderSubmit)}>
                   <Row className="m-0 pb-1">                      
                     <Col sm={12}>
                        <FormGroup>
                            <Label for="remaks">Remarks</Label>
                            <textarea {...cancelOrder("remarks", {required : 'Please Enter Remaks'})} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                            {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                    </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal> 
              <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                <ModalHeader toggle={changeCourierToggle}>Change Courier</ModalHeader>
                <ModalBody>
                  <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                   <Row className="m-0 pb-1">                      
                     <Col sm={12}>
                        <FormGroup className='position-relative'>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...changeCourier("courier_id", {required : 'Please Choose Courier'})} className="form-control-sm form-control border-right-0">
                              <option value="">Select Courier</option>
                              {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => ( */}
                              {checkData(courierList) && courierList.map((item) => (
                                item.status !== 0 &&
                                <option value={item.courier_id}>{item.brand_name}</option>
                              ))}
                            </select>
                            {errors5.courier_id && <span className="text-danger d-block error">{errors5.courier_id.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                    </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>

             <Collapse isOpen={isOpen}>
                <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_name">Customer Pincode</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_deliveryType}
                              value={deliveryType}
                              onChange={(e) => setDeliveryType(e)}
                              labelledBy="Delivery Type"
                              overrideStrings={DeliveryTypeMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>                    
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} /> */}
                            <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              <div className='form-control cursor-pointer d-flex align-items-center'>
                                {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                              </div>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn btn-sm mx-2" color="primary" outline  onClick = {processingOrderListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>  
              </Collapse>
              <CardBody className="p-0">
              {checkData(allprocessingOrder) && checkData(allprocessingOrder.data) && checkUndeNullBlank(allprocessingOrder.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(allprocessingOrder.data).length)} of {allprocessingOrder.TotalRows} entries
                    </div>
                      {allprocessingOrder.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                    {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" &&
                        <th><div className="form-check2"><input name="cust-type" type="checkbox" id="select_all_processing" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)}  value="checkedall"/><label className="form-check-label" for="select_all_processing"></label></div></th>
                      }
                      {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <th className="text-nowrap">Merchant Name</th>}
                      <th className="text-nowrap">Order Number</th>
                      <th className="text-nowrap">Customer Pincode</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">Uploaded</th>
                      <th className="text-nowrap">Last Updated</th>
                      <th className="text-nowrap">Response</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                   </thead>
                  <tbody>
                    {checkData(allprocessingOrder.data) ? allprocessingOrder.data.map((item, index) => (
                      <tr>
                      {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" &&
                          <td>
                          {getMinutes(item.updated_at) > 2 &&
                            <div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check'+index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div>
                          }
                          </td>
                        }
                        {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                      <td>{item.merchantName}</td>}
                        <td><Link to={`/order-detail/${item.id}`}>{item.order_no}</Link></td>
                        <td>{item.destination_pincode}</td>
                        <td>{item.courier_name}</td>
                        <td>{changeIntoDate(item.created_at)}</td>
                        <td>{changeIntoDate(item.updated_at)}</td>
                        <td>{item.api_msg}</td>
                        <td>
                        {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" ?
                        <>
                          {getMinutes(item.updated_at) > 2 ?
                          <>
                            <span><Button className="mx-2 btn-sm" color="primary" outline  onClick={() => placeBulkOrder(item.id,item.merchant_id,item.order_status_id)}>REPROCESS</Button></span>
                            <span><Button className="mx-2 btn-sm" color="primary" outline  onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                          </>
                          :
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          }
                        </>
                        :
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span><Button className="mx-2 btn-sm" color="primary" outline  onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                        </>
                        }
                        </td>
                      </tr>
                    )) :
                    tableLoader ? <tr><td colSpan="5" className="text-center">Loading...</td></tr>
                      :<tr><td colSpan="5" className="text-center">No Data Found</td></tr>
                      }
                    
                  </tbody>
                </Table>
                {checkData(allprocessingOrder) && checkData(allprocessingOrder.data) && checkUndeNullBlank(allprocessingOrder.TotalRows) ?
                <>
                {allprocessingOrder.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(allprocessingOrder.data).length)} of {allprocessingOrder.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
  );
}

export default ProcessingOrder;