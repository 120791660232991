import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledTooltip} from 'reactstrap';
import {Link,useHistory} from 'react-router-dom';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
import axios from '../../CommonAxios';

import { download_delay } from '../../config';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight, Copy} from 'react-feather';
import { connect } from 'react-redux';
import { toast,ToastContainer } from 'react-toastify';
import {errorHandling,remExtraSpace, changeIntoDate, copyToClipBoard} from '../../utlis';
import moment from 'moment';
import { faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';

const MerchantInvoices = (props) => {
  const [merchantInvoiceList, setMerchantInvoiceList] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [defSelYear,setDefSelYear] = useState('');
  const [defSelMonth,setDefSelMonth] = useState('');
  const dropFilterCount = 20;
  let history = useHistory();

  let Year = moment().format('YYYY');
  let prevYear = moment().add(-1, 'year').format('YYYY'); 

  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
      Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {} 
      if(merchantName && merchantId){
          tempObj = {label: merchantName, value: merchantId }
          opt_merchants.push(tempObj)   
      }
      return true
      });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  useEffect(() => {
    InvoiceListing();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register:filter, handleSubmit:filterInvoiceListSubmit,reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };

  const onFilterInvoiceList = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.invoice_no) || checkUndeNullBlank(data.bill_month) || checkData(data.bill_year) || checkData(merchants)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }else{
      Swal.fire({title: 'info',text: 'Please Choose Filter',icon: 'info',})
    }
  };
  
  const InvoiceListing = () => {
    reset();
    setMerchants([]);    

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      setDefSelYear(moment().format('YYYY'));
      setDefSelMonth(moment().add(-1, 'month').format('MM'));
    }else{
      setDefSelYear('');
      setDefSelMonth('');
    }

    let data = {};

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.bill_month = moment().add(-1, 'month').format('MM');
      data.bill_year = moment().format('YYYY');
    }

    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INVOICE}?page=${page}&per_page=${selectperpage}`,
      data: data,
    }).then((res) => {
      setLoadertable(false)
        if(res.data.error === false){
          setMerchantInvoiceList(res.data);
        }else{
          Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err,"reload");
    });
  }

  function previousPageData() {                                                                                                                                                                                                                                                                                                                       
    if(page >1){
    setPage(page-1)
    checkData(merchantInvoiceList.data)
    if(currentCount>Object.entries(merchantInvoiceList.data).length){
      setCurrentCount(currentCount-selectperpage)
    }else{
      setCurrentCount(1)
    }
    let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }

  function nextPageData() {
    if(checkData(merchantInvoiceList.data)&&checkUndeNullBlank(merchantInvoiceList.totalPage)){
        if(merchantInvoiceList.totalPage > page){
          setCurrentCount(currentCount+Object.entries(merchantInvoiceList.data).length)
      setPage(page+1)
      setLoadertable(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }

  const onCommonFilter = (data,pagePerpage) => {
    let byData = {}
  let merchantsId = [];

  if(checkData(merchants)){
    merchants.map((item) => (
      merchantsId.push(item.value)
    )); 
  } 
  setLoader(true)
  if(data === undefined){
    data = filterInput
  }
  let mId = checkUndeNullBlank(merchantsId)?merchantsId:'';
 
  byData={"invoice_no":checkUndeNullBlank(data.invoice_no)?remExtraSpace(data.invoice_no):'',"bill_month":checkUndeNullBlank(data.bill_month)?data.bill_month:'',"bill_year":checkUndeNullBlank(data.bill_year)?data.bill_year:'',"merchant_ids":mId}
  setFilterInput(byData);
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
    byData.admin_id = props.user[0].id
  }else{
    byData.merchant_id = props.user[0].id
  }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INVOICE}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setMerchantInvoiceList(res.data);
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      errorHandling(err,"");
      setLoader(false);
    });
  };

  // copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
      if(result === true){
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const exportInvoicePDF = (invoiceId) => {
    let data = {"invoice_id":invoiceId}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios.post(process.env.REACT_APP_MERCHANT_INVOICE_DOWNLOAD_PDF, data).then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
          setLoader(false);
          if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.error === false){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
        }
      }
    }
  }).catch((err) => {
    setLoader(false)
    errorHandling(err,"");
  });
}
const exportInvoiceExcel = (invoiceId, date, merchanId) => {
  let firstDay = moment(date).startOf('month').format("YYYY-MM-DD");
  let lastDay = moment(date).endOf('month').format("YYYY-MM-DD");
  let data = {"invoice_id":invoiceId, "start_date":firstDay,"end_date":lastDay}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
      data.merchant_ids = [merchanId]
    }else{
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    data.is_export = 1
    axios.post(process.env.REACT_APP_MERCHANT_FREIGHT_LIST, data).then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
          setLoader(false);
          if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.error === false){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
        }
      }
    }
  }).catch((err) => {
    setLoader(false)
    errorHandling(err,"");
  });
}
  return (
    <Col sm={12}>
    {loader && <div className="formLoader"><Spinner /></div>}
        <Card className="p-0 my-3 merchantInvoice content-wrapper border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="page-title">Manage {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") && "Merchant"} Invoices</CardTitle>
            </CardHeader>
              <Form onSubmit={filterInvoiceListSubmit(onFilterInvoiceList)} className="filter-form">
                <Row className="mx-0 justify-content-start justify-content-md-center"> 
                  {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => {setMerchants(e);}}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                    </FormGroup>
                  </Col>}

                  <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <input  {...filter("invoice_no")} type="text" className="form-control-sm form-control" placeholder="Enter Invoice No." />
                      </FormGroup>
                  </Col> 
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>                      
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select value={defSelMonth} {...filter("bill_month")} className="form-control-sm form-control" onChange={(e) => {setDefSelMonth(e.target.value);}}>
                          <option value="">Select Month</option>
                          <option value="01">Jan</option>
                          <option value="02">Feb</option>
                          <option value="03">Mar</option>
                          <option value="04">Apr</option>
                          <option value="05">May</option>
                          <option value="06">Jun</option>
                          <option value="07">Jul</option>
                          <option value="08">Aug</option>
                          <option value="09">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>                 
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>                      
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select value={defSelYear} {...filter("bill_year")} className="form-control-sm form-control" onChange={(e) => {setDefSelYear(e.target.selectedOptions[0].text)}}>
                          <option value="">Select Year</option>                          
                          <option value={prevYear}>{prevYear}</option>                          
                          <option value={Year}>{Year}</option>                          
                        </select>
                      </div>
                    </FormGroup>
                  </Col>                 
                  <Col sm={3} className="filter-btn">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={InvoiceListing} outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                  <span class="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(merchantInvoiceList.data).length)} of {merchantInvoiceList.TotalRows}</span>: ''}  
                </div>
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th className="text-nowrap">Merchant</th>}
                      <th className="text-nowrap">InvoiceNumber</th>
                      <th className="text-nowrap">Invoice Date</th>
                      <th className="text-nowrap">Due Date</th>
                      <th className="text-nowrap">GST</th>
                      <th className="text-nowrap">Invoice Amount</th>
                      <th className="text-nowrap">Invoice Clear</th>
                      <th className="text-nowrap">Balance</th>
                      <th className="text-nowrap">Pay Status</th>
                      <th className="text-nowrap">View</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(merchantInvoiceList.data) ? merchantInvoiceList.data.map((item, index) => {
                    let totalAmount = checkUndeNullBlank(item.net_amt) ? item.net_amt : 0
                    let totalAdjusted = checkUndeNullBlank(item.totalAdjust) ? item.totalAdjust : 0
                    let totalBalnce = (totalAmount - totalAdjusted).toFixed(2)
                    return(
                    <tr>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <td>{item.merchantName}</td>}
                      <td><Link to={`/invoice-detail/${item.id}`}>{item.invoice_no}</Link>
                        <span onClick={() => copied(item.invoice_no)} className="ml-1 copy-btn d-none" id={`copyInvNo${item.id}`}>
                          <Copy />
                          <UncontrolledTooltip placement="bottom" target={`copyInvNo${item.id}`}>
                            Copy
                          </UncontrolledTooltip>
                        </span> 
                      </td>
                      <td className='text-nowrap'>{changeIntoDate(item.invoice_date)}</td>
                      <td className='text-nowrap'>{changeIntoDate(item.due_date)}</td>
                      <td>{item.total_gst}</td>
                      <td>{totalAmount}</td>
                      <td>{totalAdjusted}</td>
                      <td>{totalBalnce}</td>
                      <td>
                        {item.pay_status === 0 && totalBalnce > 0 ?
                        <span className="text-danger">Unpaid</span>:
                        <span className="text-success">Paid</span>
                        }
                      </td>
                      <td>
                        <div className="text-nowrap">
                          <Link to={`/invoice-detail/${item.id}`}>Invoice</Link> 
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap icon-font text-primary">
                          <span className="mr-2 cursor-pointer" onClick={()=>exportInvoiceExcel(item.id, item.invoice_date, item.merchant_id)}><FontAwesomeIcon icon={faFileExcel} /> </span> 
                          <span className="cursor-pointer" onClick={()=>exportInvoicePDF(item.id)}><FontAwesomeIcon icon={faFilePdf} /> </span> 
                        </div>
                      </td>
                    </tr>
                    )}):
                    loadertable ? <tr><td colSpan="10" className="text-center">Loading...</td></tr>
                    :<tr><td colSpan="10" className="text-center">No Data Found</td></tr>}
                  </tbody>
                </Table>
                <ToastContainer className="copied-con" />

                {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                <>
                {merchantInvoiceList.TotalRows > dropFilterCount ?
                <Row className="m-0 table-footer">
                  <Col xs="6">               
                    <Pagination aria-label="Page navigation example align-items-center">
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                  </Col>
                  <Col xs="6">
                    <Form>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                              <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>:''}
                </>
                :''}
              </CardBody>
        </Card>
      </Col>
      
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(MerchantInvoices);