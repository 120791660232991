import React, {useEffect,useState} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Spinner} from 'reactstrap';
import lableimg1 from '../../../assets/images/Address-Label1.png'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
// import axios from 'axios';
import axios from '../../../CommonAxios';

const LabelSetting = (props) => {
  const [loader, setLoader] = useState(false);
  const { register:addresslabelChoose, handleSubmit:addressLabelSubmit, reset  } = useForm();

    useEffect(() =>{
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
            data: {"setting_id": '1', "merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'',},
          })
            .then(function (response) {
              let myResponse=response.data[0];
              reset(myResponse);
            }).catch((err) => {
                Swal.fire({icon:'error',text:err,title:'Error'});
              });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    const onSelectAddressLabel = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'');
        formData.append("setting_value", data.setting_value);
        formData.append("setting_id", '1');
        axios
          .post(process.env.REACT_APP_MERCHANT_SETTING, formData)
          .then((res) => {
            setLoader(false);
            if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
            setLoader(false);
            Swal.fire({icon:'error', text:"Something went wrong", title:'Error'})
          });
      };

    return (
        <>
        {loader && <div className="formLoader"><Spinner /></div>}
        <Form onSubmit={addressLabelSubmit(onSelectAddressLabel)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Label Setting</CardTitle>
            </div>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>
                            <input type="radio" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="1" defaultChecked />
                            Label One
                        </Label> 
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1"/>
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4} className='d-none'>
                    <FormGroup>
                        <Label>
                            <input type="radio" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="2" />
                            Label Two
                        </Label> 
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1"/>
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4} className='d-none'>
                    <FormGroup>
                        <Label>
                            <input type="radio" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="3" />
                            Label Three
                        </Label> 
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1"/>
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4} className='d-none'>
                    <FormGroup>
                        <Label>
                            <input type="radio" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="4" />
                            Label Four
                        </Label>  
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1"/>
                        </div>
                    </FormGroup>
                </Col>
                
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="btn-sm" color="primary"> Save changes</Button>
                </Col>            
            </Row>
        </Form>
        </>
    );
}

export default LabelSetting;