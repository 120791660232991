import React, { useState, useEffect } from "react";
import { Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import classnames from 'classnames';
import MerchantFreight from './MerchantFreight'
import MerchantInvoices from "../Invoices/MerchantInvoices";
import { checkPermission } from "../../ObjectExist";
const Sales = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    //   const toggleDataAdd = () => setDataAdd(!dataAdd);
    useEffect(() => {
    }, []);

    return (
        <div className="content-body">
            <div className="background-strip"></div>
            <Container fluid>
                <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
                    <div class="mb-3 mr-3">
                        <h4 class="text-white font-w600 mb-0">Manage Sales</h4>
                    </div>
                    <Nav tabs className="master-tabs">
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_Invoices?.freight_list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <span className="text-white">Merchant Freight</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_Invoices?.merchant_invoices) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    <span className="text-white">Merchant Invoices</span>
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                </div>
                <div className="master-content card border-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_Invoices?.freight_list) &&
                                        <MerchantFreight user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <MerchantInvoices user={props.user} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    )
}
export default Sales