import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button,UncontrolledTooltip,Collapse, CardTitle, Spinner,Input, InputGroup,InputGroupAddon,InputGroupText,FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown,faChevronLeft, faChevronRight, faFilter} from '@fortawesome/free-solid-svg-icons';
import {copyToClipBoard, changeIntoDateTime,firstDayOftheMonth, changeIntoDateYmd} from '../../utlis';
import { Copy } from 'react-feather';
import { toast,ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
//import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import {connect, useDispatch} from 'react-redux'
import { MerchantRemittanceList } from '../../actions';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { download_delay } from '../../config';
import {useHistory} from 'react-router-dom';

const MerchantRemittance = (props) => {
  const [loadertable, setLoadertable] = useState(false);
  const [merchantRemittance, setMerchantRemittance] = useState({});
  const [filterDate, setFilterDate] = useState('');
  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [searchValue, setSearchValue] = useState('');
  const [loader, setLoader] = useState(false);
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const history = useHistory();

  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.merchants[key].merchantName, value: props.state.shipmentMerchantCourier.merchants[key].merchant_id }     
      return opt_merchants.push(tempObj)
    });
  }  

  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;


  useEffect(() => {
    if(checkData(props.state) && checkData(props.state.MerchantRemittance)){
      setMerchantRemittance(props.state.MerchantRemittance);
    }else{
    remittanceListing();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // remittance Listing 
  const remittanceListing = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    let data = {"start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=1
    }else{
      data.merchant_id=props.user[0].id
    }

    reset();setFilterDate('');setMerchants([]);setAllCourier([]);setSearchValue('');
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = data
    let myget = {"page":page, "per_page":selectperpage}
    dispatch(MerchantRemittanceList(mypost,myget)).then((response) => {
      // setMerchant(response);
            setMerchantRemittance(response);
            setLoadertable(false)
    }).catch(function (response) {
      setLoadertable(false);
      Swal.fire({title:'Error', icon:'error', text:response})
    });
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(merchantRemittance.data)&&checkUndeNullBlank(merchantRemittance.totalPage)){
      if(merchantRemittance.totalPage > page){
        setCurrentCount(currentCount+Object.entries(merchantRemittance.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(merchantRemittance.data)
  if(currentCount>Object.entries(merchantRemittance.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register:filter, handleSubmit:filtermerchRemitSubmit, reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onFilterMerchRemit = (data,pagePerpage) => {
    pagePerpage.page=1
    if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(merchants) || checkData(allCourier) || checkUndeNullBlank(data.pay_status) || checkUndeNullBlank(datestate) || checkData(filterDate) ){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data,pagePerpage) => {
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    // let tmpallCourier = [];
    // allCourier.map((item) => (
    //   tmpallCourier.push(item.value)      
    // ));

    if(checkData(allCourier)){
      allCourier.map((item) => (
        courierId.push(item.value)
      )); 
    } 
    if(checkData(merchants)){
      merchants.map((item) => (
        merchantsId.push(item.value)
      )); 
    } 

    byData={"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"pay_status":checkUndeNullBlank(data.pay_status)?data.pay_status:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"merchant_ids":checkUndeNullBlank(merchantsId)?merchantsId:'', "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = 1
    }else{
      byData.merchant_id = props.user[0].id
    }
    setFilterInput(byData);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
            setMerchantRemittance(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
   
  
  // copy text
  const copied = (text) =>{
    let decision = copyToClipBoard(text);
    decision.then(function(result) {
      if(result === true){
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const exportRemmitance = () => {
    if(checkData(filterInput)){      
      let data = filterInput
      if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      data.is_export=1
      setLoader(true);
      console.log("filterinput",filterInput);
      
      axios.post(process.env.REACT_APP_MERCHANT_REMITTANCE_LIST, data)
    .then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
              setLoader(false);
              if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setLoader(false);
    });
  }
  }
  const onFilter = () => setIsOpen(!isOpen);

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
	   
        <Card className="p-0 my-3 master-content content-wrapper border-0">
         <div className="page-header content-header">

		<div className="card-title title_icon">
		<i className="bi bi-card-heading"></i> 
		{(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") ? "Merchant COD Remittance":"Manage COD Remittance"}   

		<div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
			<div className="btn-text btn-light mx-2" onClick={exportRemmitance}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Remittance</span></div>            
			<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
		</div>
		</div>	  
		  
              <Collapse isOpen={isOpen}>
                <Form onSubmit={filtermerchRemitSubmit(onFilterMerchRemit)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value="">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value= "customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text" className="form-control-sm form-control"
                          placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>   
                    {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect
                              options={opt_merchants}
                              value={merchants}
                              onChange={setMerchants}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                            />
                        </FormGroup>
                    </Col>}
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={(e) => setAllCourier(e)}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>                  
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("pay_status")} className="form-control-sm form-control">
                              <option value="">Payment Status</option>
                              <option value="1">Paid</option>
                              <option value="0">Unpaid</option>
                            </select>
                        </div>
                        </FormGroup>
                    </Col>                  
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" placement="bottomEnd" format="DD-MM-YYYY" ref={dateRangeRef} change={onDateChange} /> */}
                          <DateRangePicker
                                initialSettings={ranges}
                                onCallback={handleCallback}
                              >
                              <div className='form-control cursor-pointer d-flex align-items-center'>
                                {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                              </div>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick = {remittanceListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              </Collapse>
             
              <CardBody className="p-0">
              {checkData(merchantRemittance) && checkData(merchantRemittance.data) && checkUndeNullBlank(merchantRemittance.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(merchantRemittance.data).length)} of {merchantRemittance.TotalRows} entries
                    </div>
                      {merchantRemittance.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                      <th className="text-nowrap">Merchant Name</th>
                      }
                      <th>Transacton</th>
                      <th>Amount</th>
                      <th>Delivery Date</th>
                      <th>AWB NO</th>                      
                      <th>Order Uploaded</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(merchantRemittance.data) ? merchantRemittance.data.map((item, index) => (
                    <tr>
                      {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                        <td>{item.MerchantName}</td>
                      }
                      <td>
                      <div className="list-content">
                            <div className="">
                              <p className="mb-0"> <span className="font-weight-bold"></span> {item.bank_ref_no || 'NA'}</p><br></br>                        
                              <p className="mb-0"> <span className="font-weight-bold"> </span>  {item.trans_amt || 'NA'}</p><br></br>                           
                              <p className="mb-0"> <span className="font-weight-bold"></span> {changeIntoDateTime(item.trans_date)}</p>                             
                            </div>
                          </div>
                      </td>
                      <td>{item.cod_value}</td>
                      <td>{changeIntoDateYmd(item.order_delivered_date)}</td> 
                      <td>
                      <div className="list-group-item border-0 p-0 d-inline-flex align-items-center">
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{item.CourierName}</p>                             
                              <p className="mb-0">
                                <span>{item.awb_no}</span>
                                <span onClick={() => copied(item.awb_no)} className="ml-25 copy-btn d-none" id={`copy${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </p>                             
                            </div>
                          </div>
                        </div>
                      </td>                      
                      <td>{changeIntoDateTime(item.created_at)}</td>
                    </tr>        
                  )) :loadertable ? <tr><td colSpan="5">Loading...</td></tr> :
                  <tr><td colSpan="5">No Data Found</td></tr> }
                </tbody>
                </Table>
                <ToastContainer style={{width: "200px"}}/>
                {checkData(merchantRemittance) && checkData(merchantRemittance.data) && checkUndeNullBlank(merchantRemittance.TotalRows) ?
                <>
                {merchantRemittance.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(merchantRemittance.data).length)} of {merchantRemittance.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
			   </div>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}
function mapDispatchToProps (state) {
  return{
  state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(MerchantRemittance);