import React from 'react';
import PendingSummary from './PendingSummary';
import PickupPendingShipments from './PickupPendingShipments';
import NotAttemptedShipments from './NotAttemptedShipments';

const PendingShipments = (props) => {

    return (
        <div className="PendingShipments">
            <div className="mb-4"><PendingSummary user={props.user} /></div>
            <div className="mb-4"><PickupPendingShipments user={props.user} /></div>
            <div className="mb-4"><NotAttemptedShipments user={props.user} /></div>
        </div>
    );
}

export default PendingShipments;