import React, {useState, useEffect} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Collapse} from 'reactstrap';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';

const KYCDetail = (props) => {

    const [isIndividual, setIsIndividual] = useState(true);
    const [isCompany, setIsCompany] = useState(false);
    const [gstCopy, setGstCopy] = useState('');
    const [panCopy, setPanCopy] = useState('');
    const [adharCopy, setAdharCopy] = useState('');
    const [kycDetails, setKycDetails] = useState({});
    const [formDisabled, setFormDisabled] = useState(false);
    

    const toggleIndividual = () => {
        setIsIndividual(!isIndividual);
        if(isCompany){
            setIsCompany(!isCompany);
        }
    };
    const handleFileInputpan = (e) => {
        setPanCopy(e.target.files[0])
    }
    const handleFileInputAdhar = (e) => {
        setAdharCopy(e.target.files[0])
    }
      const handleFileInputgst = (e) => {
        setGstCopy(e.target.files[0])
    }
    const toggleCompany = () => {
        setIsCompany(!isCompany);
        if(isIndividual){
            setIsIndividual(!isIndividual);
        }       
    };
    const { register:regulatory, handleSubmit:kycSubmit, formState: { errors: errors3 }, reset:reset3 } = useForm();
    useEffect(() =>{
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_REGULATRYDETAILS}/view`,
            data: {"merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:checkUndeNullBlank(props.merchantId) ? props.merchantId:''},
          })
            .then(function (response) {
              let myResponse=response.data[0]; 
              if(checkData(myResponse)){
                setFormDisabled(true);
              if(myResponse.merchant_type === 2){
                  setIsCompany(true);
                  setIsIndividual(false);
            } else{
                setIsCompany(false);
                setIsIndividual(true);
            }  
            myResponse.merchant_type=String(myResponse.merchant_type) 
        }else{
            reset3({'merchant_type':'1'});
        }       
              reset3(myResponse);
              setKycDetails(myResponse);
              props.setKycDetails !== undefined && props.setKycDetails(myResponse);
            })
            .catch(function (err) {
                Swal.fire({icon:"error", title:"Error", text:err})
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset3])
    const onKyc = (data) => {
        let formData = new FormData();
        formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:checkUndeNullBlank(props.merchantId)?props.merchantId:'');
        if(data.merchant_type === '2'){
        formData.append("pan_card_no", data.pan_card_no);
        formData.append("pan_card", panCopy !== '' ? panCopy : kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" ? kycDetails.pan_card : 'NA');
        formData.append("gst_number", data.gst_number);
        formData.append("gst_file", gstCopy !== '' ? gstCopy : kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" ? kycDetails.gst_file : 'NA');
        }else{
            formData.append("adhar_card_no", data.adhar_card_no);
            formData.append("adhar_card_file", adharCopy !== '' ? adharCopy : kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== "" ? kycDetails.adhar_card_file : 'NA');
        }
        formData.append("merchant_type", data.merchant_type);
        axios
          .post(process.env.REACT_APP_MERCHANT_REGULATRYDETAILS, formData)
          .then((res) => {
            if(res.data.error === false){
              Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
              })
            }
            if(res.data.error === true){
              Swal.fire({
                title: 'Error',
                text: res.data.msg,
                icon: 'error',
              })
            }
          })
          .catch((err) => Swal.fire({title:'Error', text:'Something went wrong', icpon:'error'}));
      };

    return (
        <Form onSubmit={kycSubmit(onKyc)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">KYC Detail</CardTitle>
            </div>
            <Row>
                <Col sm={12}>
                    <FormGroup>
                        <div className="d-flex">
                            <div className="mr-4">
                                <Label>
                                <input type="radio" disabled={formDisabled} className="mx-2" {...regulatory("merchant_type")} value="1"  onClick={toggleIndividual} />
                                    Individual
                                </Label>
                            </div>
                            <div>
                                <Label >
                                <input type="radio" disabled={formDisabled} className="mx-2" {...regulatory("merchant_type")} value="2"  onClick={toggleCompany} />
                                    Company
                                </Label>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
                <Collapse isOpen={isCompany}>
                    {isCompany && 
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="gst_no">GST Number</Label>
                                <input disabled={formDisabled} {...regulatory("gst_number", { required: 'Please Enter GST Number'})} className="form-control" placeholder="Company Name" id="gst_no" />
                          {errors3.gst_number && <span className="text-danger d-block error">{errors3.gst_number.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="gst_img">Upload GST image</Label>
                                <input disabled={formDisabled} {...regulatory("gst_files", { required: kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" ? false: true && 'Please Add GST Copy'})} type="file" onChange={handleFileInputgst} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadgst" />
                                {kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== null && kycDetails.gst_file !== "" && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT+kycDetails.gst_file} alt="logo" style={{width:"120px"}} /></div>}
                          {errors3.gst_files && <span className="text-danger d-block error">{errors3.gst_files.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="pan_no">Pan Card No.</Label>
                                <input disabled={formDisabled} {...regulatory("pan_card_no",{ required: 'Please Enter Pan Card Number'})} className="form-control" placeholder="Company Name" id="pan_no" />
                                {errors3.pan_card_no && <span className="text-danger d-block error">{errors3.pan_card_no.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="gst_img">Upload Pan Card</Label>
                                <input disabled={formDisabled} {...regulatory("pan_cards", { required: kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" ? false: true && 'Please Add Pan Card Copy'})} type="file" onChange={handleFileInputpan} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadPan" />
                                {kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== null && kycDetails.pan_card !== "" && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT+kycDetails.pan_card} alt="logo" style={{width:"120px"}} /></div>}
                                {errors3.pan_cards && <span className="text-danger d-block error">{errors3.pan_cards.message}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                    }
                </Collapse>
                <Collapse isOpen={isIndividual}>
                    {isIndividual &&
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="adhar_card_no">Aadhar Card No.</Label>
                                <input disabled={formDisabled} {...regulatory("adhar_card_no", { required: 'Please Enter Aadhar Card No.',
                                pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Aadhar Card No.'}})} className="form-control" placeholder="Company Name" id="adhar_card_no" />
                                {errors3.adhar_card_no && <span className="text-danger d-block error">{errors3.adhar_card_no.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="adhar_card_file">Upload Aadhar Card</Label>
                                <input disabled={formDisabled} {...regulatory("adhar_card_files", { required: kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== "" ? false: true && 'Please Add Aadhar Card Copy'})} type="file" accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadAdhar" onChange={handleFileInputAdhar} />
                                {kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== null && kycDetails.adhar_card_file !== '' && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT+kycDetails.adhar_card_file} alt="logo" style={{width:"120px"}} /></div>}
                                {errors3.adhar_card_files && <span className="text-danger d-block error">{errors3.adhar_card_files.message}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                    }
                </Collapse>               

                {!formDisabled &&
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="btn-sm" color="primary"> Save changes</Button>
                </Col>  
            }          
            </Row>
        </Form>
    );
}

export default KYCDetail;